import { createSlice } from "@reduxjs/toolkit";

export const itemForEditBasicSlice = createSlice({
  name: "itemForEditBasicSlice",
  initialState: {
    itemForEditBasic: null,
  },
  reducers: {
    setItemForEditBasic: (state, action) => {
      return { ...state, itemForEditBasic: action.payload };
    },
  },
});

export const { setItemForEditBasic } = itemForEditBasicSlice.actions;

export default itemForEditBasicSlice.reducer;
