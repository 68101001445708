import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../Fetch/axios";
import { toast } from "react-toastify";
import i18n from "i18next";
import { itemForItemInvtURL } from "../Data/baseURL";

export const getItem = createAsyncThunk(
  "itemForItemInvSlice/getItem",
  async (id) => {
    return await axiosInstance({
      method: "GET",
      url: itemForItemInvtURL + id + "/",
    }).then((response) => {
      if (response.data) return response.data;
    });
  }
);

export const getItems = createAsyncThunk(
  "itemForItemInvSlice/getItems",
  async (route) => {
    return axiosInstance.get(itemForItemInvtURL).then((response) => {
      if (response.data) {
        route !== "/" &&
          toast.success(i18n.t("toast.invoice_items_list_updated"));
        return response.data;
      }
    });
  }
);

export const itemForItemInvSlice = createSlice({
  name: "itemForItemInvSlice",
  initialState: {
    itemForItemInv: {},
    itemsForItemInv: [],
    statusItemsForItemInv: null,
    statusItem: null,
    refreshStatus: false,
  },
  reducers: {
    setItems: (state, action) => {
      return { ...state, itemsForItemInv: [...action.payload] };
    },
    setInvItem: (state, action) => {
      return { ...state, itemForItemInv: { ...action.payload } };
    },
    addOrEditItem: (state, { payload: values }) => {
      const i = state.itemsForItemInv.findIndex(
        (item) => item.id === values.id
      );
      if (i > -1) state.itemsForItemInv[i] = values;
      else state.itemsForItemInv.push(values);
      return state;
    },
    deleteItemForItemInv: (state, { payload }) => {
      const newItems = state.itemsForItemInv.filter(
        (item) => item.id !== payload
      );

      return { ...state, itemsForItemInv: [...newItems] };
    },
  },
  extraReducers: {
    [getItems.pending]: (state) => {
      state.statusItemsForItemInv = "loading";
    },
    [getItems.fulfilled]: (state, action) => {
      state.itemsForItemInv = action.payload;
      state.statusItemsForItemInv = "loaded";
    },
    [getItems.rejected]: (state) => {
      state.statusItemsForItemInv = "failed";
    },

    [getItem.pending]: (state) => {
      state.statusItem = "loading";
    },
    [getItem.fulfilled]: (state, action) => {
      return {
        ...state,
        itemForItemInv: { ...action.payload },
        statusItem: "loaded",
      };
    },
    [getItem.rejected]: (state) => {
      state.statusItem = "failed";
    },
  },
});

export const { setItems, setInvItem, addOrEditItem, deleteItemForItemInv } =
  itemForItemInvSlice.actions;

export default itemForItemInvSlice.reducer;
