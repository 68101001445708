import React from "react";
import { useEffect, forwardRef, useImperativeHandle } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../Fetch/axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
////// Mui
import { Container, Grid } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
////// Store
import { addOrEditItem, deleteBankAccount } from "../../Store/bankAccountSlice";
import { setFormSimpleTouched } from "../../Store/formTouchedSlice";
import { setItemForEditBasic } from "../../Store/itemForEditBasicSlice";
import { setLoadingStatus } from "../../Store/loadingStatusSlice";
import { setPopup } from "../../Store/popupSlice";
////// Controls & Components
import { bankAccountURL } from "../../Data/baseURL";
import ButtonsContainer from "../../Controls/Buttons/ButtonsContainer";
import { initialValuesBankAccount } from "../Tables/InitialValues/initialValuesBankAccount";
import SelectBasic from "../../Controls/FormsUI/SelectBasic";
import TextFieldNormal from "../../Controls/Inputs/TextFieldNormal";

const validationSchema = Yup.object({
  currencycode: Yup.object()
    .shape()
    .typeError(() => i18n.t("yup.required"))
    .required(() => i18n.t("yup.required")),
  iban_number: Yup.string()
    .required(() => i18n.t("yup.required"))
    .max(50, () => i18n.t("yup.max_50_characters")),
  bank_name: Yup.string()
    .required(() => i18n.t("yup.required"))
    .max(150, () => i18n.t("yup.max_150_characters")),
  bank_code: Yup.string()
    .required(() => i18n.t("yup.required"))
    .max(30, () => i18n.t("yup.max_30_characters")),
  bank_address: Yup.string()
    .max(250, () => i18n.t("yup.max_250_characters"))
    .nullable(),
});

const BankAccount = forwardRef((props, ref) => {
  const { addOrEdit } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const lgMediaDown = useMediaQuery(theme.breakpoints.down("lg"));
  const [formValues, setFormValues] = React.useState(null);
  const { itemForEditBasic } = useSelector(
    (state) => state.itemForEditBasicSlice
  );

  const FormValues = () => {
    const { values, touched } = useFormikContext();
    useEffect(() => {
      if (values) {
        setFormValues(values);
      }
      if (Object.keys(touched).length !== 0 && values.id === undefined) {
        dispatch(setFormSimpleTouched(true));
      }
      if (
        Object.keys(touched).length !== 0 &&
        itemForEditBasic !== null &&
        values.id !== ""
      ) {
        dispatch(setFormSimpleTouched(true));
      }
    }, [values, touched]);
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: () => handleSubmit(),
  }));

  const handleSubmit = () => {
    batch(() => {
      dispatch(setItemForEditBasic(formValues));
      dispatch(setLoadingStatus(true));
    });

    if (!formValues.fieldName) {
      axiosInstance({
        method: formValues.id ? "PUT" : "POST",
        url: formValues.id
          ? bankAccountURL + formValues.id + "/"
          : bankAccountURL,
        data: formValues,
      })
        .then((response) => {
          if (response.data) {
            batch(() => {
              dispatch(setLoadingStatus(false));
              dispatch(setFormSimpleTouched(false));
              dispatch(
                addOrEditItem({
                  ...response.data,
                  fieldName: itemForEditBasic
                    ? itemForEditBasic.fieldName
                    : null,
                  index: itemForEditBasic ? itemForEditBasic.index : null,
                })
              );
            });
            addOrEdit(response.data);
            toast.success(() =>
              t(
                formValues.id
                  ? "toast.record_successfully_updated"
                  : "toast.record_successfully_created"
              )
            );
          }
        })
        .catch((error) => {
          dispatch(setLoadingStatus(false));
          error.response &&
            error.response.status === 400 &&
            toast.error(t("toast.record_could_not_be_created_or_updated"));
          error.response &&
            error.response.status === 404 &&
            toast.error(t("toast.record_not_found"));
        }, []);
    } else {
      addOrEdit(formValues);
      batch(() => {
        dispatch(setLoadingStatus(false));
        dispatch(setItemForEditBasic(null));
      });
    }
  };

  const handleDelete = () => {
    if (formValues.id) {
      dispatch(setLoadingStatus(true));
      axiosInstance({
        method: "DELETE",
        url: bankAccountURL + formValues.id + "/",
      })
        .then((response) => {
          batch(() => {
            dispatch(setPopup(false));
            dispatch(setFormSimpleTouched(false));
            dispatch(deleteBankAccount(formValues.id));
          });
          response.status === 204 &&
            toast.success(t("toast.record_successfully_deleted"));
        })
        .catch((error) => {
          dispatch(setLoadingStatus(false));
          error.response &&
            error.response.status === 400 &&
            toast.error(t("toast.record_could_not_be_created_or_updated"));
        }, []);
    } else {
      toast.error(t("toast.record_not_found"));
    }
  };

  return (
    <Container maxWidth='md' disableGutters={lgMediaDown ? true : false}>
      <Formik
        enableReinitialize
        initialValues={itemForEditBasic || initialValuesBankAccount}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => {
          return (
            <Form autoComplete='off'>
              <Grid container item spacing={2} sx={{ mt: 3, mb: 5 }}>
                <Grid item md={6} xs={12}>
                  <SelectBasic
                    name='currencycode'
                    label={t("column.currency_code")}
                    required
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextFieldNormal
                    name='iban_number'
                    label={t("column.iban")}
                    required
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextFieldNormal
                    name='bank_name'
                    label={t("column.bank_name")}
                    required
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextFieldNormal
                    name='bank_code'
                    label={t("column.bank_code")}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldNormal
                    name='bank_address'
                    label={t("bank_account.bank_address_optional")}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 3 }}>
                  <ButtonsContainer
                    values={values}
                    handleDelete={() => handleDelete()}
                  />
                </Grid>
              </Grid>
              <FormValues />
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
});

export default BankAccount;
