import React, { useEffect, useState } from "react";
import Downshift from "downshift";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
////// Mui
import { Chip, TextField } from "@mui/material";

const ChipsInput = (props) => {
  const { selectedTags, placeholder, tags, ...other } = props;
  const [inputValue, setInputValue] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  const { setFieldValue } = useFormikContext();

  const updatedTags = React.useCallback(() => {
    other.field &&
      other.field.name &&
      setFieldValue(other.field.name, selectedItems);
  }, [setFieldValue, other.field, selectedItems]);

  useEffect(() => {
    updatedTags();
  }, [updatedTags]);

  useEffect(() => {
    tags.length > 0 && setSelectedItems(tags);
  }, [tags]);

  useEffect(() => {
    selectedTags(selectedItems);
  }, [selectedItems, selectedTags]);

  function handleOnBlur(event) {
    const newSelectedItem = [...selectedItems];
    const duplicatedValues = newSelectedItem.indexOf(event.target.value.trim());

    if (duplicatedValues !== -1) {
      setInputValue("");
      return;
    }
    if (!event.target.value.replace(/\s/g, "").length) return;

    newSelectedItem.push(event.target.value.trim());
    setSelectedItems(newSelectedItem);
    setInputValue("");
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      const newSelectedItem = [...selectedItems];
      const duplicatedValues = newSelectedItem.indexOf(
        event.target.value.trim()
      );

      if (duplicatedValues !== -1) {
        setInputValue("");
        return;
      }
      if (!event.target.value.replace(/\s/g, "").length) return;

      newSelectedItem.push(event.target.value.trim());
      setSelectedItems(newSelectedItem);
      setInputValue("");
    }
    if (event.key === "Tab") {
      const newSelectedItem = [...selectedItems];
      const duplicatedValues = newSelectedItem.indexOf(
        event.target.value.trim()
      );

      if (duplicatedValues !== -1) {
        setInputValue("");
        return;
      }
      if (!event.target.value.replace(/\s/g, "").length) return;

      newSelectedItem.push(event.target.value.trim());
      setSelectedItems(newSelectedItem);
      setInputValue("");
    }

    if (
      selectedItems.length &&
      !inputValue.length &&
      event.key === "Backspace"
    ) {
      setSelectedItems(selectedItems.slice(0, selectedItems.length - 1));
    }
  }

  function handleChange(item) {
    let newSelectedItem = [...selectedItems];
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item];
    }
    setInputValue("");
    setSelectedItems(newSelectedItem);
  }

  const handleDelete = (item) => () => {
    const newSelectedItem = [...selectedItems];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    setSelectedItems(newSelectedItem);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <Downshift
      inputValue={inputValue}
      onChange={handleChange}
      selectedItem={selectedItems}
    >
      {({ getInputProps }) => {
        const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
          onKeyDown: handleKeyDown,
          onBlur: handleOnBlur,
          placeholder,
        });
        return (
          <div>
            <TextField
              size='small'
              InputProps={{
                startAdornment: selectedItems.map((item) => {
                  return (
                    <Chip
                      key={item}
                      tabIndex={-1}
                      label={item}
                      color='success'
                      variant='outlined'
                      sx={{ m: 0.5 }}
                      onDelete={handleDelete(item)}
                    />
                  );
                }),
                onBlur,
                onChange: (event) => {
                  handleInputChange(event);
                  onChange(event);
                },
                onFocus,
              }}
              FormHelperTextProps={{
                sx: {
                  color: "error.main",
                  fontSize: 15,
                  fontWeight: "medium",
                },
              }}
              {...other}
              {...inputProps}
            />
          </div>
        );
      }}
    </Downshift>
  );
};

ChipsInput.defaultProps = {
  tags: [],
};
ChipsInput.propTypes = {
  selectedTags: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
};

export default ChipsInput;
