import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

////// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 80,
    paddingLeft: 47,
    paddingRight: 35,
    // size: "A4",
  },
  boxM: {
    marginBottom: 2,
    justifyContent: "flex-start",
    width: 269,
  },
  boxXL: {
    marginTop: 10,
    flexDirection: "row",
    marginBottom: 2,
    justifyContent: "flex-start",
    width: 538,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  line: {
    strokeWidth: 2,
    color: "black",
  },
  rowContainer: {
    flexDirection: "row",
    marginBottom: 3,
    justifyContent: "flex-start",
    width: "535",
  },
  rowContainerBasic: {
    flexDirection: "row",
    marginTop: 1,
    marginBottom: 1,
    justifyContent: "flex-start",
  },
  orderDate: {
    fontSize: 12,
    fontStyle: "bold",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 16,
    textAlign: "right",
    color: "black",
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Roboto",
  },
  text1: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Roboto",
  },
  text2: {
    fontSize: 12,
    textAlign: "left",
    fontFamily: "Roboto",
  },
  text3: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    // fontWeight: "bold",
    width: 177,
  },
  text4: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    // fontWeight: "bold",
    width: 358,
  },
  titleM: {
    fontSize: 12,
    textAlign: "left",
    fontFamily: "Roboto",
    marginBottom: 4,
  },
  titleXL: {
    flexDirection: "row",
    fontSize: 14,
    textAlign: "left",
    fontFamily: "Roboto",
    marginBottom: 2,
  },
});

const InvPdfLoadDetails = (props) => {
  const { values, invoicePdfLang } = props;

  return (
    <View style={styles.boxXL}>
      <View style={styles.boxM}>
        <View style={{ ...styles.rowContainer, height: 21 }}>
          <Text style={styles.text3}>
            {invoicePdfLang === "RO/RU"
              ? "Informatii suplim./ Допол. информация:"
              : "Additional details:"}
          </Text>
          <Text style={styles.text4}>
            {values && (values.load_detail || "")}
          </Text>
        </View>

        <View style={styles.rowContainer}>
          <Text style={styles.text3}>
            {invoicePdfLang === "RO/RU"
              ? "Punct incarcare / Пункт погрузки:"
              : "Place of loading:"}
          </Text>
          <Text style={{ ...styles.text4, width: 227 }}>
            {values && (values.load_address || "")}
          </Text>
          <Text style={{ ...styles.text4, width: 3 }}></Text>
          <Text style={{ ...styles.text3, width: 73 }}>
            {invoicePdfLang === "RO/RU"
              ? "Data incarcare: Дата погрузки"
              : "Loading date:"}
          </Text>
          <Text style={{ ...styles.text4, width: 55 }}>
            {values.date_load &&
              new Date(values.date_load).toLocaleDateString("fr-EN")}
          </Text>
        </View>

        <View style={styles.rowContainer}>
          <Text style={styles.text3}>
            {invoicePdfLang === "RO/RU"
              ? "Punct descarcare / Пункт разгрузки:"
              : "Place of unloading:"}
          </Text>
          <Text style={{ ...styles.text4, width: 227 }}>
            {values && (values.unload_address || "")}
          </Text>
          <Text style={{ ...styles.text3, width: 3 }}></Text>
          <Text style={{ ...styles.text3, width: 73 }}>
            {invoicePdfLang === "RO/RU"
              ? "Data descarcare: Дата разгрузки"
              : "Unloading date:"}
          </Text>
          <Text style={{ ...styles.text4, width: 55 }}>
            {values.date_unload &&
              new Date(values.date_unload).toLocaleDateString("fr-EN")}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default InvPdfLoadDetails;
