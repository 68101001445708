import React from "react";
import { WebSocketContext } from "./websocket";

export const useSocket = () => {
  const wsClient = React.useContext(WebSocketContext);

  return wsClient;
};

export default useSocket;
