import React from "react";
import { useSelector } from "react-redux";
import { Box, LinearProgress } from "@mui/material";

const LinearProgressWrapper = () => {
  const { loadingStatus } = useSelector((state) => state.loadingStatusSlice);

  return (
    <Box sx={{ width: "100%" }}>
      {loadingStatus === true ? (
        <LinearProgress />
      ) : (
        <LinearProgress variant="determinate" value={100} />
      )}
    </Box>
  );
};

export default LinearProgressWrapper;
