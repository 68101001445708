import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
///// Mui
import { styled } from "@mui/material/styles";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { Typography, Avatar, Divider } from "@mui/material";
import { useTheme, useScrollTrigger, Fade, Fab } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
////// Controls & Components
import Copyright from "../Apm/Other/Copyright";
import MetaDecorator from "../Apm/Other/MetaDecorator";
import base from "../Data/MetaData/base.json";
import ButtonRegister from "../Controls/Buttons/ButtonRegister";
import SubscriptionTypes from "../Apm/Faq/SubscriptionTypes";
import Integration1c from "../Different/integration1c";
import HowItWorks from "./HowItWorks";
import AboutUs from "../Different/AboutUs";
import { android, apple, windows, element } from "../Data/imageURL";
import { background2, ContDePlata_Logo } from "../Data/imageURL";
import { component1 } from "../Data/imageURL";
import securityImage from "../Data/Images/SecurityImage.svg";
import workTogether from "../Data/Images/work_together.svg";
import hero_bg from "../Data/Images/hero-bg.webp";
import generateInvoices from "../Data/Images/generateinvoices.webp";
import Footer from "../Apm/Other/Footer";

const TypographyMain = styled(Typography)(({ theme, lgmedia, white }) => ({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 700,

  lineHeight: lgmedia === "true" ? "60px" : "77px",
  letterSpacing: "-0.02em",
  color: white === "white" ? "#FFFFFF" : "#00000",
  pt: "130px",
  [theme.breakpoints.down("sm")]: { lineHeight: "50px" },
}));

const TypographySecondary = styled(Typography)(({ white }) => ({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "30px",
  letterSpacing: "-0.02em",
  color: white === "white" ? "#FFFFFF" : "#00000",
}));

const BaseBox = styled(Box)(({ theme, blue, mdmedia }) => ({
  display: "flex",
  position: "relative",
  overflow: "hidden",
  // maxWidth: "1300px",
  marginLeft: "auto",
  marginRight: "auto",
  flexDirection: mdmedia === "true" ? "column" : "row",
  // justifyContent: "center",
  // alignItems: "center",
  backgroundColor: blue === "blue" ? theme.palette.baseblue.main : "#FFFFFF",
  height: "auto",
  // pt: "100px",
  pb: mdmedia === "true" ? "60px" : null,
  // gap: "60px",
  // boxSizing: "border-box",
}));

const Base = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const xsMediaMax500 = useMediaQuery("(max-width:500px)");
  const mdMediaMax900 = useMediaQuery("(max-width:900px)");
  const lgMediaMax1200 = useMediaQuery("(max-width:1200px)");
  const { authLogIn, statusLogIn } = useSelector(
    (state) => state.authLogInSlice
  );

  function ScrollTop(props) {
    const { children } = props;

    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 100,
    });

    const handleClick = (event) => {
      const anchor = (event.target.ownerDocument || document).querySelector(
        "#overview"
      );

      if (anchor) {
        anchor.scrollIntoView({
          block: "center",
        });
      }
    };

    return (
      <Fade in={trigger}>
        <Box
          onClick={handleClick}
          role="presentation"
          sx={{ position: "fixed", bottom: 20, right: 20 }}
        >
          {children}
        </Box>
      </Fade>
    );
  }

  const px = { xs: "30px", md: "60px" };
  const plImage = { xs: "0px", md: "50px" };
  const mainFontSize = { xs: "40px", md: "60px", lg: "72px" };

  return (
    <React.Fragment>
      <MetaDecorator
        description={base.pageDescriptionRo}
        title={base.pageTitle}
        imageUrl={ContDePlata_Logo}
        imageAlt={base.pageDescriptionRo}
        rel="https://contdeplata.md/"
      />
      <Box
        sx={{
          backgroundColor: "#FFF",
          width: "100%",
          maxWidth: "2200px",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: "auto",
          boxSizing: "content-box",
          "&.MuiContainer-root": { padding: 0 },
        }}
      >
        {statusLogIn === "notAuthorized" && !authLogIn && (
          <>
            <BaseBox
              blue="blue"
              theme={theme}
              mdmedia={mdMediaMax900.toString()}
              id="overview"
            >
              {/* Hero component */}
              <Grid
                container
                item
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  maxWidth: "1300px",
                  mx: "auto",
                  height: "auto",
                  mb: { xs: "30px", md: "100px", lg: "190px" },
                  mt: { xs: "60px", md: "120px", lg: "180px" },
                  px: { xs: "30px", md: "60px" },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={7}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    // paddingTop: lgMediaMax1200 ? "50px" : null,
                    // gap: lgMediaMax1200 ? "30px" : "60px",

                    // zIndex: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <TypographyMain
                    component="h1"
                    lgmedia={lgMediaMax1200.toString()}
                    white="white"
                    sx={{ maxWidth: "630px", fontSize: mainFontSize }}
                  >
                    {t("base.title_hero")}
                  </TypographyMain>
                  <TypographySecondary
                    component="h2"
                    white="white"
                    sx={{ pt: "24px", maxWidth: "630px" }}
                  >
                    {xsMediaMax500
                      ? t("base.subtitle_hero_mobile")
                      : t("base.subtitle_hero")}
                  </TypographySecondary>
                  <ButtonRegister
                    variant="contained"
                    component={NavLink}
                    to="/apps/signup"
                    sx={{ mt: "60px" }}
                  >
                    {t("button.signup_now")}
                  </ButtonRegister>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={5}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      height: "100%",
                      width: "100%",
                      maxWidth: "500px",
                      maxHeight: "500px",
                      alignSelf: "center",
                      pl: { xs: 0, md: "50px" },
                    }}
                  >
                    <img
                      src={hero_bg}
                      alt="Abstract"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </BaseBox>

            {/* 1 component */}
            <BaseBox theme={theme} mdmedia={mdMediaMax900.toString()}>
              <Grid
                id="characteristics"
                container
                item
                sx={{
                  maxWidth: "1300px",
                  mx: "auto",
                  pb: { xs: "30px", sm: "50px", md: "100px" },
                  // pt: { xs: "60px", md: "90px" },
                  px: { xs: "30px", md: "60px" },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: 0,
                    gap: lgMediaMax1200 ? "30px" : "60px",
                    // height: "575px",
                    // width: "656px",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "100%",
                      height: "auto",
                      alignSelf: "center",
                      width: "100%",
                      maxWidth: "550px",
                      // pr: { xs: 0, md: "10%" },
                    }}
                  >
                    <img
                      src={component1}
                      alt="3rd abastract"
                      style={{
                        height: "auto",
                        objectFit: "contain",
                        borderRadius: "10px",
                        alignSelf: "center",
                        zIndex: 1,
                      }}
                    ></img>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    boxSizing: "border-box",
                    position: "static",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    gap: lgMediaMax1200 ? "30px" : "60px",
                    width: "100%",
                    // maxWidth: "1300px",
                    mx: "auto",
                    // mb: { xs: "0px", md: "190px" },
                    pt: { xs: "60px", md: "90px" },
                    px: { xs: "0px", md: 0 },
                    pl: plImage,
                  }}
                >
                  <Box>
                    <TypographyMain
                      component="h2"
                      lgmedia={lgMediaMax1200.toString()}
                      sx={{ fontSize: mainFontSize }}
                    >
                      {t("base.title_first_component")}
                    </TypographyMain>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        pt: "10px",
                        width: "100%",
                        maxWidth: "400px",
                      }}
                    >
                      <img
                        src={element}
                        alt="line element"
                        style={{ width: "100%" }}
                      />
                    </Box>
                  </Box>
                  <TypographySecondary component="h2">
                    {t("base.subtitle_first_component")}
                  </TypographySecondary>
                </Grid>
              </Grid>
            </BaseBox>

            {/* 2nd Work in Team */}
            <BaseBox theme={theme} mdmedia={mdMediaMax900.toString()}>
              <Grid
                container
                item
                sx={{
                  // pb: 0,
                  maxWidth: "1300px",
                  mx: "auto",
                  // pt: { xs: "30px", md: "90px" },
                  mb: { xs: "90px", md: "120px" },
                  px: { xs: "30px", md: "60px" },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    position: "static",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    gap: lgMediaMax1200 ? "30px" : "60px",
                    // width: "656px",
                  }}
                >
                  <Box>
                    <TypographyMain
                      component="h2"
                      lgmedia={lgMediaMax1200.toString()}
                      sx={{ fontSize: mainFontSize }}
                    >
                      {t("base.title_work_in_team")}
                    </TypographyMain>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        pt: "10px",
                        width: "100%",
                        maxWidth: "400px",
                      }}
                    >
                      <img
                        src={element}
                        alt="line element"
                        style={{ width: "100%" }}
                      />
                    </Box>
                  </Box>
                  <TypographySecondary component="h2">
                    {t("base.subtitle_work_in_team")}
                  </TypographySecondary>
                  <ButtonRegister
                    variant="contained"
                    component={NavLink}
                    to="/apps/signup"
                  >
                    {t("button.signup_now")}
                  </ButtonRegister>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    position: "static",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    gap: "60px",
                    width: "656px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      maxWidth: "550px",
                      height: "auto",
                      alignSelf: "center",
                      pl: { xs: 0, md: "10%" },
                      pt: { xs: "60px", md: "0px" },
                    }}
                  >
                    <img
                      src={workTogether}
                      alt="work_together"
                      style={{
                        zIndex: 1,
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                        borderRadius: "10px",
                        alignSelf: "center",
                      }}
                    ></img>
                  </Box>
                </Grid>
              </Grid>
            </BaseBox>

            {/* 1C intregration */}
            <BaseBox
              blue="blue"
              theme={theme}
              mdmedia={mdMediaMax900.toString()}
            >
              <Grid
                container
                item
                sx={{
                  maxWidth: "1300px",
                  mx: "auto",
                  mt: "90px",
                  mb: "120px",
                  px: px,
                }}
              >
                <Integration1c px={px} mainFontSize={mainFontSize} />
              </Grid>
            </BaseBox>

            {/* 3 Customs invoice  */}
            <BaseBox theme={theme} mdmedia={mdMediaMax900.toString()}>
              <Grid
                container
                item
                sx={{
                  maxWidth: "1300px",
                  mx: "auto",
                  pt: { xs: "60px", md: "90px" },
                  pb: { xs: "60px", md: "120px" },
                  px: px,
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: 0,
                    gap: lgMediaMax1200 ? "30px" : "60px",
                    height: "auto",
                    // width: "656px",
                    justifyContent: "center",
                    // px: px,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "auto",
                      alignSelf: "center",
                      pr: { xs: 0, md: "10%" },
                      maxWidth: "500px",
                    }}
                  >
                    <img
                      src={generateInvoices}
                      alt="print_invoice"
                      style={{
                        zIndex: 1,
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                        borderRadius: "10px",
                        alignSelf: "center",
                        backgroundColor: "rgba(255, 255, 255, 0.97)",
                      }}
                    ></img>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    // position: "static",
                    flexDirection: "column",
                    padding: 0,
                    alignItems: "flex-start",
                    justifyContent: "center",
                    // width: "656px",
                    gap: lgMediaMax1200 ? "30px" : "60px",
                    height: "auto",
                    boxSizing: "content",
                    pl: { md: "50px" },
                    pt: { xs: "30px", md: 0 },
                    // mx: px,
                  }}
                >
                  <div>
                    <TypographyMain
                      component="h2"
                      lgmedia={lgMediaMax1200.toString()}
                      sx={{ fontSize: mainFontSize }}
                    >
                      {t("base.title_customs_invoices")}
                    </TypographyMain>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        pt: "10px",
                        width: "100%",
                        maxWidth: "400px",
                      }}
                    >
                      <img
                        src={element}
                        alt="line element"
                        style={{ width: "100%" }}
                      />
                    </Box>
                  </div>
                  <TypographySecondary component="h2">
                    {t("base.subtitle_customs_invoices")}
                  </TypographySecondary>
                  <ButtonRegister
                    variant="contained"
                    component={NavLink}
                    to="/apps/signup"
                  >
                    {t("button.start_now")}
                  </ButtonRegister>
                </Grid>
              </Grid>
            </BaseBox>
            {/* 6th component Secure data */}
            <BaseBox theme={theme} mdmedia={mdMediaMax900.toString()}>
              <Grid
                container
                item
                sx={{
                  maxWidth: "1300px",
                  width: "100%",
                  mx: "auto",
                  pt: { xs: "30px", md: "0px" },
                  pb: { xs: "60px", sm: "50px", md: "120px" },
                  px: px,
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    position: "static",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    gap: lgMediaMax1200 ? "30px" : "60px",
                    // width: "656px",
                  }}
                >
                  <Box>
                    <TypographyMain
                      component="h3"
                      lgmedia={lgMediaMax1200.toString()}
                      sx={{ fontSize: mainFontSize }}
                    >
                      {t("base.title_data_protection")}
                    </TypographyMain>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        pt: "10px",
                        maxWidth: "400px",
                        width: "100%",
                      }}
                    >
                      <img
                        src={element}
                        alt="line element"
                        style={{ width: "100%" }}
                      />
                    </Box>
                  </Box>
                  <TypographySecondary component="h2">
                    {t("base.subtitle_data_protection")}
                  </TypographySecondary>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: 0,
                    gap: lgMediaMax1200 ? "30px" : "60px",
                    height: "auto",
                    width: "656px",
                    justifyContent: "center",
                    pt: { xs: "50px", md: 0 },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "auto",
                      alignSelf: "center",
                      pl: { xs: 0, md: "50px" },
                      maxWidth: "500px",
                    }}
                  >
                    <img
                      src={securityImage}
                      alt="security_image"
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                        borderRadius: "10px",
                        alignSelf: "center",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </BaseBox>
            {/* 5th component How it works */}
            <BaseBox
              blue="blue"
              theme={theme}
              mdmedia={mdMediaMax900.toString()}
              sx={{ overflow: "hidden", flexDirection: "column" }}
            >
              <Grid
                id="howitworks"
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // boxSizing: "border-box",
                  maxWidth: "1300px",
                  width: "100%",
                  // mx: { xs: "30px", md: "auto" },
                  mx: "auto",
                  mt: { xs: "60px", md: "120px" },
                  mb: { xs: "60px", md: "190px" },
                  px: px,
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    left: "0%",
                    top: "0%",
                  }}
                >
                  <img
                    src={background2}
                    alt="background_2"
                    style={{
                      opacity: 0.3,
                    }}
                  />
                </Box>
                <Box
                  id="howitworks"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mx: "auto",
                    // boxSizing: "border-box",
                    justifyContent: mdMediaMax900 ? "flex-start" : "center",
                    alignItems: mdMediaMax900 ? "flex-start" : "center",
                    padding: 0,
                    m: 0,
                    gap: lgMediaMax1200 ? "30px" : "40px",
                    // width: "auto",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      mx: "auto",
                    }}
                  >
                    <TypographyMain
                      component="h3"
                      white="white"
                      lgmedia={lgMediaMax1200.toString()}
                      sx={{
                        textAlign: { xs: "left", md: "center" },
                        width: "auto",
                        fontSize: mainFontSize,
                      }}
                    >
                      {t("base.title_howitworks")}
                    </TypographyMain>
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: "400px",
                        alignSelf: { xs: "left", md: "center" },
                      }}
                    >
                      <img
                        src={element}
                        alt="line element"
                        style={{
                          width: "100%",
                          paddingTop: "10px",
                        }}
                      />
                    </Box>
                  </Box>
                  <HowItWorks />
                  <ButtonRegister
                    variant="contained"
                    component={NavLink}
                    to="/apps/signup"
                    sx={{ alignItems: "center", justifyContent: "center" }}
                  >
                    {t("button.signup_now")}
                  </ButtonRegister>
                </Box>
              </Grid>
            </BaseBox>

            {/* 4th component Abonament */}
            <BaseBox
              theme={theme}
              mdmedia={mdMediaMax900.toString()}
              sx={{
                boxSizing: "border-box",
                overflow: "hidden",
                flexDirection: "column",
              }}
              id="subscribes"
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  // boxSizing: "border-box",
                  flexDirection: "column",
                  alignItems: "flex-start",

                  gap: "20px",
                  zIndex: 1,
                  overflow: "hidden",
                  width: "auto",
                  maxWidth: "1300px",
                  px: { xs: "30px", md: "30px", lg: "0px" },
                  pt: { xs: "30px", md: "120px" },
                  height: "100%",
                  mx: "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <TypographyMain
                    component="h3"
                    lgmedia={lgMediaMax1200.toString()}
                    sx={{
                      // textAlign: mdMediaMax900 ? "start" : "center",
                      fontSize: mainFontSize,
                    }}
                  >
                    {t("base.title_select_subscription")}
                  </TypographyMain>
                  <Box
                    sx={{
                      pt: "10px",
                      width: "100%",
                      maxWidth: "400px",
                    }}
                  >
                    <img
                      src={element}
                      alt="line element"
                      style={{
                        width: "100%",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      maxWidth: mdMediaMax900 ? null : "fit-content",
                    }}
                  >
                    <TypographySecondary
                      component="h2"
                      sx={{ maxWidth: { md: "800px" } }}
                    >
                      {t("base.subtitle_select_subscription")}
                    </TypographySecondary>
                  </Box>
                </Box>
              </Grid>
              <SubscriptionTypes />
            </BaseBox>

            {/* AboutUs */}
            <BaseBox
              blue="blue"
              theme={theme}
              mdmedia={mdMediaMax900.toString()}
              id="aboutUs"
            >
              <Grid
                container
                item
                sx={{
                  maxWidth: "1300px",
                  width: "100%",
                  mx: "auto",
                  pb: { xs: "90px", md: "100px" },
                  pt: { xs: "60px", md: "120px" },
                  px: px,
                }}
              >
                <AboutUs />
              </Grid>
            </BaseBox>

            {/* Testimonials
             */}
            <BaseBox
              blue="blue"
              theme={theme}
              mdmedia={mdMediaMax900.toString()}
              sx={{ overflow: "hidden", flexDirection: "column", pb: 0 }}
              id="testimonials"
            >
              <Grid
                item
                sx={{
                  display: "block",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  px: px,
                  gap: "20px",
                  overflow: "hidden",
                  maxWidth: "1300px",
                  mx: "auto",
                  mt: { xs: "30px", md: "120px" },
                  mb: { xs: "90px", md: "100px", lg: "190px" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: 0,
                    gap: "40px",
                    // width: mdMediaMax900 ? "auto" : "610px",
                    height: "auto",
                    mx: "auto",
                  }}
                >
                  <TypographyMain
                    component="h3"
                    white="white"
                    lgmedia={lgMediaMax1200.toString()}
                    sx={{
                      textAlign: "center",
                      fontSize: mainFontSize,
                    }}
                  >
                    {t("base.title_try_it_now")}
                  </TypographyMain>
                  <Box
                    sx={{
                      pt: "10px",
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    <TypographySecondary
                      component="h2"
                      white="white"
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {t("base.subtitle_try_it_now")}
                    </TypographySecondary>
                  </Box>
                  <ButtonRegister
                    variant="contained"
                    component={NavLink}
                    to="/apps/signup"
                  >
                    {t("button.start_now")}
                  </ButtonRegister>
                  <TypographySecondary
                    component="h2"
                    white="white"
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <NavLink
                      to="/apps/support"
                      style={{ textDecoration: "none", color: "#FFF" }}
                    >
                      {t("base.do_you_have_questions")}
                    </NavLink>
                  </TypographySecondary>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      pt: "10px",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <Avatar
                      src={apple}
                      sx={{
                        height: "80px",
                        width: "80px",
                        objectFit: "contain",
                        fill: "#FFF",
                        borderRadius: 0,
                      }}
                    ></Avatar>
                    <Avatar
                      src={android}
                      sx={{
                        height: "80px",
                        width: "80px",
                        objectFit: "contain",
                        fill: "#FFF",
                        borderRadius: 0,
                      }}
                    ></Avatar>
                    <Avatar
                      src={windows}
                      sx={{
                        height: "80px",
                        width: "80px",
                        objectFit: "contain",
                        fill: "#FFF",
                        borderRadius: 0,
                      }}
                    ></Avatar>
                  </Box>
                </Box>
              </Grid>
            </BaseBox>

            {/* Footer */}
            <BaseBox
              blue="blue"
              theme={theme}
              mdmedia={mdMediaMax900.toString()}
              sx={{ gap: lgMediaMax1200 ? "30px" : "60px", pb: "10px" }}
            >
              {/* <Grid item sx={{ mx: "auto" }}> */}
              <Footer />
              {/* </Grid> */}
            </BaseBox>
          </>
        )}

        {statusLogIn !== "notAuthorized" && authLogIn && (
          <>
            <Divider sx={{ backgroundColor: "#2E4E73" }} />
            <Box
              sx={{
                backgroundColor: theme.palette.baseblue.main,
                color: "#FFFF",
                py: "10px",
              }}
            >
              <Copyright></Copyright>
            </Box>
          </>
        )}
      </Box>

      <ScrollTop {...props}>
        <Fab
          size="large"
          color="primary"
          aria-label="scroll back to top"
          sx={{
            zIndex: 5,
          }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
};
export default Base;
