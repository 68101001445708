import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormikContext, useField } from "formik";
////// Mui
import { styled } from "@mui/material/styles";
import { Autocomplete, TextField } from "@mui/material";
import { FormHelperText, InputLabel } from "@mui/material";

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(-1),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#f5f5f5" : "#f5f5f5",
    fontSize: 16,
    fontWeight: 500,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const AutocompleteWrapper = ({
  name,
  label,
  required,
  disabled,
  personsArray,
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext();
  const [options, setOptions] = React.useState([]);
  const { persons } = useSelector((state) => state.personSlice);
  const { statusesLocal } = useSelector((state) => state.statusSlice);
  const { contacts } = useSelector((state) => state.contactSlice);
  const { currencies } = useSelector((state) => state.currencySlice);
  const { bankAccounts } = useSelector((state) => state.bankAccountSlice);
  const { countries } = useSelector((state) => state.countrySlice);
  const { noteTerms } = useSelector((state) => state.noteTermSlice);
  const { contractTerms } = useSelector((state) => state.contractTermSlice);
  const { itemsForItemInv } = useSelector((state) => state.itemForItemInvSlice);

  const [field, meta, helpers] = useField(String(name));

  const handleChange = (_, value) => {
    setFieldValue(field.name, value);
  };

  const configAutocomplete = {
    disabled: disabled,
  };

  const inputLabelProps = {
    label: label,
    required: required,
    shrink: true,
    sx: { fontSize: 18, fontWeight: "bold" },
  };

  const configTextField = {
    name: field.name,
    fullWidth: true,
    size: "small",
    autoComplete: "off",
    sx: { ...otherProps.sx, backgroundColor: "#f5f5f5" },
    ...otherProps,
  };

  const configHelperText = {
    sx: { color: "red", fontSize: 14, fullWidth: true },
  };

  let helperText = null;

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    helperText = meta.error;
  }

  const helperTextShow = !field.name.startsWith("iteminv_invs");
  // field.name !== "bill_to" &&

  useEffect(() => {
    if (field.name === "person" && personsArray) {
      setOptions(personsArray);
    } else if (field.name.startsWith("personscontact[")) {
      setOptions(persons);
    } else if (field.name === "stype") {
      setOptions(statusesLocal);
    } else if (field.name === "currency") {
      setOptions(currencies);
    } else if (field.name === "currencycode") {
      setOptions(currencies);
    } else if (field.name.includes("country")) {
      setOptions(countries);
    } else if (field.name === "status") {
      setOptions(statusesLocal);
    } else if (field.name === "note_terms") {
      setOptions(noteTerms);
    } else if (field.name === "contract_terms") {
      setOptions(contractTerms);
    } else if (field.name.startsWith("iteminv_invs")) {
      setOptions(itemsForItemInv);
    } else if (field.name === "payment_term") {
      setOptions(noteTerms);
    } else {
      setOptions(contacts);
    }
  }, [
    field,
    field.name,
    persons,
    personsArray,
    statusesLocal,
    currencies,
    bankAccounts,
    countries,
    noteTerms,
    contractTerms,
    itemsForItemInv,
    contacts,
  ]);

  return (
    <Autocomplete
      {...configAutocomplete}
      onChange={handleChange}
      onBlur={() => helpers.setTouched(true)}
      getOptionLabel={(option) => {
        let result = "";

        if (typeof option === "number" && field.name === "payment_term") {
          option = options.find((el) => el.id === option);
          return option && (option.note_short || "");
        }
        if (field.name === "status") {
          option = statusesLocal.find((el) => el.id === option.id);
          return option.type;
        }
        if (field.name === "stype") {
          result = option.st;
        } else if (field.name === "htype") {
          result = option.ht;
        } else if (field.name === "person") {
          result = option.first_name + " " + option.last_name;
        } else if (field.name.startsWith("personscontact[")) {
          result = option.first_name + " " + option.last_name;
        } else if (field.name === "mtype") {
          result = option.mt;
        } else if (field.name === "currency") {
          result = option.currencycode;
        } else if (field.name === "currencycode") {
          result = option.currencycode;
        } else if (field.name === "mode") {
          result = option.mt;
        } else if (field.name === "status") {
          result = option.type;
        } else if (field.name.includes("country")) {
          result = option.label;
        } else if (field.name === "note_terms") {
          result = option.note_short;
        } else if (field.name === "contract_terms") {
          result = option.term_short;
        } else if (field.name.startsWith("iteminv_invs")) {
          result = option.description;
        } else if (field.name === "lang") {
          result = option.lang;
        } else if (field.name === "payment_term") {
          result = option.note_short;
        } else {
          result = option.companyname;
        }
        return result;
      }}
      isOptionEqualToValue={(option, value) => {
        if (typeof option === "number" && field.name === "payment_term") {
          value = options.find((el) => el.id === option);
        }
        // All other options
        if (option.id) {
          return option.id === value.id;
        } else return option.code === value.code;
      }}
      value={field.value || null}
      options={options}
      renderInput={(params) => (
        <>
          <InputLabel {...inputLabelProps} htmlFor={field.name}>
            {label}
          </InputLabel>
          <TextFieldStyled id={field.name} {...params} {...configTextField} />
          <FormHelperText error {...configHelperText}>
            {helperTextShow && helperText}
          </FormHelperText>
        </>
      )}
    />
  );
};
export default AutocompleteWrapper;
