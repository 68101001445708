import React from "react";
import { FieldArray, Field } from "formik";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
//// Mui
import { useMediaQuery, Stack, useTheme, SvgIcon } from "@mui/material";
import { Box, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
////// Controls & Components
import ButtonAction from "../../Controls/Buttons/ButtonAction";
import CargoIcon from "../../Data/Icons/CargoIcon";
import CheckboxSimple from "../../Controls/FormsUI/CheckboxSimple";
import InputArray from "../../Controls/FormsUI/InputArray";
import InputArrayNumber from "../../Controls/FormsUI/InputArrayNumber";
import TooltipBasic from "../../Controls/Inputs/TooltipBasic";

const Entries = (props) => {
  const { entries, arrayHelpers } = props;
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const theme = useTheme();
  const mdMediaDown = useMediaQuery(theme.breakpoints.down("md"));

  let form_entries = pathname === "/apps/quotes" ? "entry_invs" : "entries";

  return (
    <React.Fragment>
      <FieldArray
        name={pathname !== "/apps/quotes" ? "entries" : "entry_invs"}
        entriesArrayHelpers={arrayHelpers}
      >
        {(entriesArrayHelpers) => {
          return (
            <>
              {/* Shipper, Consignee & Shipment details & Palletexchange, DG, tail-lift*/}
              {entries.map((shipment, i) => {
                const { entrydetails } = shipment;

                return (
                  <Grid container item key={i + 500} xs={12}>
                    {/* Shipment details table */}
                    <Grid container item xs={12} sx={{ mt: 0.5 }}>
                      {pathname === "/apps/quotes" ? (
                        <Grid
                          item
                          md={0.48}
                          xs={12}
                          sx={{ display: "flex", mt: 2.5 }}
                        >
                          <Stack
                            direction='column'
                            justifyContent='start'
                            alignItems='flex-start'
                          >
                            <TooltipBasic
                              title={t("quote.loading_place")}
                              placement='top-end'
                            >
                              {/* span needed in order to display the tooltip */}
                              <span>
                                <SvgIcon
                                  component={CargoIcon}
                                  inheritViewBox
                                  fontSize='large'
                                  sx={{ mb: 1 }}
                                />
                              </span>
                            </TooltipBasic>
                          </Stack>
                        </Grid>
                      ) : undefined}

                      <Grid
                        container
                        item
                        md={pathname === "/apps/quotes" ? 11.52 : 12}
                        xs={12}
                      >
                        <FieldArray
                          name={`${
                            pathname !== "/apps/quotes"
                              ? "entries"
                              : "entry_invs"
                          }[${i}].entrydetails`}
                          entrydetailsArrayHelpers={arrayHelpers}
                        >
                          {(entrydetailsArrayHelpers) => {
                            return (
                              <>
                                {/* Table headers */}
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  sx={{
                                    width: "100%",
                                    mt: !mdMediaDown ? 2 : undefined,
                                    mb: 1.1,
                                  }}
                                >
                                  {!mdMediaDown && (
                                    <Box
                                      flexDirection={
                                        mdMediaDown ? "column" : "row"
                                      }
                                      sx={{ display: "flex", width: "100%" }}
                                    >
                                      <Box
                                        sx={{
                                          alignSelf: "start",
                                          width: mdMediaDown ? "100%" : "92%",
                                        }}
                                      >
                                        <Grid container item xs={12}>
                                          <Grid
                                            item
                                            md={1.2}
                                            sx={{ bgcolor: "#ccc" }}
                                          >
                                            <Typography
                                              noWrap
                                              sx={{
                                                ml: 1.5,
                                                fontWeight: "600",
                                                color: "black",
                                              }}
                                            >
                                              {t("entry.pieces")}
                                            </Typography>
                                          </Grid>

                                          <Grid
                                            item
                                            md={1.5}
                                            sx={{ bgcolor: "#ccc" }}
                                          >
                                            <Typography
                                              noWrap
                                              sx={{
                                                ml: 1.5,
                                                fontWeight: "600",
                                                color: "black",
                                              }}
                                            >
                                              {t("entry.weight")}
                                            </Typography>
                                          </Grid>

                                          <Grid
                                            item
                                            md={1.5}
                                            sx={{ bgcolor: "#ccc" }}
                                          >
                                            <Typography
                                              noWrap
                                              sx={{
                                                ml: 1.5,
                                                fontWeight: "600",
                                                color: "black",
                                              }}
                                            >
                                              {t("entry.volume")}
                                            </Typography>
                                          </Grid>

                                          <Grid
                                            item
                                            md={1.5}
                                            sx={{ bgcolor: "#ccc" }}
                                          >
                                            <Typography
                                              noWrap
                                              sx={{
                                                ml: 1.5,
                                                fontWeight: "600",
                                                color: "black",
                                              }}
                                            >
                                              {t("entry.ldm")}
                                            </Typography>
                                          </Grid>

                                          <Grid
                                            item
                                            md={6.3}
                                            sx={{ bgcolor: "#ccc" }}
                                          >
                                            <Typography
                                              noWrap
                                              sx={{
                                                ml: 1.5,
                                                fontWeight: "600",
                                                color: "black",
                                              }}
                                            >
                                              {t(
                                                "entry.dims_and_nature_of_goods"
                                              )}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Box>

                                      <Box
                                        sx={{
                                          alignSelf: "start",
                                          width: mdMediaDown ? "100%" : "8%",
                                          bgcolor: "#ccc",
                                          height: "100%",
                                        }}
                                      ></Box>
                                    </Box>
                                  )}
                                </Grid>

                                {/* Table body / shipment details */}
                                {entrydetails &&
                                  entrydetails.map((_, index) => {
                                    let entrydetailsLength =
                                      entrydetails.length;
                                    return (
                                      <Grid
                                        container
                                        item
                                        xs={12}
                                        key={index + 800}
                                        sx={{
                                          width: "100%",
                                          mt:
                                            mdMediaDown && index !== 0
                                              ? 4
                                              : undefined,
                                        }}
                                      >
                                        <Box
                                          flexDirection={
                                            mdMediaDown ? "column" : "row"
                                          }
                                          sx={{
                                            display: "flex",
                                            width: "100%",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              alignSelf: "start",
                                              width: mdMediaDown
                                                ? "100%"
                                                : "92%",
                                            }}
                                          >
                                            <Grid container item xs={12}>
                                              <Grid item md={1.2} xs={12}>
                                                <InputArrayNumber
                                                  name={`${form_entries}[${i}].entrydetails[${index}].pieces`}
                                                  type='number'
                                                  label={
                                                    mdMediaDown
                                                      ? t("entry.pieces")
                                                      : null
                                                  }
                                                />
                                              </Grid>
                                              <Grid item md={1.5} xs={12}>
                                                <InputArrayNumber
                                                  name={`${form_entries}[${i}].entrydetails[${index}].weight`}
                                                  type='number'
                                                  label={
                                                    mdMediaDown
                                                      ? t("entry.weight")
                                                      : null
                                                  }
                                                />
                                              </Grid>
                                              <Grid item md={1.5} xs={12}>
                                                <InputArrayNumber
                                                  name={`${form_entries}[${i}].entrydetails[${index}].volume`}
                                                  type='number'
                                                  label={
                                                    mdMediaDown
                                                      ? t("entry.volume")
                                                      : null
                                                  }
                                                />
                                              </Grid>
                                              <Grid item md={1.5} xs={12}>
                                                <InputArrayNumber
                                                  name={`${form_entries}[${i}].entrydetails[${index}].ldm`}
                                                  type='number'
                                                  label={
                                                    mdMediaDown
                                                      ? t("entry.ldm")
                                                      : null
                                                  }
                                                />
                                              </Grid>
                                              <Grid item md={6.3} xs={12}>
                                                <Field
                                                  component={InputArray}
                                                  name={`${form_entries}[${i}].entrydetails[${index}].dims`}
                                                  type='text'
                                                  label={
                                                    mdMediaDown
                                                      ? t(
                                                          "entry.dims_and_nature_of_goods"
                                                        )
                                                      : null
                                                  }
                                                />
                                              </Grid>
                                            </Grid>
                                          </Box>

                                          <Box
                                            sx={{
                                              alignSelf: "start",
                                              width: mdMediaDown
                                                ? "100%"
                                                : "8%",
                                            }}
                                          >
                                            <Grid
                                              container
                                              item
                                              xs={12}
                                              spacing={
                                                mdMediaDown ? 1 : undefined
                                              }
                                            >
                                              <Grid item md={6} xs={12}>
                                                <ButtonAction
                                                  type='button'
                                                  style={
                                                    mdMediaDown
                                                      ? undefined
                                                      : {
                                                          maxWidth: "40px",
                                                          maxHeight: "20px",
                                                          minWidth: "40px",
                                                          minHeight: "20px",
                                                        }
                                                  }
                                                  fullWidth={
                                                    mdMediaDown ? true : false
                                                  }
                                                  color={
                                                    entrydetailsLength &&
                                                    entrydetailsLength > 1
                                                      ? "secondary"
                                                      : "inherit"
                                                  }
                                                  variant={
                                                    mdMediaDown
                                                      ? "contained"
                                                      : "text"
                                                  }
                                                  disabled={
                                                    entrydetailsLength &&
                                                    entrydetailsLength > 1
                                                      ? false
                                                      : true
                                                  }
                                                  onClick={() =>
                                                    entrydetailsArrayHelpers.remove(
                                                      index
                                                    )
                                                  }
                                                >
                                                  <RemoveIcon />
                                                </ButtonAction>
                                              </Grid>

                                              <Grid item md={6} xs={12}>
                                                <ButtonAction
                                                  type='button'
                                                  style={
                                                    mdMediaDown
                                                      ? undefined
                                                      : {
                                                          maxWidth: "40px",
                                                          maxHeight: "20px",
                                                          minWidth: "40px",
                                                          minHeight: "20px",
                                                        }
                                                  }
                                                  fullWidth={
                                                    mdMediaDown ? true : false
                                                  }
                                                  color={
                                                    entrydetailsLength &&
                                                    entrydetailsLength < 5
                                                      ? "primary"
                                                      : "inherit"
                                                  }
                                                  variant={
                                                    mdMediaDown
                                                      ? "contained"
                                                      : "text"
                                                  }
                                                  disabled={
                                                    entrydetailsLength &&
                                                    entrydetailsLength < 5
                                                      ? false
                                                      : true
                                                  }
                                                  onClick={() =>
                                                    entrydetailsArrayHelpers.push(
                                                      {
                                                        pieces: "",
                                                        weight: "",
                                                        ldm: "",
                                                        volume: "",
                                                        dims: "",
                                                      }
                                                    )
                                                  }
                                                >
                                                  <AddIcon />
                                                </ButtonAction>
                                              </Grid>
                                            </Grid>
                                          </Box>

                                          {mdMediaDown &&
                                            entrydetailsLength !== i + 1 && (
                                              <Box
                                                sx={{
                                                  width: "100%",
                                                }}
                                              >
                                                <Grid
                                                  item
                                                  xs={12}
                                                  sx={{ mt: 1.75 }}
                                                >
                                                  <hr />
                                                </Grid>
                                              </Box>
                                            )}
                                        </Box>
                                      </Grid>
                                    );
                                  })}
                              </>
                            );
                          }}
                        </FieldArray>
                      </Grid>
                    </Grid>

                    {/* Palletexchange, DG, tail-lift */}
                    <Grid container item sx={{ mt: 1 }} spacing={1}>
                      {pathname !== "/apps/quotes" ? undefined : (
                        <Grid
                          item
                          md={0.48}
                          xs={12}
                          sx={{ display: "flex" }}
                        ></Grid>
                      )}
                      <Grid
                        container
                        item
                        md={pathname !== "/apps/quotes" ? 12 : 11.52}
                        xs={12}
                        spacing={mdMediaDown ? 3 : 1}
                      >
                        <Grid item md={3.5} xs={12}>
                          <Field
                            as={CheckboxSimple}
                            name={`${form_entries}[${i}].tail_lift`}
                            type='checkbox'
                            label={t("entry.tail_lift")}
                            color='primary'
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                          />
                        </Grid>

                        <Grid item md={8.5} xs={12}>
                          <Box
                            flexDirection={mdMediaDown ? "column" : "row"}
                            sx={{ display: "flex", width: "100%" }}
                          >
                            <Box sx={{ width: 190 }}>
                              <Field
                                as={CheckboxSimple}
                                name={`${form_entries}[${i}].dangerousgoods`}
                                type='checkbox'
                                label={t("entry.dangerous_goods")}
                                color='primary'
                                style={{
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                }}
                              />
                            </Box>

                            <Box>
                              <Typography
                                variant='body1'
                                textAlign={mdMediaDown ? "start" : "end"}
                                sx={{ pr: 1, mb: 1 }}
                              >
                                {t("entry.class_colon")}
                              </Typography>
                            </Box>

                            <Box sx={{ flexGrow: 1 }}>
                              <Field
                                component={InputArray}
                                name={`${form_entries}[${i}].dangerousgoods_class`}
                                type='text'
                              />
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item md={3.5} xs={12}>
                          <Field
                            as={CheckboxSimple}
                            name={`${form_entries}[${i}].palletexchange`}
                            type='checkbox'
                            label={t("entry.pallet_exchange")}
                            color='primary'
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          />
                        </Grid>

                        <Grid item md={8.5} xs={12}>
                          <Box
                            flexDirection={mdMediaDown ? "column" : "row"}
                            sx={{ display: "flex", width: "100%" }}
                          >
                            <Box sx={{ width: 190 }}>
                              <Field
                                as={CheckboxSimple}
                                name={`${form_entries}[${i}].temp_control`}
                                type='checkbox'
                                label={t("entry.temp_control")}
                                color='primary'
                                style={{
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                }}
                              />
                            </Box>

                            <Box>
                              <Typography
                                variant='body1'
                                noWrap
                                textAlign={mdMediaDown ? "start" : "end"}
                                sx={{ pr: 1, mb: 1 }}
                              >
                                {t("entry.temp_control_details")}
                              </Typography>
                            </Box>

                            <Box sx={{ flexGrow: 1 }}>
                              <Field
                                component={InputArray}
                                name={`${form_entries}[${i}].temp_control_details`}
                                type='text'
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </>
          );
        }}
      </FieldArray>
    </React.Fragment>
  );
};

export default Entries;
