import React from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import { Image, Line, Svg, StyleSheet } from "@react-pdf/renderer";
////// Controls & Components
// import InvPdfUp from "../InvPdfUp";
import InvPdfLoadDetails from "./InvPdfLoadDetails";
import InvPdfTable from "./InvPdfTable";
import InvPdfTerms from "./InvPdfTerms";
import InvPdfConditions from "./InvPdfConditions";
import InvPdfUp1 from "./InvTemplate1/InvPdfUp1";

////// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 2,
    paddingBottom: 80,
    paddingLeft: 47,
    paddingRight: 35,
  },
  boxM: {
    // flexDirection: "row",
    marginBottom: 2,
    justifyContent: "flex-start",
    width: 269,
  },
  boxXL: {
    marginTop: 2,
    flexDirection: "row",
    marginBottom: 2,
    justifyContent: "flex-start",
    width: 538,
  },
  logo: {
    width: "auto",
    maxWidth: 150,
    maxHeight: 80,
    marginLeft: "auto",
    // marginRight: "auto",
    marginRight: 70,
  },
  stamp: {
    maxHeight: 100,
    width: "auto",
    maxWidth: 156,
    marginLeft: 7,
    marginRight: "auto",
  },
  rowContainer: {
    flexDirection: "row",
    marginBottom: 1,
    justifyContent: "flex-start",
    width: 535,
  },
  rowContainerBasic: {
    flexDirection: "row",
    marginTop: 1,
    marginBottom: 1,
    justifyContent: "flex-start",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 8,
    bottom: 20,
    left: 0,
    right: 16,
    textAlign: "right",
    color: "black",
  },
  text2: {
    fontSize: 12,
    textAlign: "left",
    fontFamily: "Roboto",
  },
  text4: {
    fontSize: 8,
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    // fontWeight: "bold",
    width: 358,
  },
  titleXL: {
    flexDirection: "row",
    fontSize: 14,
    textAlign: "left",
    fontFamily: "Roboto",
    marginBottom: 2,
  },
});

////// Create Document Component
const InvPdfDocument = (
  account,
  company,
  values,
  bankAccounts,
  logo,
  stamp,
  invoicePdfLang,
  checked,
  freeAppMssg
) => {
  return (
    <React.Fragment>
      <Document
        title={`${
          invoicePdfLang === "RO/RU" ? "FACTURA / СЧЕТ НА ОПЛАТУ" : "INVOICE"
        } # ${values.vn || ""}`}
      >
        <Page style={styles.body}>
          <View style={{ ...styles.boxXL, marginTop: 12 }}>
            <View style={{ ...styles.boxM, justifyContent: "flex-end" }}>
              <View>
                <Text
                  style={styles.titleXL}
                  render={() =>
                    `${
                      invoicePdfLang === "RO/RU"
                        ? "FACTURA / СЧЕТ НА ОПЛАТУ"
                        : "INVOICE"
                    } # ${values.vn || ""}`
                  }
                ></Text>
              </View>
              <View>
                <Text
                  style={styles.text2}
                  render={() =>
                    `${
                      invoicePdfLang === "RO/RU" ? "Data / Дата:" : "Date:"
                    } ${new Date(values.date_inv).toLocaleDateString("fr-EN")}`
                  }
                ></Text>
              </View>
              <View style={{ marginBottom: 5 }}>
                <Text
                  style={styles.text2}
                  render={() =>
                    `${
                      invoicePdfLang === "RO/RU"
                        ? "Termenul de achitare / Оплатить до"
                        : "Due date"
                    } : ${
                      values.date_due
                        ? new Date(values.date_due).toLocaleDateString("fr-EN")
                        : ""
                    }`
                  }
                ></Text>
              </View>
            </View>
            <View style={{ ...styles.boxM, justifyContent: "flex-end" }}>
              {logo && (
                <Image
                  style={styles.logo}
                  src={{
                    uri: `${logo}`,
                    method: "GET",
                    headers: { "Cache-Control": "no-cache" },
                    body: "",
                  }}
                />
              )}
            </View>
          </View>

          <Svg style={{ height: 2, width: "535" }}>
            <Line
              x1='0'
              y1='0'
              x2='538'
              y2='0'
              strokeWidth={2}
              stroke='black'
            />
          </Svg>

          <InvPdfUp1
            account={account}
            company={company}
            values={values}
            bankAccounts={bankAccounts}
            invoicePdfLang={invoicePdfLang}
          />

          {/* For subscription invoice do not show InvPdfLoadDetails */}
          {values && values.bill_to && values.bill_to.id !== 88777 && (
            <>
              {checked && (
                <>
                  <Svg style={{ height: 2, width: "535" }}>
                    <Line
                      x1='0'
                      y1='0'
                      x2='535'
                      y2='0'
                      strokeWidth={2}
                      stroke='black'
                    />
                  </Svg>
                  <InvPdfLoadDetails
                    values={values}
                    invoicePdfLang={invoicePdfLang}
                  />
                </>
              )}

              <Svg style={{ height: 2, width: "535" }}>
                <Line
                  x1='0'
                  y1='0'
                  x2='535'
                  y2='0'
                  strokeWidth={2}
                  stroke='black'
                />
              </Svg>
            </>
          )}
          <InvPdfTable values={values} invoicePdfLang={invoicePdfLang} />

          <InvPdfTerms values={values} invoicePdfLang={invoicePdfLang} />

          {values && values.contract_terms && (
            <InvPdfConditions values={values} invoicePdfLang={invoicePdfLang} />
          )}

          {/* Signature / stamp */}
          <View
            style={{
              left: 0,
              right: 0,
              position: "absolute",
              verticalAlign: "bottom",
              paddingLeft: 47,
              bottom: 45,
            }}
            fixed
          >
            <View style={styles.boxM}>
              {stamp && (
                <Image
                  style={styles.stamp}
                  src={{
                    uri: `${stamp}`,
                    method: "GET",
                    headers: { "Cache-Control": "no-cache" },
                    body: "",
                  }}
                />
              )}
            </View>

            <Svg height='3' width='538'>
              <Line
                x1='0'
                y1='0'
                x2='250'
                y2='0'
                strokeWidth={2}
                stroke='black'
              />
            </Svg>

            <View style={{ ...styles.rowContainerBasic, margin: 0 }}>
              <Text style={{ ...styles.text4, maxWidth: 250, fontSize: 6 }}>
                (
                {`${
                  invoicePdfLang === "RO/RU"
                    ? "semn./stamp./ печ./подп."
                    : "stamp / signature"
                }`}
                )
              </Text>
            </View>
          </View>

          {/* FreeAppText & Page number */}
          <View
            style={{
              left: 0,
              right: 0,
              position: "absolute",
              verticalAlign: "bottom",
              bottom: 30,
            }}
            fixed
          >
            <View>
              <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                  `Page ${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </View>
            <View>
              <Text
                style={{
                  ...styles.text4,
                  width: 535,
                  fontSize: 6,
                  textAlign: "center",
                }}
                render={() => freeAppMssg}
              />
            </View>
          </View>
        </Page>
      </Document>
    </React.Fragment>
  );
};

export default InvPdfDocument;
