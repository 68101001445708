import React from "react";
import { useTranslation } from "react-i18next";
////// Mui
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "../Popups/ConfirmDialog";

const ButtonDelete = ({ id, handleDelete, children, ...otherProps }) => {
  const { t } = useTranslation();
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    message: "",
    title: "",
    subTitle: "",
    type: "",
  });

  const configButton = {
    ...otherProps,
    sx: { ...otherProps.sx, mt: 1, mr: 1, mb: 1 },
  };

  return (
    <>
      <Button
        type='button'
        variant='outlined'
        color='secondary'
        startIcon={<DeleteIcon />}
        onClick={(e) => {
          setConfirmDialog({
            isOpen: true,
            title: t("popup.are_you_sure_to_delete_record"),
            subTitle: t("popup.you_can_not_undo_this_operation"),
            onConfirm: (e) => [
              handleDelete(e, id),
              setConfirmDialog({ ...confirmDialog, isOpen: false }),
            ],
          });
        }}
        {...configButton}
      >
        {children}
      </Button>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      ></ConfirmDialog>
    </>
  );
};

export default ButtonDelete;
