import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Font } from "@react-pdf/renderer";
import { NavLink } from "react-router-dom";
////// Mui
import { styled } from "@mui/material/styles";
import { useTheme, useMediaQuery, SvgIcon, Slider } from "@mui/material/";
import { Box, Grid, Typography } from "@mui/material/";
import { Card, CardHeader, CardContent, CardActions } from "@mui/material/";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import StarIcon from "@mui/icons-material/StarBorder";
////// Controls & Components
import { appURL } from "../../Data/baseURL";
import { maxNumberAllowedPerSubscription } from "../../Data/generalData";
import { supplier, invoiceValues } from "../../Data/generalData";
import { tiers, supplierBankAccounts } from "../../Data/generalData";
import InvPdf from "../Inv/InvPdf/InvPdf";
import CheckBoxIcon from "../../Data/Icons/CheckBoxIcon";
import ButtonRegister from "../../Controls/Buttons/ButtonRegister";

const SubscribtionSliderStyled = styled(Slider)({
  "& .MuiSlider-root": {
    color: "#043873",
    height: 8,
    backgroundColor: "#F36815",
  },
  "& .MuiSlider-valueLabelOpen": {
    width: "33px",
    height: "33px",
    display: "flex",
    alignItems: "center",
    borderRadius: "50% 50% 50% 50%",
    justifyContent: "center",
    backgroundColor: "#F36815",
  },

  "& .PrivateValueLabel-circle-19": {
    backgroundColor: "#F36815",
    width: 34,
    height: 34,
  },
  "& .MuiSlider-track": {
    borderRadius: "5px",
  },
  "& .MuiSlider-thumb": {
    height: 17,
    width: 17,
    backgroundColor: "#fff",
    border: "2px solid #F36815",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      color: "#F36815",
      boxShadow: "auto",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .PrivateValueLabel-label-20": {
    color: "#fff",
    fontSize: 13,
    opacity: 0.9,
  },
  "& .MuiSlider-valueLabel": {
    color: "#FFFF",
    "&:before": {
      position: "absolute",
      bottom: "6%",
      width: "10px",
      height: "10px",
    },
  },
});

const SubscriptionTypes = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const mdMediaDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgMediaDown = useMediaQuery(theme.breakpoints.down("xl"));
  const lgMediaMax1200 = useMediaQuery("(max-width:1200px)");
  const [showPdf, setShowPdf] = useState(false);
  const [payerValues, setPayerValues] = useState([]);
  const [subscriptionTypeInvoice, setSubscriptionTypeInvoice] = useState(null);
  const [value, setValue] = useState(20);
  const { authLogIn } = useSelector((state) => state.authLogInSlice);
  const { company } = useSelector((state) => state.companySlice);
  const [blueBackground, setBlueBackground] = useState(1);

  ////// Start register font in advance with/for @react-pdf/renderer
  const loadFonts = React.useCallback(async () => {
    await Promise.all([
      Font.load({ fontFamily: "Roboto" }),
      Font.load({ fontFamily: "Oswald" }),
    ]);
  }, []);

  useEffect(() => {
    loadFonts();
  }, [loadFonts]);
  ////// End register font in advance with/for @react-pdf/renderer

  function valueLabelFormat(value) {
    if (value === 0) {
      return "0";
    }
    return "-" + value + "%";
  }

  const marks = [
    {
      value: 0,
      label: t(
        mdMediaDown
          ? "subscription.1_month_short"
          : lgMediaDown
          ? "subscription.1_month"
          : "subscription.1_month_payment"
      ),
    },
    {
      value: 10,
      label: t(
        mdMediaDown
          ? "subscription.6_month_short"
          : lgMediaDown
          ? "subscription.6_month"
          : "subscription.6_month_payment"
      ),
    },
    {
      value: 20,
      label: t(
        mdMediaDown
          ? "subscription.12_month_short"
          : lgMediaDown
          ? "subscription.12_month"
          : "subscription.12_month_payment"
      ),
    },
  ];

  useEffect(() => {
    setPayerValues(
      invoiceValues().map((payerValue, index) => {
        let newIteminv_invs = payerValue.iteminv_invs.map((item) => {
          return {
            ...item,
            amount:
              Number(value) === 0
                ? item.amount / 12
                : value === 10
                ? (item.amount * (100 - value)) / 100 / 2
                : (item.amount * (100 - value)) / 100,
            item_for_item_inv: {
              ...item.item_for_item_inv,
              description:
                value === 0
                  ? index === 0
                    ? "Abonament PRO pentru 1 luna "
                    : " Abonament PREMIUM pentru 1 luna"
                  : value === 10
                  ? index === 0
                    ? "Abonament PRO pentru 6 luni"
                    : " Abonament PREMIUM pentru 6 luni"
                  : index === 0
                  ? "Abonament PRO pentru 12 luni"
                  : "Abonament PREMIUM pentru 12 luni",
            },
          };
        });
        return { ...payerValue, iteminv_invs: newIteminv_invs };
      })
    );
  }, [company, value]);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  function generatePdfInvoice(index) {
    setShowPdf(true);
    setSubscriptionTypeInvoice(index);
    return null;
  }

  function discountPrice(price) {
    let newPrice = price;
    if (value !== undefined) {
      newPrice = price - (value / 100) * price;
    }
    return newPrice;
  }

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "100%",
          p: { xs: "30px", sm: "60px" },
          pb: "50px",
          // boxSizing: "border-box",
          maxWidth: "1300px",
          mx: "auto",
        }}
      >
        <SubscribtionSliderStyled
          aria-label="Always visible"
          defaultValue={20}
          step={10}
          marks={marks}
          min={0}
          max={20}
          valueLabelDisplay="on"
          onChange={handleChange}
          valueLabelFormat={valueLabelFormat}
        />
      </Box>

      <Grid
        // spacing={{ xs: 0, md: 3 }}
        container
        item
        sx={{
          display: "flex",
          maxWidth: "1300px",
          mx: "auto",
          mt: { xs: 0, md: "60px" },
          mb: { xs: "90px", md: "190px" },
          height: "auto",
          px: { xs: "30px", md: "60px" },
        }}
      >
        {/* Discount Sliders */}
        {tiers().map((tier, index) => {
          return (
            <Grid
              item
              key={tier.title}
              xs={12}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "row",
                paddingLeft: 0,
                pb: index === 2 ? (mdMediaDown ? "5%" : null) : null,
                justifyContent: "center",
                height: "auto",
                // px: { xs: "30px" },
                mt: { xs: "50px", md: 0 },
                pr: { xs: "0", md: "30px" },
              }}
            >
              <Card
                sx={{
                  flexDirection: "column",
                  width: "100%",
                  maxWidth: index === blueBackground ? "400px" : "350px",

                  border:
                    index === blueBackground ? null : " 1px solid #FFE492",
                  padding: mdMediaDown
                    ? "30px 34px"
                    : lgMediaMax1200
                    ? "10px 12px"
                    : "40px 44px",
                  gap: "50px",
                  backgroundColor:
                    index === blueBackground
                      ? theme.palette.baseblue.main
                      : "#FFFF",
                  height: "auto",
                  mx: "auto",
                  transition: "transform .2s",
                  transform:
                    index === blueBackground
                      ? mdMediaDown
                        ? "scale(1)"
                        : "scale(1.1)"
                      : null,
                  ":hover": {
                    boxShadow: 15,
                    transform: "scale(1.1)",
                  },
                }}
                onMouseEnter={() => setBlueBackground(index)}
              >
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{
                    color: index === blueBackground ? "#FFFF" : "#212529",
                    letterSpacing: 2,
                    fontFamily: "Inter",
                    fontWeight: "600",
                    fontSize: "24px",
                    lineHeight: "36px",
                  }}
                  action={tier.title === "Pro" ? <StarIcon /> : null}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "baseline",
                      mb: 2,
                      color:
                        index === blueBackground
                          ? theme.palette.baseyellow.main
                          : "#212529",
                    }}
                  >
                    <EuroSymbolIcon
                      fontSize="large"
                      sx={{ mr: 1, alignSelf: "center" }}
                    />
                    <Typography
                      variant="h3"
                      color="text.primary"
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "36px",
                        lineHeight: "44px",
                        letterSpacing: "-0.02em",
                        color:
                          index === blueBackground
                            ? theme.palette.baseyellow.main
                            : "#212529",
                      }}
                    >
                      {discountPrice(tier.price)}
                    </Typography>
                    <Typography variant="h6">
                      {t("subscription.slash_month")}
                    </Typography>
                  </Box>
                  <ul
                    style={{
                      margin: 0,
                      padding: 0,
                      gap: "25px",
                    }}
                  >
                    {tier.description.map((line, i) => {
                      let indexNumber = i === 0 ? i + index : i * 3 + index;
                      return (
                        <Box
                          key={i}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "15px",
                            paddingBottom: "20px",
                          }}
                        >
                          <SvgIcon
                            component={CheckBoxIcon}
                            inheritViewBox
                            sx={{
                              color:
                                index === blueBackground
                                  ? "transparent"
                                  : "#FFFF",
                            }}
                          />
                          <Typography
                            variant="subtitle1"
                            align="left"
                            key={line}
                            sx={{
                              color:
                                index === blueBackground ? "#FFFF" : "#212529",
                            }}
                          >
                            {maxNumberAllowedPerSubscription[indexNumber] +
                              " " +
                              line}
                          </Typography>
                        </Box>
                      );
                    })}
                  </ul>
                </CardContent>

                <CardActions>
                  {!authLogIn && (
                    <ButtonRegister
                      variant="contained"
                      component={NavLink}
                      to={`${appURL}/signup`}
                      sx={{
                        display: index === 0 ? "flex" : "none",
                        backgroundColor:
                          index === blueBackground
                            ? theme.palette.baseblue.light
                            : "#FFFF",
                        color: index === blueBackground ? "#FFFF" : "#000000",
                        border:
                          index === blueBackground ? null : "2px solid #FFE492",
                        "&:hover": {
                          backgroundColor:
                            index === blueBackground
                              ? null
                              : theme.palette.baseyellow.main,
                        },
                      }}
                    >
                      {t("button.start_now")}
                    </ButtonRegister>
                  )}
                  {authLogIn && (
                    <ButtonRegister
                      fullWidth
                      variant={
                        showPdf === true && index === subscriptionTypeInvoice
                          ? "outlined"
                          : tier.buttonVariant
                      }
                      sx={{
                        backgroundColor:
                          index === blueBackground
                            ? theme.palette.baseblue.light
                            : "#FFFF",
                        color: index === blueBackground ? "#FFFF" : "#000000",
                        border:
                          index === blueBackground ? null : "2px solid #FFE492",
                        "&:hover": {
                          backgroundColor:
                            index === blueBackground
                              ? null
                              : theme.palette.baseyellow.main,
                        },
                      }}
                      onClick={
                        index === 0 ? null : () => generatePdfInvoice(index)
                      }
                    >
                      {showPdf === true && index === subscriptionTypeInvoice ? (
                        <InvPdf
                          company={supplier}
                          values={{ ...payerValues[index === 1 ? 0 : 1] }}
                          bankAccounts={supplierBankAccounts}
                          logo={null}
                          stamp={null}
                          showPdf={showPdf}
                          setShowPdf={setShowPdf}
                          invoicePdfLang="RO/RU"
                          update
                        />
                      ) : (
                        <Typography variant="button">
                          {tier.buttonText}
                        </Typography>
                      )}
                    </ButtonRegister>
                  )}
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </React.Fragment>
  );
};

export default SubscriptionTypes;
