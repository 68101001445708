import React from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../Fetch/axios";
import { useTranslation } from "react-i18next";
////// Mui
import { Button, Typography, Paper } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
////// Store
import { setPopupBasic } from "../../Store/popupBasicSlice";
import { addOrEditNotification } from "../../Store/notificationSlice";
////// Controls & Components
import { notificationReadURL } from "../../Data/baseURL";

function PaperComponent(props) {
  return (
    <Draggable
      handle='#draggable-popupbasic-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function PopupBasic() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mdMediaDown = useMediaQuery(theme.breakpoints.down("md"));
  const { popupBasic } = useSelector((state) => state.popupBasicSlice);
  const { notification } = useSelector((state) => state.notificationSlice);

  const handleClose = () => {
    dispatch(setPopupBasic(false));
  };

  const handleReadClick = () => {
    axiosInstance({
      method: "PATCH",
      url: notificationReadURL + notification.id + "/",
      data: { pk: notification.id },
    })
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(addOrEditNotification(resp.data));
        }
      })
      .catch(() => {});
  };

  return (
    <React.Fragment>
      <Dialog
        open={popupBasic}
        fullWidth={true}
        maxWidth='md'
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            justifyContent: "flex-center",
            alignItems: "flex-start",
          },
        }}
        PaperProps={{
          sx: {
            mt: 2,
            m: mdMediaDown ? 0.75 : undefined,
          },
        }}
        PaperComponent={PaperComponent}
        BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.1)" } }}
        aria-labelledby='draggable-popupbasic-title'
      >
        <DialogTitle
          variant='h6'
          sx={{ backgroundColor: "#f1f1f1", height: 85 }}
        >
          {notification && notification.title}
        </DialogTitle>
        <DialogContent dividers>
          <Typography
            variant='Subtitle2'
            sx={{ fontWeight: "500" }}
            style={{ whiteSpace: "pre-line" }}
          >
            {notification && notification.message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' size='small' onClick={handleReadClick}>
            {notification.read === true
              ? t("button.mark_as_unread")
              : t("button.mark_as_read")}
          </Button>
          <Button
            variant='outlined'
            size='small'
            onClick={handleClose}
            autoFocus
          >
            {t("button.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
