import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../Fetch/axios";
import { toast } from "react-toastify";
import i18n from "i18next";
import { contactURL } from "../Data/baseURL";

export const getContacts = createAsyncThunk(
  "contactSlice/getContacts",
  async (route) => {
    return axiosInstance.get(contactURL).then((response) => {
      if (response.data) {
        route !== "/" && toast.success(i18n.t("toast.client_list_updated"));
        return response.data;
      }
    });
  }
);

export const contactSlice = createSlice({
  name: "contactSlice",
  initialState: {
    contacts: [],
    contact: {},
    statusContacts: null,
  },
  reducers: {
    setContacts: (state, action) => {
      return { ...state, contacts: [...action.payload] };
    },
    setContact: (state, action) => {
      return { ...state, contact: { ...action.payload } };
    },
    addOrEditContact: (state, { payload: values }) => {
      const i = state.contacts.findIndex((item) => item.id === values.id);
      if (i > -1) {
        state.contacts[i] = { ...values };
      } else state.contacts.unshift(values);
      return state;
    },
    deleteContact: (state, { payload }) => {
      const newItems = state.contacts.filter((item) => item.id !== payload);
      return { ...state, contacts: [...newItems] };
    },
  },
  extraReducers: {
    [getContacts.pending]: (state) => {
      state.statusContacts = "loading";
    },
    [getContacts.fulfilled]: (state, action) => {
      Array.isArray(action.payload) === true &&
        (state.contacts = action.payload);
      Array.isArray(action.payload) === true &&
        (state.statusContacts = "loaded");
    },
    [getContacts.rejected]: (state) => {
      state.statusContacts = "failed";
    },
  },
});

export const { setContacts, setContact, addOrEditContact, deleteContact } =
  contactSlice.actions;

export default contactSlice.reducer;
