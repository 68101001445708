import { createSlice } from "@reduxjs/toolkit";

export const loadingStatusSlice = createSlice({
  name: "loadingStatusSlice",
  initialState: {
    loadingStatus: false,
  },
  reducers: {
    setLoadingStatus: (state, action) => {
      return { ...state, loadingStatus: action.payload };
    },
  },
});

export const { setLoadingStatus } = loadingStatusSlice.actions;

export default loadingStatusSlice.reducer;
