import React, { useState, useEffect } from "react";
import axiosInstance from "../../Fetch/axios";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { batch, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
////// Mui
import { styled } from "@mui/material/styles";
import { Grid, TableContainer, Popover } from "@mui/material";
import { Table, TableSortLabel, Stack, useMediaQuery } from "@mui/material";
import { TableHead, TableBody, TableRow } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { TablePagination } from "@mui/material";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
////// Store
import { setAccount } from "../../Store/accountSlice";
import { setAuthLogIn, setStatusLogIn } from "../../Store/authLogInSlice";
import { getGroupsUser, deleteUser } from "../../Store/accountGroupUserSlice";
import { getNotifications } from "../../Store/notificationSlice";
import { getContacts, setContact } from "../../Store/contactSlice";
import { deleteContact } from "../../Store/contactSlice";
import { getItems as getCurrencies } from "../../Store/currencySlice";
import {
  getItems as getBankAccounts,
  deleteBankAccount,
} from "../../Store/bankAccountSlice";
import { setItemForEditBasic } from "../../Store/itemForEditBasicSlice";
import {
  getItem as getItemForItemInv,
  getItems as getInvItems,
  deleteItemForItemInv,
} from "../../Store/itemForItemInvSlice";
import {
  getItems as getTermsOfPayment,
  deleteNote,
} from "../../Store/noteTermSlice";
import {
  getItems as getContractTerms,
  deleteContractTerm,
} from "../../Store/contractTermSlice";
import { getCountries } from "../../Store/countrySlice";
import { setLoadingStatus } from "../../Store/loadingStatusSlice";
import { setPopup } from "../../Store/popupSlice";
import { setItemForEdit } from "../../Store/itemForEditSlice";
import {
  setNotification,
  addOrEditNotification,
} from "../../Store/notificationSlice";
import { setPopupBasic } from "../../Store/popupBasicSlice";
import { setFormSimpleTouched } from "../../Store/formTouchedSlice";
////// Controls & Components
import { appURL, baseURL } from "../../Data/baseURL";
import ButtonAction from "../../Controls/Buttons/ButtonAction";
import BankAccount from "../BankAccount/BankAccount";
import ConfirmDialog from "../../Controls/Popups/ConfirmDialog";
import Contact from "../Contact/Contact";
import ContractTerm from "../Term/ContractTerm";
import SignUpAddUser from "../../Pages/Authentication/SignUpAddUser";
import ItemInv from "../ItemInv/ItemInv";
import LinearProgressWrapper from "../../Controls/FormsUI/LinearProgress";
import { pages } from "../../Data/generalData";
import Popup from "../../Controls/Popups/Popup";
import PopupBasic from "../../Controls/Popups/PopupBasic";
import TermOfPayment from "../Term/TermOfPayment";
import QuickSearchInput from "../../Controls/Inputs/QuickSearchInput";
import { notificationReadURL } from "../../Data/baseURL";
import { maxNumberAllowedPerSubscription } from "../../Data/generalData";
import { AccountProfileDetails } from "../Profile/AccountProfileDetails";
import { columnsPersons } from "./Columns/columnsPersons";
import { useSocket } from "../../Fetch/useSocket";

const TableStyled = styled(Table)(({ theme }) => ({
  marginTop: theme.spacing(6 / 4),
  "& thead th": {
    fontWeight: "500",
    color: theme.palette.primary.dark,
    backgroundColor: "#9adcfb",
  },
  "& tbody th": {
    fontWeight: "300",
  },
  "& tbody tr:hover": {
    backgroundColor: "#fffbf2",
    cursor: "pointer",
  },
}));

const TableRowStyled = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: "#fffbf2",
    cursor: "pointer",
  },
}));

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {},
  [`&.${tableCellClasses.body}`]: {},
}));

const TypographyPopover = styled(Typography)(({ theme }) => ({
  padding: 16,
  color: "rgba(0, 0, 0, 0.87)",
  maxWidth: 350,
  fontSize: theme.typography.pxToRem(20),
}));

const TableSimple = ({ popupTitle, addButtonText, columns, data }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wsClient = useSocket();
  const theme = useTheme();
  const smMediaDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [items, setItems] = React.useState(data);
  const [refresh, setRefresh] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    type: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[1]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [filterFn] = useState({
    fn: (array) => {
      return array;
    },
  });
  const [quickSearchInput, setQuickSearchInput] = useState({
    target: "",
    initialStartSearchPage: null,
  });
  const [disabledAddButton, setDisabledAddButton] = useState(false);
  const [rowSelected, setRowSelected] = useState(null);
  const { account, subscription_type } = useSelector(
    (state) => state.accountSlice
  );
  const { itemForEdit } = useSelector((state) => state.itemForEditSlice);
  const { notification } = useSelector((state) => state.notificationSlice);

  ////// Start Popover section
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  ////// End Popover section

  ////// Set disabledAddButton depending of the subscription
  useEffect(() => {
    if (pathname === "/apps/items-for-item-inv") {
      if (
        (subscription_type === "active_free" &&
          data.length >= maxNumberAllowedPerSubscription[9]) ||
        (subscription_type === "active_pro" &&
          data.length >= maxNumberAllowedPerSubscription[10]) ||
        (subscription_type === "active_premium" &&
          data.length >= maxNumberAllowedPerSubscription[11])
      ) {
        setDisabledAddButton(true);
      } else {
        setDisabledAddButton(false);
      }
    }
    if (pathname === "/apps/clients") {
      if (
        (subscription_type === "active_free" &&
          data.length >= maxNumberAllowedPerSubscription[6]) ||
        (subscription_type === "active_pro" &&
          data.length >= maxNumberAllowedPerSubscription[7]) ||
        (subscription_type === "active_premium" &&
          data.length >= maxNumberAllowedPerSubscription[8])
      ) {
        setDisabledAddButton(true);
      } else {
        setDisabledAddButton(false);
      }
    }
    if (pathname === "/apps/users" && account && account.is_manager === true) {
      if (
        (subscription_type === "active_free" &&
          data.length >= maxNumberAllowedPerSubscription[0]) ||
        (subscription_type === "active_pro" &&
          data.length >= maxNumberAllowedPerSubscription[1]) ||
        (subscription_type === "active_premium" &&
          data.length >= maxNumberAllowedPerSubscription[2])
      ) {
        setDisabledAddButton(true);
      } else {
        setDisabledAddButton(false);
      }
    }
  }, [pathname, data.length, subscription_type, account]);

  useEffect(() => {
    setItems(data);
    dispatch(setLoadingStatus(false));
    setRefresh(false);
  }, [data, dispatch]);

  ////// On smMediaDown change rowsPerPage
  useEffect(() => {
    smMediaDown ? setRowsPerPage(pages[0]) : setRowsPerPage(pages[1]);
  }, [smMediaDown]);

  const addOrEdit = () => {
    batch(() => {
      dispatch(setPopup(false));
      dispatch(setItemForEdit(null));
    });
  };

  const handleClickRow = (i) => {
    setRowSelected(i || i === 0 ? i : null);
  };

  const openInPopup = (item) => {
    dispatch(setFormSimpleTouched(false));

    if (addButtonText === "") {
      dispatch(setNotification(item));
      dispatch(setPopupBasic(true));
    } else if (pathname === `${appURL}/bank-accounts`) {
      dispatch(setItemForEditBasic(item));
      dispatch(setPopup(true));
    } else if (
      pathname !== `${appURL}/countries` &&
      pathname !== `${appURL}/currencies`
    ) {
      batch(() => {
        dispatch(setItemForEdit(item));
        dispatch(setPopup(true));
      });
      if (pathname === `${appURL}/clients`) {
        wsClient
          .retrieve("contactstream", item.id)
          .then((resp) => {
            batch(() => {
              dispatch(setContact(resp));
              dispatch(setLoadingStatus(false));
            });
          })
          .catch(() => {
            dispatch(setLoadingStatus(false));
            toast.error(t("toast.entry_not_retrieved"));
          });
      }
      if (pathname === `${appURL}/items-for-item-inv`) {
        dispatch(getItemForItemInv(item.id));
      }
    }
    return null;
  };

  const handleAdd = () => {
    batch(() => {
      dispatch(setItemForEdit(null));
      dispatch(setItemForEditBasic(null));
      dispatch(setPopup(true));
    });
    return null;
  };

  const handleQuickSearch = (e) => {
    const target = e?.target?.value?.toString().toLowerCase() || "";

    setItems(() => {
      let newItems = [];
      if (target === "") {
        newItems = data;
      } else if (
        pathname !== "/apps/clients" &&
        pathname !== "/apps/users" &&
        pathname !== "/apps/bank-accounts"
      ) {
        // Default search
        newItems = data.filter((item) => {
          return columns.some((column) => {
            let stringItem = "";
            if (typeof JSON.stringify(item[column.id]) === "string")
              stringItem = JSON.stringify(item[column.id]);
            return stringItem.toString().toLowerCase().indexOf(target) > -1;
          });
        });
      } else if (pathname === "/apps/bank-accounts")
        newItems = data.filter(
          (item) =>
            (item.currencycode &&
              item.currencycode.currencycode
                .toString()
                .toLowerCase()
                .includes(target)) ||
            (item.bank_name &&
              item.bank_name.toString().toLowerCase().includes(target)) ||
            (item.bank_code &&
              item.bank_code.toString().toLowerCase().includes(target)) ||
            (item.iban_number &&
              item.iban_number.toString().toLowerCase().includes(target)) ||
            (item.bank_address &&
              item.bank_address.toString().toLowerCase().includes(target))
        );
      else if (pathname === "/apps/users")
        newItems = data.filter(
          (item) =>
            (item.first_name &&
              item.first_name.toString().toLowerCase().includes(target)) ||
            (item.last_name &&
              item.last_name.toString().toLowerCase().includes(target)) ||
            (item.email &&
              item.email.toString().toLowerCase().includes(target)) ||
            (item.phone &&
              item.phone.toString().toLowerCase().includes(target)) ||
            (item.is_manager && item.is_manager === true
              ? "manager".includes(target)
              : "dispatcher".includes(target))
        );
      else if (pathname === "/apps/clients") {
        newItems = data.filter(
          (item) =>
            (item.companyname &&
              item.companyname.toString().toLowerCase().includes(target)) ||
            (item.fiscalcode &&
              item.fiscalcode.toString().toLowerCase().includes(target)) ||
            (item.vatcode &&
              item.vatcode.toString().toLowerCase().includes(target)) ||
            (item.phone &&
              item.phone.toString().toLowerCase().includes(target)) ||
            (item.email1 &&
              item.email1.toString().toLowerCase().includes(target)) ||
            (item.countrycodelegal &&
              item.countrycodelegal.value
                .toString()
                .toLowerCase()
                .includes(target)) ||
            (item.zipcodelegal &&
              item.zipcodelegal.toString().toLowerCase().includes(target)) ||
            (item.citylegal &&
              item.citylegal.toString().toLowerCase().includes(target)) ||
            (item.countrycodepost &&
              item.countrycodepost.value
                .toString()
                .toLowerCase()
                .includes(target)) ||
            (item.zipcodepost &&
              item.zipcodepost.toString().toLowerCase().includes(target)) ||
            (item.citypost &&
              item.citypost.toString().toLowerCase().includes(target)) ||
            (item.addresspost &&
              item.addresspost.toString().toLowerCase().includes(target)) ||
            (item.comment1 &&
              item.comment1.toString().toLowerCase().includes(target)) ||
            (item.contact_persons &&
              item.contact_persons.find((person) => {
                return columnsPersons().some((column) => {
                  let stringItem = "";
                  if (typeof JSON.stringify(person[column.id]) === "string")
                    stringItem = JSON.stringify(person[column.id]);

                  return (
                    stringItem.toString().toLowerCase().indexOf(target) > -1
                  );
                });
              }))
        );
      }

      const lastPage =
        Math.ceil(filterFn.fn(newItems).length / rowsPerPage) - 1;

      if (page > lastPage && page !== 0) {
        handleChangePage(undefined, lastPage);
        setQuickSearchInput({ target: target, initialStartSearchPage: page });
      } else if (
        page > 0 &&
        lastPage > 0 &&
        page <= lastPage &&
        quickSearchInput.initialStartSearchPage
      ) {
        handleChangePage(undefined, quickSearchInput.initialStartSearchPage);
        setQuickSearchInput({ ...quickSearchInput, target: target });
      } else if (lastPage >= 0) {
        quickSearchInput.initialStartSearchPage &&
          lastPage >= quickSearchInput.initialStartSearchPage &&
          handleChangePage(undefined, quickSearchInput.initialStartSearchPage);
        setQuickSearchInput({ ...quickSearchInput, target: target });
      } else {
        setQuickSearchInput({ ...quickSearchInput, target: target });
      }

      return newItems;
    });
  };

  const clearQuickSearch = () => {
    handleChangePage(
      undefined,
      quickSearchInput.initialStartSearchPage || page
    );
    setItems(data);
    setQuickSearchInput({ target: "", initialStartSearchPage: null });
  };

  const handleRefresh = () => {
    setRefresh(true);
    setTimeout(() => dispatch(setLoadingStatus(false)), 5000);
    if (addButtonText === "") {
      dispatch(getNotifications("/"));
    } else
      switch (pathname) {
        case "/apps/clients":
          dispatch(getContacts());
          break;
        case "/apps/bank-accounts":
          dispatch(getBankAccounts());
          break;
        case "/apps/items-for-item-inv":
          dispatch(getInvItems());
          break;
        case "/apps/terms":
          dispatch(getContractTerms());
          break;
        case "/apps/terms-of-payment":
          dispatch(getTermsOfPayment("/apps/terms-of-payment"));
          break;
        case "/apps/countries":
          dispatch(getCountries());
          break;
        case "/apps/currencies":
          dispatch(getCurrencies());
          break;
        case "/apps/users":
          dispatch(getGroupsUser());
          break;
        default:
          break;
      }
  };

  const handleDelete = (id) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    axiosInstance({
      method: "DELETE",
      url: baseURL + pathname.replace(/s/, "") + "/" + id + "/",
    })
      .then((resp) => {
        if (resp.status === 204) {
          batch(() => {
            switch (pathname) {
              case `${appURL}/clients`:
                dispatch(deleteContact(id));
                break;
              case `${appURL}/bank-accounts`:
                dispatch(deleteBankAccount(id));
                break;
              case `${appURL}/items-for-item-inv`:
                dispatch(deleteItemForItemInv(id));
                break;
              case `${appURL}/terms-of-payment`:
                dispatch(deleteNote(id));
                break;
              case `${appURL}/terms`:
                dispatch(deleteContractTerm(id));
                break;
              case `${appURL}/users`:
                if (account.id === id) {
                  localStorage.removeItem("access_token");
                  localStorage.removeItem("refresh_token");
                  axiosInstance.defaults.headers["Authorization"] = null;
                  batch(() => {
                    dispatch(deleteUser(id));
                    dispatch(setAccount(null));
                    dispatch(setAuthLogIn(false));
                    dispatch(setStatusLogIn("notAuthorized"));
                  });
                  navigate("/");
                } else {
                  dispatch(deleteUser(id));
                }
                break;
              default:
                break;
            }
            dispatch(setLoadingStatus(false));
            dispatch(setPopup(false));
          });
          toast.success(() => t("toast.record_successfully_deleted"), {
            style: {
              marginTop: pathname === `${appURL}/users` ? 60 : undefined,
            },
          });
        }
      })
      .catch((error) => {
        error.response &&
          error.response.status === 401 &&
          toast.error(() => t("toast.only_account_manager_allowed"));
        error.response &&
          error.response.status === 404 &&
          toast.error(() => t("toast.record_not_found"));
        error.response &&
          error.response.status !== 401 &&
          error.response.status !== 404 &&
          toast.error(() => t("toast.record_not_allowed_be_deleted"));
      }, []);
  };

  const handleReadClick = (notification) => {
    axiosInstance({
      method: "PATCH",
      url: notificationReadURL + notification.id + "/",
      data: { pk: notification.id },
    })
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(addOrEditNotification(resp.data));
        }
      })
      .catch(() => {});
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const itemsAfterPagingAndSorting = () => {
    return stableSort(filterFn.fn(items), getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  };

  ////// Start empty rows generation
  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - items.length);
  const emptyRowsArray = Array.from(Array(emptyRows)).map((_, index) => index);
  ////// End empty rows generation

  return (
    <>
      {addButtonText !== "" && (
        <Grid container item sx={{ mt: 1 }}>
          <LinearProgressWrapper />
        </Grid>
      )}

      <Grid
        container
        item
        sx={{ mt: 2 }}
        component={addButtonText !== "" ? Paper : undefined}
      >
        {/* Add button  */}
        <Grid item sm={6} xs={12}>
          {(addButtonText && pathname !== "/apps/users") ||
          (addButtonText &&
            pathname === "/apps/users" &&
            account &&
            account.is_manager === true) ? (
            <>
              <ButtonAction
                color="primary"
                variant="outlined"
                size="medium"
                fullWidth={smMediaDown ? true : false}
                onClick={disabledAddButton ? handleClick : () => handleAdd()}
              >
                {addButtonText}
              </ButtonAction>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <TypographyPopover>
                  {t(
                    pathname === `${appURL}/users`
                      ? "toast.need_to_upgrade_in_order_to_register_new_users"
                      : pathname === "/apps/clients"
                      ? "toast.need_to_upgrade_in_order_to_register_new_clients"
                      : pathname === "/apps/items-for-item-inv"
                      ? "toast.need_to_upgrade_in_order_to_register_new_items_for_item_inv"
                      : undefined
                  )}
                </TypographyPopover>
              </Popover>
            </>
          ) : null}
        </Grid>

        {/* Quick search */}
        <Grid item sm={6} xs={12} sx={{ mt: smMediaDown ? 1.5 : 0 }}>
          <QuickSearchInput
            quickSearchInput={quickSearchInput}
            handleQuickSearch={handleQuickSearch}
            clearQuickSearch={clearQuickSearch}
            handleRefresh={handleRefresh}
            refresh={refresh}
          />
        </Grid>

        <TableContainer sx={{ mt: 1 }}>
          <TableStyled
            size="small"
            sx={{ minWidth: smMediaDown ? undefined : 650 }}
          >
            <TableHead>
              <TableRowStyled>
                {columns &&
                  columns.map((column, i) => {
                    return (
                      <TableCellStyled
                        key={i}
                        sx={{
                          minWidth: column.minWidth,
                          maxWidth: smMediaDown ? column.maxWidth : undefined,
                          align: column.align,
                        }}
                      >
                        <Typography
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          overflow="hidden"
                          fontWeight="550"
                        >
                          {column.disableSorting ? (
                            column.label
                          ) : (
                            <TableSortLabel
                              active={orderBy === column.id}
                              direction={orderBy === column.id ? order : "asc"}
                              onClick={() => {
                                handleSortRequest(column.id);
                              }}
                            >
                              {column.label}
                            </TableSortLabel>
                          )}
                        </Typography>
                      </TableCellStyled>
                    );
                  })}

                {pathname !== "/apps/countries" &&
                  pathname !== "/apps/currencies" && (
                    <TableCellStyled
                      align="left"
                      sx={{ minWidth: 110, maxWidth: 130, pl: 3 }}
                    >
                      <Typography
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        fontWeight="550"
                      >
                        {t("column.actions")}
                      </Typography>
                    </TableCellStyled>
                  )}
              </TableRowStyled>
            </TableHead>

            <TableBody>
              {columns &&
                // itemsAfterPagingAndSorting()?.length > 0 &&
                itemsAfterPagingAndSorting().map((row, i) => (
                  <TableRowStyled
                    key={i}
                    id={row.id}
                    onDoubleClick={() => {
                      openInPopup(row);
                    }}
                    onClick={() => handleClickRow(i)}
                    style={{
                      backgroundColor:
                        rowSelected === i ? "#fffbf2" : undefined,
                    }}
                  >
                    {columns.map((column, i) => {
                      return (
                        <TableCellStyled
                          key={column.id}
                          sx={{
                            minWidth: column.minWidth,
                            maxWidth: column.maxWidth,
                          }}
                        >
                          <Typography
                            noWrap
                            textOverflow="ellipsis"
                            overflow="hidden"
                            fontWeight="450"
                          >
                            {column.id === "contact_persons" &&
                              row[column.id].map(
                                (item, ind) =>
                                  (ind > 0 ? " / ... " : "") +
                                  item.last_name +
                                  " " +
                                  item.first_name
                              )}
                          </Typography>

                          {column.id === "countrycodepost" && (
                            <Typography
                              noWrap
                              textOverflow="ellipsis"
                              overflow="hidden"
                              fontWeight="450"
                            >
                              {row[column.id].value +
                                " " +
                                (row.zipcodepost || "") +
                                " " +
                                (row.citypost || "")}
                            </Typography>
                          )}

                          {column.id.startsWith("currencycode.") && (
                            <Typography
                              noWrap
                              textOverflow="ellipsis"
                              overflow="hidden"
                              fontWeight="450"
                            >
                              {row.currencycode?.currencycode}
                            </Typography>
                          )}

                          {column.id === "is_manager" && (
                            <Typography
                              noWrap
                              textOverflow="ellipsis"
                              overflow="hidden"
                              fontWeight="450"
                            >
                              {row[column.id] === true
                                ? t("general.manager")
                                : t("general.dispatcher")}
                            </Typography>
                          )}

                          {/* All other cases render below */}
                          {column.id !== "contact_persons" &&
                            column.id !== "countrycodepost" &&
                            !column.id.startsWith("currencycode.") &&
                            column.id !== "is_manager" && (
                              <Typography
                                noWrap
                                textOverflow="ellipsis"
                                overflow="hidden"
                                fontWeight="450"
                              >
                                {row[column.id]}
                              </Typography>
                            )}
                        </TableCellStyled>
                      );
                    })}
                    {pathname !== "/apps/countries" &&
                      pathname !== "/apps/currencies" &&
                      addButtonText !== "" && (
                        <TableCellStyled
                          align="left"
                          sx={{ minWidth: 90, maxWidth: 130 }}
                        >
                          <Stack direction="row">
                            <ButtonAction
                              color="primary"
                              style={{
                                maxWidth: "40px",
                                maxHeight: "20px",
                                minWidth: "40px",
                                minHeight: "20px",
                              }}
                              onClick={() => {
                                openInPopup(row);
                              }}
                            >
                              <ModeEditOutlineIcon fontSize="small" />
                            </ButtonAction>
                            <ButtonAction
                              color="secondary"
                              style={{
                                maxWidth: "40px",
                                maxHeight: "20px",
                                minWidth: "40px",
                                minHeight: "20px",
                              }}
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: t(
                                    "popup.are_you_sure_to_delete_record"
                                  ),
                                  subTitle: (
                                    <>
                                      <div>
                                        {t(
                                          "popup.you_can_not_undo_this_operation"
                                        )}
                                      </div>
                                      {pathname === "/apps/users" &&
                                        account &&
                                        account.is_manager && (
                                          <div>
                                            {t(
                                              "popup.additional_users_and_related_entries_will_be_deleted"
                                            )}
                                          </div>
                                        )}
                                    </>
                                  ),
                                  onConfirm: () => handleDelete(row.id),
                                });
                              }}
                            >
                              <DeleteOutlineIcon
                                fontSize="small"
                                color="secondary"
                              />
                            </ButtonAction>
                          </Stack>
                        </TableCellStyled>
                      )}

                    {/* Notification table only */}
                    {addButtonText === "" && (
                      <TableCellStyled
                        align="left"
                        size="small"
                        sx={{ minWidth: 110, maxWidth: 130 }}
                      >
                        <Stack direction="row">
                          <Box
                            onClick={() => handleReadClick(row)}
                            sx={{ ml: 1.5 }}
                          >
                            {row.read && row.read === true ? (
                              <MarkAsUnreadIcon color="warning" />
                            ) : (
                              <MarkunreadIcon color="primary" />
                            )}
                          </Box>
                        </Stack>
                      </TableCellStyled>
                    )}
                  </TableRowStyled>
                ))}

              {emptyRows > 0 &&
                emptyRowsArray.map((_, ind) => {
                  return (
                    <TableRowStyled
                      key={110 + ind}
                      style={{
                        height: 37.5,
                      }}
                    >
                      <TableCell
                        key={150 + ind}
                        colSpan={columns.length + 1}
                      ></TableCell>
                    </TableRowStyled>
                  );
                })}
            </TableBody>
          </TableStyled>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={pages}
          count={items.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>

      <Popup title={popupTitle}>
        {pathname === "/apps/clients" && (
          <Contact addOrEdit={addOrEdit} itemForEdit={itemForEdit} />
        )}
        {pathname === "/apps/bank-accounts" && (
          <BankAccount addOrEdit={addOrEdit} />
        )}
        {pathname === "/apps/items-for-item-inv" && (
          <ItemInv addOrEdit={addOrEdit} />
        )}
        {pathname === "/apps/terms-of-payment" && (
          <TermOfPayment addOrEdit={addOrEdit} itemForEdit={itemForEdit} />
        )}
        {pathname === "/apps/terms" && (
          <ContractTerm addOrEdit={addOrEdit} itemForEdit={itemForEdit} />
        )}
        {pathname === "/apps/users" && itemForEdit && (
          <AccountProfileDetails itemForEdit={itemForEdit} />
        )}
        {pathname === "/apps/users" && !itemForEdit && <SignUpAddUser />}
      </Popup>

      <PopupBasic notification={notification}></PopupBasic>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};
export default TableSimple;
