import { createSlice } from "@reduxjs/toolkit";

export const popupSlice = createSlice({
  name: "popupSlice",
  initialState: {
    popup: false,
    popup2: false,
    popupQuote: false,
    popupInv: false,
    popupInv2: false,
  },
  reducers: {
    setPopup: (state, action) => {
      return { ...state, popup: action.payload };
    },
    setPopup2: (state, action) => {
      return { ...state, popup2: action.payload };
    },
    setPopupQuote: (state, action) => {
      return { ...state, popupQuote: action.payload };
    },
    setPopupInv: (state, action) => {
      return { ...state, popupInv: action.payload };
    },
    setPopupInv2: (state, action) => {
      return { ...state, popupInv2: action.payload };
    },
  },
});

export const { setPopup, setPopup2, setPopupQuote, setPopupInv, setPopupInv2 } =
  popupSlice.actions;

export default popupSlice.reducer;
