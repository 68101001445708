import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../Fetch/axios";
import { imageURL } from "../Data/baseURL";

export const getLogo = createAsyncThunk(
  "companySlice/getLogo",
  async (imageid) => {
    return await axiosInstance({
      method: "GET",
      url: imageURL,
      data: JSON.stringify({ unique_field: imageid }),
      params: { unique_field: imageid },
    })
      .then((resp) => {
        if (resp.data) return resp.data;
      })
      .catch(() => {});
  }
);

export const getStamp = createAsyncThunk(
  "companySlice/getStamp",
  async (imageid) => {
    return await axiosInstance({
      method: "GET",
      url: imageURL,
      data: JSON.stringify({ unique_field: imageid }),
      params: { unique_field: imageid },
    })
      .then((resp) => {
        if (resp.data) return resp.data;
      })
      .catch(() => {});
  }
);

export const avatarSlice = createSlice({
  name: "avatarSlice",
  initialState: {
    avatar: null,
    logo: null,
    stamp: null,
    statusLogo: null,
    statusStamp: null,
  },
  reducers: {
    setAvatar: (state, action) => {
      state.avatar = action.payload;
      return state;
    },
    setLogo: (state, action) => {
      return { ...state, logo: action.payload };
    },
    setStamp: (state, action) => {
      return { ...state, stamp: action.payload };
    },
  },
  extraReducers: {
    [getLogo.pending]: (state) => {
      state.statusLogo = "loading";
    },
    [getLogo.fulfilled]: (state, action) => {
      return { ...state, logo: { ...action.payload }, statusLogo: "loaded" };
    },
    [getLogo.rejected]: (state) => {
      state.statusLogo = "failed";
    },
    [getStamp.pending]: (state) => {
      state.statusStamp = "loading";
    },
    [getStamp.fulfilled]: (state, action) => {
      return { ...state, stamp: { ...action.payload }, statusStamp: "loaded" };
    },
    [getStamp.rejected]: (state) => {
      state.statusLogo = "failed";
    },
  },
});

export const { setAvatar, setLogo, statusLogo, setStamp, statusStamp } =
  avatarSlice.actions;

export default avatarSlice.reducer;
