import React, { useEffect } from "react";
import { batch, useSelector, useDispatch } from "react-redux";
import axiosInstance from "../Fetch/axios";
import { useTranslation } from "react-i18next";
////// Mui
import { styled } from "@mui/material/styles";
import { Box, Typography, IconButton, Button } from "@mui/material";
import { Badge, Menu, MenuItem, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
////// Store
import { getNotifications, setNotification } from "../Store/notificationSlice";
import { addOrEditNotification } from "../Store/notificationSlice";
import { setPopupNotification } from "../Store/popupNotificationSlice";
import { setPopupBasic } from "../Store/popupBasicSlice";
////// Controls & Components
import TooltipBasic from "../Controls/Inputs/TooltipBasic";
import PopupBasic from "../Controls/Popups/PopupBasic";
import PopupRegular from "../Controls/Popups/PopupRegular";
import Notifications from "../Apm/Notification/Notifications";
import { notificationReadURL } from "../Data/baseURL";

const TypographyStyled = styled(Typography)(() => ({
  "&.MuiTypography-root": { fontWeight: "500" },
}));

const TypographyMessageStyled = styled(Typography)(() => ({
  "&.MuiTypography-root": { fontWeight: "300" },
}));

export default function NotificationMenu() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { notifications, status } = useSelector(
    (state) => state.notificationSlice
  );

  const ITEM_HEIGHT = 50;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    status === null && dispatch(getNotifications());
  }, [dispatch, status]);

  const notificationsUnread = notifications.filter(
    (notification) => notification.read === false
  );

  const notificationsUnreadLength = notificationsUnread.length;

  const handleNotificationCenterClick = () => {
    dispatch(setPopupNotification(true));
    setAnchorEl(null);
  };

  const handleReadClick = (id) => {
    axiosInstance({
      method: "PATCH",
      url: notificationReadURL + id + "/",
      data: { pk: id },
    })
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(addOrEditNotification(resp.data));
        }
      })
      .catch(() => {});
  };

  const openInPopupRegular = (notification) => {
    batch(() => {
      dispatch(setPopupBasic(true));
      dispatch(setNotification(notification));
    });

    return null;
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <TooltipBasic
          title={t("notification.tooltip")}
          placement='bottom-start'
        >
          <IconButton
            id='notification-button'
            aria-controls={open ? "'notification-menu" : undefined}
            aria-haspopup='true'
            aria-expanded={open ? "true" : undefined}
            color='inherit'
            sx={{ mt: 0.5, ml: 2 }}
            onClick={handleClick}
          >
            <Badge badgeContent={notificationsUnreadLength} color='secondary'>
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </TooltipBasic>
      </Box>

      <Menu
        id='notification-menu'
        aria-labelledby='notification-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 2,
          sx: {
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 2,
          },
          style: {
            maxHeight: ITEM_HEIGHT * 9.5,
            minWidth: 340,
            maxWidth: matches ? 600 : 350,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          component={Paper}
          sx={{ justifyContent: "center" }}
          onClick={() => handleNotificationCenterClick()}
        >
          <TypographyStyled variant='h6' lineHeight={2.5}>
            {t("notification.center")}
          </TypographyStyled>
          <OpenInNewIcon sx={{ ml: 1, pt: 0.5 }} color='primary' />
        </MenuItem>

        {notificationsUnreadLength === 0 ? (
          <MenuItem component={Paper}>
            <TypographyStyled>
              {t("notification.no_new_notifications")}
            </TypographyStyled>
          </MenuItem>
        ) : (
          notificationsUnread.map((notification, i) => {
            return (
              <div key={i} style={{ width: "100%" }}>
                <MenuItem
                  component={Paper}
                  divider={notificationsUnreadLength > i + 1 ? true : false}
                  sx={{
                    mt: 0.5,
                    p: 1,
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{ pl: 0.5, width: "72%" }}
                    onClick={() => openInPopupRegular(notification)}
                  >
                    <TypographyStyled noWrap>
                      {notification.title}
                    </TypographyStyled>
                    <TypographyMessageStyled noWrap>
                      {notification.message}
                    </TypographyMessageStyled>
                  </Box>

                  <Box sx={{ pl: 1.5, width: "28%" }}>
                    {matches ? (
                      <Button
                        variant='outlined'
                        size='small'
                        onClick={() => handleReadClick(notification.id)}
                      >
                        <Typography
                          variant='button'
                          style={{
                            display: "inline-block",
                            whiteSpace: "pre-line",
                          }}
                        >
                          {t("button.mark_as_read")}
                        </Typography>
                        <MarkAsUnreadIcon size='large' color='warning' />
                      </Button>
                    ) : (
                      <IconButton
                        size='large'
                        color='warning'
                        onClick={() => handleReadClick(notification.id)}
                      >
                        <MarkAsUnreadIcon />
                      </IconButton>
                    )}
                  </Box>
                </MenuItem>
              </div>
            );
          })
        )}
      </Menu>

      <PopupRegular title={t("notification.center")}>
        <Notifications />
      </PopupRegular>

      {/* Need it empty here to open the notificatoin on click */}
      <PopupBasic></PopupBasic>
    </>
  );
}
