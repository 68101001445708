import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../Fetch/axios";
import { toast } from "react-toastify";
import i18n from "i18next";

import { currencyURL } from "../Data/baseURL";

export const getItems = createAsyncThunk(
  "currencySlice/getItems",
  async (route) => {
    return axiosInstance.get(currencyURL).then((response) => {
      if (response.data) {
        route !== "/" && toast.success(i18n.t("toast.currency_list_updated"));
        return response.data;
      }
    });
  }
);

export const currencySlice = createSlice({
  name: "currencySlice",
  initialState: {
    currencies: [],
    currency: {},
    statusCurrencies: null,
  },
  reducers: {
    setItems: (state, action) => {
      return { ...state, currencies: [...action.payload] };
    },
    setCurrency: (state, action) => {
      return { ...state, currency: { ...action.payload } };
    },
    addOrEditItem: (state, { payload: values }) => {
      const i = state.currencies.findIndex((item) => item.id === values.id);
      if (i > -1) state.currencies[i] = values;
      else state.currencies.push(values);
      return state;
    },
    deleteItem: (state, { payload }) => {
      const newItems = state.currencies.filter((item) => item.id !== payload);

      return { ...state, currencies: [...newItems] };
    },
  },
  extraReducers: {
    [getItems.pending]: (state) => {
      state.statusCurrencies = "loading";
    },
    [getItems.fulfilled]: (state, action) => {
      Array.isArray(action.payload) === true &&
        (state.currencies = action.payload);
      state.statusCurrencies = "loaded";
    },
    [getItems.rejected]: (state) => {
      state.statusCurrencies = "failed";
    },
  },
});

export const { setItems, setCurrency, addOrEditItem, deleteItem } =
  currencySlice.actions;

export default currencySlice.reducer;
