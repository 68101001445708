import { createSlice } from "@reduxjs/toolkit";

export const popupTopSlice = createSlice({
  name: "popupTopSlice",
  initialState: {
    popupTop: false,
    popupTopInv: false,
  },
  reducers: {
    setPopupTop: (state, action) => {
      state.popupTop = action.payload;
      return state;
    },
    setPopupTopInv: (state, action) => {
      state.popupTopInv = action.payload;
      return state;
    },
  },
});

export const { setPopupTop, setPopupTopInv } = popupTopSlice.actions;

export default popupTopSlice.reducer;
