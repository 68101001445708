import React from "react";
import { useField } from "formik";
////// Mui
import { styled } from "@mui/material/styles";
import { FormHelperText } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";

const OutlinedInputStyled = styled(OutlinedInput)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(-1),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#f5f5f5" : "#f5f5f5",
    fontSize: 16,
    fontWeight: 500,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function TextFieldNormal({
  name,
  label,
  required,
  type,
  ...otherProps
}) {
  const [field, meta] = useField(String(name));

  const inputLabelProps = {
    shrink: true,
    required: required,
    sx: { fontSize: 18, fontWeight: "700" },
  };

  const configTextField = {
    ...field,
    ...otherProps,
    fullWidth: true,
    type: type,
    size: "small",
    value:
      (field.value !== undefined &&
        field.value !== null &&
        field.value.toString()) ||
      "",
    sx: {
      backgroundColor: "#f5f5f5",
      autoComplete: "off",
    },
  };

  const configHelperText = {
    sx: { color: "red", fontSize: 14, fullWidth: true },
  };

  let helperText = "";

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    helperText = meta.error;
  }

  return (
    <React.Fragment>
      <InputLabel {...inputLabelProps} htmlFor={field.name}>
        {label}
      </InputLabel>
      <OutlinedInputStyled
        id={field.name}
        name={name}
        {...configTextField}
        style={{
          cursor: field.name.startsWith("date_") ? "pointer" : undefined,
        }}
      />
      <FormHelperText error {...configHelperText}>
        {helperText}
      </FormHelperText>
    </React.Fragment>
  );
}
