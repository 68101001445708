import React, { useState, useRef, Children } from "react";
import Draggable from "react-draggable";
import { batch, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
////// Mui
import { useTheme, useMediaQuery, Paper } from "@mui/material";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { Box, Grid, LinearProgress, Typography } from "@mui/material";
////// Store
import { setPopup2 } from "../../Store/popupSlice";
import ButtonAction from "../Buttons/ButtonAction";
import { setItemForEdit } from "../../Store/itemForEditSlice";
import { setItemEmail } from "../../Store/itemEmailSlice";
import { setFormSimpleTouched } from "../../Store/formTouchedSlice";
////// Controls & Components
import ConfirmTouched from "./ConfirmTouched";
import ConfirmDialog from "./ConfirmDialog";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-popup2-title"
      cancel={'[class*="popup2-cancel"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Popup2 = ({ title, children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const mdMediaDown = useMediaQuery(theme.breakpoints.down("md"));
  const { loadingStatus } = useSelector((state) => state.loadingStatusSlice);
  const { formSimpleTouched } = useSelector((state) => state.formTouchedSlice);
  const { popup2 } = useSelector((state) => state.popupSlice);
  const [confirmTouched, setConfirmTouched] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    type: "",
  });

  const childCompRef = useRef();

  const childrenWithProps = Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { refSecond: childCompRef });
    }
    return child;
  });

  function confirmEscClose() {
    batch(() => {
      dispatch(setPopup2(false));
      dispatch(setItemEmail(null));
      dispatch(setFormSimpleTouched(false));
    });
    setTimeout(() => dispatch(setItemForEdit(null)), 100); // setTime used not to flackern/blick when closing popup in popoupTop
  }

  function handleEscClose() {
    if (formSimpleTouched === false) {
      confirmEscClose();
    } else {
      setConfirmTouched({
        isOpen: true,
        title: t("popup.you_have_unsaved_changes"),
        onBackToPage: () => [
          setConfirmTouched({ ...confirmTouched, isOpen: false }),
        ],
        onSave: () => [
          childCompRef.current.handleSubmit(),
          setConfirmTouched({ ...confirmTouched, isOpen: false }),
          dispatch(setFormSimpleTouched(false)),
        ],
        onConfirm: () => [
          confirmEscClose(),
          setConfirmTouched({ ...confirmTouched, isOpen: false }),
          dispatch(setFormSimpleTouched(false)),
        ],
      });
    }
  }

  return (
    <>
      <Dialog
        open={popup2}
        fullWidth={
          title &&
          !mdMediaDown &&
          (title === t("popup.title.invoice_terms") ||
            title === t("popup.title.email_basic"))
            ? true
            : false
        }
        maxWidth={location.pathname === "/apps/terms" ? "xl" : "lg"}
        sx={{
          "& .MuiDialog-container": {
            justifyContent: "flex-center",
            alignItems: "flex-start",
          },
        }}
        PaperProps={{
          sx: {
            mt: 2,
            m: mdMediaDown ? 0.75 : undefined,
          },
        }}
        onClose={handleEscClose}
        PaperComponent={PaperComponent}
        BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.1)" } }}
      >
        <DialogTitle sx={{ bgcolor: "#f5f5f5" }}>
          <Grid container item>
            <Grid item xs={9} id="draggable-popup2-title">
              <Typography variant="h6">{title}</Typography>
            </Grid>

            <Grid
              container
              item
              xs={3}
              justifyContent={"flex-end"}
              id="popup2-cancel"
            >
              <ButtonAction color="secondary" onClick={() => handleEscClose()}>
                {t("button.esc")}
              </ButtonAction>
            </Grid>
          </Grid>
        </DialogTitle>

        <Box sx={{ width: "100%" }}>
          {loadingStatus === true ? (
            <LinearProgress />
          ) : (
            <LinearProgress variant="determinate" value={100} />
          )}
        </Box>

        <DialogContent sx={{ p: 1.5 }} dividers>
          {childrenWithProps}
        </DialogContent>
      </Dialog>

      <ConfirmTouched
        confirmTouched={confirmTouched}
        setConfirmTouched={setConfirmTouched}
      />

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default Popup2;
