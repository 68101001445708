import React from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import { Image, Line, Svg, StyleSheet } from "@react-pdf/renderer";
////// Controls & Components
import InvPdfLoadDetails from "../../Inv/InvPdf/InvPdfLoadDetails";
import QuotePdfUp from "./QuotePdfUp";
// import QuotePdfPortDetails from "./QuotePdfPortDetails";
import QuotePdfLoadDetails from "./QuotePdfLoadDetails";
import QuotePdfTable from "./QuotePdfTable";
import QuotePdfConditions from "./QuotePdfConditions";
import QuotePdfTerms from "./QuotePdfTerms";

////// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 2,
    paddingBottom: 80,
    paddingLeft: 47,
    paddingRight: 13,
  },
  boxM: {
    marginBottom: 2,
    justifyContent: "flex-start",
    width: 269,
  },
  boxXL: {
    marginTop: 2,
    flexDirection: "row",
    marginBottom: 2,
    justifyContent: "flex-start",
    width: 538,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  line: {
    strokeWidth: 2,
    color: "black",
  },
  logo: {
    maxHeight: 80,
    width: "auto",
    maxWidth: 150,
    marginLeft: "auto",
    marginRight: "auto",
    objectFit: "contain",
  },
  stamp: {
    maxHeight: 100,
    width: "auto",
    maxWidth: 156,
    marginLeft: 7,
    marginRight: "auto",
  },
  rowContainer: {
    flexDirection: "row",
    marginBottom: 1,
    justifyContent: "flex-start",
    width: 535,
  },
  rowContainerBasic: {
    flexDirection: "row",
    marginTop: 1,
    marginBottom: 1,
    justifyContent: "flex-start",
  },
  rowContainerClient: {
    width: "auto",
    maxWidth: "250px",
    minWidth: "200px",
    justifyContent: "end",
    backgroundColor: "#f5f5f5",
    borderRadius: "5px",
    border: "1px solid #cccccc ",
    padding: "5px",
    height: "auto",
  },
  orderDate: {
    fontSize: 12,
    fontStyle: "bold",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 8,
    left: 0,
    right: 16,
    textAlign: "right",
    color: "black",
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Roboto",
  },
  text1: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Roboto",
  },
  text2: {
    fontSize: 12,
    textAlign: "left",
    fontFamily: "Roboto",
  },
  text3: {
    fontSize: 8,
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    // fontWeight: "bold",
    width: 177,
  },
  text4: {
    fontSize: 8,
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    // fontWeight: "bold",
    width: 358,
  },
  titleM: {
    fontSize: 12,
    textAlign: "left",
    fontFamily: "Roboto",
  },
  titleXL: {
    flexDirection: "row",
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Roboto",
    marginTop: 3,
  },
  date: {
    flexDirection: "row",
    fontSize: 10,
    textAlign: "end",
    fontFamily: "Roboto",
    marginTop: 3,
    maxWidth: "250px",
  },
  noteText: {
    display: "flex",
    fontSize: 10,
    justifyContent: "center",
    fontFamily: "Roboto",
    marginTop: 3,
  },
});

////// Create Document Component
const QuotePdfDocument = (
  account,
  company,
  values,
  logo,
  invoicePdfLang,
  checked,
  checkedEntries,
  freeAppMssg
) => {
  const clientLegalAddress =
    values &&
    values.bill_to &&
    (values.bill_to.countrycodelegal &&
      values.bill_to.countrycodelegal.value &&
      values.bill_to.countrycodelegal.value) +
      (values.bill_to.zipcodelegal ? " - " + values.bill_to.zipcodelegal : "") +
      (values.bill_to.citylegal ? ", " + values.bill_to.citylegal : "") +
      (values.bill_to.addresslegal ? ", " + values.bill_to.addresslegal : "");

  return (
    <React.Fragment>
      <Document
        title={
          (invoicePdfLang === "RO/RU"
            ? "OFERTA/ ЦЕНОВОЕ ПРЕДЛ. # "
            : "Quote#") + (values.qn || "")
        }
      >
        {values && (
          <Page style={styles.body}>
            <View
              style={{
                ...styles.boxXL,
                marginTop: 12,
                justifyContent: "space-between",
              }}
            >
              <QuotePdfUp
                account={account}
                company={company}
                invoicePdfLang={invoicePdfLang}
              />

              {/* Logo */}
              <View
                style={{
                  ...styles.boxM,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {logo && (
                  <Image
                    style={styles.logo}
                    src={{
                      uri: `${logo}`,
                      method: "GET",
                      headers: { "Cache-Control": "no-cache" },
                      body: "",
                    }}
                  />
                )}
              </View>
            </View>

            <Svg style={{ height: 2, width: "538" }}>
              <Line
                x1='0'
                y1='0'
                x2='538'
                y2='0'
                strokeWidth={2}
                stroke='grey'
              />
            </Svg>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <View
                style={{ ...styles.rowContainerBasic, flexDirection: "column" }}
              >
                <Text
                  style={{ ...styles.date, fontSize: 12 }}
                  render={() =>
                    (invoicePdfLang === "RO/RU"
                      ? "OFERTA / ЦЕНОВОЕ ПРЕДЛОЖЕНИЕ # "
                      : "QUOTE # ") + (values.qn || "")
                  }
                ></Text>
                <Text
                  style={styles.date}
                  render={() =>
                    `${
                      invoicePdfLang === "RO/RU"
                        ? `Din/ От: ${
                            values.date_due
                              ? new Date(values.date_inv).toLocaleDateString(
                                  "fr-EN"
                                )
                              : ""
                          }`
                        : `From: ${
                            values.date_due
                              ? new Date(values.date_inv).toLocaleDateString(
                                  "fr-EN"
                                )
                              : ""
                          }`
                    }`
                  }
                />
                <Text
                  style={styles.date}
                  render={() =>
                    `${
                      invoicePdfLang === "RO/RU"
                        ? `Valabila pana la/ Действ. до: ${
                            values.date_due
                              ? new Date(values.date_due).toLocaleDateString(
                                  "fr-EN"
                                )
                              : ""
                          }`
                        : `Valid until:  ${
                            values.date_due
                              ? new Date(values.date_due).toLocaleDateString(
                                  "fr-EN"
                                )
                              : ""
                          }`
                    }`
                  }
                />

                <Text style={styles.date}>
                  {invoicePdfLang === "RO/RU"
                    ? "Termeni/ Условия: "
                    : "Terms: "}
                  {values.incoterm ? values.incoterm.it || "" : ""}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View style={{ ...styles.rowContainerClient, padding: "10px" }}>
                  <Text style={styles.titleM}>
                    {values.bill_to ? values.bill_to.companyname : "- / -"}
                  </Text>
                  <Text style={styles.date}>{clientLegalAddress}</Text>
                  <Text style={styles.date}>
                    {(invoicePdfLang === "RO/RU"
                      ? "Cod fisc./ Фиск. код: "
                      : "Fiscal code: ") +
                      (values.bill_to ? values.bill_to.fiscalcode || "" : "")}
                  </Text>
                  <Text style={styles.date}>
                    {values.person &&
                      values.person.first_name + " " + values.person.last_name}
                  </Text>
                  <Text style={styles.date}>
                    Tel/ e-mail:{" "}
                    {values && values.person
                      ? values.person.phone && values.person.email
                        ? values.person.phone + " / " + values.person.email
                        : (values.person.phone || "") +
                          (values.person.email || "")
                      : ""}
                  </Text>
                </View>
              </View>
            </View>

            {/* Place of loading / unloading */}
            {checked && (
              <>
                <Svg style={{ height: 2, width: "538" }}>
                  <Line
                    x1='0'
                    y1='0'
                    x2='535'
                    y2='0'
                    strokeWidth={2}
                    stroke='grey'
                  />
                </Svg>

                <InvPdfLoadDetails
                  values={values}
                  invoicePdfLang={invoicePdfLang}
                />
              </>
            )}

            {/* Load details */}
            {checkedEntries && (
              <>
                <Svg style={{ height: 2, width: "538" }}>
                  <Line
                    x1='0'
                    y1='0'
                    x2='535'
                    y2='0'
                    strokeWidth={2}
                    stroke='grey'
                  />
                </Svg>
                <QuotePdfLoadDetails
                  values={values}
                  invoicePdfLang={invoicePdfLang}
                />
              </>
            )}

            <Svg style={{ height: 2, width: "538" }}>
              <Line
                x1='0'
                y1='0'
                x2='538'
                y2='0'
                strokeWidth={2}
                stroke='grey'
              />
            </Svg>

            <QuotePdfTable values={values} invoicePdfLang={invoicePdfLang} />

            <QuotePdfTerms values={values} invoicePdfLang={invoicePdfLang} />

            <QuotePdfConditions
              values={values}
              invoicePdfLang={invoicePdfLang}
            />

            <Svg
              style={{
                height: 2,
                width: "538",
                left: 0,
                right: 0,
                verticalAlign: "bottom",
              }}
            >
              <Line
                x1='0'
                y1='0'
                x2='538'
                y2='0'
                strokeWidth={2}
                stroke='grey'
              />
            </Svg>

            <View
              style={{
                ...styles.boxXl,
                flexDirection: "column",
                marginTop: "10px",
              }}
            />

            <View
              style={{
                left: 0,
                right: 0,
                position: "absolute",
                verticalAlign: "bottom",
                paddingLeft: 47,
                bottom: 40,
              }}
              fixed
            >
              <Text
                style={{
                  ...styles.text4,
                  position: "absolute",
                  marginLeft: 50,
                  fontSize: 6,
                }}
                render={() => freeAppMssg}
              />

              <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                  `Page ${pageNumber} / ${totalPages}`
                }
              />
            </View>
          </Page>
        )}
      </Document>
    </React.Fragment>
  );
};

export default QuotePdfDocument;
