import React from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
////// Mui
import { DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { Dialog, IconButton, Typography, Button, Paper } from "@mui/material";
import FmdBadIcon from "@mui/icons-material/FmdBad";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-confirm-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const ConfirmDialog = (props) => {
  const { confirmDialog, setConfirmDialog } = props;
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Dialog
        open={confirmDialog.isOpen}
        sx={{
          "& .MuiDialog-container": {
            justifyContent: "flex-center",
            alignItems: "flex-start",
          },
          top: 75,
        }}
        PaperComponent={PaperComponent}
        BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.1)" } }}
        aria-labelledby="draggable-confirm-dialog"
      >
        <DialogTitle sx={{ textAlign: "center", cursor: "move" }}>
          <IconButton
            disableRipple
            sx={{
              color: "#ba000d",
              "&:hover": {
                backgroundColor: "inherit",
                cursor: "move",
              },
              "& .MuiSvgIcon-root": {
                fontSize: "4rem",
              },
            }}
          >
            <FmdBadIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ textAlign: "center" }}>
          <Typography variant="h6">{confirmDialog.title}</Typography>
          <Typography variant="subtitle2">{confirmDialog.subTitle}</Typography>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center", mb: 1 }}>
          <Button
            variant="contained"
            color="inherit"
            onClick={() =>
              setConfirmDialog({ ...confirmDialog, isOpen: false })
            }
          >
            {t("button.no")}
          </Button>

          <Button
            variant="contained"
            color="error"
            onClick={() => [
              setConfirmDialog({ ...confirmDialog, isOpen: false }),
              confirmDialog.onConfirm(),
            ]}
          >
            {t("button.yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ConfirmDialog;
