import React from "react";
import { useTranslation } from "react-i18next";
////// Mui
import { styled } from "@mui/material/styles";
import { Typography, Grid, Box, useMediaQuery } from "@mui/material";
////// Controls & Components
import { element } from "../Data/imageURL";
import printInvoice from "../Data/Images/PrintInvoice.svg";

const TypographyMain = styled(Typography)(({ lgmedia, white }) => ({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 700,
  // fontSize: lgmedia === "true" ? "40px" : "64px",
  lineHeight: lgmedia === "true" ? "50px" : "77px",
  letterSpacing: "-0.02em",
  color: white === "white" ? "#FFFFFF" : "#00000",
}));
const TypographySecondary = styled(Typography)(({ white }) => ({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "30px",
  letterSpacing: "-0.02em",
  color: white === "white" ? "#FFFFFF" : "#00000",
}));

const Integration1c = ({ px, mainFontSize }) => {
  const { t } = useTranslation();
  // const xsMediaMax500 = useMediaQuery("(max-width:500px)");
  const lgMediaMax1200 = useMediaQuery("(max-width:1200px)");

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          position: "static",
          flexDirection: "column",
          padding: 0,
          alignItems: "flex-start",
          justifyContent: "center",
          width: "656px",
          gap: lgMediaMax1200 ? "30px" : "60px",
          height: "auto",
          boxSizing: "content",
        }}
      >
        <div>
          <TypographyMain
            white="white"
            sx={{ fontSize: mainFontSize }}
            lgmedia={lgMediaMax1200.toString()}
          >
            {t("base.title_1c_integration")}
          </TypographyMain>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              pt: "10px",
              width: "100%",
              maxWidth: "400px",
            }}
          >
            <img src={element} alt="line element" style={{ width: "100%" }} />
          </Box>
        </div>
        <TypographySecondary white="white">
          {t("base.subtitle_1c_integration")}
        </TypographySecondary>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: 0,
          gap: lgMediaMax1200 ? "30px" : "60px",
          height: "auto",
          justifyContent: "center",
          pt: { xs: "60px", md: 0 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "auto",
            alignSelf: "center",
            pl: { xs: 0, md: "10%" },
            maxWidth: "500px",

            borderRadius: "8px",
          }}
        >
          <img
            src={printInvoice}
            alt="1c"
            style={{
              zIndex: 1,
              width: "100%",
              height: "auto",
              objectFit: "contain",
              borderRadius: "10px",
              alignSelf: "center",
              backgroundColor: "#E6EBF1",
            }}
          ></img>
        </Box>
      </Grid>
    </React.Fragment>
  );
};

export default Integration1c;
