import React from "react";
// Controls & Components
import TextFieldAuto from "./TextFieldAuto";

const InputArray = ({ field, label, required, ...otherProps }) => {

  const inputLabelProps = {
    label: label,
    required: required,
  };

  const configTextfield = {
    ...field,
    ...otherProps,
    value:
      otherProps.type === "datetime-local"
        ? field.value !== ""
          ? new Date(new Date(field.value))
          : // .toString().split("GMT")[0] + " UTC").toISOString().split(".")[0]
            new Date(
              new Date(new Date().setHours(0, 0, 0, 0))
              // .toString().split("GMT")[0] + " UTC"
            )
              .toISOString()
              .split(".")[0]
        : otherProps.type === "date"
        ? field.value && field.value !== ""
          ? new Date(field.value).toISOString().split("T")[0]
          : new Date(new Date()).toISOString().split("T")[0]
        : otherProps.type === "number"
        ? field.value && field.value === 0
          ? "0"
          : (field.value &&
              Number(field.value) &&
              Number(field.value).toFixed(2)) ||
            ""
        : field.value || "",
  };

  return (
    <React.Fragment>
      <TextFieldAuto inputLabelProps={inputLabelProps} {...configTextfield} />
    </React.Fragment>
  );
};

export default InputArray;
