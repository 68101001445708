import { createSlice } from "@reduxjs/toolkit";

export const websocketSlice = createSlice({
  name: "websocketSlice",
  initialState: {
    websocket: {},
    wsClientIsReady: true,
    statusWsClient: "1",
  },
  reducers: {
    setWsClientIsReady: (state, action) => {
      state.wsClientIsReady = action.payload;
      return state;
    },
    setStatusWsClient: (state, action) => {
      state.statusWsClient = action.payload;
      return state;
    },
  },
});

export const { setWsClientIsReady, setStatusWsClient } = websocketSlice.actions;

export default websocketSlice.reducer;
