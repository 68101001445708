import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../Fetch/axios";
import { toast } from "react-toastify";
import i18n from "i18next";
import { countryURL } from "../Data/baseURL";

export const getCountries = createAsyncThunk(
  "countrySlice/getCountries",
  async (route) => {
    return axiosInstance.get(countryURL).then((response) => {
      if (response.data && response.status === 200) {
        route !== "/" &&
          route !== "/apps/clients" &&
          toast.success(i18n.t("toast.country_list_updated"));
        return response.data;
      }
    });
  }
);

export const countrySlice = createSlice({
  name: "countrySlice",
  initialState: {
    countries: [],
    statusCountries: null,
  },
  reducers: {
    setItems: (state, action) => {
      return { ...state, countries: [...action.payload] };
    },
    addOrEditItem: (state, { payload: values }) => {
      const i = state.countries.findIndex((item) => item.id === values.id);
      if (i > -1) state.loadTypes[i] = values;
      else state.countries.unshift(values);
      return state;
    },
    deleteItem: (state, { payload }) => {
      const newItems = state.items.filter((item) => item.id !== payload);
      return { ...state, countries: [...newItems] };
    },
  },
  extraReducers: {
    [getCountries.pending]: (state) => {
      state.statusCountries = "loading";
    },
    [getCountries.fulfilled]: (state, action) => {
      Array.isArray(action.payload) === true &&
        (state.countries = action.payload);
      state.statusCountries = "loaded";
    },
    [getCountries.rejected]: (state) => {
      state.statusCountries = "failed";
    },
  },
});

export const { setItems, addOrEditItem, deleteItem } = countrySlice.actions;

export default countrySlice.reducer;
