import React, { useEffect, lazy, Suspense } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./styles/style.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enGB, ro, ru } from "date-fns/locale";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, Slide } from "react-toastify";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Navigate, useLocation } from "react-router-dom";
import i18next from "i18next";
import { HelmetProvider } from "react-helmet-async";

////// Mui
import { Box, CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import * as locales from "@mui/material/locale";
////// Store
import { getAuthenticated } from "./components/Store/authLogInSlice";
import { setStatusLogIn } from "./components/Store/authLogInSlice";
////// Controls & Components
import { appURL } from "./components/Data/baseURL";
// import ActivateUser from "./components/Pages/Authentication/ActivateUser";
// import BankAccounts from "./components/Apm/BankAccount/BankAccounts";
// import AllCountries from "./components/Pages/AllCountries";
// import Account from "./components/Pages/Account";
import Base from "./components/Pages/Base";
// import Company from "./components/Pages/Company";
// import Contacts from "./components/Pages/Contacts";
// import ContractTerms from "./components/Apm/Term/ContractTerms";
// import Currencies from "./components/Apm/Currency/Currencies";
import Dashboard from "./components/Pages/Dashboard";
// import Faq from "./components/Apm/Faq/Faq";
// import ItemsInv from "./components/Apm/ItemInv/ItemsInv";
import Header from "./components/Header/Header";
// import LogIn from "./components/Pages/Authentication/LogIn";
// import MyInvs from "./components/Pages/MyInvs";
// import MyQuotes from "./components/Pages/MyQuotes";
// import NotFound from "./components/Pages/NotFound";
// import PassReset from "./components/Pages/Authentication/PassReset";
// import PassResetConfirm from "./components/Pages/Authentication/PassResetConfirm";
// import Products from "./components/Pages/Products";
// import Settings from "./components/Pages/Settings";
// import SignUp from "./components/Pages/Authentication/SignUp";
// import Subscription from "./components/Pages/Subscription";
// import Support from "./components/Apm/Other/Support";
// import TermsConditions from "./components/Apm/Faq/TermsConditions";
// import TermsOfPayment from "./components/Apm/Term/TermsOfPayment";
// import UserManagement from "./components/Pages/UserManagement";
import { WebSocketProvider } from "./components/Fetch/websocket";
// import LinearProgressWrapper from "./components/Controls/FormsUI/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

// Suspense
const LogIn = lazy(() => import("./components/Pages/Authentication/LogIn"));
const MyInvs = lazy(() => import("./components/Pages/MyInvs"));
const MyQuotes = lazy(() => import("./components/Pages/MyQuotes"));
const NotFound = lazy(() => import("./components/Pages/NotFound"));
const PassReset = lazy(() =>
  import("./components/Pages/Authentication/PassReset")
);
const PassResetConfirm = lazy(() =>
  import("./components/Pages/Authentication/PassResetConfirm")
);
const Products = lazy(() => import("./components/Pages/Products"));
const Settings = lazy(() => import("./components/Pages/Settings"));
const SignUp = lazy(() => import("./components/Pages/Authentication/SignUp"));
const Subscription = lazy(() => import("./components/Pages/Subscription"));
const Support = lazy(() => import("./components/Apm/Other/Support"));
const TermsConditions = lazy(() =>
  import("./components/Apm/Faq/TermsConditions")
);
const TermsOfPayment = lazy(() =>
  import("./components/Apm/Term/TermsOfPayment")
);
const UserManagement = lazy(() => import("./components/Pages/UserManagement"));
const BankAccounts = lazy(() =>
  import("./components/Apm/BankAccount/BankAccounts")
);

const ActivateUser = lazy(() =>
  import("./components/Pages/Authentication/ActivateUser")
);
const AllCountries = lazy(() => import("./components/Pages/AllCountries"));
const Account = lazy(() => import("./components/Pages/Account"));
const Company = lazy(() => import("./components/Pages/Company"));
const Contacts = lazy(() => import("./components/Pages/Contacts"));
const ContractTerms = lazy(() => import("./components/Apm/Term/ContractTerms"));
const Currencies = lazy(() => import("./components/Apm/Currency/Currencies"));
const Faq = lazy(() => import("./components/Apm/Faq/Faq"));
const ItemsInv = lazy(() => import("./components/Apm/ItemInv/ItemsInv"));
// const Dashboard = lazy(() => import("./components/Pages/Dashboard"));
// const Header = lazy(() => import("./components/Header/Header"));

function App() {
  const dispatch = useDispatch();
  const lang = localStorage.getItem("i18nextLng" || "ro");
  const [langLocalizationProvider, setLangLocalizationProvider] =
    React.useState(ro);
  const { authLogIn, statusLogIn } = useSelector(
    (state) => state.authLogInSlice
  );
  const { account } = useSelector((state) => state.accountSlice);

  ////// Start setting locale depening of i18next selection or user setting
  const theme = useTheme();
  const smMediaDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [locale, setLocale] = React.useState("roRO");
  const themeWithLocale = React.useMemo(
    () =>
      createTheme(theme, locales[locale], {
        palette: {
          baseblue: {
            main: "#043873",
            light: "#4F9CF9",
          },
          baseyellow: {
            main: "#FFE492",
          },
        },
      }),
    [locale, theme]
  );
  ////// End setting locale depening of i18next selection or user setting

  ////// Obtain a fresh token each time the below 2 functions are called
  function now() {
    return Math.round(Date.now() / 1000);
  }
  function getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }
  function refreshTokenParts() {
    return (
      getRefreshToken() &&
      JSON.parse(
        Buffer.from(getRefreshToken().split(".")[1], "base64").toString()
      )
    );
  }

  ////// GET authenticated
  useEffect(() => {
    if (
      !authLogIn &&
      statusLogIn === null &&
      refreshTokenParts() &&
      refreshTokenParts().exp - 20 > now()
    ) {
      dispatch(getAuthenticated());
    } else if (statusLogIn === null) {
      dispatch(setStatusLogIn("notAuthorized"));
      <Navigate to={`${appURL}/login`} replace />;
    }
  });

  ////// SET lang for locale
  useEffect(() => {
    if (account && account.lang) {
      i18next.changeLanguage(account.lang);

      account.lang === "en" && setLocale("enUS");
      account.lang === "ro" && setLocale("roRO");
      account.lang === "ru" && setLocale("ruRU");
    }
    if (account && !account.lang) {
      lang === "en" && setLocale("enUS");
      lang === "ro" && setLocale("roRO");
      lang === "ru" && setLocale("ruRU");
    }
    if (account && account.lang) {
      account.lang === "en" && setLangLocalizationProvider(enGB);
      account.lang === "ro" && setLangLocalizationProvider(ro);
      account.lang === "ru" && setLangLocalizationProvider(ru);
    }
    if (account && !account.lang) {
      lang === "en" && setLangLocalizationProvider(enGB);
      lang === "ro" && setLangLocalizationProvider(ro);
      lang === "ru" && setLangLocalizationProvider(ru);
    }
  }, [account, lang]);

  ////// Private Routes
  function RequireAuth({ children }) {
    let location = useLocation();

    if (
      (!authLogIn && !refreshTokenParts) ||
      (!authLogIn && refreshTokenParts.exp < now())
    ) {
      // Redirect them to the /login page, but save the current location they were trying
      // to go to when they were redirected. This allows us to send them along to that page
      // after they login, which is a nicer user experience than dropping them off on the home page.
      return <Navigate to="/apps/login" state={{ from: location }} replace />;
    }
    return children;
  }

  function AuthOrNotAuth({ children }) {
    if (authLogIn) {
      return <Navigate to={`${appURL}`} />;
    }
    return children;
  }

  // function RedirectNotFound() {
  //   return <Navigate to="/not-found" />;
  // }

  return (
    <WebSocketProvider>
      <HelmetProvider>
        <ThemeProvider theme={themeWithLocale}>
          <LocalizationProvider
            adapterLocale={langLocalizationProvider}
            dateAdapter={AdapterDateFns}
          >
            <BrowserRouter>
              <CssBaseline />
              <ToastContainer
                position="top-center"
                autoClose={2500}
                draggable
                pauseOnHover
                style={{ width: smMediaDown ? undefined : 500, marginTop: 0 }}
                theme="dark"
                transition={Slide}
              />
              {/* <Suspense
                fallback={
                  <Box
                    sx={{
                      display: "flex",
                      height: "100vh",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                }
              > */}
              <Header>
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={
                      <AuthOrNotAuth>
                        <Base />
                      </AuthOrNotAuth>
                    }
                  />
                  <Route
                    path={`${appURL}/login`}
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              height: "100vh",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <LogIn />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/apps/signup"
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              height: "100vh",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <SignUp />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/api/activate/:uid/:token"
                    element={<ActivateUser />}
                  />
                  <Route
                    path={`${appURL}/passreset`}
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              height: "100vh",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <PassReset />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/api/password/reset/confirm/:uid/:token"
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              height: "100vh",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <PassResetConfirm />
                      </Suspense>
                    }
                  />
                  <Route
                    path={`${appURL}/terms-conditions`}
                    element={<TermsConditions />}
                  />
                  <Route
                    exact
                    path={`${appURL}/faq`}
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              height: "100vh",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <Faq />
                      </Suspense>
                    }
                  />
                  <Route
                    exact
                    path={`${appURL}/support`}
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              height: "100vh",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <Support />
                      </Suspense>
                    }
                  />
                  <Route path={`${appURL}/products`} element={<Products />} />

                  {/* PrivateRoutes */}
                  <Route
                    path="/apps/account"
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              height: "100vh",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <RequireAuth>
                          <Account />
                        </RequireAuth>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/apps/subscription"
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              height: "100vh",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <RequireAuth>
                          <Subscription />
                        </RequireAuth>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/apps/settings"
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              height: "100vh",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <RequireAuth>
                          <Settings />
                        </RequireAuth>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/apps/users"
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              height: "100vh",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <RequireAuth>
                          <UserManagement />
                        </RequireAuth>
                      </Suspense>
                    }
                  />
                  <Route
                    exact
                    path="/apps"
                    element={
                      // <Suspense
                      //   fallback={
                      //     <Box
                      //       sx={{
                      //         display: "flex",
                      //         height: "100vh",
                      //         justifyContent: "center",
                      //         alignItems: "center",
                      //       }}
                      //     >
                      //       <CircularProgress />
                      //     </Box>
                      //   }
                      // >
                      <RequireAuth>
                        <Dashboard />
                      </RequireAuth>
                      // </Suspense>
                    }
                  />
                  <Route
                    path="/apps/quotes"
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              height: "100vh",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <RequireAuth>
                          <MyQuotes />
                        </RequireAuth>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/apps/invoices"
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              height: "100vh",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <RequireAuth>
                          <MyInvs />
                        </RequireAuth>
                      </Suspense>
                    }
                  />
                  <Route
                    exact
                    path="/apps/clients"
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              height: "100vh",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <RequireAuth>
                          <Contacts />
                        </RequireAuth>
                      </Suspense>
                    }
                  />
                  <Route
                    exact
                    path="/apps/items-for-item-inv"
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              height: "100vh",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <RequireAuth>
                          <ItemsInv />
                        </RequireAuth>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/apps/company"
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              height: "100vh",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <RequireAuth>
                          <Company />
                        </RequireAuth>
                      </Suspense>
                    }
                  />
                  <Route
                    exact
                    path="/apps/bank-accounts"
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              height: "100vh",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <RequireAuth>
                          <BankAccounts />
                        </RequireAuth>
                      </Suspense>
                    }
                  />
                  <Route
                    exact
                    path="/apps/terms"
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              height: "100vh",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <RequireAuth>
                          <ContractTerms />
                        </RequireAuth>
                      </Suspense>
                    }
                  />
                  <Route
                    exact
                    path="/apps/terms-of-payment"
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              height: "100vh",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <RequireAuth>
                          <TermsOfPayment />
                        </RequireAuth>
                      </Suspense>
                    }
                  />
                  <Route
                    exact
                    path="/apps/countries"
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              height: "100vh",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <RequireAuth>
                          <AllCountries />
                        </RequireAuth>
                      </Suspense>
                    }
                  />
                  <Route
                    exact
                    path="/apps/currencies"
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              height: "100vh",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <RequireAuth>
                          <Currencies />
                        </RequireAuth>
                      </Suspense>
                    }
                  />
                  {/* <Route path="*" element={<RedirectNotFound />} /> */}
                  <Route
                    exact
                    path="*"
                    element={
                      <Suspense
                        fallback={
                          <Box
                            sx={{
                              display: "flex",
                              height: "100vh",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        }
                      >
                        <NotFound />
                      </Suspense>
                    }
                  />
                </Routes>
              </Header>
              {/* </Suspense> */}
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </HelmetProvider>
    </WebSocketProvider>
  );
}

export default App;
