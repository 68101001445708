import React from "react";
import { useField } from "formik";
////// Mui
import { styled } from "@mui/material/styles";
import { FormHelperText, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

const TextFieldStyped = styled(TextField)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#f5f5f5" : "#f5f5f5",
    fontSize: 16,
    fontWeight: 500,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
}));

export default function TextFieldAuto({
  name,
  inputLabelProps,
  required,
  ...otherProps
}) {
  const [field, meta] = useField(name);

  // function ParseFloat(str, val) {
  //   str = str.toString();
  //   str = str.slice(0, str.indexOf(".") + val + 1);
  //   return Number(str);
  // }

  const inputLabelprops = {
    ...inputLabelProps,
    shrink: true,
    sx: { marginBottom: -1, fontSize: 18, fontWeight: "bold" },
  };

  const configTextField = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
    size: "small",
    value:
      otherProps.type === "number"
        ? field.value === 0
          ? "0"
          : Math.round(field.value * 100 + Number.EPSILON) / 100 || ""
        : otherProps.type === "date"
        ? field.value && field.value !== ""
          ? new Date(new Date(field.value).toString().split("GMT")[0] + " UTC")
              .toISOString()
              .split("T")[0]
          : null
        : field.value || "",

    sx: { backgroundColor: "#f5f5f5", autoComplete: "off" },
  };

  const configHelperText = {
    sx: { color: "red", fontSize: 14, fullWidth: true },
  };

  let helperText = "";

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    helperText = meta.error;
  }

  return (
    <React.Fragment>
      <InputLabel {...inputLabelprops}>{inputLabelprops.label}</InputLabel>
      <TextFieldStyped name={name} {...configTextField} />
      <FormHelperText {...configHelperText}>{helperText}</FormHelperText>
    </React.Fragment>
  );
}
