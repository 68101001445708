import i18n from "i18next";

export function columnsBankAccounts() {
  return [
    {
      id: "currencycode.currencycode",
      label: i18n.t("column.currency_code"),
      minWidth: 30,
      maxWidth: 110,
      align: "left",
    },
    {
      id: "iban_number",
      label: i18n.t("column.iban"),
      minWidth: 150,
      maxWidth: 170,
      align: "left",
    },
    {
      id: "bank_name",
      label: i18n.t("column.bank_name"),
      minWidth: 150,
      maxWidth: 170,
      align: "left",
    },
    {
      id: "bank_address",
      label: i18n.t("column.bank_address"),
      minWidth: 110,
      maxWidth: 150,
      align: "left",
    },
    {
      id: "bank_code",
      label: i18n.t("column.bank_code"),
      minWidth: 140,
      maxWidth: 170,
      align: "left",
    },
  ];
}
