import React, { useEffect, useState } from "react";
import { sum } from "lodash-es";
import { useDispatch, useSelector, batch } from "react-redux";
import { useTranslation } from "react-i18next";
////// Mui
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useMediaQuery, useTheme } from "@mui/material";
import { Grid } from "@mui/material";
import { CardHeader, Typography, Stack } from "@mui/material";
import { Box, Divider } from "@mui/material";
import { Table, TableBody, TableCell, TableHead } from "@mui/material";
import { TableContainer, TableRow } from "@mui/material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
////// Store
import { getQuotes } from "../Store/quoteSlice";
import { setInvsFiltered } from "../Store/searchInvSlice";
import { getContacts } from "../Store/contactSlice";
import { getItems as getBankAccounts } from "../Store/bankAccountSlice";
import { getItems as getItemsForItemInv } from "../Store/itemForItemInvSlice";
import { getItems as getNoteTerms } from "../Store/noteTermSlice";
import { getItems as getContractTerms } from "../Store/contractTermSlice";
import { getCountries } from "../Store/countrySlice";
import { getItems as getCurrencies } from "../Store/currencySlice";
import { getExRates } from "../Store/exRatesSlice";
import { setPopup2 } from "../Store/popupSlice";
import { setItemForEdit } from "../Store/itemForEditSlice";
import { getStatuses, setStatusesLocal } from "../Store/statusSlice";
import { setStatusesLocalInv } from "../Store/statusSlice";
////// Controls & Components
import Contact from "../Apm/Contact/Contact";
import FirstSteps from "../Apm/Dashboard/FirstSteps";
import Inv from "../Apm/Inv/Inv";
import Invoiced30Days from "../Apm/Dashboard/Invoiced30Days";
import Invoiced6Months from "../Apm/Dashboard/Invoiced6Months";
import Popup2 from "../Controls/Popups/Popup2";
import PopupQuote from "../Controls/Popups/PopupQuote";
import PopupInv from "../Controls/Popups/PopupInv";
import { maxNumberAllowedPerSubscription } from "../Data/generalData";
import Quote from "../Apm/Quote/Quote";
import QuoteIcon from "../Data/Icons/QuoteIcon";
import { statusesInDiffLang } from "../Data/generalData";
import { euFlag, usFlag, roFlag, ruFlag, uaFlag } from "../Data/imageURL";

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const smMediaDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [disabledAddButtonQuote, setDisabledAddButtonQuote] =
    React.useState(false);
  const [disabledAddButtonInv, setDisabledAddButtonInv] = useState(false);
  const [disabledAddButtonClient, setDisabledAddButtonClient] = useState(false);
  const { popup2 } = useSelector((state) => state.popupSlice);
  const { popupQuote, popupInv } = useSelector((state) => state.popupSlice);
  const { subscription_type } = useSelector((state) => state.accountSlice);
  const { company } = useSelector((state) => state.companySlice);
  const { groups } = useSelector((state) => state.accountGroupUserSlice);
  const groupUsers = groups && groups[0] && groups[0].user_set;
  const { quote, statusQuote, quotesCount } = useSelector(
    (state) => state.quoteSlice
  );
  const { inv } = useSelector((state) => state.invSlice);
  const { invsUnfiltered, invsFiltered } = useSelector(
    (state) => state.searchInvSlice
  );
  const { itemForEdit } = useSelector((state) => state.itemForEditSlice);
  const { contacts, statusContacts } = useSelector(
    (state) => state.contactSlice
  );
  const { bankAccounts, statusBankAccounts } = useSelector(
    (state) => state.bankAccountSlice
  );
  const { itemsForItemInv, statusItemsForItemInv } = useSelector(
    (state) => state.itemForItemInvSlice
  );
  const { noteTerms, statusNoteTerms } = useSelector(
    (state) => state.noteTermSlice
  );
  const { contractTerms, statusContractTerms } = useSelector(
    (state) => state.contractTermSlice
  );
  const { countries, statusCountries } = useSelector(
    (state) => state.countrySlice
  );
  const { currencies, statusCurrencies } = useSelector(
    (state) => state.currencySlice
  );
  const { statuses, statusStatuses } = useSelector(
    (state) => state.statusSlice
  );
  const { exRates, statusExRates } = useSelector((state) => state.exRatesSlice);

  ////// GET Exchange rates
  useEffect(() => {
    if (exRates === null && statusExRates === null) {
      dispatch(getExRates());
    }
  }, [dispatch, exRates, statusExRates]);

  ////// GET Contacts, bankAccounts, itemsForItemInv ...
  useEffect(() => {
    batch(() => {
      if (contacts.length < 1 && statusContacts === null) {
        dispatch(getContacts("/"));
      }
      if (bankAccounts.length < 1 && statusBankAccounts === null) {
        dispatch(getBankAccounts("/"));
      }
      if (itemsForItemInv.length < 1 && statusItemsForItemInv === null) {
        dispatch(getItemsForItemInv("/"));
      }
      if (noteTerms.length < 1 && statusNoteTerms === null) {
        dispatch(getNoteTerms("/"));
      }
      if (contractTerms.length < 1 && statusContractTerms === null) {
        dispatch(getContractTerms("/"));
      }
      if (statuses.length < 1 && statusStatuses === null) {
        dispatch(getStatuses());
      }
      if (currencies.length < 1 && statusCurrencies === null) {
        dispatch(getCurrencies("/"));
      }
      if (countries.length < 1 && statusCountries === null) {
        dispatch(getCountries("/"));
      }
    });
  }, [
    contacts,
    bankAccounts,
    itemsForItemInv,
    noteTerms,
    contractTerms,
    countries,
    currencies,
    statuses,
    statusContacts,
    statusItemsForItemInv,
    statusNoteTerms,
    statusContractTerms,
    statusCurrencies,
    statusCountries,
    statusBankAccounts,
    statusStatuses,
    dispatch,
  ]);

  ////// GET quotes
  useEffect(() => {
    if (statusQuote === null) {
      dispatch(getQuotes());
    }
  }, [dispatch, statusQuote]);

  ////// Localize statuses
  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng" || "ro");

    const statusesQuote = statuses.filter(
      (status) => status.serial_number >= 100 && status.serial_number < 200
    );

    const statusesInv = statuses.filter(
      (status) => status.serial_number >= 200
    );

    let statusesInAppLang = null;
    let statusesInAppLangInv = null;

    if (lang === "en") {
      statusesInAppLang = statusesInDiffLang().slice(0, 4);
      statusesInAppLangInv = statusesInDiffLang().slice(12, 17);
    }

    if (lang === "ro") {
      statusesInAppLang = statusesInDiffLang().slice(4, 8);
      statusesInAppLangInv = statusesInDiffLang().slice(17, 22);
    }

    if (lang === "ru") {
      statusesInAppLang = statusesInDiffLang().slice(8, 12);
      statusesInAppLangInv = statusesInDiffLang().slice(22, 27);
    }

    let statusesLocal = [];
    let statusesLocalInv = [];

    statusesQuote.map((status, i) => {
      statusesLocal.push({ ...status, type: statusesInAppLang[i] });
      return null;
    });

    statusesInv.map((status, i) => {
      statusesLocalInv.push({ ...status, type: statusesInAppLangInv[i] });
      return null;
    });

    dispatch(setStatusesLocal(statusesLocal));
    dispatch(setStatusesLocalInv(statusesLocalInv));
  }, [dispatch, statuses]);

  ////// Calculate data for Invoiced6Months Component
  useEffect(() => {
    const d = new Date();
    const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
    const firstDayOf1Month = new Date(d.getFullYear(), d.getMonth(), 1);
    const firstDayTime = firstDayOf1Month.getTime();
    const firstDayOf2Month = new Date(d.getFullYear(), d.getMonth() - 1, 1);
    const firstDayOf3Month = new Date(d.getFullYear(), d.getMonth() - 2, 1);
    const firstDayOf4Month = new Date(d.getFullYear(), d.getMonth() - 3, 1);
    const firstDayOf5Month = new Date(d.getFullYear(), d.getMonth() - 4, 1);
    const firstDayOf6Month = new Date(d.getFullYear(), d.getMonth() - 5, 1);

    const firstDayOf12Month = new Date(d.getFullYear(), d.getMonth() - 11, 1);
    const firstDayOf13Month = new Date(d.getFullYear(), d.getMonth() - 12, 1);
    const firstDayOf14Month = new Date(d.getFullYear(), d.getMonth() - 13, 1);
    const firstDayOf15Month = new Date(d.getFullYear(), d.getMonth() - 14, 1);
    const firstDayOf16Month = new Date(d.getFullYear(), d.getMonth() - 15, 1);
    const firstDayOf17Month = new Date(d.getFullYear(), d.getMonth() - 16, 1);
    const firstDayOf18Month = new Date(d.getFullYear(), d.getMonth() - 17, 1);

    let graphMonths = [
      firstDayOf6Month.toLocaleString("default", {
        month: "short",
      }),
      firstDayOf5Month.toLocaleString("default", {
        month: "short",
      }),
      firstDayOf4Month.toLocaleString("default", {
        month: "short",
      }),
      firstDayOf3Month.toLocaleString("default", {
        month: "short",
      }),
      firstDayOf2Month.toLocaleString("default", {
        month: "short",
      }),
      firstDayOf1Month.toLocaleString("default", {
        month: "short",
      }),
    ];

    let arrayYearToDate = () => {
      let arrayCurrentYear = [];
      invsUnfiltered
        .filter((item) => item.quote === false)
        .map((item) => {
          let dateInv = new Date(item.date_inv).getTime();

          if (dateInv < d && dateInv >= firstDayOfYear) {
            arrayCurrentYear.push(item);
          }

          return null;
        });
      return [arrayCurrentYear];
    };

    let arrayYearToDateSum = arrayYearToDate().map((array) =>
      sum(
        array.map((inv) =>
          inv.iteminv_invs
            .map((item) => {
              let a = item.amount;
              let b = item.quantity || 1;
              let c =
                item.item !== null &&
                a *
                  b *
                  (item.item_for_item_inv && item.item_for_item_inv.vat / 100);
              return a * b + c;
            })
            .reduce((prev, cur) => {
              return (
                prev +
                (cur &&
                  inv.currency &&
                  cur *
                    (inv.currency.currencycode &&
                      exRates &&
                      (inv.currency.currencycode === "MDL"
                        ? 1
                        : exRates && exRates.Valute
                        ? Number(
                            exRates.Valute.find(
                              (obj) =>
                                obj.CharCode === inv.currency.currencycode
                            ).Value
                          )
                        : 0)))
              );
            }, 0)
        )
      )
    );

    let filteredArray = () => {
      let array1 = [];
      let array2 = [];
      let array3 = [];
      let array4 = [];
      let array5 = [];
      let array6 = [];
      let array12 = [];
      let array13 = [];
      let array14 = [];
      let array15 = [];
      let array16 = [];
      let array17 = [];

      invsUnfiltered
        .filter((item) => item.quote === false)
        .map((item) => {
          let dateInv = new Date(item.date_inv).getTime();

          if (dateInv >= firstDayTime) {
            array1.push(item);
          } else if (dateInv <= firstDayTime && dateInv >= firstDayOf2Month) {
            array2.push(item);
          } else if (
            dateInv < firstDayOf2Month &&
            dateInv >= firstDayOf3Month
          ) {
            array3.push(item);
          } else if (
            dateInv < firstDayOf3Month &&
            dateInv >= firstDayOf4Month
          ) {
            array4.push(item);
          } else if (
            dateInv < firstDayOf4Month &&
            dateInv >= firstDayOf5Month
          ) {
            array5.push(item);
          } else if (
            dateInv < firstDayOf5Month &&
            dateInv >= firstDayOf6Month
          ) {
            array6.push(item);
          } else if (
            dateInv < firstDayOf12Month &&
            dateInv >= firstDayOf13Month
          ) {
            array12.push(item);
          } else if (
            dateInv < firstDayOf13Month &&
            dateInv >= firstDayOf14Month
          ) {
            array13.push(item);
          } else if (
            dateInv < firstDayOf14Month &&
            dateInv >= firstDayOf15Month
          ) {
            array14.push(item);
          } else if (
            dateInv < firstDayOf15Month &&
            dateInv >= firstDayOf16Month
          ) {
            array15.push(item);
          } else if (
            dateInv < firstDayOf16Month &&
            dateInv >= firstDayOf17Month
          ) {
            array16.push(item);
          } else if (
            dateInv < firstDayOf17Month &&
            dateInv >= firstDayOf18Month
          ) {
            array17.push(item);
          }

          return null;
        });
      return [
        array1,
        array2,
        array3,
        array4,
        array5,
        array6,
        array12,
        array13,
        array14,
        array15,
        array16,
        array17,
      ];
    };

    let arraySum = filteredArray().map((array) =>
      sum(
        array.map((inv) =>
          inv.iteminv_invs
            .map((item) => {
              let a = item.amount;
              let b = item.quantity || 1;
              let c =
                item.item !== null &&
                a *
                  b *
                  (item.item_for_item_inv && item.item_for_item_inv.vat / 100);
              return a * b + c;
            })
            .reduce((prev, cur) => {
              return (
                prev +
                cur *
                  (inv.currency &&
                    inv.currency.currencycode &&
                    exRates &&
                    (inv.currency.currencycode === "MDL"
                      ? 1
                      : exRates && exRates.Valute
                      ? Number(
                          exRates.Valute.find(
                            (obj) => obj.CharCode === inv.currency.currencycode
                          ).Value
                        )
                      : 0))
              );
            }, 0)
        )
      )
    );

    dispatch(
      setInvsFiltered({
        filteredArray: [...filteredArray()],
        arraySum: [...arraySum],
        arrayYearToDateSum: [...arrayYearToDateSum],
        graphMonths: [...graphMonths],
      })
    );
  }, [dispatch, invsUnfiltered, exRates]);

  const addOrEditClient = () => {
    batch(() => {
      dispatch(setPopup2(false));
      dispatch(setItemForEdit(null));
    });
  };

  ////// Set restrictions due to subscription plan
  useEffect(() => {
    if (
      (subscription_type === "active_free" &&
        quotesCount >= maxNumberAllowedPerSubscription[3]) ||
      (subscription_type === "active_pro" &&
        quotesCount >= maxNumberAllowedPerSubscription[4]) ||
      (subscription_type === "active_premium" &&
        quotesCount >= maxNumberAllowedPerSubscription[5])
    ) {
      setDisabledAddButtonQuote(true);
      // dispatch(setAllowedToAddInv(true));
    } else {
      setDisabledAddButtonQuote(false);
      // dispatch(setAllowedToAddInv(false));
    }

    if (
      (subscription_type === "active_free" &&
        invsFiltered.filteredArray[0] &&
        invsFiltered.filteredArray[0].length >=
          maxNumberAllowedPerSubscription[6]) ||
      (subscription_type === "active_pro" &&
        invsFiltered.filteredArray[0] &&
        invsFiltered.filteredArray[0].length >=
          maxNumberAllowedPerSubscription[7]) ||
      (subscription_type === "active_premium" &&
        invsFiltered.filteredArray[0] &&
        invsFiltered.filteredArray[0].length >=
          maxNumberAllowedPerSubscription[8])
    ) {
      setDisabledAddButtonInv(true);
    } else {
      setDisabledAddButtonInv(false);
    }

    if (
      (subscription_type === "active_free" &&
        contacts.length >= maxNumberAllowedPerSubscription[9]) ||
      (subscription_type === "active_pro" &&
        contacts.length >= maxNumberAllowedPerSubscription[10]) ||
      (subscription_type === "active_premium" &&
        contacts.length >= maxNumberAllowedPerSubscription[11])
    ) {
      setDisabledAddButtonClient(true);
    } else {
      setDisabledAddButtonClient(false);
    }
  }, [
    dispatch,
    invsFiltered.filteredArray,
    subscription_type,
    contacts,
    quotesCount,
  ]);

  return (
    <>
      <Grid container item spacing={2} sx={{ mt: 0, height: "100%" }}>
        <Grid item lg={4} md={4} xl={4} xs={12}>
          <FirstSteps
            disabledAddButtonInv={disabledAddButtonInv}
            disabledAddButtonQuote={disabledAddButtonQuote}
            disabledAddButtonClient={disabledAddButtonClient}
            bankAccounts={bankAccounts}
            invLength={invsUnfiltered.length}
          />
        </Grid>

        <Grid item lg={4} md={4} xl={4} xs={12}>
          <Card
            sx={{
              height: 520,
              maxWidth: "auto",
            }}
          >
            <CardHeader
              title={
                company && company.companyname
                  ? company.companyname
                  : t("dashboard.sales")
              }
              subheader=' ' // need to keep to keep it empty
              sx={{ backgroundColor: "#f1f1f1", height: 85, pt: 0 }}
            />

            <Divider />

            <CardContent>
              <Grid container item xs={12}>
                <Grid item sx={{ width: "100%" }}>
                  <Box sx={{ display: "flex", mt: 1 }}>
                    <Box sx={{ width: "65%" }}>
                      <Stack direction='row'>
                        <QuoteIcon fill='#757575' />
                        <Typography
                          variant='body1'
                          sx={{ ml: 1, pt: 0.5, pb: 1, fontWeight: "500" }}
                        >
                          {t("dashboard.quotes_issued_this_month_colon")}
                        </Typography>
                      </Stack>
                    </Box>
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "end",
                      }}
                    >
                      <Typography
                        variant='body1'
                        sx={{ pt: 0.5, pr: 1, pb: 1, fontWeight: "500" }}
                      >
                        {quotesCount}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Divider />

              <Grid container item xs={12}>
                <Grid item sx={{ width: "100%" }}>
                  <Box sx={{ display: "flex", mt: 1 }}>
                    <Box sx={{ width: "65%" }}>
                      <Stack direction='row'>
                        <ReceiptLongIcon />
                        <Typography
                          variant='body1'
                          sx={{ ml: 1, pt: 0.5, pb: 1, fontWeight: "500" }}
                        >
                          {t("dashboard.invoices_issued_this_month_colon")}
                        </Typography>
                      </Stack>
                    </Box>
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "end",
                      }}
                    >
                      <Typography
                        variant='body1'
                        sx={{ pt: 0.5, pr: 1, pb: 1, fontWeight: "500" }}
                      >
                        {invsFiltered.filteredArray[0] &&
                          invsFiltered.filteredArray[0].length}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Divider />

              <Grid container item xs={12}>
                <Grid item sx={{ width: "100%" }}>
                  <Box sx={{ display: "flex", mt: 1 }}>
                    <Box sx={{ width: "65%" }}>
                      <Stack direction='row'>
                        <PeopleAltIcon />
                        <Typography
                          variant='body1'
                          sx={{ ml: 1, pt: 0.5, pb: 1, fontWeight: "500" }}
                        >
                          {t("dashboard.clients_colon")}
                        </Typography>
                      </Stack>
                    </Box>
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "end",
                      }}
                    >
                      <Typography
                        variant='body1'
                        sx={{ pt: 0.5, pr: 1, pb: 1, fontWeight: "500" }}
                      >
                        {contacts && contacts.length}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Divider />

              <Grid container item xs={12}>
                <Grid item sx={{ width: "100%" }}>
                  <Box sx={{ display: "flex", mt: 1 }}>
                    <Box sx={{ width: "65%" }}>
                      <Stack direction='row'>
                        <PriceCheckIcon />
                        <Typography
                          variant='body1'
                          sx={{ ml: 1, pt: 0.5, pb: 1, fontWeight: "500" }}
                        >
                          {t("dashboard.goods_services_colon")}
                        </Typography>
                      </Stack>
                    </Box>
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "end",
                      }}
                    >
                      <Typography
                        variant='body1'
                        sx={{ pt: 0.5, pr: 1, pb: 1, fontWeight: "500" }}
                      >
                        {itemsForItemInv && itemsForItemInv.length}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Divider />

              <Grid container item xs={12}>
                <Grid item sx={{ width: "100%" }}>
                  <Box sx={{ display: "flex", mt: 1 }}>
                    <Box sx={{ width: "65%" }}>
                      <Stack direction='row'>
                        <PeopleOutlineIcon />
                        <Typography
                          variant='body1'
                          sx={{ ml: 1, pt: 0.5, pb: 1, fontWeight: "500" }}
                        >
                          {t("dashboard.users_colon")}
                        </Typography>
                      </Stack>
                    </Box>
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "end",
                      }}
                    >
                      <Typography
                        variant='body1'
                        sx={{ pt: 0.5, pr: 1, pb: 1, fontWeight: "500" }}
                      >
                        {groupUsers && groupUsers.length}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Divider />

              <Grid container item xs={12}>
                <Grid item sx={{ width: "100%" }}>
                  <Box sx={{ display: "flex", mt: 1 }}>
                    <Box sx={{ width: "65%" }}>
                      <Stack direction='row'>
                        <AttachMoneyIcon />
                        <Typography
                          variant='body1'
                          sx={{ ml: 1, pt: 0.5, pb: 1, fontWeight: "500" }}
                        >
                          {t("dashboard.total_invoiced_this_year")}
                        </Typography>
                      </Stack>
                    </Box>
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "end",
                      }}
                    >
                      <Typography
                        variant='body1'
                        sx={{ pt: 0.5, pr: 1, pb: 1, fontWeight: "500" }}
                      >
                        {invsFiltered &&
                        invsFiltered.arrayYearToDateSum[0] &&
                        !isNaN(invsFiltered.arrayYearToDateSum[0])
                          ? invsFiltered.arrayYearToDateSum[0].toLocaleString(
                              "en-US",
                              {
                                maximumFractionDigits: 0,
                              }
                            ) + " MDL"
                          : 0}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Divider />
            </CardContent>
          </Card>
        </Grid>

        <Grid item lg={4} md={4} xl={4} xs={12}>
          <Card
            sx={{
              height: 520,
              maxWidth: "auto",
            }}
          >
            <CardHeader
              title={t("dashboard.exchange_rates_nbm")}
              subheader={`${t(
                "dashboard.exchange_rate_updated_for"
              )} ${new Date().toLocaleDateString("en-GB")}`}
              subheaderTypographyProps={{ fontWeight: "500" }}
              sx={{ backgroundColor: "#f1f1f1", minHeight: 85 }}
            />
            <Divider />

            <CardContent sx={{}}>
              <TableContainer>
                <Table sx={{ width: "100%" }} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("inv.currency")}</TableCell>
                      <TableCell align='right'>
                        {t("dashboard.exchange_rate_nbm")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {exRates &&
                      exRates.Valute &&
                      exRates.Valute.map((row) => (
                        <TableRow
                          key={row.CharCode}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                width: "100%",
                                gap: "10px",
                              }}
                            >
                              <img
                                src={
                                  row.CharCode === "EUR"
                                    ? euFlag
                                    : row.CharCode === "USD"
                                    ? usFlag
                                    : row.CharCode === "RUB"
                                    ? ruFlag
                                    : row.CharCode === "RON"
                                    ? roFlag
                                    : uaFlag
                                }
                                alt=''
                                style={{
                                  width: "30px",
                                  weight: "auto",
                                }}
                              />
                              {row.CharCode}
                            </Box>
                          </TableCell>
                          <TableCell
                            align='right'
                            variant='body'
                            sx={{ fontWeight: "500" }}
                          >
                            {row.Value}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <Divider />
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12} lg={6} xl={6}>
          <Invoiced6Months filtered={invsFiltered} />
        </Grid>

        <Grid item xs={12} md={12} lg={6} xl={6}>
          <Invoiced30Days />
        </Grid>
      </Grid>

      {popupQuote && (
        <PopupQuote
          title={`${t(
            smMediaDown ? "quote.quote_hash_short" : "quote.quote_hash"
          )} ${quote ? quote.qn || "" : ""}`}
        >
          <Quote />
        </PopupQuote>
      )}

      {popupInv && (
        <PopupInv
          title={`${t(
            smMediaDown ? "inv.invoice_hash_short" : "inv.invoice_hash"
          )} ${inv ? inv.vn || "" : ""}`}
        >
          <Inv />
        </PopupInv>
      )}

      {popup2 && itemForEdit && itemForEdit.fieldName === "bill_to" && (
        <Popup2 title={t("popup.title.client_details")}>
          <Contact addOrEdit={addOrEditClient} />
        </Popup2>
      )}
    </>
  );
};

export default Dashboard;
