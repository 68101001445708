import React, { useState, useEffect } from "react";
import { forwardRef, useImperativeHandle } from "react";
import axiosInstance from "../../Fetch/axios";
import { Formik, Form, FastField } from "formik";
import { Field, FieldArray, useFormikContext } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useSelector, useDispatch, batch } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
///// Mui
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FormGroup, FormControlLabel } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { Box, Container, Popover, Checkbox } from "@mui/material";
import { useMediaQuery, useTheme, IconButton } from "@mui/material";
import { Grid, Stack, Typography, Divider, Badge } from "@mui/material";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RemoveIcon from "@mui/icons-material/Remove";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import SaveIcon from "@mui/icons-material/Save";
////// Store
import { setQuote, addOrEditQuote, deleteQuote } from "../../Store/quoteSlice";
import { setInv, setStatus } from "../../Store/invSlice";
import { setItemForEdit } from "../../Store/itemForEditSlice";
import { setPopup } from "../../Store/popupSlice";
import { setFormTouchedQuote } from "../../Store/formTouchedSlice";
import { setLoadingStatus } from "../../Store/loadingStatusSlice";
import { setPopupQuote } from "../../Store/popupSlice";
import { setPopupInv2 } from "../../Store/popupSlice";
////// Controls & Components
import Autocomplete from "../../Controls/FormsUI/Autocomplete";
import AutocompleteItemForItem from "../../Controls/FormsUI/AutocompleteItemForItem";
import ButtonAction from "../../Controls/Buttons/ButtonAction";
import ButtonBasic from "../../Controls/Buttons/ButtonBasic";
import ButtonDelete from "../../Controls/Buttons/ButtonDelete";
import Contact from "../Contact/Contact";
import ContractTerm from "../Term/ContractTerm";
import EmailBasic from "../Email/EmailBasic";
import Entries from "../Load/Entries";
import { initialValuesContact } from "../Tables/InitialValues/initialValuesContact";
import { initialValuesContractTerms } from "../Tables/InitialValues/initialValuesContractTerms";
import { initialValuesInv } from "../Tables/InitialValues/initialValuesInv";
import { initialValuesItemInv } from "../Tables/InitialValues/initialValuesItemInv";
import { initialValuesPerson } from "../Tables/InitialValues/initialValuesPerson";
import { initialValuesTermOfPayment } from "../Tables/InitialValues/initialValuesTermOfPayment";
import Inv from "../Inv/Inv";
import ItemInv from "../ItemInv/ItemInv";
import { invURL } from "../../Data/baseURL";
import InputArray from "../../Controls/FormsUI/InputArray";
import InputArrayNumber from "../../Controls/FormsUI/InputArrayNumber";
import LoadDetails from "../Load/LoadDetails";
import { maxNumberAllowedPerSubscription } from "../../Data/generalData";
import DesktopDatePickerBasic from "../../Controls/Inputs/DesktopDatePickerBasic";
import Person from "../Person/Person";
import Popup from "../../Controls/Popups/Popup";
import PopupInv2 from "../../Controls/Popups/PopupInv2";
import QuotePdf from "./QuotePdf/QuotePdf";
import SelectBasic from "../../Controls/FormsUI/SelectBasic";
import TermOfPayment from "../Term/TermOfPayment";
import TextFieldNormal from "../../Controls/Inputs/TextFieldNormal";
import TextFieldRegular from "../../Controls/Inputs/TextFieldRegular";
import TooltipBasic from "../../Controls/Inputs/TooltipBasic";
import { useSocket } from "../../Fetch/useSocket";

const MenuStyled = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(0),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const TypographyPopover = styled(Typography)(({ theme }) => ({
  padding: 16,
  color: "rgba(0, 0, 0, 0.87)",
  maxWidth: 350,
  fontSize: theme.typography.pxToRem(20),
}));

const validationSchema = Yup.object().shape({
  bill_to: Yup.object()
    .shape()
    .typeError(() => i18n.t("yup.required"))
    .required(() => i18n.t("yup.required")),
  person: Yup.object().shape().nullable(),
  mode: Yup.object().shape().nullable(),
  status: Yup.object().shape().nullable(),
  qn: Yup.number()
    .lessThan(99999999, () => i18n.t("yup.invoice_number_max_length"))
    .positive(() => i18n.t("yup.invoice_number_should_be_positive"))
    .integer(() => i18n.t("yup.invoice_number_should_be_integer"))
    .typeError(() => i18n.t("yup.invoice_number_should_be_number"))
    .nullable(),
  vn: Yup.number()
    .lessThan(99999999, () => i18n.t("yup.invoice_number_max_length"))
    .positive(() => i18n.t("yup.invoice_number_should_be_positive"))
    .integer(() => i18n.t("yup.invoice_number_should_be_integer"))
    .typeError(() => i18n.t("yup.invoice_number_should_be_number"))
    .nullable(),
  date_inv: Yup.date().required(() => i18n.t("yup.required")),

  currency: Yup.object()
    .shape()
    .typeError(() => i18n.t("yup.required"))
    .required(() => i18n.t("yup.required")),
  load_detail: Yup.string()
    .max(60, () => i18n.t("yup.max_60_characters"))
    .nullable(),
  load_address: Yup.string()
    .max(30, () => i18n.t("yup.max_30_characters"))
    .nullable(),
  unload_address: Yup.string()
    .max(30, () => i18n.t("yup.max_30_characters"))
    .nullable(),
  iteminv_invs: Yup.array().of(
    Yup.object().shape({
      item_for_item_inv: Yup.object()
        .shape()
        .typeError(() => i18n.t("yup.required"))
        .required(() => i18n.t("yup.required")),
    })
  ),
  entry_invs: Yup.array().of(
    Yup.object().shape({
      country_load: Yup.object().shape().typeError().nullable(),
      country_unload: Yup.object().shape().typeError().nullable(),
      zip_load: Yup.string()
        .max(20, () => i18n.t("yup.max_20_characters"))
        .nullable(),
      zip_unload: Yup.string()
        .max(20, () => i18n.t("yup.max_20_characters"))
        .nullable(),
      city_load: Yup.string()
        .max(50, () => i18n.t("yup.max_50_characters"))
        .nullable(),
      city_unload: Yup.string()
        .max(50, () => i18n.t("yup.max_50_characters"))
        .nullable(),
      dangerousgoods_class: Yup.string()
        .max(30, () => i18n.t("yup.max_30_characters"))
        .nullable(),
      temp_control_details: Yup.string()
        .max(40, () => i18n.t("yup.max_40_characters"))
        .nullable(),
    })
  ),
  comment_invs: Yup.array().of(
    Yup.object().shape({
      comment: Yup.string().max(400, () => i18n.t("yup.max_400_characters")),
    })
  ),
});

const Quote = forwardRef((_, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const wsClient = useSocket();
  const theme = useTheme();
  const smallMedia = useMediaQuery("(max-width:1075px)");
  const smMediaDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdMediaDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgMediaDown = useMediaQuery(theme.breakpoints.down("lg"));
  const [showPdf, setShowPdf] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [checked, setChecked] = useState(false);
  const [checkedEntries, setCheckedEntries] = useState(false);
  const [disabledAddButtonInv, setDisabledAddButtonInv] = useState(false);
  const [disabledAddButtonClient, setDisabledAddButtonClient] = useState(false);
  const [disabledAddButtonItemInv, setDisabledAddButtonItemInv] =
    useState(false);
  const { popup } = useSelector((state) => state.popupSlice);
  const { popupInv2 } = useSelector((state) => state.popupSlice);
  const { account, subscription_type } = useSelector(
    (state) => state.accountSlice
  );
  const { company } = useSelector((state) => state.companySlice);
  const { logo } = useSelector((state) => state.avatarSlice);
  const { quote } = useSelector((state) => state.quoteSlice);
  const { inv } = useSelector((state) => state.invSlice);
  const { invsFiltered } = useSelector((state) => state.searchInvSlice);
  const { contacts } = useSelector((state) => state.contactSlice);
  const { itemsForItemInv } = useSelector((state) => state.itemForItemInvSlice);
  const { itemForEdit } = useSelector((state) => state.itemForEditSlice);
  const [invoicePdfLang, setInvoicePdfLang] = useState("");

  const freeAppMssg =
    subscription_type === "active_free"
      ? t("note.quote_created_with_online_app_cargos_md")
      : "";

  let totalInvoice = 0;

  useImperativeHandle(ref, () => ({
    handleSubmit: () => handleSubmit(),
  }));

  ////// Restrictions due to subscription plan
  useEffect(() => {
    if (
      (subscription_type === "active_free" &&
        invsFiltered.filteredArray[0] &&
        invsFiltered.filteredArray[0].length >=
          maxNumberAllowedPerSubscription[6]) ||
      (subscription_type === "active_pro" &&
        invsFiltered.filteredArray[0] &&
        invsFiltered.filteredArray[0].length >=
          maxNumberAllowedPerSubscription[7]) ||
      (subscription_type === "active_premium" &&
        invsFiltered.filteredArray[0] &&
        invsFiltered.filteredArray[0].length >=
          maxNumberAllowedPerSubscription[8])
    ) {
      setDisabledAddButtonInv(true);
    } else {
      setDisabledAddButtonInv(false);
    }

    if (
      (subscription_type === "active_free" &&
        contacts.length >= maxNumberAllowedPerSubscription[9]) ||
      (subscription_type === "active_pro" &&
        contacts.length >= maxNumberAllowedPerSubscription[10]) ||
      (subscription_type === "active_premium" &&
        contacts.length >= maxNumberAllowedPerSubscription[11])
    ) {
      setDisabledAddButtonClient(true);
    } else {
      setDisabledAddButtonClient(false);
    }

    if (
      (subscription_type === "active_free" &&
        itemsForItemInv.length >= maxNumberAllowedPerSubscription[12]) ||
      (subscription_type === "active_pro" &&
        itemsForItemInv.length >= maxNumberAllowedPerSubscription[13]) ||
      (subscription_type === "active_premium" &&
        itemsForItemInv.length >= maxNumberAllowedPerSubscription[14])
    ) {
      setDisabledAddButtonItemInv(true);
    } else {
      setDisabledAddButtonItemInv(false);
    }
  }, [
    itemsForItemInv.length,
    contacts.length,
    subscription_type,
    invsFiltered.filteredArray,
  ]);

  ////// Start Popover
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl6, setAnchorEl6] = useState(null);
  const [anchorElHistory, setAnchorElHistory] = useState(null);
  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickPopover2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClickPopover6 = (event) => {
    setAnchorEl6(event.currentTarget);
  };
  const handleClickHistory = (event) => {
    setAnchorElHistory(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
    setAnchorEl6(null);
    setAnchorElHistory(null);
    setAnchorElInv(null);
  };
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const open6 = Boolean(anchorEl6);
  const openHistory = Boolean(anchorElHistory);
  const id = open ? "popover" : undefined;
  ////// End Popover

  ////// Start selectmenu email & print
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorElEmail, setAnchorElEmail] = React.useState(null);
  const openSelectButton = Boolean(anchorEl3);
  const openSelectButtonEmail = Boolean(anchorElEmail);
  const handleClick = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClickEmail = (event) => {
    setAnchorElEmail(event.currentTarget);
  };
  const handleCloseSelectMenu = () => {
    setAnchorEl3(null);
  };
  const handleCloseEmail = () => {
    setAnchorElEmail(null);
  };
  ////// End selectmenu email & print

  ////// Start Popover Invoice section
  const [anchorElInv, setAnchorElInv] = useState(null);
  const handleClickPopoverInv = (event) => {
    setAnchorElInv(event.currentTarget);
  };
  const openInv = Boolean(anchorElInv);
  const idInv = open ? "inv-popover" : undefined;
  ////// End Popover Invoice section

  const FormValues = () => {
    const { values, touched } = useFormikContext();
    useEffect(() => {
      if (values) {
        setFormValues(values);
      }
      if (Object.keys(touched).length !== 0) {
        dispatch(setFormTouchedQuote(true));
      }
    }, [values, touched]);
  };

  ////// Include place of loading & unloading
  useEffect(() => {
    if (
      quote &&
      ((quote.load_detail && quote.load_detail.length > 0) ||
        (quote.load_address && quote.load_address.length > 0) ||
        (quote.unload_address && quote.unload_address.length > 0))
    ) {
      setChecked(true);
    }
    if (
      quote &&
      quote.entry_invs &&
      quote.entry_invs[0] &&
      quote.entry_invs[0].entrydetails &&
      quote.entry_invs[0].entrydetails[0] &&
      ((quote.entry_invs[0].entrydetails[0].pieces &&
        quote.entry_invs[0].entrydetails[0].pieces.length > 0) ||
        (quote.entry_invs[0].entrydetails[0].weight &&
          quote.entry_invs[0].entrydetails[0].weight.length > 0) ||
        (quote.entry_invs[0].entrydetails[0].ldm &&
          quote.entry_invs[0].entrydetails[0].ldm.length > 0) ||
        (quote.entry_invs[0].entrydetails[0].volume &&
          quote.entry_invs[0].entrydetails[0].volume.length > 0) ||
        (quote.entry_invs[0].entrydetails[0].dims &&
          quote.entry_invs[0].entrydetails[0].dims.length > 0))
    ) {
      setCheckedEntries(true);
    }
  }, [quote]);

  const handleSwitchChange = () => {
    setChecked(!checked);
  };

  const handleSwitchChangeEntries = () => {
    setCheckedEntries(!checkedEntries);
  };

  const addOrEditAutoField = (value) => {
    let updatedItemsArray = null;

    if (itemForEdit.fieldName === "iteminv_invs") {
      let newValue = null;
      let newArray = [...formValues.iteminv_invs];

      newValue = { ...formValues.iteminv_invs[itemForEdit.index] };
      newValue.item_for_item_inv = value;

      updatedItemsArray = newArray.map((iteminv, i) => {
        let newItem = { ...iteminv };
        if (!iteminv.item_for_item_inv) {
          newItem = { ...newValue };
        } else if (i === itemForEdit.index) {
          newItem = { ...iteminv, item_for_item_inv: { ...value } };
        }
        return newItem;
      });
    }

    batch(() => {
      dispatch(setPopup(false));
      itemForEdit.fieldName !== "person" &&
        dispatch(
          setQuote({
            ...formValues,
            [itemForEdit.fieldName]: updatedItemsArray || value,
          })
        );
      itemForEdit.fieldName === "person" &&
        dispatch(
          setQuote({
            ...formValues,
            bill_to: value,
            person:
              itemForEdit.index === null
                ? value.contact_persons[value.contact_persons.length - 1]
                : value.contact_persons[itemForEdit.index],
          })
        );
      setTimeout(() => {
        dispatch(setItemForEdit(null));
      }, 400);
    });
  };

  const deleteAutoField = (id) => {
    let updatedIteminv_invs = null;
    if (itemForEdit.fieldName === "iteminv_invs") {
      updatedIteminv_invs = formValues.iteminv_invs.filter((item) => {
        return item.item_for_item_inv.id !== id;
      });
    }

    let updatedBillToPersons = null;
    if (itemForEdit.fieldName === "person") {
      updatedBillToPersons = formValues.bill_to.contact_persons.filter(
        (item) => {
          return item.id !== id;
        }
      );
    }

    batch(() => {
      itemForEdit.fieldName !== "person" &&
        dispatch(
          setQuote({
            ...formValues,
            [itemForEdit.fieldName]: updatedIteminv_invs || "",
          })
        );
      itemForEdit.fieldName === "person" &&
        dispatch(
          setQuote({
            ...formValues,
            bill_to: {
              ...formValues.bill_to,
              contact_persons: updatedBillToPersons,
            },
            person: null,
          })
        );
      setTimeout(() => {
        dispatch(setItemForEdit(null));
      }, 400);
    });
  };

  const openInPopupAutoField = (item, fieldName, index = null) => {
    let fields = {};
    if (fieldName === "bill_to" && !item) {
      fields = { ...initialValuesContact };
    } else if (fieldName === "person" && !item) {
      fields = { ...initialValuesPerson };
    } else if (fieldName === "note_terms" && !item) {
      fields = { ...initialValuesTermOfPayment };
    } else if (fieldName === "contract_terms" && !item) {
      fields = { ...initialValuesContractTerms };
    } else if (fieldName === "iteminv_invs" && !item) {
      fields = { ...initialValuesItemInv };
    } else {
      fields = { ...item };
    }

    batch(() => {
      dispatch(setPopup(true));
      dispatch(
        setItemForEdit({
          ...fields,
          fieldName: fieldName,
          index: index,
        })
      );
    });
    return null;
  };

  let formDataForSubmit = () => {
    let entry_invsArray = []; // adding time to date because backend needs date+time
    formValues.entry_invs.map((entry) =>
      entry_invsArray.push({
        ...entry,
        date_load:
          entry.date_load !== "" && entry.date_load !== null
            ? new Date(
                new Date(new Date(entry.date_load).setHours(0, 0, 0, 0))
                  .toString()
                  .split("GMT")[0] + " UTC"
              ).toISOString()
            : null,
        date_unload:
          entry.date_unload !== "" && entry.date_unload !== null
            ? new Date(
                new Date(new Date(entry.date_unload).setHours(0, 0, 0, 0))
                  .toString()
                  .split("GMT")[0] + " UTC"
              ).toISOString()
            : null,
      })
    );

    return {
      id: formValues.id,
      qn: formValues.qn,
      date_inv: formValues.date_inv,
      date_due: formValues.date_due,
      bill_to: formValues.bill_to || null,
      currency: formValues.currency,
      load: "",
      load_detail: formValues.load_detail,
      load_address: formValues.load_address,
      unload_address: formValues.unload_address,
      entry_invs: entry_invsArray,
      iteminv_invs: formValues.iteminv_invs,
      comment_invs: formValues.comment_invs,
      note_terms: formValues.note_terms,
      contract_terms: formValues.contract_terms,
      mode: null,
      incoterm: null,
      status: formValues.status,
      person: formValues.person,
      quote: true,
    };
  };

  const handleSubmit = () => {
    dispatch(setLoadingStatus(true));
    axiosInstance({
      method: formValues.id ? "PUT" : "POST",
      url: formValues.id ? invURL + formValues.id + "/" : invURL,
      data: formDataForSubmit(),
    })
      .then((resp) => {
        setFormValues(null);
        batch(() => {
          dispatch(setStatus("loaded"));
          dispatch(addOrEditQuote(resp.data));
          dispatch(setPopupQuote(false));
          dispatch(setLoadingStatus(false));
          dispatch(setFormTouchedQuote(false));
        });
        toast.success(
          `${t("toast.quote_capital")} # ${resp.data.qn && resp.data.qn} ${
            formValues.id
              ? t("toast.successfully_updated")
              : t("toast.successfully_created")
          }`
        );
      })
      .catch((error) => {
        dispatch(setLoadingStatus(false));
        if (error && error.response && error.response.status === 402) {
          toast.error(
            () => t("toast.need_to_upgrade_in_order_to_register_new_quotes"),
            {
              autoClose: 4000,
            }
          );
        } else if (
          error.response &&
          error.response.data &&
          JSON.stringify(error.response.data).toString().includes("not unique")
        ) {
          toast.error(() => t("toast.quote_with_this_number_exist"));
        } else if (
          error.errors &&
          error.response.data &&
          error.response.data.includes("key value violates unique constraint")
        ) {
          toast.error(() => t("toast.quote_with_this_number_exist"));
        } else {
          toast.error(() => t("toast.not_saved_try_again"));
        }
      });
  };

  const handleSave = (e) => {
    e.preventDefault();
    setShowPdf(false); // related to PDF generate
    batch(() => {
      dispatch(setLoadingStatus(true));
      dispatch(setQuote(formValues));
    });
    axiosInstance({
      method: formValues.id ? "PUT" : "POST",
      url: formValues.id ? invURL + formValues.id + "/" : invURL,
      data: formDataForSubmit(),
    })
      .then((resp) => {
        batch(() => {
          dispatch(setQuote(resp.data));
          dispatch(addOrEditQuote(resp.data));
          dispatch(setLoadingStatus(false));
          dispatch(setFormTouchedQuote(false));
        });
        toast.success(
          `${t("toast.quote_capital")} # ${resp.data.qn && resp.data.qn} ${
            formValues.id
              ? t("toast.successfully_updated")
              : t("toast.successfully_created")
          }`
        );
      })
      .catch((error) => {
        dispatch(setLoadingStatus(false));
        if (error.response && error.response.status === 402) {
          toast.error(
            () => t("toast.need_to_upgrade_in_order_to_register_new_quotes"),
            {
              autoClose: 4000,
            }
          );
        } else if (
          error.errors &&
          error.response.data &&
          error.response.data.includes("key value violates unique constraint")
        ) {
          toast.error(() => t("toast.quote_with_this_number_exist"));
        } else if (
          error.response &&
          error.response.data &&
          JSON.stringify(error.response.data).toString().includes("not unique")
        ) {
          toast.error(() => t("toast.quote_with_this_number_exist"));
        } else {
          toast.error(() => t("toast.not_saved_try_again"));
        }
      });
  };

  const handleDelete = (e, id) => {
    if (wsClient && id) {
      wsClient
        .delete("invstream", id)
        .then(() => {
          batch(() => {
            dispatch(deleteQuote(id));
            dispatch(setLoadingStatus(false));
            dispatch(setPopupQuote(false));
            dispatch(setFormTouchedQuote(false));
          });
          toast.success(
            `${t("toast.quote_capital")} # ${quote.qn ? quote.qn : ""} ${t(
              "toast.successfully_deleted"
            )}`
          );
        })
        .catch(() => {
          dispatch(setLoadingStatus(false));
          toast.error(() => t("toast.quote_could_not_be_deleted"));
        });
    } else {
      toast.error(() => t("toast.quote_could_not_be_found"));
    }
  };

  function generatePdfDocument(lang) {
    setShowPdf(true);
    setInvoicePdfLang(lang);
    handleClose();
    return null;
  }

  function emailPdfDocument(values) {
    batch(() => {
      dispatch(setItemForEdit({ ...values, fieldName: "email", totalInvoice }));
      dispatch(setPopup(true));
    });
    setInvoicePdfLang(arguments[3]);
    handleCloseEmail();
    return null;
  }

  const openInPopupTopInv = (e, values) => {
    e.preventDefault();

    let date_inv = new Date(
      new Date(new Date()).toString().split("GMT")[0] + " UTC"
    )
      .toISOString()
      .split("T")[0];

    let inv_iteminv_invs = []; // adding iteminv_invs as new list
    values.iteminv_invs.map((entry) => {
      inv_iteminv_invs.push({ ...entry, id: undefined });
      return null;
    });

    let inv_comment_invs = [];
    values.comment_invs.map((comment) => {
      inv_comment_invs.push({ ...comment, id: undefined });
      return null;
    });

    batch(() => {
      dispatch(
        setInv({
          ...values,
          id: undefined,
          vn: "",
          qn: undefined,
          date_inv: date_inv,
          entry_invs: "",
          iteminv_invs: inv_iteminv_invs,
          history_invs: [],
          comment_invs: inv_comment_invs,
          quote: false,
          status: "",
        })
      );
      dispatch(setPopupInv2(true));
    });
    return null;
  };

  return (
    <>
      <Container
        sx={{ width: "100%" }}
        maxWidth={false}
        disableGutters={lgMediaDown ? true : false}
      >
        <Formik
          enableReinitialize
          initialValues={
            Object.keys(quote).length !== 0 ? quote : initialValuesInv
          }
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}
        >
          {({
            values,
            arrayHelpers,
            errors,
            handleBlur,
            validateForm,
            setTouched,
            setFieldValue,
          }) => {
            totalInvoice =
              values.iteminv_invs &&
              values.iteminv_invs
                .map((item) => {
                  let a = item.amount;
                  let b = item.quantity || 1;
                  let c =
                    item.item !== null &&
                    a *
                      b *
                      (item.item_for_item_inv &&
                        item.item_for_item_inv.vat / 100);
                  return a * b + c;
                })
                .reduce((prev, cur) => {
                  return prev + cur;
                }, 0)
                .toFixed(2);
            return (
              <Form autoComplete="off">
                <Grid container item spacing={1} sx={{ mt: 2, mb: 1 }}>
                  {/* Big document type label */}
                  <Grid container item xs={12} spacing={1} sx={{ mb: 2 }}>
                    <Grid item md={6} xs={12}>
                      <Stack direction="row">
                        <Typography variant="h4" textAlign="start">
                          {t(smMediaDown ? "quote.quote_short" : "quote.quote")}
                        </Typography>{" "}
                      </Stack>
                    </Grid>

                    <Grid
                      container
                      item
                      md={6}
                      xs={12}
                      sx={{ justifyContent: "flex-end" }}
                    >
                      {Object.keys(errors).length !== 0 && (
                        <Typography variant="h6" color="error">
                          {t("yup.fields_color_red_are_mandatory")}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  {/* Client, person, types fields */}
                  <Grid container item md={7.7} xs={12} spacing={1}>
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={2}
                      sx={{ alignContent: "start" }}
                    >
                      <Grid container item xs={12}>
                        <Grid
                          container
                          item
                          md={8}
                          xs={12}
                          spacing={mdMediaDown ? 1 : undefined}
                        >
                          <Grid item md={4.35} xs={12}>
                            <Typography
                              variant="h6"
                              textAlign={mdMediaDown ? "start" : "start"}
                              noWrap
                            >
                              {t("quote.quote_to")}
                            </Typography>
                          </Grid>

                          <Grid item md={7.65} xs={12}>
                            <Box sx={{ display: "flex" }}>
                              <Box sx={{ flexGrow: 1 }}>
                                <Autocomplete
                                  name="bill_to"
                                  onBlur={handleBlur}
                                />
                              </Box>
                              <Box>
                                <IconButton
                                  id="client-popover"
                                  color="primary"
                                  sx={{ mx: 0, mt: -1 }}
                                  onClick={
                                    disabledAddButtonClient &&
                                    values.bill_to === null
                                      ? handleClickPopover
                                      : () =>
                                          openInPopupAutoField(
                                            values.bill_to,
                                            "bill_to"
                                          )
                                  }
                                >
                                  <ModeEditOutlineIcon fontSize="small" />
                                </IconButton>
                                <Popover
                                  id={id}
                                  open={open}
                                  anchorEl={anchorEl}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                >
                                  <TypographyPopover>
                                    {t(
                                      "toast.need_to_upgrade_in_order_to_register_new_clients"
                                    )}
                                  </TypographyPopover>
                                </Popover>
                              </Box>
                              <Menu
                                id="status-menu"
                                aria-labelledby="status-button"
                                anchorEl={anchorElHistory}
                                open={openHistory}
                                onClose={handleClose}
                                PaperProps={{
                                  elevation: 2,
                                  sx: {
                                    filter:
                                      "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 2,
                                  },
                                  style: {
                                    maxHeight: ITEM_HEIGHT * 9.5,
                                    minWidth: 300,
                                    maxWidth: 650,
                                  },
                                }}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                                anchorOrigin={{
                                  horizontal: "right",
                                  vertical: "bottom",
                                }}
                              >
                                {values &&
                                values.history_invs &&
                                values.history_invs.length < 1 ? (
                                  <MenuItem>
                                    <Typography noWrap>
                                      {t("notification.no_history")}
                                    </Typography>
                                  </MenuItem>
                                ) : (
                                  <MenuItem>
                                    <Typography sx={{ fontWeight: "bold" }}>
                                      {t("notification.history")}
                                    </Typography>
                                  </MenuItem>
                                )}

                                {values &&
                                  values.history_invs &&
                                  values.history_invs
                                    .slice(0)
                                    .reverse()
                                    .map((history, i) => {
                                      return (
                                        <MenuItem key={i + 350}>
                                          <Typography component="div" noWrap>
                                            <Stack direction="row">
                                              <Box>
                                                {new Date(
                                                  history.date_registered
                                                ).toLocaleDateString("en-GB", {
                                                  day: "numeric",
                                                  month: "numeric",
                                                  year: "numeric",
                                                  hour: "2-digit",
                                                  minute: "2-digit",
                                                })}{" "}
                                                {history.action
                                                  ? t(
                                                      `status.${history.action}`
                                                    )
                                                  : ""}
                                              </Box>
                                              <Box
                                                sx={{
                                                  fontWeight: "bold",
                                                  mx: 1,
                                                }}
                                              >
                                                {history.status
                                                  ? t(
                                                      `status.${history.status}`
                                                    )
                                                  : ""}
                                              </Box>
                                              <Box
                                                sx={{
                                                  whiteSpace: "nowrap",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                }}
                                              >
                                                {history.changed_by &&
                                                  (history.changed_by
                                                    .first_name ||
                                                  history.changed_by.last_name
                                                    ? (history.changed_by
                                                        .first_name || "") +
                                                      " " +
                                                      (history.changed_by
                                                        .last_name || "")
                                                    : history.changed_by
                                                        .email || "")}
                                              </Box>
                                            </Stack>
                                          </Typography>
                                        </MenuItem>
                                      );
                                    })}
                              </Menu>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Contact person */}
                      <Grid container item xs={12}>
                        <Grid
                          container
                          item
                          md={8}
                          xs={12}
                          spacing={mdMediaDown ? 1 : undefined}
                        >
                          <Grid item md={4.35} xs={12}>
                            <Typography
                              variant="h6"
                              textAlign={mdMediaDown ? "start" : "start"}
                              noWrap
                              sx={{ pr: 0.5 }}
                            >
                              {t("quote.contact_person")}
                            </Typography>
                          </Grid>

                          <Grid item md={7.65} xs={12}>
                            <Box sx={{ display: "flex" }}>
                              <Box sx={{ flexGrow: 1 }}>
                                <Autocomplete
                                  name="person"
                                  personsArray={
                                    values &&
                                    values.bill_to &&
                                    values.bill_to.contact_persons.length > 0
                                      ? values.bill_to.contact_persons
                                      : []
                                  }
                                />
                              </Box>
                              <Box>
                                <IconButton
                                  id="client-popover"
                                  sx={{ mx: 0, mt: -1 }}
                                  color="primary"
                                  onClick={
                                    (values && !values.bill_to) ||
                                    (values &&
                                      values.bill_to &&
                                      !values.bill_to.id)
                                      ? handleClickPopover6
                                      : () =>
                                          openInPopupAutoField(
                                            values.person,
                                            "person",
                                            values &&
                                              values.person &&
                                              values.bill_to &&
                                              values.bill_to.contact_persons &&
                                              values.bill_to.contact_persons.findIndex(
                                                (person) =>
                                                  person.id === values.person.id
                                              )
                                          )
                                  }
                                >
                                  <ModeEditOutlineIcon fontSize="small" />
                                </IconButton>
                                <Popover
                                  id={id}
                                  open={open6}
                                  anchorEl={anchorEl6}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                >
                                  <TypographyPopover>
                                    {t("popover.need_to_select_client_first")}
                                  </TypographyPopover>
                                </Popover>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* Mode, incoterm, status ... */}
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={mdMediaDown ? 1 : 1.5}
                      sx={{ mt: 2, alignContent: "end" }}
                    >
                      <Grid
                        container
                        item
                        md={8}
                        xs={12}
                        spacing={mdMediaDown ? 1 : undefined}
                      >
                        <Grid item md={4.35} xs={12}>
                          <Typography
                            variant="h6"
                            textAlign={mdMediaDown ? "start" : "start"}
                            noWrap
                          >
                            {t("quote.status")}
                          </Typography>
                        </Grid>
                        <Grid item md={7.65} xs={12}>
                          <Box sx={{ display: "flex" }}>
                            <Box sx={{ flexGrow: 1 }}>
                              <SelectBasic name="status" quoteorinv="0" />
                            </Box>
                            <Box>
                              <TooltipBasic
                                title={t("notification.history")}
                                placement="bottom-start"
                              >
                                <IconButton
                                  id="status-button"
                                  aria-controls={
                                    openHistory ? "status-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={
                                    openHistory ? "true" : undefined
                                  }
                                  color="inherit"
                                  sx={{ m: 0, pt: 0 }}
                                  onClick={handleClickHistory}
                                >
                                  <Badge
                                    badgeContent={
                                      values.history_invs &&
                                      values.history_invs.length
                                    }
                                  >
                                    <AutoAwesomeMotionIcon />
                                  </Badge>
                                </IconButton>
                              </TooltipBasic>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {!mdMediaDown ? undefined : (
                    <Grid item xs={12}>
                      <hr />
                    </Grid>
                  )}

                  {/* Quote #, Quote date, valid until, payment terms, currency */}
                  <Grid container item md={4.3} xs={12} spacing={1.5}>
                    <Grid
                      container
                      item
                      xs={12}
                      spacing={mdMediaDown ? 1 : undefined}
                    >
                      <Grid item md={5.5} xs={12}>
                        <Typography
                          variant="h6"
                          textAlign="start"
                          noWrap
                          sx={{ pr: 0.5 }}
                        >
                          {t("quote.quote_hash_short")}
                        </Typography>
                      </Grid>

                      <Grid item md={6.5} xs={12}>
                        <FastField
                          as={TextFieldNormal}
                          name="qn"
                          type="number"
                          placeholder={t("inv.number_will_be_generated")}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      item
                      xs={12}
                      spacing={mdMediaDown ? 1 : undefined}
                    >
                      <Grid item md={5.5} xs={12}>
                        <Typography
                          variant="h6"
                          textAlign={mdMediaDown ? "start" : "start"}
                          noWrap
                        >
                          {t("quote.quote_date")}
                        </Typography>
                      </Grid>

                      <Grid item md={6.5} xs={12}>
                        <FastField
                          as={DesktopDatePickerBasic}
                          name="date_inv"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      item
                      xs={12}
                      spacing={mdMediaDown ? 1 : undefined}
                    >
                      <Grid item md={5.5} xs={12}>
                        <Typography
                          variant="h6"
                          textAlign={mdMediaDown ? "start" : "start"}
                          noWrap
                        >
                          {t("quote.valid_until")}
                        </Typography>
                      </Grid>

                      <Grid item md={6.5} xs={12}>
                        <FastField
                          as={DesktopDatePickerBasic}
                          name="date_due"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      item
                      xs={12}
                      spacing={mdMediaDown ? 1 : undefined}
                    >
                      <Grid item md={5.5} xs={12}>
                        <Typography
                          variant="h6"
                          textAlign={mdMediaDown ? "start" : "start"}
                          noWrap
                          sx={{ pr: 0.5 }}
                        >
                          {t("quote.payment_term")}
                        </Typography>
                      </Grid>

                      <Grid item md={6.5} xs={12} sx={{ width: "100%" }}>
                        <Box sx={{ display: "flex" }}>
                          <Box sx={{ flexGrow: 1 }}>
                            <Autocomplete name="note_terms" />
                          </Box>
                          <Box>
                            <IconButton
                              color="primary"
                              sx={{ mx: 0, mt: -1 }}
                              onClick={() =>
                                openInPopupAutoField(
                                  values.note_terms,
                                  "note_terms"
                                )
                              }
                            >
                              <ModeEditOutlineIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      item
                      xs={12}
                      spacing={mdMediaDown ? 1 : undefined}
                    >
                      <Grid item md={5.5} xs={12}>
                        <Typography variant="h6" textAlign="start" noWrap>
                          {t("inv.currency_asterisk")}
                        </Typography>
                      </Grid>

                      <Grid item md={6.5} xs={12}>
                        <SelectBasic name="currency" />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <hr />
                  </Grid>

                  <FormGroup sx={{ ml: 1.5, mt: 0.6 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleSwitchChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label={t("button.include_details_loading_unloading")}
                      componentsProps={{
                        typography: {
                          variant: "button",
                          color: "primary",
                        },
                      }}
                    />
                  </FormGroup>

                  {/* Load details, loading & unloading address */}
                  {checked && (
                    <Grid container item xs={12} spacing={1}>
                      <LoadDetails />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <hr />
                  </Grid>

                  <FormGroup sx={{ ml: 1.5, mt: 0.6 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedEntries}
                          onChange={handleSwitchChangeEntries}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label={t("button.include_load_details")}
                      componentsProps={{
                        typography: {
                          variant: "button",
                          color: "primary",
                        },
                      }}
                    />
                  </FormGroup>

                  {/* Entries */}
                  {checkedEntries && (
                    <Grid container item xs={12}>
                      <Entries
                        entries={values.entry_invs}
                        arrayHelpers={arrayHelpers}
                        openInPopupAutoField={openInPopupAutoField}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <hr />
                  </Grid>

                  {/* Invoice table items*/}
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{ mt: mdMediaDown ? undefined : 1 }}
                  >
                    <FieldArray
                      name="iteminv_invs"
                      iteminv_invsArrayHelpers={arrayHelpers}
                    >
                      {(iteminv_invsArrayHelpers) => {
                        return (
                          <>
                            {/* Table header */}
                            {!mdMediaDown ? (
                              <>
                                <Box sx={{ display: "flex", width: "100%" }}>
                                  <Box sx={{ flexGrow: 1, width: "100%" }}>
                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      sx={{ mt: 1, mb: mdMediaDown ? 0 : 1 }}
                                    >
                                      <Grid
                                        item
                                        md={6.5}
                                        xs={12}
                                        sx={{ bgcolor: "#ccc" }}
                                      >
                                        <Typography
                                          sx={{
                                            ml: 1.5,
                                            fontWeight: "600",
                                            color: "black",
                                          }}
                                          noWrap
                                        >
                                          {t("inv.description")}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={1}
                                        sx={{ bgcolor: "#ccc" }}
                                      >
                                        <Typography
                                          sx={{
                                            ml: 1.5,
                                            fontWeight: "600",
                                            color: "black",
                                          }}
                                          noWrap
                                        >
                                          {t("inv.quantity")}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={1.5}
                                        sx={{ bgcolor: "#ccc" }}
                                      >
                                        <Typography
                                          sx={{
                                            ml: 1.5,
                                            fontWeight: "600",
                                            color: "black",
                                          }}
                                        >
                                          {t("inv.rate")}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={1}
                                        sx={{ bgcolor: "#ccc" }}
                                      >
                                        <Typography
                                          sx={{
                                            ml: 1.5,
                                            fontWeight: "600",
                                            color: "black",
                                          }}
                                          noWrap
                                        >
                                          {t("inv.vat_%")}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={2}
                                        sx={{ bgcolor: "#ccc" }}
                                      >
                                        <Typography
                                          align="right"
                                          marginRight={2}
                                          sx={{
                                            ml: 1.5,
                                            fontWeight: "600",
                                            color: "black",
                                          }}
                                        >
                                          {t("inv.amount")}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  <Box
                                    sx={{
                                      alignSelf: "start",
                                      minWidth: 31.8,
                                    }}
                                  ></Box>
                                </Box>
                              </>
                            ) : (
                              <Grid
                                item
                                xs={12}
                                sx={{ mb: mdMediaDown ? 1.5 : undefined }}
                              >
                                <Typography variant="h6" textAlign="start">
                                  {t("general.goods_services_capital")}
                                </Typography>
                              </Grid>
                            )}

                            {/* Table rows */}
                            {values.iteminv_invs &&
                              values.iteminv_invs.map((item, i) => {
                                return (
                                  <div key={i} style={{ width: "100%" }}>
                                    <Box
                                      sx={{
                                        display: mdMediaDown
                                          ? "inline"
                                          : "flex",
                                      }}
                                    >
                                      <Box sx={{ flexGrow: 1, width: "100%" }}>
                                        <Grid container item xs={12}>
                                          <Grid
                                            item
                                            md={6.5}
                                            xs={12}
                                            sx={{ width: "100%" }}
                                          >
                                            <Box sx={{ display: "flex" }}>
                                              <Box sx={{ flexGrow: 1 }}>
                                                <AutocompleteItemForItem
                                                  name={`iteminv_invs[${i}].item_for_item_inv`}
                                                  label={
                                                    mdMediaDown
                                                      ? t("inv.description")
                                                      : null
                                                  }
                                                />
                                              </Box>
                                              <Box
                                                sx={{
                                                  mt: mdMediaDown ? 2 : -1,
                                                }}
                                              >
                                                <IconButton
                                                  id="item-inv-popover"
                                                  color="primary"
                                                  onClick={
                                                    disabledAddButtonItemInv &&
                                                    values.iteminv_invs[i]
                                                      .item_for_item_inv ===
                                                      null
                                                      ? handleClickPopover2
                                                      : () =>
                                                          openInPopupAutoField(
                                                            values.iteminv_invs[
                                                              i
                                                            ].item_for_item_inv,
                                                            "iteminv_invs",
                                                            i
                                                          )
                                                  }
                                                >
                                                  <ModeEditOutlineIcon fontSize="small" />
                                                </IconButton>
                                                <Popover
                                                  id={id}
                                                  open={open2}
                                                  anchorEl={anchorEl2}
                                                  onClose={handleClose}
                                                  anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "right",
                                                  }}
                                                >
                                                  <TypographyPopover>
                                                    {t(
                                                      "toast.need_to_upgrade_in_order_to_register_new_items_for_item_inv"
                                                    )}
                                                  </TypographyPopover>
                                                </Popover>
                                              </Box>
                                            </Box>
                                          </Grid>

                                          <Grid item md={1} xs={12}>
                                            <InputArrayNumber
                                              name={`iteminv_invs[${i}].quantity`}
                                              type="number"
                                              label={
                                                mdMediaDown
                                                  ? t("inv.quantity")
                                                  : null
                                              }
                                            />
                                          </Grid>

                                          <Grid item md={1.5} xs={12}>
                                            <InputArrayNumber
                                              name={`iteminv_invs[${i}].amount`}
                                              type="number"
                                              label={
                                                mdMediaDown
                                                  ? t("inv.rate")
                                                  : null
                                              }
                                            />
                                          </Grid>

                                          <Grid item md={1} xs={12}>
                                            <Field
                                              component={InputArray}
                                              name={`iteminv_invs[${i}].item_for_item_inv.vat`}
                                              type="number"
                                              disabled
                                              label={
                                                mdMediaDown
                                                  ? t("inv.vat_%")
                                                  : null
                                              }
                                            />
                                          </Grid>

                                          <Grid
                                            container
                                            item
                                            md={2}
                                            xs={12}
                                            sx={{ mt: mdMediaDown ? 0 : -1 }}
                                          >
                                            <Field
                                              name="article_total"
                                              component={TextFieldRegular}
                                              type="number"
                                              label={
                                                mdMediaDown
                                                  ? t("inv.total")
                                                  : null
                                              }
                                              inputProps={{
                                                style: {
                                                  textAlign: mdMediaDown
                                                    ? "start"
                                                    : "end",
                                                },
                                              }}
                                              value={
                                                Math.round(
                                                  item.amount *
                                                    (1 +
                                                      (item.item_for_item_inv
                                                        ? item.item_for_item_inv
                                                            .vat
                                                        : 0) /
                                                        100) *
                                                    (typeof item.quantity !==
                                                    "number"
                                                      ? 1
                                                      : item.quantity) *
                                                    100 +
                                                    Number.EPSILON
                                                ) / 100 || ""
                                              }
                                              onChange={(e) => {
                                                return setFieldValue(
                                                  `iteminv_invs[${i}].amount`,
                                                  e.target.value /
                                                    (1 +
                                                      (item.item_for_item_inv &&
                                                        item.item_for_item_inv
                                                          .vat &&
                                                        item.item_for_item_inv
                                                          .vat) /
                                                        100) /
                                                    (item.quantity &&
                                                    item.quantity !== "string"
                                                      ? item.quantity
                                                      : 1)
                                                );
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      </Box>

                                      {/* Remove button */}
                                      <Box
                                        sx={{
                                          alignSelf: "start",
                                          display: mdMediaDown
                                            ? "inline"
                                            : undefined,
                                        }}
                                      >
                                        <ButtonAction
                                          type="button"
                                          fullWidth={mdMediaDown ? true : false}
                                          disabled={
                                            values.iteminv_invs.length < 2
                                          }
                                          size="small"
                                          color="secondary"
                                          variant={
                                            mdMediaDown ? "contained" : "text"
                                          }
                                          sx={{ mb: mdMediaDown ? 2 : 2.5 }}
                                          style={{
                                            maxWidth: mdMediaDown
                                              ? undefined
                                              : "32px",
                                            maxHeight: mdMediaDown
                                              ? undefined
                                              : "20px",
                                            minWidth: mdMediaDown
                                              ? undefined
                                              : "32px",
                                            minHeight: mdMediaDown
                                              ? undefined
                                              : "20px",
                                          }}
                                          onClick={() =>
                                            iteminv_invsArrayHelpers.remove(i)
                                          }
                                        >
                                          <RemoveIcon />
                                        </ButtonAction>
                                      </Box>
                                    </Box>
                                    {mdMediaDown &&
                                      values.iteminv_invs.length !== i + 1 && (
                                        <Grid item xs={12}>
                                          <Divider variant="middle" />
                                        </Grid>
                                      )}
                                  </div>
                                );
                              })}

                            {/* Add button */}
                            <Grid container item>
                              <Grid item xs={12}>
                                <ButtonBasic
                                  type="button"
                                  color="primary"
                                  variant="outlined"
                                  fullWidth={smMediaDown ? true : false}
                                  disabled={
                                    values.iteminv_invs &&
                                    values.iteminv_invs.length <= 4
                                      ? false
                                      : true
                                  }
                                  style={{ marginTop: 0, marginBottom: 0 }}
                                  onClick={() =>
                                    iteminv_invsArrayHelpers.push({
                                      item_for_item_inv: null,
                                      description: "",
                                      quantity: null,
                                      amount: null,
                                      vat: null,
                                    })
                                  }
                                >
                                  <Typography noWrap variant="button">
                                    {t("button.add_line_item")}
                                  </Typography>
                                </ButtonBasic>
                              </Grid>
                            </Grid>
                          </>
                        );
                      }}
                    </FieldArray>
                  </Grid>

                  {/* Subtotal, vat, total */}
                  <Grid container item xs={12} sx={{ mb: 1, mt: 0 }}>
                    <Grid item md={4} xs={0}></Grid>

                    <Grid item md={5} xs={7} sx={{ pr: 1 }}>
                      <Stack spacing={2}>
                        <Typography variant="h6" align="right">
                          {t("inv.sub_total")}
                        </Typography>
                        <Typography variant="h6" align="right">
                          {t("inv.vat")}
                        </Typography>

                        <Divider variant="middle" />

                        <Typography
                          variant="h6"
                          align="right"
                          sx={{ fontWeight: "700" }}
                        >
                          {t("inv.total")}
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item md={2.5} xs={4.5}>
                      <Stack spacing={2} marginRight={2}>
                        <Typography variant="h6" align="right">
                          {values.iteminv_invs &&
                            values.iteminv_invs
                              .map((item) => {
                                let a = item.amount;
                                let b =
                                  typeof item.quantity === "number"
                                    ? item.quantity || 1
                                    : 1;
                                return a * b;
                              })
                              .reduce((prev, cur) => {
                                return prev + cur;
                              }, 0)
                              .toFixed(2)}
                        </Typography>
                        <Typography variant="h6" align="right">
                          {values.iteminv_invs &&
                            values.iteminv_invs
                              .map((item) => {
                                let c =
                                  item.item !== null &&
                                  item.amount *
                                    (typeof item.quantity === "number"
                                      ? item.quantity || 1
                                      : 1) *
                                    (item.item_for_item_inv &&
                                      item.item_for_item_inv.vat / 100);
                                return c;
                              })
                              .reduce((prev, cur) => {
                                return prev + cur;
                              }, 0)
                              .toFixed(2)}
                        </Typography>

                        <Divider variant="middle" />

                        <Typography
                          variant="h6"
                          align="right"
                          sx={{ fontWeight: "700" }}
                        >
                          {totalInvoice}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <hr />
                  </Grid>

                  {/* Notes */}
                  <Grid container item spacing={1}>
                    <FieldArray name="comment_invs">
                      {(arrayHelpers) => {
                        return (
                          <>
                            {values.comment_invs &&
                              values.comment_invs.map((_, i) => {
                                return (
                                  <Grid
                                    item
                                    xs={12}
                                    key={i}
                                    sx={{ width: "100%" }}
                                  >
                                    <Box sx={{ display: "flex" }}>
                                      <Box sx={{ flexGrow: 1 }}>
                                        <FastField
                                          component={InputArray}
                                          name={`comment_invs[${i}].comment`}
                                          label={`${t(
                                            smMediaDown
                                              ? "quote.note_to_quote_short"
                                              : "quote.note_to_quote"
                                          )}`}
                                          type="text"
                                          fullWidth={true}
                                          multiline={true}
                                          minRows={3}
                                          maxRows={4}
                                          helperText={
                                            errors.comment_invs &&
                                            errors.comment_invs[i].comment
                                          }
                                        />
                                      </Box>
                                      <Box sx={{ alignSelf: "center" }}>
                                        <IconButton
                                          type="button"
                                          onClick={() => arrayHelpers.remove(i)}
                                          color="secondary"
                                        >
                                          <RemoveIcon />
                                        </IconButton>
                                      </Box>
                                    </Box>
                                  </Grid>
                                );
                              })}
                            <Grid
                              container
                              item
                              xs={12}
                              style={{ paddingTop: 0 }}
                            >
                              <ButtonBasic
                                size="small"
                                color="primary"
                                variant="outlined"
                                fullWidth={smMediaDown ? true : false}
                                disabled={
                                  values.comment_invs &&
                                  values.comment_invs.length > 2
                                    ? true
                                    : false
                                }
                                style={{ marginTop: 0 }}
                                onClick={() =>
                                  arrayHelpers.push({
                                    comment: "",
                                  })
                                }
                              >
                                <Typography noWrap variant="button">
                                  {t("button.add_note_to_invoice")}
                                </Typography>
                              </ButtonBasic>
                            </Grid>
                          </>
                        );
                      }}
                    </FieldArray>
                  </Grid>

                  {/* Terms */}
                  <Grid container item spacing={1}>
                    <Grid item xs={12} sx={{ width: "100%" }}>
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ flexGrow: 1 }}>
                          <Autocomplete
                            name="contract_terms"
                            label={t("quote.quote_terms")}
                          />
                        </Box>
                        <Box>
                          <IconButton
                            color="primary"
                            sx={{ mt: 2.5 }}
                            onClick={() =>
                              openInPopupAutoField(
                                values.contract_terms,
                                "contract_terms"
                              )
                            }
                          >
                            <ModeEditOutlineIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <hr />
                  </Grid>

                  {/* Save & delete buttons & email generate PDF, create invoice */}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      mt: 3,
                      mb: 3,

                      justifyContent: mdMediaDown ? "center" : "start",
                    }}
                  >
                    <Stack direction={smallMedia ? "column" : "row"}>
                      {/* Save & close save & delete buttons */}
                      <Stack direction={mdMediaDown ? "column" : "row"}>
                        <ButtonBasic
                          type="button"
                          variant="outlined"
                          fullWidth={mdMediaDown ? true : false}
                          startIcon={<SaveAsIcon />}
                          onClick={(e) =>
                            validateForm()
                              .then((errors) => {
                                if (Object.keys(errors).length > 0) {
                                  toast.error(() =>
                                    t("toast.correct_errors_on_page")
                                  );
                                  return setTouched(errors);
                                } else {
                                  handleSubmit(values);
                                }
                              })
                              .catch((e) => new Error(e))
                          }
                        >
                          <Typography variant="button">
                            {t("button.save_and_close")}
                          </Typography>
                        </ButtonBasic>

                        <ButtonBasic
                          type="button"
                          fullWidth={mdMediaDown ? true : false}
                          variant="outlined"
                          startIcon={<SaveIcon />}
                          sx={{ ml: mdMediaDown ? undefined : 2 }}
                          onClick={(e) =>
                            validateForm()
                              .then((errors) => {
                                if (Object.keys(errors).length > 0) {
                                  toast.error(() =>
                                    t("toast.correct_errors_on_page")
                                  );
                                  return setTouched(errors);
                                } else {
                                  handleSave(e, values);
                                }
                              })
                              .catch((e) => new Error(e))
                          }
                        >
                          <Typography variant="button">
                            {t("button.save")}
                          </Typography>
                        </ButtonBasic>

                        <ButtonDelete
                          id={values.id}
                          type="button"
                          variant="outlined"
                          color="secondary"
                          fullWidth={mdMediaDown ? true : false}
                          sx={{ ml: mdMediaDown ? null : 2 }}
                          handleDelete={handleDelete}
                        >
                          <Typography variant="button">
                            {t("button.delete")}
                          </Typography>
                        </ButtonDelete>
                      </Stack>

                      {/* Email select, generate PDF buttons, create Invoice */}
                      <Stack direction={mdMediaDown ? "column" : "row"}>
                        <ButtonBasic
                          id="demo-customized-button"
                          variant="outlined"
                          color="success"
                          fullWidth={mdMediaDown ? true : false}
                          startIcon={
                            smallMedia ? (
                              <ForwardToInboxIcon />
                            ) : (
                              <PictureAsPdfIcon />
                            )
                          }
                          disabled={values.id ? false : true}
                          sx={{
                            ml: smallMedia ? null : 2,
                          }}
                          aria-controls={
                            openSelectButtonEmail
                              ? "demo-customized-menu"
                              : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={
                            openSelectButtonEmail ? "true" : undefined
                          }
                          disableElevation
                          onClick={handleClickEmail}
                          endIcon={<KeyboardArrowDownIcon />}
                        >
                          <Typography variant="button">
                            {t("button.email")}
                          </Typography>
                        </ButtonBasic>
                        <MenuStyled
                          id="demo-customized-menu"
                          MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                          }}
                          anchorEl={anchorElEmail}
                          open={openSelectButtonEmail}
                          onClose={handleCloseEmail}
                          // onClick={() => setAnchorElEmail(false)}
                        >
                          <MenuItem
                            type="button"
                            disableRipple
                            onClick={(e) =>
                              validateForm()
                                .then((errors) => {
                                  if (Object.keys(errors).length > 0) {
                                    return setTouched(errors);
                                  } else {
                                    emailPdfDocument(
                                      values,
                                      "email",
                                      totalInvoice,
                                      "RO/RU"
                                    );
                                  }
                                })
                                .catch((e) => new Error(e))
                            }
                          >
                            <PictureAsPdfIcon />
                            <Typography>{t("general.ro_ru")}</Typography>
                          </MenuItem>
                          <MenuItem
                            type="button"
                            disableRipple
                            onClick={(e) =>
                              validateForm()
                                .then((errors) => {
                                  if (Object.keys(errors).length > 0) {
                                    return setTouched(errors);
                                  } else {
                                    emailPdfDocument(
                                      values,
                                      "email",
                                      totalInvoice,
                                      "EN"
                                    );
                                  }
                                })
                                .catch((e) => new Error(e))
                            }
                          >
                            <PictureAsPdfIcon />
                            <Typography>{t("general.en")}</Typography>
                          </MenuItem>
                        </MenuStyled>

                        {/* Generate PDF */}
                        <ButtonBasic
                          id="demo-customized-button"
                          variant="outlined"
                          color={showPdf ? "error" : "success"}
                          startIcon={<PictureAsPdfIcon sx={{ ml: 1 }} />}
                          disabled={values.id ? false : true}
                          fullWidth={mdMediaDown ? true : false}
                          sx={{
                            ml: mdMediaDown ? null : 2,
                          }}
                          aria-controls={
                            openSelectButton
                              ? "demo-customized-menu"
                              : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={openSelectButton ? "true" : undefined}
                          disableElevation
                          onClick={handleClick}
                          endIcon={<KeyboardArrowDownIcon sx={{ mr: 1 }} />}
                        >
                          <Typography
                            variant="button"
                            sx={{ display: showPdf ? "none" : "inline" }}
                          >
                            {t("button.generate")}
                          </Typography>

                          {showPdf ? (
                            <Typography
                              variant="button"
                              sx={{
                                display: showPdf ? "inline" : "none",
                              }}
                            >
                              {t("general.download")}
                            </Typography>
                          ) : null}
                        </ButtonBasic>
                        <MenuStyled
                          id="demo-customized-menu"
                          MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                          }}
                          anchorEl={anchorEl3}
                          open={openSelectButton}
                          onClose={handleCloseSelectMenu}
                        >
                          <MenuItem
                            type="button"
                            disableRipple
                            onClick={(e) =>
                              validateForm()
                                .then((errors) => {
                                  if (Object.keys(errors).length > 0) {
                                    toast.error(() =>
                                      t("toast.correct_errors_on_page")
                                    );
                                    return setTouched(errors);
                                  } else {
                                    generatePdfDocument("RO/RU");
                                  }
                                })
                                .catch((e) => new Error(e))
                            }
                          >
                            <PictureAsPdfIcon
                              sx={{ display: showPdf ? "none" : "auto" }}
                            />
                            <Typography
                              sx={{ display: showPdf ? "none" : "auto" }}
                            >
                              {t("general.ro_ru")}
                            </Typography>
                            {showPdf === true && invoicePdfLang === "RO/RU" ? (
                              <QuotePdf
                                company={company}
                                account={account}
                                values={values}
                                logo={logo}
                                update
                                setShowPdf={setShowPdf}
                                showPdf={showPdf}
                                invoicePdfLang={invoicePdfLang}
                                checked={checked}
                                checkedEntries={checkedEntries}
                                freeAppMssg={freeAppMssg}
                              />
                            ) : null}
                          </MenuItem>

                          <MenuItem
                            type="button"
                            disableRipple
                            onClick={(e) =>
                              validateForm()
                                .then((errors) => {
                                  if (Object.keys(errors).length > 0) {
                                    toast.error(() =>
                                      t("toast.correct_errors_on_page")
                                    );
                                    return setTouched(errors);
                                  } else {
                                    generatePdfDocument("EN");
                                  }
                                })
                                .catch((e) => {
                                  return new Error(e);
                                })
                            }
                          >
                            <PictureAsPdfIcon
                              sx={{ display: showPdf ? "none" : "auto" }}
                            />
                            <Typography
                              sx={{ display: showPdf ? "none" : "auto" }}
                            >
                              {t("general.en")}
                            </Typography>

                            {showPdf === true && invoicePdfLang === "EN" ? (
                              <QuotePdf
                                account={account}
                                company={company}
                                values={values}
                                logo={logo}
                                update
                                setShowPdf={setShowPdf}
                                showPdf={showPdf}
                                invoicePdfLang={invoicePdfLang}
                                checked={checked}
                                checkedEntries={checkedEntries}
                                freeAppMssg={freeAppMssg}
                              />
                            ) : null}
                          </MenuItem>
                        </MenuStyled>

                        {/* Create Invoice */}
                        <ButtonBasic
                          type="button"
                          fullWidth={mdMediaDown ? true : false}
                          disabled={values && values.id ? false : true}
                          variant="outlined"
                          color="warning"
                          startIcon={<ReceiptLongIcon />}
                          sx={{ ml: mdMediaDown ? undefined : 2 }}
                          onClick={(e) =>
                            disabledAddButtonInv
                              ? handleClickPopoverInv(e)
                              : openInPopupTopInv(e, values)
                          }
                        >
                          <Typography variant="button">
                            {t("button.create")}
                          </Typography>
                        </ButtonBasic>
                        <Popover
                          id={idInv}
                          open={openInv}
                          anchorEl={anchorElInv}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                        >
                          <TypographyPopover>
                            {t(
                              "toast.need_to_upgrade_in_order_to_register_new_invoices"
                            )}
                          </TypographyPopover>
                        </Popover>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>

                <FormValues />
              </Form>
            );
          }}
        </Formik>
      </Container>

      {popup && (
        <Popup
          title={
            itemForEdit &&
            (itemForEdit.fieldName === "bill_to"
              ? t("popup.title.client_details")
              : itemForEdit.fieldName === "note_terms"
              ? t("popup.title.payment_term")
              : itemForEdit.fieldName === "contract_terms"
              ? t("popup.title.invoice_terms")
              : itemForEdit.fieldName === "email"
              ? t("popup.title.email_basic")
              : itemForEdit.fieldName === "person"
              ? t("popup.title.contact_person")
              : t("popup.title.invoice_item"))
          }
        >
          {itemForEdit && itemForEdit.fieldName === "bill_to" && (
            <Contact
              addOrEdit={addOrEditAutoField}
              deleteAutoField={deleteAutoField}
            />
          )}
          {itemForEdit && itemForEdit.fieldName === "person" && (
            <Person
              addOrEdit={addOrEditAutoField}
              deleteAutoField={deleteAutoField}
              bill_to={formValues.bill_to}
            />
          )}
          {itemForEdit && itemForEdit.fieldName === "note_terms" && (
            <TermOfPayment
              addOrEdit={addOrEditAutoField}
              deleteAutoField={deleteAutoField}
            />
          )}
          {itemForEdit && itemForEdit.fieldName === "iteminv_invs" && (
            <ItemInv
              addOrEdit={addOrEditAutoField}
              deleteAutoField={deleteAutoField}
            />
          )}
          {itemForEdit && itemForEdit.fieldName === "contract_terms" && (
            <ContractTerm
              addOrEdit={addOrEditAutoField}
              deleteAutoField={deleteAutoField}
            />
          )}
          {itemForEdit && itemForEdit.fieldName === "email" && (
            <EmailBasic
              values={formValues}
              invoicePdfLang={invoicePdfLang}
              checked={checked}
              checkedEntries={checkedEntries}
              freeAppMssg={freeAppMssg}
            />
          )}
        </Popup>
      )}

      {popupInv2 && (
        <PopupInv2 title={`${t("inv.invoice_hash")} ${inv?.vn || ""}`}>
          <Inv />
        </PopupInv2>
      )}
    </>
  );
});

export default Quote;
