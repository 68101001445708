import React from "react";
import { Line, Text, View, StyleSheet, Svg } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  boxXL: {
    marginTop: 8,
    flexDirection: "row",
    justifyContent: "flex-start",
    width: 535,
  },
  text3: {
    fontSize: 10,
    textAlign: "left",
    fontFamily: "Roboto",
    paddingLeft: 2,
    maxWidth: 200,
  },
  text4: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Roboto",
    width: 387,
  },
});

const QuotePdfTerms = ({ values, invoicePdfLang }) => {
  return (
    <>
      {/* Termentul de plata / Срок оплаты: */}
      {values && values.note_terms && (
        <>
          <View style={{ ...styles.boxXL, marginTop: 16 }}>
            <Text style={{ ...styles.text3, maxWidth: 160 }}>
              {invoicePdfLang === "RO/RU"
                ? "Termentul de plata / Срок оплаты: "
                : "Payment terms: "}
            </Text>
            <Text style={{ ...styles.text3, maxWidth: 375 }}>
              {values &&
                values.note_terms &&
                values.note_terms.note_description}
            </Text>
          </View>

          <Svg style={{ height: 2, width: "535", marginTop: 2 }}>
            <Line x1='0' y1='0' x2='538' y2='0' strokeWidth={1} stroke='gray' />
          </Svg>
        </>
      )}

      {/* Nota / примечание */}
      {values &&
        values.comment_invs.map((comment_invs, i) => {
          if (comment_invs.comment.length < 1) {
            return null;
          } else {
            return (
              <div key={i}>
                <View style={styles.boxXL}>
                  <Text style={{ ...styles.text3, maxWidth: 110 }}>
                    {invoicePdfLang === "RO/RU"
                      ? `Nota/ Примечание ${
                          values.comment_invs.length > 1 ? `(${i + 1})` : ""
                        }: `
                      : "Note: "}
                  </Text>
                  <Text style={{ ...styles.text3, maxWidth: 425 }}>
                    {comment_invs.comment}
                  </Text>
                </View>
                <Svg style={{ height: 2, width: "535", marginTop: 2 }}>
                  <Line
                    x1='0'
                    y1='0'
                    x2='535'
                    y2='0'
                    strokeWidth={1}
                    stroke='gray'
                  />
                </Svg>
              </div>
            );
          }
        })}
    </>
  );
};

export default QuotePdfTerms;
