import React from "react";
import { Button } from "@mui/material";

const ButtonBasic = ({ children, ...otherProps }) => {
  const configButton = {
    ...otherProps,
    sx: { ...otherProps.sx, mt: 1, mr: 1, mb: 1 },
  };

  return (
    <React.Fragment>
      <Button {...configButton}>{children}</Button>
    </React.Fragment>
  );
};

export default ButtonBasic;
