import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../Fetch/axios";
import { toast } from "react-toastify";
import i18n from "i18next";
import { bankAccountURL } from "../Data/baseURL";

export const getItems = createAsyncThunk(
  "bankAccountSlice/getItems",
  async (route) => {
    return await axiosInstance
      .get(bankAccountURL)
      .then((response) => {
        if (response.data) {
          route !== "/" &&
            toast.success(i18n.t("toast.bank_account_list_updated"));
          return response.data;
        }
      })
      .catch(() => {}, []);
  }
);

export const bankAccountSlice = createSlice({
  name: "bankAccountSlice",
  initialState: {
    bankAccount: {},
    bankAccounts: [],
    statusBankAccounts: null,
  },
  reducers: {
    setItems: (state, action) => {
      return { ...state, bankAccounts: [...action.payload] };
    },
    setItem: (state, action) => {
      return { ...state, bankAccount: { ...action.payload } };
    },
    addOrEditItem: (state, { payload: values }) => {
      const i = state.bankAccounts.findIndex((item) => item.id === values.id);
      if (i > -1) state.bankAccounts[i] = values;
      else state.bankAccounts.push(values);
      return state;
    },
    deleteBankAccount: (state, { payload }) => {
      const newItems = state.bankAccounts.filter((item) => item.id !== payload);
      return { ...state, bankAccounts: [...newItems] };
    },
  },
  extraReducers: {
    [getItems.pending]: (state) => {
      state.statusBankAccounts = "loading";
    },

    [getItems.fulfilled]: (state, action) => {
      Array.isArray(action.payload) === true &&
        (state.bankAccounts = action.payload);
      state.statusBankAccounts = "loaded";
    },
    [getItems.rejected]: (state) => {
      state.statusBankAccounts = "failed";
    },
  },
});

export const { setItems, setItem, addOrEditItem, deleteBankAccount } =
  bankAccountSlice.actions;

export default bankAccountSlice.reducer;
