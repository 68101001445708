import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../Fetch/axios";
import { toast } from "react-toastify";
import i18n from "i18next";
import { getAccount } from "./accountSlice";
import { usersURL } from "../Data/baseURL";

// To get only 1 user, request.user, specifically user groups

export const getGroupsUser = createAsyncThunk(
  "accountGroupUserSlice/getGroupsUser",
  async () => {
    return axiosInstance({
      method: "GET",
      url: usersURL + "1/",
    }).then((response) => {
      if (response.data) {
        toast.success(() => i18n.t("toast.user_list_updated"));
        return response.data;
      }
    });
  }
);

export const accountGroupUserSlice = createSlice({
  name: "accountGroupUserSlice",
  initialState: {
    groups: null,
    statusAccount: null,
  },
  reducers: {
    setGroupsUser: (state, action) => {
      return { ...state, groups: { ...action.payload } };
    },
    addOrEditUser: (state, { payload: values }) => {
      const i = state.groups[0].user_set.findIndex(
        (user) => user.id === values.id
      );
      if (i > -1) state.groups[0].user_set[i] = values;
      else state.groups[0].user_set.push(values);
      return state;
    },
    deleteUser: (state, { payload }) => {
      const newItems = state.groups[0].user_set.filter(
        (item) => item.id !== payload
      );
      state.groups[0].user_set = newItems;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGroupsUser.pending, (state) => {
      state.statusAccount = "loading";
    });
    builder.addCase(getGroupsUser.fulfilled, (state, action) => {
      return {
        ...state,
        groups: [...action.payload.groups],
        statusAccount: "loaded",
      };
    });
    builder.addCase(getGroupsUser.rejected, (state, action) => {
      return { ...state, statusAccount: "failed" };
    });

    builder.addCase(getAccount.fulfilled, (state, action) => {
      return {
        ...state,
        groups: action.payload.groups,
        statusAccount: "loaded",
      };
    });
  },
});

export const { setGroupsUser, addOrEditUser, deleteUser } =
  accountGroupUserSlice.actions;

export default accountGroupUserSlice.reducer;
