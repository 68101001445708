import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../Fetch/axios";
import { appURL } from "../Data/baseURL";

export const getAuthenticated = createAsyncThunk(
  "authLogInSlice/getAuthenticated",
  async () => {
    function now() {
      return Math.round(Date.now() / 1000);
    }

    ////// Obtain the refresh token each time the function is called
    function getRefreshToken() {
      return localStorage.getItem("refresh_token");
    }

    function refreshTokenParts() {
      return (
        getRefreshToken() &&
        JSON.parse(
          Buffer.from(getRefreshToken().split(".")[1], "base64").toString()
        )
      );
    }

    if (refreshTokenParts() && refreshTokenParts().exp - 10 > now()) {
      return axiosInstance
        .post("api/token/refresh/", { refresh: getRefreshToken() })
        .then((response) => {
          response.data.access &&
            localStorage.setItem("access_token", response.data.access);
          response.data.refresh &&
            localStorage.setItem("refresh_token", response.data.refresh);
          axiosInstance.defaults.headers["Authorization"] =
            "JWT " + response.data.access;
        })
        .catch(() => {
          // window.location.href = `${appURL}/login`;
        });
    } else {
      window.location.href = `${appURL}/login`;
    }
  }
);

export const authLogInSlice = createSlice({
  name: "authLogInSlice",
  initialState: {
    authLogIn: false,
    statusLogIn: null,
  },
  reducers: {
    setAuthLogIn: (state, action) => {
      return { ...state, authLogIn: action.payload };
    },
    setStatusLogIn: (state, action) => {
      state.statusLogIn = action.payload;
      return state;
    },
  },
  extraReducers: {
    [getAuthenticated.pending]: (state) => {
      state.statusLogIn = "loading";
    },
    [getAuthenticated.fulfilled]: (state, action) => {
      state.authLogIn = true;
      state.statusLogIn = "loaded";
    },
    [getAuthenticated.rejected]: (state) => {
      state.statusLogIn = "failed";
    },
  },
});

export const { setAuthLogIn, setStatusLogIn } = authLogInSlice.actions;

export default authLogInSlice.reducer;
