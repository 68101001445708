import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../Fetch/axios";
import { statusURL } from "../Data/baseURL";

export const getStatuses = createAsyncThunk(
  "statusSlice/getStatuses",
  async () => {
    return axiosInstance
      .get(statusURL)
      .then((response) => {
        if (response.data) return response.data;
      })
      .catch(() => {}, []);
  }
);

export const statusSlice = createSlice({
  name: "statusSlice",
  initialState: {
    statuses: [],
    statusesLocal: [],
    statusesLocalInv: [],
    statusStatuses: null,
  },
  reducers: {
    setStatuses: (state, action) => {
      return { ...state, statuses: [...action.payload] };
    },
    setStatusesLocal: (state, action) => {
      return { ...state, statusesLocal: action.payload };
    },
    setStatusesLocalInv: (state, action) => {
      return { ...state, statusesLocalInv: action.payload };
    },
  },
  extraReducers: {
    [getStatuses.pending]: (state) => {
      state.statusStatuses = "loading";
    },
    [getStatuses.fulfilled]: (state, action) => {
      Array.isArray(action.payload) === true &&
        (state.statuses = action.payload);
      Array.isArray(action.payload) === true &&
        (state.statusStatuses = "loaded");
    },
    [getStatuses.rejected]: (state) => {
      state.statusStatuses = "failed";
    },
  },
});

export const { setItems, setStatuses, setStatusesLocal, setStatusesLocalInv } =
  statusSlice.actions;

export default statusSlice.reducer;
