import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../Fetch/axios";
import { toast } from "react-toastify";
import i18n from "i18next";
import { noteURL } from "../Data/baseURL";

export const getItems = createAsyncThunk(
  "noteTermSlice/getItems",
  async (route) => {
    return axiosInstance.get(noteURL).then((response) => {
      if (response.data) {
        route === "/apps/terms-of-payment" &&
          toast.success(i18n.t("toast.terms_of_payment_list_updated"));
        return response.data;
      }
    });
  }
);

export const noteTermSlice = createSlice({
  name: "noteTermSlice",
  initialState: {
    noteTerms: [],
    noteTerm: {},
    statusNoteTerms: null,
  },
  reducers: {
    setItems: (state, action) => {
      return { ...state, noteTerms: [...action.payload] };
    },
    setNoteTerm: (state, action) => {
      return { ...state, noteTerm: { ...action.payload } };
    },
    addOrEditItem: (state, { payload: values }) => {
      const i = state.noteTerms.findIndex((item) => item.id === values.id);
      if (i > -1) state.noteTerms[i] = values;
      else state.noteTerms.unshift(values);
      return state;
    },
    deleteNote: (state, { payload }) => {
      const newItems = state.noteTerms.filter((item) => item.id !== payload);
      return { ...state, noteTerms: [...newItems] };
    },
  },
  extraReducers: {
    [getItems.pending]: (state) => {
      state.statusNoteTerms = "loading";
    },
    [getItems.fulfilled]: (state, action) => {
      Array.isArray(action.payload) === true &&
        (state.noteTerms = action.payload);
      state.statusNoteTerms = "loaded";
    },
    [getItems.rejected]: (state) => {
      state.statusNoteTerms = "failed";
    },
  },
});

export const { setItems, setNoteTerm, addOrEditItem, deleteNote } =
  noteTermSlice.actions;

export default noteTermSlice.reducer;
