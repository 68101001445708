import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

////// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 80,
    paddingLeft: 47,
    paddingRight: 35,
    // size: "A4",
  },
  boxM: {
    marginBottom: 2,
    justifyContent: "flex-start",
    flexDirection: "column",
    width: 100,
  },
  boxSmall: {
    marginBottom: 2,
    justifyContent: "flex-start",
    flexDirection: "column",
    width: 71,
    padding: 3,
  },
  boxAutosize: {
    marginBottom: 2,
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: 3,
    marginRight: "5px",
    backgroundColor: "#f5f5f5",
    border: "1px solid #cccccc",
    borderRadius: "5px",
    width: "auto",
    maxWidth: 146,
  },
  boxMContent: {
    marginBottom: 2,
    flexDirection: "column",
    width: 100,
    backgroundColor: "#f5f5f5",
    border: "1px solid #cccccc",
    borderRadius: "5px",
    height: "20px",
    justifyContent: "center",
    padding: 3,
    overflow: "hidden",
  },
  boxXL: {
    marginTop: 0,
    flexDirection: "column",
    marginBottom: 2,
    justifyContent: "flex-start",
    width: 538,
  },
  rowContainer: {
    flexDirection: "row",
    marginBottom: 3,
    justifyContent: "flex-start",
    width: "535",
  },
  text3: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
  },
  text4: {
    fontSize: 8,
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    color: "rgba(0, 0, 0, 0.6)",
    paddingBottom: "1px",
    paddingTop: "3px",
  },
  titleM: {
    fontSize: 12,
    textAlign: "left",
    fontFamily: "Roboto",
    marginBottom: 4,
  },
  titleXL: {
    flexDirection: "row",
    fontSize: 14,
    textAlign: "left",
    fontFamily: "Roboto",
    marginBottom: 2,
  },
});

function checkDate(date) {
  return date ? new Date(date).toLocaleDateString("fr-EN") : null;
}

const QuotePdfLoadDetails = (props) => {
  const { values, invoicePdfLang } = props;
  const inv_entry = values && values.entry_invs && values.entry_invs[0];

  return (
    <React.Fragment>
      {inv_entry && (
        <View style={styles.boxXL}>
          {10 === 20 && (
            <>
              {/* Loading address */}
              <View style={styles.rowContainer}>
                <View style={styles.boxM}>
                  <Text style={styles.text4}>
                    {invoicePdfLang === "RO/RU"
                      ? "Tara incarc./ Страна загр."
                      : "Country of loading"}
                  </Text>
                  <View style={styles.boxMContent}>
                    <Text style={styles.text3}>
                      {inv_entry.country_load && inv_entry.country_load.label}
                    </Text>
                  </View>
                </View>
                <View style={{ ...styles.boxM, width: 85 }}>
                  <Text style={styles.text4}>
                    {invoicePdfLang === "RO/RU"
                      ? "Cod postal/ Почт. код"
                      : "Zip"}
                  </Text>
                  <View style={{ ...styles.boxMContent, width: 85 }}>
                    <Text style={styles.text3}> {inv_entry.zip_load}</Text>
                  </View>
                </View>
                <View style={{ ...styles.boxM, width: 250 }}>
                  <Text style={styles.text4}>
                    {invoicePdfLang === "RO/RU" ? "Localitate/ Город" : "City"}
                  </Text>
                  <View style={{ ...styles.boxMContent, width: 250 }}>
                    <Text style={styles.text3}>{inv_entry.city_load}</Text>
                  </View>
                </View>
                <View style={styles.boxM}>
                  <Text style={styles.text4}>
                    {invoicePdfLang === "RO/RU"
                      ? "Data incarc./ Дата загр."
                      : "Date of loading"}
                  </Text>
                  <View style={styles.boxMContent}>
                    <Text style={styles.text3}>
                      {checkDate(inv_entry.date_load)}
                    </Text>
                  </View>
                </View>
              </View>

              {/* Unloading address */}
              <View style={styles.rowContainer}>
                <View style={styles.boxM}>
                  <Text style={styles.text4}>
                    {invoicePdfLang === "RO/RU"
                      ? "Tara desc./ Страна разгр."
                      : "Country of unloading"}
                  </Text>
                  <View style={styles.boxMContent}>
                    <Text style={styles.text3}>
                      {inv_entry.country_unload &&
                        inv_entry.country_unload.label}
                    </Text>
                  </View>
                </View>
                <View style={{ ...styles.boxM, width: 85 }}>
                  <Text style={styles.text4}>
                    {invoicePdfLang === "RO/RU"
                      ? " Cod postal/ Почт. код"
                      : "Zip"}
                  </Text>
                  <View style={{ ...styles.boxMContent, width: 85 }}>
                    <Text style={styles.text3}>{inv_entry.zip_unload}</Text>
                  </View>
                </View>
                <View style={{ ...styles.boxM, width: 250 }}>
                  <Text style={styles.text4}>
                    {invoicePdfLang === "RO/RU" ? "Localitate/ Город" : "City"}
                  </Text>
                  <View style={{ ...styles.boxMContent, width: 250 }}>
                    <Text style={styles.text3}>{inv_entry.city_unload}</Text>
                  </View>
                </View>
                <View style={styles.boxM}>
                  <Text style={styles.text4}>
                    {invoicePdfLang === "RO/RU"
                      ? "Data descarc./ Дата разгр."
                      : "Date of unloading"}
                  </Text>
                  <View style={styles.boxMContent}>
                    <Text style={styles.text3}>
                      {checkDate(inv_entry.date_unload)}
                    </Text>
                  </View>
                </View>
              </View>
            </>
          )}

          {/* Load details */}
          <View
            style={{
              ...styles.rowContainer,
              backgroundColor: "#cccccc",
              borderRadius: "5px",
              marginTop: 2,
            }}
          >
            <View style={styles.boxSmall}>
              <Text style={styles.text3}>
                {invoicePdfLang === "RO/RU" ? "Cant./Кол-тво" : "Pieces"}
              </Text>
            </View>
            <View style={styles.boxSmall}>
              <Text style={styles.text3}>
                {invoicePdfLang === "RO/RU" ? "Greutate/Вес" : "Weight"}
              </Text>
            </View>
            <View style={styles.boxSmall}>
              <Text style={styles.text3}>
                {invoicePdfLang === "RO/RU" ? "Volum/ Объём" : "Volume"}
              </Text>
            </View>
            <View style={styles.boxSmall}>
              <Text style={styles.text3}>
                {invoicePdfLang === "RO/RU" ? "m.l./пог.м." : "LDM"}
              </Text>
            </View>
            <View style={{ ...styles.boxSmall, width: 250 }}>
              <Text style={styles.text3}>
                {invoicePdfLang === "RO/RU"
                  ? "Dimensiuni/ Размеры"
                  : "Dimensions and nature of goods"}
              </Text>
            </View>
          </View>

          {/* Load data createa map function */}
          {inv_entry.entrydetails.map((entry) => (
            <View key={entry.id} style={styles.rowContainer}>
              <View
                style={{
                  ...styles.boxSmall,
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #cccccc",
                  borderRadius: "5px",
                  height: "20px",
                  justifyContent: "center",
                  padding: 3,
                  overflow: "hidden",
                }}
              >
                <Text style={styles.text3}>{entry.pieces}</Text>
              </View>
              <View
                style={{
                  ...styles.boxSmall,
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #cccccc",
                  borderRadius: "5px",
                  height: "20px",
                  justifyContent: "center",
                  padding: 3,
                  overflow: "hidden",
                }}
              >
                <Text style={styles.text3}>{entry.weight} kg</Text>
              </View>
              <View
                style={{
                  ...styles.boxSmall,
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #cccccc",
                  borderRadius: "5px",
                  height: "20px",
                  justifyContent: "center",
                  padding: 3,
                  overflow: "hidden",
                }}
              >
                <Text style={styles.text3}>{entry.volume} cbm</Text>
              </View>
              <View
                style={{
                  ...styles.boxSmall,
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #cccccc",
                  borderRadius: "5px",
                  height: "20px",
                  justifyContent: "center",
                  padding: 3,
                  overflow: "hidden",
                }}
              >
                <Text style={styles.text3}>{entry.ldm}</Text>
              </View>
              <View
                style={{
                  ...styles.boxSmall,
                  width: 250,
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #cccccc",
                  borderRadius: "5px",
                  height: "20px",
                  justifyContent: "center",
                  padding: 3,
                  overflow: "hidden",
                }}
              >
                <Text style={styles.text3}>{entry.dims}</Text>
              </View>
            </View>
          ))}

          {/* Aditional instruction  de setat prin map */}
          <View style={{ marginTop: 5 }}>
            <Text style={styles.text3}>
              {invoicePdfLang === "RO/RU"
                ? "Solicitari suplimentare/ Дополнительные требывания:"
                : "Additional requests"}
            </Text>
          </View>
          <View style={styles.rowContainer}>
            {inv_entry.tail_lift && (
              <View style={styles.boxAutosize}>
                <Text style={{ ...styles.text3, width: "auto" }}>
                  {invoicePdfLang === "RO/RU"
                    ? "Lift/ Грузовая лопата"
                    : "Tail lift"}
                </Text>
              </View>
            )}
            {inv_entry.palletexchange && (
              <View style={styles.boxAutosize}>
                <Text style={{ ...styles.text3, width: "auto" }}>
                  {invoicePdfLang === "RO/RU"
                    ? "Schimb paleti/ Обмен паддонов"
                    : "Pallet Exchange"}
                </Text>
              </View>
            )}
            {inv_entry.dangerousgoods && (
              <View style={styles.boxAutosize}>
                <Text style={{ ...styles.text3, width: "auto" }}>
                  {invoicePdfLang === "RO/RU"
                    ? "Marfa periculoasa/ Опасный груз "
                    : "Dang. goods "}
                  {inv_entry.dangerousgoods_class}
                </Text>
              </View>
            )}
            {inv_entry.temp_control && (
              <View style={styles.boxAutosize}>
                <Text style={{ ...styles.text3, width: "auto" }}>
                  {invoicePdfLang === "RO/RU"
                    ? "Temp. controlata/ Темп. режим "
                    : "Temp. control goods "}
                  {inv_entry.temp_control_details}
                </Text>
              </View>
            )}
          </View>
        </View>
      )}
    </React.Fragment>
  );
};

export default QuotePdfLoadDetails;
