import React, { useEffect } from "react";
import { useFormikContext, useField } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
////// Mui
import { styled } from "@mui/material/styles";
import { InputLabel, FormHelperText } from "@mui/material";
import { Stack, TextField, MenuItem } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
////// Controls & Components
import { langs } from "../../Data/generalData";

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(-1),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#f5f5f5" : "#f5f5f5",
    fontSize: 16,
    fontWeight: 500,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function SelectBasic(props) {
  const { name, label, required, disabled, ...otherProps } = props;
  const { t } = useTranslation();
  const [options, setOptions] = React.useState([]);
  const { statusesLocal, statusesLocalInv } = useSelector(
    (state) => state.statusSlice
  );
  const { currencies } = useSelector((state) => state.currencySlice);

  const { setFieldValue } = useFormikContext();

  const [field, meta, helpers] = useField(String(name));

  const handleChange = (e) => {
    const { value } = e.target;
    setFieldValue(field.name, field.name !== "lang" ? value : value.code);
  };

  useEffect(() => {
    if (field.name === "status") {
      setOptions(
        otherProps.quoteorinv === "0" ? statusesLocal : statusesLocalInv
      );
    }
    if (field.name.startsWith("currency")) {
      setOptions(currencies);
    }
    if (field.name === "lang") {
      setOptions(langs);
    }
  }, [
    field.name,
    currencies,
    statusesLocal,
    statusesLocalInv,
    otherProps.quoteorinv,
  ]);

  const getItemIndex = (id) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].id === id) {
        return i;
      }
    }
    return "";
  };

  const configHelperText = {
    sx: { color: "red", fontSize: 14, fullWidth: true },
  };

  const inputLabelProps = {
    label: label,
    required: required,
    shrink: true,
    sx: { fontSize: 18, fontWeight: "bold" },
  };

  const configTextField = {
    name: field.name,
    disabled: disabled,
    fullWidth: true,
    size: "small",
    autoComplete: "off",
    sx: { ...otherProps.sx, backgroundColor: "#f5f5f5" },
    ...otherProps,
  };

  let statusColor = null;

  let helperText = null;

  const helperTextShow = !field.name.startsWith("some_specific_field");

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    helperText = meta.error;
  }

  return (
    <>
      <InputLabel {...inputLabelProps} htmlFor={field.name}>
        {label}
      </InputLabel>
      <TextFieldStyled
        id='basic-select'
        select
        value={
          field.value && options.length > 0
            ? options[
                getItemIndex(
                  field.name !== "lang"
                    ? field.value.id
                    : langs.find((lang) => lang.code === field.value).id
                )
              ]
            : ""
        }
        onChange={handleChange}
        {...configTextField}
        onBlur={() => helpers.setTouched(true)}
      >
        <MenuItem value=''>
          <em>{t("general.none")}</em>
        </MenuItem>

        {options.map((option) => {
          if (option.serial_number) {
            switch (option.serial_number) {
              case "100":
                statusColor = "inherit";
                break;
              case "105":
                statusColor = "primary";
                break;
              case "110":
                statusColor = "success";
                break;
              case "115":
                statusColor = "warning";
                break;
              case "200":
                statusColor = "inherit";
                break;
              case "205":
                statusColor = "warning";
                break;
              case "210":
                statusColor = "primary";
                break;
              case "215":
                statusColor = "success";
                break;
              case "220":
                statusColor = "error";
                break;
              default: // registered
                statusColor = "inherit";
                break;
            }
          }
          return (
            <MenuItem key={option.id} value={option}>
              {field.name === "status" && (
                <>
                  <Stack direction='row' sx={{ pl: 0, pr: 0 }}>
                    <FiberManualRecordIcon
                      color={statusColor}
                      fontSize='small'
                      sx={{ pt: 0.2, pr: 1 }}
                    />
                    {option.type}
                  </Stack>
                </>
              )}
              {field.name.startsWith("currency") && (
                <>
                  <img
                    src={`https://flagcdn.com/w20/${option?.currencycode
                      ?.slice(0, 2)
                      .toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option?.currencycode
                      ?.slice(0, 2)
                      .toLowerCase()}.png 2x`}
                    alt=''
                    style={{
                      width: "28px",
                      height: "14px",
                      weight: "auto",
                      marginRight: 12,
                    }}
                  />
                  {option.currencycode}
                </>
              )}
              {field.name === "lang" && (
                <>
                  <img
                    src={`https://flagcdn.com/w20/${
                      option.code === "ro"
                        ? "md"
                        : option.code === "ru"
                        ? "ru"
                        : "gb"
                    }.png`}
                    srcSet={`https://flagcdn.com/w40/${
                      option.code === "ro"
                        ? "md"
                        : option.code === "ru"
                        ? "ru"
                        : "gb"
                    }.png 2x`}
                    alt=''
                    style={{
                      width: "28px",
                      height: "14px",
                      weight: "auto",
                      marginRight: 12,
                    }}
                  />
                  {option.lang}
                </>
              )}
            </MenuItem>
          );
        })}
      </TextFieldStyled>
      <FormHelperText error {...configHelperText}>
        {helperTextShow && helperText}
      </FormHelperText>
    </>
  );
}
