import { createSlice } from "@reduxjs/toolkit";

export const itemForEditSlice = createSlice({
  name: "itemForEditSlice",
  initialState: {
    itemForEdit: null,
  },
  reducers: {
    setItemForEdit: (state, action) => {
      return { ...state, itemForEdit: action.payload };
    },
  },
});

export const { setItemForEdit } = itemForEditSlice.actions;

export default itemForEditSlice.reducer;
