import { createSlice } from "@reduxjs/toolkit";

export const popupNotificationSlice = createSlice({
  name: "popupNotificationSlice",
  initialState: {
    popupNotification: false,
  },
  reducers: {
    setPopupNotification: (state, action) => {
      return { ...state, popupNotification: action.payload };
    },
  },
});

export const { setPopupNotification } = popupNotificationSlice.actions;

export default popupNotificationSlice.reducer;
