import { createSlice } from "@reduxjs/toolkit";

export const popupBasicSlice = createSlice({
  name: "popupBasicSlice",
  initialState: {
    popupBasic: false,
  },
  reducers: {
    setPopupBasic: (state, action) => {
      return { ...state, popupBasic: action.payload };
    },
  },
});

export const { setPopupBasic } = popupBasicSlice.actions;

export default popupBasicSlice.reducer;
