import React from "react";
import { Typography, Link } from "@mui/material";

const Copyright = ({ color }) => {
  return (
    <React.Fragment>
      <Typography
        variant='body2'
        sx={{
          color: color === "white" ? "#FFFF" : "textSecondary",
          opacity: 0.8,
        }}
        align='center'
      >
        {"Copyright © "}
        <Link color='inherit' href='https://contdeplata.md'>
          CONTDEPLATA.MD
        </Link>{" "}
        {new Date().getFullYear()}
      </Typography>
    </React.Fragment>
  );
};

export default Copyright;
