import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../Fetch/axios";
import { exRatesURL } from "../Data/baseURL";

export const getExRates = createAsyncThunk(
  "exRatesSlice/getExRates",
  async () => {
    return await axiosInstance
      .get(exRatesURL, {
        timeout: 10000,
      })
      .then((response) => {
        if (
          response.data &&
          response.data.ValCurs &&
          response.data.ValCurs.Valute
        ) {
          let slicedData = response.data.ValCurs.Valute.slice(0, 5);
          return { ...response.data.ValCurs, Valute: slicedData };
        } else {
          return null;
        }
      })
      .catch(() => {}, []);
  }
);

export const exRatesSlice = createSlice({
  name: "exRatesSlice",
  initialState: {
    exRates: null,
    statusExRates: null,
  },
  reducers: {
    setExRates: (state, action) => {
      return { ...state, exRates: [...action.payload] };
    },
  },
  extraReducers: {
    [getExRates.pending]: (state) => {
      state.statusExRates = "loading";
    },
    [getExRates.fulfilled]: (state, action) => {
      state.exRates = action.payload;
      state.statusExRates = "loaded";
    },
    [getExRates.rejected]: (state) => {
      state.statusExRates = "failed";
    },
  },
});

export const { exRates, statusExRates } = exRatesSlice.actions;

export default exRatesSlice.reducer;
