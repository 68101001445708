import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../Fetch/axios";
import { toast } from "react-toastify";
import i18n from "i18next";
import { notificationURL } from "../Data/baseURL";

export const getNotifications = createAsyncThunk(
  "notificationSlice/getNotifications",
  async (route) => {
    return await axiosInstance
      .get(notificationURL)
      .then((response) => {
        if (response.data) {
          route === "/" &&
            toast.success(i18n.t("toast.notification_list_updated"));
          return response.data;
        }
      })
      .catch(() => {});
  }
);

export const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState: {
    notification: {},
    notifications: [],
    statusNotifications: null,
  },
  reducers: {
    setNotifications: (state, action) => {
      return { ...state, notifications: [...action.payload] };
    },
    setNotification: (state, action) => {
      return { ...state, notification: { ...action.payload } };
    },
    addOrEditNotification: (state, { payload: values }) => {
      if (values !== "") {
        const i = state.notifications.findIndex(
          (item) => item.id === values.id
        );
        if (i > -1) state.notifications[i] = values;
        else state.notifications.unshift(values);
        return state;
      } else return state;
    },
    readNotification: (state, { payload }) => {
      const newItems = state.notifications.filter(
        (item) => item.id !== payload
      );

      return { ...state, notifications: [...newItems] };
    },
    deleteNotification: (state, { payload }) => {
      const newItems = state.notifications.filter(
        (item) => item.id !== payload
      );

      return { ...state, notifications: [...newItems] };
    },
  },
  extraReducers: {
    [getNotifications.pending]: (state) => {
      state.statusNotifications = "loading";
    },
    [getNotifications.fulfilled]: (state, action) => {
      Array.isArray(action.payload) === true &&
        (state.notifications = action.payload);
      state.statusNotifications = "loaded";
    },
    [getNotifications.rejected]: (state) => {
      state.statusNotifications = "failed";
    },
  },
});

export const {
  setNotifications,
  setNotification,
  addOrEditNotification,
  readNotification,
  deleteNotification,
} = notificationSlice.actions;

export default notificationSlice.reducer;
