import { createSlice } from "@reduxjs/toolkit";

export const formTouchedSlice = createSlice({
  name: "formTouchedSlice",
  initialState: {
    formTouchedQuote: false,
    formTouchedInv: false,
    formSimpleTouched: false,
    formSecondTouched: false,
  },
  reducers: {
    setFormTouchedQuote: (state, action) => {
      state.formTouchedQuote = action.payload;
      return state;
    },
    setFormTouchedInv: (state, action) => {
      state.formTouchedInv = action.payload;
      return state;
    },
    setFormSimpleTouched: (state, action) => {
      state.formSimpleTouched = action.payload;
      return state;
    },
    setFormSecondTouched: (state, action) => {
      state.formSecondTouched = action.payload;
      return state;
    },
  },
});

export const {
  setFormTouchedQuote,
  setFormTouchedInv,
  setFormSimpleTouched,
  setFormSecondTouched,
} = formTouchedSlice.actions;

export default formTouchedSlice.reducer;
