import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch, batch } from "react-redux";
import { useTranslation } from "react-i18next";
////// Mui
import styled from "@emotion/styled";
import { Box, IconButton } from "@mui/material";
import { CardActionArea, Divider, Popover, Card } from "@mui/material";
import { Typography, CardActions, CardMedia, Stack } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
////// Store
import { setInv } from "../../Store/invSlice";
import { setQuote } from "../../Store/quoteSlice";
import { setPopupQuote, setPopupInv, setPopup2 } from "../../Store/popupSlice";
import { setFormTouchedInv } from "../../Store/formTouchedSlice";
import { setFormTouchedQuote } from "../../Store/formTouchedSlice";
import { setItemForEdit } from "../../Store/itemForEditSlice";
////// Controls & Components
import { appURL } from "../../Data/baseURL";
import { createIcon_1 } from "../../Data/imageURL";
import { ContDePlataLogo_onwhite1 } from "../../Data/imageURL";
import { initialValuesContact } from "../Tables/InitialValues/initialValuesContact";

import QuoteIcon from "../../Data/Icons/QuoteIcon";
import TooltipBasic from "../../Controls/Inputs/TooltipBasic";

const TypographyPopover = styled(Typography)(({ theme }) => ({
  padding: 16,
  color: "rgba(0, 0, 0, 0.87)",
  maxWidth: 350,
  fontSize: theme.typography.pxToRem(20),
}));

const FirstSteps = ({
  disabledAddButtonInv,
  disabledAddButtonQuote,
  disabledAddButtonClient,
  bankAccounts,
  invLength,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { company, statusCompany } = useSelector((state) => state.companySlice);
  const steps = { step1: 1, step2: 2, step3: 3, step4: 4 };

  function checksteps(step) {
    let result = 0;
    if (step === 1 && company?.companyname) result = 1;
    if (step === 2 && bankAccounts.length > 0) result = 1;
    if (step === 3 && company?.logo) result = 1;
    if (step === 4 && invLength) result = 1;

    return result;
  }

  const openInPopup = (quoteOrInv) => {
    batch(() => {
      if (quoteOrInv === "0") {
        dispatch(setPopupQuote(true));
        dispatch(setQuote({}));
        dispatch(setFormTouchedQuote(false));
      }

      if (quoteOrInv === "1") {
        dispatch(setPopupInv(true));
        dispatch(setInv({}));
        dispatch(setFormTouchedInv(false));
      }
    });
    return null;
  };

  const handleAddClient = () => {
    batch(() => {
      dispatch(
        setItemForEdit({
          ...initialValuesContact,
          fieldName: "bill_to",
          index: null,
        })
      );
      dispatch(setPopup2(true));
    });
    return null;
  };

  ////// Start popover quote section
  const [anchorElQuote, setAnchorElQuote] = useState(null);
  const handleClickPopoverQuote = (event) => {
    setAnchorElQuote(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElQuote(null);
    setAnchorEl(null);
    setAnchorElClient(null);
  };
  const openQuote = Boolean(anchorElQuote);
  const idQuote = openQuote ? "popover-quote" : undefined;
  ////// End popover quote section

  ////// Start popover invoice section
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  ////// End popover invoice section

  ////// PopoverClient section start
  const [anchorElClient, setAnchorElClient] = useState(null);
  const handleClickPopoverClient = (event) => {
    setAnchorElClient(event.currentTarget);
  };
  const openClient = Boolean(anchorElClient);
  const idClient = open ? "popover-client" : undefined;
  ////// PopoverClient section end

  return (
    <React.Fragment>
      <Card
        sx={{
          minHeight: 520,
          height: "100%",
          maxWidth: "auto",
        }}
      >
        {bankAccounts && statusCompany ? (
          <>
            {checksteps(steps.step1) !== 1 ||
            checksteps(steps.step2) !== 1 ||
            checksteps(steps.step4) !== 1 ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                    height: "auto",
                    py: "10px",
                    px: "10%",
                    gap: "10px",
                  }}
                >
                  <Typography variant='h5'>
                    {t("dashboard.first_steps")}
                  </Typography>
                  <img
                    src={ContDePlataLogo_onwhite1}
                    alt='ContDePlata Logo'
                    style={{ width: "60px", height: "auto" }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flewWrap: "wrap",
                    width: "100%",
                    padding: "10px 10px 0px 10px",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      justifyContent: "space-between",
                      width: "50%",
                      gap: "10px",
                      px: "10px",
                    }}
                  >
                    <CardActionArea
                      disabled={checksteps(steps.step1) === 1 ? true : false}
                      component={NavLink}
                      to={`${appURL}/company`}
                      onClick={() => checksteps(steps.step1)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          minHeight: "180px",
                          height: "auto",
                          backgroundColor: "#34D77B",
                          borderRadius: "5px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#FFF",
                            textAlign: "center",
                            py: "5px",
                            height: "20%",
                          }}
                        >
                          {t("dashboard.step")} 1
                        </Typography>
                        <Divider
                          variant='middle'
                          sx={{ backgroundColor: "#fff" }}
                        />
                        <Typography
                          sx={{
                            display: "flex",
                            color: "#FFF",
                            textAlign: "start",
                            py: "5px",
                            width: "80%",
                            minHeight: "50%",
                            alignItems: "center",
                            mx: "5%",
                          }}
                        >
                          {t("dashboard.complete_company_data")}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "end",
                            height: "20%",
                          }}
                        >
                          <ArrowRightAltIcon
                            sx={{
                              display:
                                checksteps(steps.step1) === 1 ? "none" : "auto",
                              fill: "#fff",
                              width: "40px",
                              height: "auto",
                            }}
                          />
                          <TaskAltIcon
                            sx={{
                              display:
                                checksteps(steps.step1) === 0 ? "none" : "auto",
                              fill: "#fff",
                              width: "40px",
                              height: "auto",
                            }}
                          />
                        </Box>
                      </Box>
                    </CardActionArea>
                    <CardActionArea
                      disabled={checksteps(steps.step2) === 1 ? true : false}
                      component={NavLink}
                      to={`${appURL}/bank-accounts`}
                      onClick={() => checksteps(steps.step2)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          minHeight: "180px",
                          height: "auto",
                          backgroundColor: "#FB8C00",
                          borderRadius: "5px",
                        }}
                      >
                        <Typography
                          sx={{ color: "#FFF", textAlign: "center", py: "5px" }}
                        >
                          {t("dashboard.step")} 2
                        </Typography>
                        <Divider
                          variant='middle'
                          sx={{ backgroundColor: "#fff" }}
                        />
                        <Typography
                          sx={{
                            display: "flex",
                            color: "#FFF",
                            textAlign: "start",
                            py: "5px",
                            width: "80%",
                            height: "50%",
                            alignItems: "center",
                            mx: "5%",
                          }}
                        >
                          {t("dashboard.add_bank_account")}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "end",
                            height: "20%",
                          }}
                        >
                          <ArrowRightAltIcon
                            sx={{
                              display:
                                checksteps(steps.step2) === 1 ? "none" : "auto",
                              fill: "#fff",
                              width: "40px",
                              height: "auto",
                            }}
                          />
                          <TaskAltIcon
                            sx={{
                              display:
                                checksteps(steps.step2) === 0 ? "none" : "auto",
                              fill: "#fff",
                              width: "40px",
                              height: "auto",
                            }}
                          />
                        </Box>
                      </Box>
                    </CardActionArea>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      width: "50%",
                      justifyContent: "space-between",
                      gap: "10px",
                      px: "10px",
                    }}
                  >
                    <CardActionArea
                      component={NavLink}
                      to={`${appURL}/company`}
                      disabled={checksteps(steps.step3) === 1 ? true : false}
                      onClick={() => checksteps(steps.step3)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          minHeight: "180px",
                          height: "auto",
                          backgroundColor: "#0288D1",
                          borderRadius: "5px",
                        }}
                      >
                        <Typography
                          sx={{ color: "#FFF", textAlign: "center", py: "5px" }}
                        >
                          {t("dashboard.step")} 3
                        </Typography>
                        <Divider
                          variant='middle'
                          sx={{ backgroundColor: "#fff" }}
                        />
                        <Typography
                          sx={{
                            display: "flex",
                            color: "#FFF",
                            textAlign: "start",
                            py: "5px",
                            width: "80%",
                            height: "50%",
                            alignItems: "center",
                            mx: "5%",

                            textOverflow: "ellipsis",
                          }}
                        >
                          {t("dashboard.customize_your_invoice")}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "end",
                            height: "20%",
                          }}
                        >
                          <ArrowRightAltIcon
                            sx={{
                              display:
                                checksteps(steps.step3) === 1 ? "none" : "auto",
                              fill: "#fff",
                              width: "40px",
                              height: "auto",
                            }}
                          />
                          <TaskAltIcon
                            sx={{
                              display:
                                checksteps(steps.step3) === 0 ? "none" : "auto",
                              fill: "#fff",
                              width: "40px",
                              height: "auto",
                            }}
                          />
                        </Box>
                      </Box>
                    </CardActionArea>
                    <CardActionArea
                      component={NavLink}
                      to={`${appURL}/invoices`}
                      disabled={checksteps(steps.step4) === 1 ? true : false}
                      onClick={
                        disabledAddButtonInv
                          ? handleClickPopover
                          : () => openInPopup()
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          minHeight: "180px",
                          height: "auto",
                          backgroundColor: "#8338ec",
                          borderRadius: "5px",
                        }}
                      >
                        <Typography
                          sx={{ color: "#FFF", textAlign: "center", py: "5px" }}
                        >
                          {t("dashboard.step")} 4
                        </Typography>
                        <Divider
                          variant='middle'
                          sx={{ backgroundColor: "#fff" }}
                        />
                        <Typography
                          sx={{
                            display: "flex",
                            color: "#FFF",
                            textAlign: "start",
                            py: "5px",
                            width: "80%",
                            height: "50%",
                            alignItems: "center",
                            mx: "5%",
                          }}
                        >
                          {t("dashboard.issue_first_invoice")}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "end",
                            height: "20%",
                          }}
                        >
                          <ArrowRightAltIcon
                            sx={{
                              display:
                                checksteps(steps.step4) === 1 ? "none" : "auto",
                              fill: "#fff",
                              width: "40px",
                              height: "auto",
                            }}
                          />
                          <TaskAltIcon
                            sx={{
                              display:
                                checksteps(steps.step4) === 0 ? "none" : "auto",
                              fill: "#fff",
                              width: "40px",
                              height: "auto",
                            }}
                          />
                        </Box>
                      </Box>
                    </CardActionArea>
                  </Box>
                </Box>
              </>
            ) : (
              <Stack direction='column' alignItems='center'>
                <CardMedia
                  component='img'
                  image={createIcon_1}
                  alt='invoice'
                  style={{ height: 390, maxWidth: 390 }}
                />

                <CardActions>
                  <Box sx={{ width: "100%" }}>
                    <Stack spacing={2}>
                      <Typography variant='h6' color='primary' align='center'>
                        {t("button.create_new_dots")}
                      </Typography>

                      <Stack direction='row' spacing={5} alignSelf='center'>
                        <TooltipBasic
                          title={t("button.add_quote_lower")}
                          placement='bottom-start'
                        >
                          <IconButton
                            onClick={
                              disabledAddButtonQuote
                                ? handleClickPopoverQuote
                                : () => openInPopup("0")
                            }
                          >
                            <QuoteIcon
                              fontSize='large'
                              sx={{ color: "#ffd827" }}
                            />
                          </IconButton>
                        </TooltipBasic>
                        <Popover
                          id={idQuote}
                          open={openQuote}
                          anchorEl={anchorElQuote}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                        >
                          <TypographyPopover>
                            {t(
                              "toast.need_to_upgrade_in_order_to_register_new_quotes"
                            )}
                          </TypographyPopover>
                        </Popover>

                        <TooltipBasic
                          title={t("button.add_invoice_lower")}
                          placement='bottom-start'
                        >
                          <IconButton
                            onClick={
                              disabledAddButtonInv
                                ? handleClickPopover
                                : () => openInPopup("1")
                            }
                            sx={{ color: "#9EB305" }}
                          >
                            <ReceiptLongIcon fontSize='large' />
                          </IconButton>
                        </TooltipBasic>
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                        >
                          <TypographyPopover>
                            {t(
                              "toast.need_to_upgrade_in_order_to_register_new_invoices"
                            )}
                          </TypographyPopover>
                        </Popover>

                        <TooltipBasic
                          title={t("button.add_client_lower")}
                          placement='bottom-start'
                        >
                          <IconButton
                            color='info'
                            onClick={
                              disabledAddButtonClient
                                ? handleClickPopoverClient
                                : () => handleAddClient()
                            }
                          >
                            <PeopleAltIcon fontSize='large' />
                          </IconButton>
                        </TooltipBasic>
                        <Popover
                          id={idClient}
                          open={openClient}
                          anchorEl={anchorElClient}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                        >
                          <TypographyPopover>
                            {t(
                              "toast.need_to_upgrade_in_order_to_register_new_clients"
                            )}
                          </TypographyPopover>
                        </Popover>
                      </Stack>
                    </Stack>
                  </Box>
                </CardActions>
              </Stack>
            )}
          </>
        ) : null}
      </Card>
    </React.Fragment>
  );
};

export default FirstSteps;
