import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
////// Store
import { getNotifications } from "../../Store/notificationSlice";
////// Controls & Components
import { columnsNotifications } from "../Tables/Columns/columnsNotifications";
import TableSimple from "../Tables/TableSimple";

const addButtonText = "";

const Notifications = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { notifications, statusNotifications } = useSelector(
    (state) => state.notificationSlice
  );

  React.useEffect(() => {
    if (notifications.length < 1 && statusNotifications === null) {
      dispatch(getNotifications());
    }
  }, [notifications, statusNotifications, dispatch]);

  return (
    <TableSimple
      columns={columnsNotifications()}
      popupTitle={t("popup.title.notifications")}
      data={notifications}
      addButtonText={addButtonText}
    ></TableSimple>
  );
};

export default Notifications;
