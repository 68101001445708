import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Font } from "@react-pdf/renderer";
////// Mui
import { styled } from "@mui/material/styles";
import { useTheme, Divider } from "@mui/material";
import { IconButton, Box, Drawer } from "@mui/material";
import { Container, Grid, useMediaQuery } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
////// Store
import { addOrEditQuote, deleteQuote } from "../Store/quoteSlice";
import { setAddQuotesCount, deleteQuotesCount } from "../Store/quoteSlice";
import { addOrEditInv, deleteInv } from "../Store/invSlice";
import { addOrEditFilteredCurrentMonthInv } from "../Store/searchInvSlice";
import { deleteFilteredCurrentMonthInv } from "../Store/searchInvSlice";
import { addOrEditContact, deleteContact } from "../Store/contactSlice";
import { addOrEditNotification } from "../Store/notificationSlice";
import { deleteNotification } from "../Store/notificationSlice";
import { setStatusWsClient } from "../Store/websocketSlice";
////// Controls & Components
import { appURL } from "../Data/baseURL";
import base from "../Data/MetaData/base.json";
import { ContDePlataLogo_onwhite1 } from "../Data/imageURL";
import MetaDecorator from "../Apm/Other/MetaDecorator";
import useSocket from "../Fetch/useSocket";
import HeaderDrawerList from "./HeaderDrawerList";
import Navbar from "./NavBar";

const drawerWidth = 265;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("md")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  [theme.breakpoints.down("md")]: {
    width: 0,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Header({ children }) {
  const { pathname } = useLocation();
  const wsClient = useSocket();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mdLgMediaMax = useMediaQuery("(max-width:1200px)");
  const smMediaDown = useMediaQuery(theme.breakpoints.down("sm"));
  const lgMediaDown = useMediaQuery(theme.breakpoints.down("lg"));
  const lgMediaMin = useMediaQuery("(min-width:2000px)");
  const xlMediaMin = useMediaQuery("(min-width:2300px)");
  const [open, setOpen] = React.useState(false);
  const { authLogIn } = useSelector((state) => state.authLogInSlice);

  const loginPath =
    pathname === `${appURL}/login` ||
    pathname === `${appURL}/signup` ||
    pathname === `${appURL}/passreset` ||
    pathname.startsWith("/api/password/reset") ||
    pathname.startsWith("/api/activate/");

  ////// Start register font in advance with / for @react-pdf/renderer
  Font.register({
    family: "Oswald",
    src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
  });
  const loadFonts = useCallback(async () => {
    await Promise.all([
      Font.load({ fontFamily: "Roboto" }),
      Font.load({ fontFamily: "Oswald" }),
    ]);
  }, []);
  useEffect(() => {
    loadFonts();
  }, [loadFonts]);
  ////// End register font in advance with/for @react-pdf/renderer

  useEffect(() => {
    !authLogIn && !mdLgMediaMax && setOpen(false);
  }, [authLogIn, mdLgMediaMax]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  ////// Start wsClient subscriptions
  useEffect(() => {
    if (authLogIn && wsClient) {
      wsClient.transport.on("connect", () => {
        dispatch(setStatusWsClient("1"));
        wsClient.subscribe(
          "invstream",
          {},
          (item, action) => {
            if (action === "create" && item.quote === true) {
              dispatch(setAddQuotesCount());
            }

            if (action !== "delete") {
              item.quote && dispatch(addOrEditQuote(item));
              if (!item.quote) {
                dispatch(addOrEditInv(item));
                dispatch(addOrEditFilteredCurrentMonthInv(item));
              }
            }
            if (action === "delete" && item.id !== null) {
              batch(() => {
                if (item.quote) {
                  dispatch(deleteQuotesCount());
                  dispatch(deleteQuote(item.id));
                }
                if (!item.quote) {
                  dispatch(deleteInv(item.id));
                  dispatch(deleteFilteredCurrentMonthInv(item.id));
                }
              });
            }
          },
          {
            subscribeAction: "subscribe_myinv",
            unsubscribeAction: "unsubscribe_myinv",
            includeCreateEvents: true,
          }
        );
        wsClient.subscribe(
          "contactstream",
          {},
          (item, action) => {
            if (action !== "delete") {
              dispatch(addOrEditContact(item));
            }
            if (action === "delete" && item.pk !== null) {
              dispatch(deleteContact(item.pk));
            }
          },
          {
            subscribeAction: "subscribe_mycontacts",
            unsubscribeAction: "unsubscribe_mycontacts",
            includeCreateEvents: true,
          }
        );
        wsClient.subscribe(
          "notificationstream",
          {},
          (notification, action) => {
            if (action !== "delete") {
              dispatch(addOrEditNotification(notification));
            }
            if (action === "delete" && notification.pk !== null) {
              dispatch(deleteNotification(notification.pk));
            }
          },
          {
            subscribeAction: "subscribe_all",
            unsubscribeAction: "unsubscribe_all",
            includeCreateEvents: true,
          }
        );
      });
    }
  }, [dispatch, authLogIn, wsClient]);
  ////// End wsClient subscriptions

  return (
    <>
      <MetaDecorator
        description={base.pageDescriptionRo}
        title={base.pageTitle}
        imageUrl={ContDePlataLogo_onwhite1}
        imageAlt={base.pageDescriptionRo}
        rel='https://contdeplata.md/'
      />

      <Box sx={{ display: "flex" }}>
        <Navbar open={open} setOpen={setOpen} />

        {((!authLogIn && lgMediaDown) || (authLogIn && pathname !== "/")) && (
          <Drawer
            variant={lgMediaDown ? undefined : "permanent"}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              whiteSpace: "nowrap",
              boxSizing: "border-box",
              ...(open && {
                ...openedMixin(theme),
                "& .MuiDrawer-paper": openedMixin(theme),
              }),

              ...(!open && {
                ...closedMixin(theme),
                "& .MuiDrawer-paper": closedMixin(theme),
              }),
            }}
            PaperProps={{
              style: {
                backgroundColor: "#043873",
                color: "white",
              },
            }}
            anchor='left'
            open={open}
            onClose={handleDrawerClose}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose} color='inherit'>
                <ChevronLeftIcon />
              </IconButton>
            </DrawerHeader>

            <Divider />

            <HeaderDrawerList
              authLogIn={authLogIn}
              open={open}
              handleDrawerClose={handleDrawerClose}
            />
          </Drawer>
        )}

        <Box
          component='main'
          sx={{
            flexGrow: 1,
            width: "100vw",
            overflow: "auto",
          }}
          open={open}
        >
          <DrawerHeader />

          <Container
            disableGutters={pathname === "/" ? true : undefined}
            maxWidth={false}
            width='100%'
            height='100%'
            sx={{
              maxWidth: xlMediaMin ? "80%" : lgMediaMin ? "90%" : "100%",
              pt: pathname === "/" ? 0 : 2,
              pb: pathname === "/" ? 0 : 1,
              mt: loginPath && !smMediaDown ? 5 : undefined,
            }}
          >
            <Grid container>{children}</Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
}
