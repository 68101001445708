import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../Fetch/axios";
import { toast } from "react-toastify";
import i18n from "i18next";
import { invURL } from "../Data/baseURL";

export const getQuotes = createAsyncThunk("quoteSlice/getQuotes", async () => {
  const response = await axiosInstance.get(invURL, { params: { quote: true } });
  return response.data;
});

export const quoteSlice = createSlice({
  name: "quoteSlice",
  initialState: {
    quote: {},
    quotesCount: 0,
    quotes: [],
    statusQuote: null,
    changedTime: null,
  },
  reducers: {
    setQuotes: (state, action) => {
      return { ...state, quotes: [...action.payload] };
    },
    setQuote: (state, action) => {
      return { ...state, quote: { ...action.payload } };
    },
    setStatusQuote: (state, action) => {
      state.status = action.payload;
      return state;
    },
    setAddQuotesCount: (state) => {
      state.quotesCount = state.quotesCount + 1;
      return state;
    },
    deleteQuotesCount: (state) => {
      return { ...state, quotesCount: state.quotesCount - 1 };
    },
    addOrEditQuote: (state, action) => {
      const i = state.quotes.findIndex((item) => item.id === action.payload.id);
      if (i > -1) state.quotes[i] = action.payload;
      else state.quotes.unshift(action.payload);
      return state;
    },
    deleteQuote: (state, action) => {
      const items = state.quotes.filter((item) => item.id !== action.payload);
      return { ...state, quotes: [...items] };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getQuotes.pending, (state) => {
      state.statusQuote = "loading";
    });
    builder.addCase(getQuotes.fulfilled, (state, action) => {
      state.quotesCount = action.payload.length;
      state.quotes = action.payload;
      state.statusQuote = "loaded";
    });
    builder.addCase(getQuotes.rejected, (state) => {
      toast.error(() => i18n.t("toast.unable_to_connect_to_server_try_later"));
      state.statusQuote = "failed";
    });
  },
});

export const {
  setQuotes,
  setQuote,
  setStatusQuote,
  setAddQuotesCount,
  addOrEditQuote,
  deleteQuotesCount,
  deleteQuote,
} = quoteSlice.actions;

export default quoteSlice.reducer;
