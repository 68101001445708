import React from "react";
import * as Yup from "yup";
import { useDispatch, batch } from "react-redux";
import axiosInstance from "../../Fetch/axios";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
////// Mui
import { Box, Container, Grid, Typography } from "@mui/material";
////// Store
import { setPopup } from "../../Store/popupSlice";
////// Controls & Components
import ButtonLoading from "../../Controls/Buttons/ButtonLoading";
import TextFieldNormal from "../../Controls/Inputs/TextFieldNormal";
import { numbers_and_letters } from "../../Data/regex";
import { userCreateURL } from "../../Data/baseURL";
import { addOrEditUser } from "../../Store/accountGroupUserSlice";
import { initialValuesUserCreate } from "../../Apm/Tables/InitialValues/initialValuesUserCreate";

const validationSchema = Yup.object({
  email: Yup.string()
    .email(() => i18n.t("yup.invalid_email"))
    .required(() => i18n.t("yup.invalid_email")),
  password: Yup.string()
    .required(() => i18n.t("yup.password_is_required"))
    .min(8, () => i18n.t("yup.password_is_too_short"))
    .matches(numbers_and_letters, () =>
      i18n.t("yup.password_can_only_contain_latin_letters_numbers")
    ),
  re_password: Yup.string()
    .oneOf([Yup.ref("password"), null], () => i18n.t("yup.password_must_match"))
    .required(() => i18n.t("yup.password_is_required")),
});

export default function SignUpAddUser() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = (values) => {
    setLoading(true);
    axiosInstance
      .post(userCreateURL, {
        username: values.email.trim().toLowerCase(),
        password: values.password,
      })
      .then((res) => {
        setLoading(false);
        batch(() => {
          dispatch(setPopup(false));
          dispatch(addOrEditUser(res.data));
        });
        res.status && res.status === 201
          ? toast.success(() => t("toast.user_successfully_created"), {
              autoClose: 4000,
            })
          : toast.error(() => t("toast.user_not_created"), {
              autoClose: 4000,
            });
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status && error.response.status === 402) {
          toast.error(
            () => t("toast.need_to_upgrade_in_order_to_register_new_users"),
            {
              autoClose: 4000,
            }
          );
        } else if (
          error.response.status &&
          (error.response.status === 400 || error.response.status === 403) &&
          error.response.data &&
          JSON.stringify(error.response.data)
            .toString()
            .includes("user with this email already exists")
        ) {
          toast.error(() => t("toast.user_with_this_email_already_exists"), {
            autoClose: 4000,
          });
        } else {
          toast.error(() => t("toast.new_user_could_not_be_registered"), {
            autoClose: 4000,
          });
        }
      });
  };

  return (
    <>
      <Container maxWidth='md'>
        <Typography align='center' variant='h5' sx={{ mt: 10, mb: 2 }}>
          {t("users_page.register_and_add_user")}
        </Typography>
        <Formik
          initialValues={initialValuesUserCreate}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => {
            return (
              <Form>
                <Grid container item spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12}>
                    <TextFieldNormal
                      name='email'
                      label={t("general.email")}
                      id='email'
                      variant='outlined'
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldNormal
                      name='password'
                      type='password'
                      label={t("general.password_new_user")}
                      id='password'
                      variant='outlined'
                      required
                      fullWidth={false}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldNormal
                      name='re_password'
                      type='password'
                      id='re_password'
                      label={t("general.re_password_new_user")}
                      variant='outlined'
                      required
                      fullWidth={false}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Typography variant='subtitle2' color='primary'>
                      {t("yup.password_can_only_contain_latin_letters_numbers")}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ mt: 2, mb: 4 }}>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      ml: -1,
                    }}
                  ></Box>
                  <ButtonLoading
                    type='submit'
                    fullWidth
                    loading={loading}
                    variant='contained'
                    color='primary'
                    sx={{ mt: 4, mr: 0, mb: 2 }}
                  >
                    <Typography variant='button'>
                      {t("button.register_user")}
                    </Typography>
                  </ButtonLoading>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </>
  );
}
