import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import { useLocation } from "react-router";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../Fetch/axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
////// Mui
import { Card, CardContent, Typography, Box } from "@mui/material";
import { CardHeader, Divider, Grid, CardActions } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
////// Store
import { setAccount } from "../../Store/accountSlice";
import { setAuthLogIn, setStatusLogIn } from "../../Store/authLogInSlice";
import { addOrEditUser } from "../../Store/accountGroupUserSlice";
import { setPopup } from "../../Store/popupSlice";
import { setLoadingStatus } from "../../Store/loadingStatusSlice";
import { setFormSimpleTouched } from "../../Store/formTouchedSlice";
////// Controls & Components
import { appURL, usersURL } from "../../Data/baseURL";
import ButtonAction from "../../Controls/Buttons/ButtonAction";
import ButtonLoading from "../../Controls/Buttons/ButtonLoading";
import ConfirmDialog from "../../Controls/Popups/ConfirmDialog";
import { initialValuesAccount } from "../Tables/InitialValues/initialValuesAccount";
import TextFieldNormal from "../../Controls/Inputs/TextFieldNormal";

const validationSchema = Yup.object({
  first_name: Yup.string()
    .max(250, () => i18n.t("yup.max_250_characters"))
    .nullable(),
  last_name: Yup.string()
    .max(250, () => i18n.t("yup.max_250_characters"))
    .nullable(),
  phone: Yup.string()
    .max(100, () => i18n.t("yup.max_100_characters"))
    .nullable(),
  skype: Yup.string()
    .max(100, () => i18n.t("yup.max_100_characters"))
    .nullable(),
});

export const AccountProfileDetails = ({ itemForEdit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const smMediaDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    type: "",
  });
  const [formValues, setFormValues] = useState(null);
  const { account } = useSelector((state) => state.accountSlice);
  const initialAccount = React.useRef(account);

  const userOrManagerAndPathname = Boolean(
    formValues &&
      account &&
      (account.id === formValues.id || account.is_manager)
  );

  const FormValues = () => {
    const { values, touched } = useFormikContext();
    useEffect(() => {
      if (values) {
        setFormValues(values);
      }
      if (Object.keys(touched).length !== 0 && values.id === undefined) {
        dispatch(setFormSimpleTouched(true));
      }
      if (
        Object.keys(touched).length !== 0 &&
        itemForEdit !== null &&
        values.id !== ""
      ) {
        dispatch(setFormSimpleTouched(true));
      }
    }, [values, touched]);
    return null;
  };

  const handleOnClick = () => {
    edit === false ? setEdit(true) : setEdit(false);
  };

  const handleSubmit = (values) => {
    location.pathname === `${appURL}/users` && dispatch(setLoadingStatus(true));
    setLoading(true);
    axiosInstance({
      method: formValues.id ? "PATCH" : "PUT,",
      url: usersURL + values.id + "/",
      data: {
        id: values.id || null,
        first_name: values.first_name,
        last_name: values.last_name,
        phone: values.phone,
        skype: values.skype,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          location.pathname === `${appURL}/account` &&
            batch(() => {
              dispatch(setAccount(res.data));
            });
          if (location.pathname === `${appURL}/users`) {
            batch(() => {
              dispatch(addOrEditUser(res.data));
              dispatch(setPopup(false));
              dispatch(setFormSimpleTouched(false));
              dispatch(setLoadingStatus(false));
            });
          }
          setEdit(false);
          setLoading(false);
          toast.success(
            `${t("toast.profile")} ${t("toast.successfully_updated")}`
          );
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.status) {
          let responseStatus = error.response.status;
          responseStatus === 400 &&
            toast.error(() => t("toast.not_saved_try_again"), {
              autoClose: 4000,
            });
          responseStatus === 401 &&
            toast.error(() => t("toast.only_account_manager_allowed"), {
              autoClose: 4000,
            });
          responseStatus === 404 &&
            toast.error(() => t("toast.record_not_found"), {
              autoClose: 4000,
            });
        }
        setLoading(false);
        dispatch(setLoadingStatus(false));
      }, []);
  };

  const handleCancel = () => {
    dispatch(setAccount(initialAccount.current));
    setEdit(false);
    return null;
  };

  const deleteAccount = (e, values) => {
    e.preventDefault();
    const userId = location.pathname === "/apps/users" ? values.id : account.id;
    setLoading(true);
    axiosInstance({
      method: "DELETE",
      url: `/app/users/` + userId + "/",
    })
      .then((res) => {
        if (res.status === 204) {
          setLoading(false);
          dispatch(setPopup(false));
          if (account.id === values.id) {
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            axiosInstance.defaults.headers["Authorization"] = null;
            batch(() => {
              dispatch(setAccount(null));
              dispatch(setAuthLogIn(false));
              dispatch(setStatusLogIn("notAuthorized"));
            });
            navigate("/");
          }
          toast.success(() => t("toast.record_successfully_deleted"));
        } else {
          toast.error(() => t("toast.not_deleted_try_again"), {
            autoClose: 4000,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.error(() => t("toast.only_account_manager_allowed"), {
            autoClose: 4000,
          });
        } else {
          toast.error(() => t("toast.not_deleted_try_again"), {
            autoClose: 4000,
          });
        }
        setLoading(false);
      }, []);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
          location.pathname === "/apps/users"
            ? itemForEdit
            : account || initialValuesAccount
        }
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => {
          return (
            <Form autoComplete='off' noValidate>
              <Card
                elevation={location.pathname === "/apps/users" ? 0 : undefined}
              >
                {location.pathname === "/apps/account" && (
                  <>
                    <CardHeader
                      title={
                        <Grid container item>
                          <Grid item xs={6}>
                            <Typography variant='h6'>
                              {t("general.profile")}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}></Grid>
                          <Grid
                            container
                            item
                            xs={2}
                            justifyContent={"flex-end"}
                          >
                            <ButtonAction
                              color='error'
                              disabled={!edit}
                              onClick={() => handleCancel()}
                            >
                              <Typography variant='button'>
                                {t("button.cancel")}
                              </Typography>
                            </ButtonAction>
                          </Grid>
                        </Grid>
                      }
                      sx={{ backgroundColor: "#f1f1f1" }}
                    />
                    <Divider />
                  </>
                )}
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextFieldNormal
                        fullWidth
                        label={t("person.first_name")}
                        name='first_name'
                        variant='outlined'
                        disabled={!edit}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextFieldNormal
                        fullWidth
                        label={t("person.last_name")}
                        name='last_name'
                        variant='outlined'
                        disabled={!edit}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextFieldNormal
                        fullWidth
                        label={t("person.email_can_not_be_changed")}
                        name='email'
                        variant='outlined'
                        disabled
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextFieldNormal
                        fullWidth
                        label={t("general.phone")}
                        name='phone'
                        variant='outlined'
                        disabled={!edit}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextFieldNormal
                        fullWidth
                        label={t("general.skype")}
                        name='skype'
                        type='text'
                        variant='outlined'
                        disabled={!edit}
                      />
                    </Grid>
                  </Grid>
                </CardContent>

                <Divider />

                <CardActions sx={{ mt: 3, mb: 3, ml: 1, mr: 1 }}>
                  {userOrManagerAndPathname && (
                    <Grid container item>
                      <Grid item sm={8} xs={12}>
                        <ButtonLoading
                          color='primary'
                          variant='outlined'
                          size='small'
                          fullWidth={smMediaDown ? true : undefined}
                          disabled={edit}
                          onClick={() => handleOnClick()}
                        >
                          <Typography variant='button'>
                            {t("button.edit_profile")}
                          </Typography>
                        </ButtonLoading>
                      </Grid>

                      <Grid item sm={4} xs={12}>
                        <Box
                          display='flex'
                          justifyContent={
                            !location.pathname === "/apps/users"
                              ? "flex-start"
                              : "flex-end"
                          }
                          alignItems='flex-end'
                          m={0}
                        >
                          <ButtonLoading
                            type='submit'
                            color='primary'
                            variant='outlined'
                            loading={loading}
                            size='small'
                            fullWidth={smMediaDown ? true : undefined}
                            disabled={!edit}
                            sx={{ mr: 0 }}
                          >
                            <Typography variant='button'>
                              {location.pathname === "/apps/users"
                                ? t("button.save_and_close")
                                : t("button.save")}
                            </Typography>
                          </ButtonLoading>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </CardActions>
              </Card>

              {userOrManagerAndPathname && (
                <Card
                  elevation={
                    location.pathname === "/apps/users" ? 0 : undefined
                  }
                  sx={{ mt: 4 }}
                >
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant='h6' color='error'>
                          {t("account_page.delete_account_text_1")}
                        </Typography>
                        <Typography variant='h6' color='error'>
                          {t("account_page.delete_account_text_2")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />

                  <CardActions sx={{ mt: 3, mb: 3, ml: 1, mr: 1 }}>
                    <Grid item xs={12}>
                      <ButtonLoading
                        variant='outlined'
                        size='small'
                        color='error'
                        fullWidth={smMediaDown ? true : undefined}
                        onClick={(e) => {
                          setConfirmDialog({
                            isOpen: true,
                            title: t("popup.are_you_sure_to_delete_account"),
                            subTitle: (
                              <>
                                <div>
                                  {t("popup.you_can_not_undo_this_operation")}
                                </div>
                                {account && account.is_manager && (
                                  <div>
                                    {t(
                                      "popup.additional_users_and_related_entries_will_be_deleted"
                                    )}
                                  </div>
                                )}
                              </>
                            ),
                            onConfirm: () => [
                              deleteAccount(e, values),
                              setConfirmDialog({
                                ...confirmDialog,
                                isOpen: false,
                              }),
                            ],
                          });
                        }}
                      >
                        <Typography variant='button'>
                          {t("button.delete_account")}
                        </Typography>
                      </ButtonLoading>
                    </Grid>
                  </CardActions>
                </Card>
              )}

              <FormValues />
            </Form>
          );
        }}
      </Formik>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      ></ConfirmDialog>
    </>
  );
};
