import i18n from "i18next";

export function columnsNotifications() {
  return [
    {
      id: "title",
      label: i18n.t("column.title"),
      minWidth: 80,
      maxWidth: 190,
      align: "left",
    },
    {
      id: "message",
      label: i18n.t("column.message"),
      minWidth: 150,
      maxWidth: 850,
      align: "left",
    },
  ];
}
