import React from "react";
import { useField, useFormikContext } from "formik";
////// Mui
import { styled } from "@mui/material/styles";
import { FormHelperText, TextField, InputLabel } from "@mui/material";

const TextFieldStyped = styled(TextField)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#f5f5f5" : "#f5f5f5",
    fontSize: 16,
    fontWeight: 500,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    // },
  },
}));

const InputArrayNumber = ({ name, label, required, ...otherProps }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(String(name));

  const handleChange = (e) => {
    const { value } = e.target;
    const fieldValue = Number(value)
      ? Math.round(value * 100 + Number.EPSILON) / 100
      : "";

    setFieldValue(String(name), fieldValue);
  };

  // function ParseFloat(str, val) {
  //   str = str.toString();
  //   str = str.slice(0, str.indexOf(".") + val + 1);
  //   return Number(str);
  // }

  function toFixedIfNecessary(value, dp) {
    return +parseFloat(value).toFixed(dp);
  }
  const inputLabelProps = {
    label: label,
    shrink: true,
    sx: { marginBottom: -1, fontSize: 18, fontWeight: "bold" },
    required: required,
  };

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
    size: "small",
    onChange: handleChange,
    value:
      otherProps.type === "number"
        ? field.value && Number(field.value)
          ? toFixedIfNecessary(field.value, 2)
          : ""
        : field.value || "",
  };

  const configHelperText = {
    sx: { color: "red", fontSize: 14, fullWidth: true },
  };

  let helperText = "";

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    helperText = meta.error;
  }

  return (
    <React.Fragment>
      <InputLabel {...inputLabelProps}>{inputLabelProps.label}</InputLabel>
      <TextFieldStyped {...configTextfield} />
      <FormHelperText {...configHelperText}>{helperText}</FormHelperText>
    </React.Fragment>
  );
};

export default InputArrayNumber;
