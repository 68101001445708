import i18n from "i18next";
////// Store
import store from "../Store/store";

export const pages = [12, 20, 35, 50, 100];

////// 0-2 (users included) 3-5 (quotes included) 6-8 (invoices included) 9-11 (clients included)
////// 12-14 (invoice items included) 15-17 (monthly fee without discount)
export const maxNumberAllowedPerSubscription = [
  2, 5, 10, 20, 100, 300, 20, 100, 300, 20, 100, 300, 20, 100, 300, 0, 7, 14,
];

export function modesInDiffLang() {
  return [
    "ROAD",
    "AIR",
    "MARITIME",
    "RAIL",
    "MULTIMODAL",
    "RUTIER",
    "AERIAN",
    "MARITIM",
    "FEROVIAR",
    "MULTIMODAL",
    "АВТО",
    "АВИА",
    "МОРСКОЙ",
    "ЖЕЛЕЗНОДОP.",
    "МУЛЬТИМОД.",
  ];
}

export function statusesInDiffLang() {
  return [
    "Draft",
    "Created",
    "Sent",
    "Closed",
    "Proiect",
    "Creat",
    "Expediat",
    "Inchis",
    "Черновик",
    "Создан",
    "Отправлен",
    "Закрыт",
    "Draft",
    "Unpaid",
    "Partly paid",
    "Paid",
    "Overdue",
    "Proiect",
    "Neplatit",
    "Platit partial",
    "Platit",
    "Depasit",
    "Черновик",
    "Неоплачен",
    "Частично оплачен",
    "Оплачен",
    "Просрочен",
  ];
}

export const langs = [
  {
    id: 1,
    code: "ro",
    lang: "RO",
  },
  {
    id: 2,
    code: "ru",
    lang: "RU",
  },
  {
    id: 3,
    code: "en",
    lang: "EN",
  },
];

export function tiers() {
  return [
    {
      title: i18n.t("subscription.free"),
      price: maxNumberAllowedPerSubscription[15],
      description: [
        i18n.t("subscription.users_included"),
        i18n.t("subscription.quotes_included"),
        i18n.t("subscription.invoices_included"),
        i18n.t("subscription.clients_included"),
        i18n.t("subscription.invoice_items_included"),
      ],
      buttonText: i18n.t("subscription.sign_up_for_free"),
      buttonVariant: "outlined",
      backgroundColor: "#48cae4",
      buttonbackgroundColor: "#edfafd",
    },
    {
      title: i18n.t("subscription.pro"),
      price: maxNumberAllowedPerSubscription[16],
      description: [
        i18n.t("subscription.users_included"),
        i18n.t("subscription.quotes_included"),
        i18n.t("subscription.invoices_included"),
        i18n.t("subscription.clients_included"),
        i18n.t("subscription.invoice_items_included"),
      ],
      buttonText: i18n.t("subscription.generate_invoice"),
      buttonVariant: "contained",
      backgroundColor: "#1976d2",
      buttonbackgroundColor: "#64b5f6",
    },
    {
      title: i18n.t("subscription.premium"),
      price: maxNumberAllowedPerSubscription[17],
      description: [
        i18n.t("subscription.users_included"),
        i18n.t("subscription.quotes_included"),
        i18n.t("subscription.invoices_included"),
        i18n.t("subscription.clients_included"),
        i18n.t("subscription.invoice_items_included"),
      ],
      buttonText: i18n.t("subscription.generate_invoice"),
      buttonVariant: "contained",
      backgroundColor: "#F36815",
      buttonbackgroundColor: "#f4a261",
    },
  ];
}

export const supplier = {
  addresslegal: "str. Sf. Gheorghe, 36",
  citylegal: "mun. Chisinau, or. Codru",
  companyname: "PRO-LOGISTIC SRL",
  countrycodelegal: {
    id: 9,
    label: "Moldova",
    value: "MD",
    value_iso3: "MDA",
    value_numeric: 333,
  },
  countrycodepost: {
    id: 9,
    label: "Moldova",
    value: "MD",
    value_iso3: "MDA",
    value_numeric: 333,
  },
  email: "support@cargosmd.com",
  fiscalcode: "1004600068072",
  id: 999567,
  logo: "90e2fbf4-d1cf-4edf-8294-15ed232de8be",
  phone: "00373 22 999470",
  skype: null,
  vatcode: "0605280",
  zipcodelegal: "2019",
};

export const supplierBankAccounts = [
  {
    bank_address: "",
    bank_code: "AGRNMD2X",
    bank_name: "BC MAIB SA",
    currencycode: {
      id: 98124,
      currencycode: "EUR",
      currency_name: "EURO",
      currency_symbol: "E",
    },
    iban_number: "MD20AG000000022511376975",
    id: 98124,
  },
];

export function invoiceValues() {
  let d = new Date();

  const state = store.getState();
  const payer = state.companySlice.company;

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  let vn = (d.getTime() / 1000).toFixed(0) - 1659621429;

  return [
    {
      bill_to: {
        id: 88777,
        companyname: payer && payer.companyname && payer.companyname,
        fiscalcode: payer && payer.fiscalcode && payer.fiscalcode,
        vatcode: payer && payer.vatcode && payer.vatcode,
        phone: payer && payer.phone && payer.phone,
        email1: payer && payer.email && payer.email,
        countrycodelegal:
          payer && payer.countrycodelegal && payer.countrycodelegal.value
            ? { ...payer.countrycodelegal }
            : "",
        zipcodelegal: payer && payer.zipcodelegal && payer.zipcodelegal,
        citylegal: payer && payer.citylegal && payer.citylegal,
        addresslegal: payer && payer.addresslegal && payer.addresslegal,
        contact_bank_accounts: [...state.bankAccountSlice.bankAccounts],
      },
      contract_terms: {
        term_description: ` Plata se va efectua in MDL conform cursului stabilit de Banca Nationala a Moldovei la ziua platii.
                          Оплата будет производиться в молдавских леях по курсу, установленному Национальным банком Молдовы на день оплаты.            
      `,
      },
      currency: {
        id: 9,
        currencycode: "EUR",
        currency_name: "EURO",
        currency_symbol: "E",
      },
      date_due: addDays(d, 3),
      date_inv: d,
      head: null,
      id: 200,
      iteminv_invs: [
        {
          id: 99678,
          description: "",
          quantity: 1,
          amount: 70.0,
          vat: null,
          item_for_item_inv: {
            id: 99702,
            description: "Abonament PRO termen 12 luni",
            vat: 20,
          },
        },
      ],
      note_terms: {
        note_description: ` Factura este valabila pana la data de ${addDays(
          d,
          3
        ).toLocaleDateString("en-GB")} inclusiv
                   Счет действителен до ${addDays(d, 3).toLocaleDateString(
                     "en-GB"
                   )} включительно
                   `,
      },
      vn: vn,
      comment_invs: [],
    },

    {
      bill_to: {
        id: 88777,
        companyname: payer && payer.companyname && payer.companyname,
        fiscalcode: payer && payer.fiscalcode && payer.fiscalcode,
        vatcode: payer && payer.vatcode && payer.vatcode,
        phone: payer && payer.phone && payer.phone,
        email1: payer && payer.email && payer.email,
        countrycodelegal:
          payer && payer.countrycodelegal && payer.countrycodelegal.value
            ? { ...payer.countrycodelegal }
            : "",
        zipcodelegal: payer && payer.zipcodelegal && payer.zipcodelegal,
        citylegal: payer && payer.citylegal && payer.citylegal,
        addresslegal: payer && payer.addresslegal && payer.addresslegal,
        contact_bank_accounts: [...state.bankAccountSlice.bankAccounts],
      },
      contract_terms: {
        term_description: ` Plata se va efectua in MDL conform cursului stabilit de Banca Nationala a Moldovei la ziua platii.
                          Оплата будет производиться в молдавских леях по курсу, установленному Национальным банком Молдовы на день оплаты.      
      
      `,
      },
      currency: {
        id: 9,
        currencycode: "EUR",
        currency_name: "EURO",
        currency_symbol: "E",
      },
      date_due: addDays(d, 3),
      date_inv: d,
      head: null,
      id: 200,
      iteminv_invs: [
        {
          id: 99679,
          description: "",
          quantity: 1,
          amount: 100.0,
          vat: null,
          item_for_item_inv: {
            id: 99702,
            description: "Abonament PREMIUM termen 12 luni",
            vat: 20,
          },
        },
      ],
      note_terms: {
        note_description: ` Factura este valabila pana la data de ${addDays(
          d,
          3
        ).toLocaleDateString("en-GB")} inclusiv
                   Счет действителен до ${addDays(d, 3).toLocaleDateString(
                     "en-GB"
                   )} включительно
                   `,
      },
      vn: vn,
      comment_invs: [],
    },
  ];
}
