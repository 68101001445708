import React from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
////// Mui
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { DialogActions, IconButton, Paper } from "@mui/material";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
////// Controls & Components
import ButtonBasic from "../Buttons/ButtonBasic";

function PaperComponent(props) {
  return (
    <Draggable
      handle='#draggable-touched-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const ConfirmTouched = (props) => {
  const { confirmTouched } = props;
  const { t } = useTranslation();
  const xsMedia = useMediaQuery("(max-width:900px)");

  return (
    <React.Fragment>
      <Dialog
        open={confirmTouched.isOpen}
        sx={{
          "& .MuiDialog-container": {
            justifyContent: "flex-center",
            alignItems: "flex-start",
          },
          top: 35,
        }}
        PaperComponent={PaperComponent}
        BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.1)" } }}
        aria-labelledby='draggable-touched-title'
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          <IconButton
            disableRipple
            sx={{
              color: "#ba000d",
              "&:hover": {
                backgroundColor: "inherit",
                cursor: "default",
              },
              "& .MuiSvgIcon-root": {
                fontSize: "4rem",
              },
            }}
          >
            <QuestionMarkIcon color='primary' />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ textAlign: "center" }}>
          <Typography variant='h6'>{confirmTouched.title}</Typography>
          <Typography variant='subtitle2'>{confirmTouched.subTitle}</Typography>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center", m: 2 }}>
          <Stack direction={xsMedia ? "column" : "row"}>
            <ButtonBasic
              color='success'
              variant='outlined'
              fullWidth={xsMedia ? true : false}
              onClick={confirmTouched.onBackToPage}
            >
              {t("button.back_to_page")}
            </ButtonBasic>

            <ButtonBasic
              color='secondary'
              variant='outlined'
              sx={{ ml: xsMedia ? 0 : 1.5 }}
              fullWidth={xsMedia ? true : false}
              onClick={confirmTouched.onConfirm}
            >
              {t("button.discard")}
            </ButtonBasic>
          </Stack>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ConfirmTouched;
