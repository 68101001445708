import React, { useEffect } from "react";
import axiosInstance from "./axios";
import { useSelector, useDispatch } from "react-redux";
////// Stores
import store from "../Store/store";
////// Controls & Components
import { baseWSURL } from "../Data/baseURL";

const dcrf = require("dcrf-client");
const state = store.getState();
const authLogIn = state.authLogInSlice.authLogIn;

////// Obtain a fresh token each time the below 2 functions are called
function now() {
  return Math.ceil(Date.now() / 1000);
}
function getAccessToken() {
  return localStorage.getItem("access_token") || null;
}
function getRefreshToken() {
  return localStorage.getItem("refresh_token") || null;
}
const refreshTokenParts =
  getRefreshToken() && JSON.parse(atob(getRefreshToken().split(".")[1]));

function getJWTExpireDate(jwtToken) {
  if (jwtToken) {
    try {
      const [, payload] = jwtToken.split(".");
      const { exp: expires } = JSON.parse(atob(payload));
      if (typeof expires === "number") {
        return new Date(expires);
      }
    } catch {}
  }
  return null;
}

async function getValidAccessToken() {
  if (
    getAccessToken() &&
    Math.ceil(getJWTExpireDate(getAccessToken())) - 15 > now()
  ) {
    return getAccessToken();
  } else if (refreshTokenParts && refreshTokenParts.exp - 20 > now()) {
    await axiosInstance
      .post("/api/token/refresh/", {
        refresh: getRefreshToken(),
      })
      .then((tokenRefreshResponse) => {
        localStorage.setItem("access_token", tokenRefreshResponse.data.access);
        tokenRefreshResponse.data.refresh &&
          localStorage.setItem(
            "refresh_token",
            tokenRefreshResponse.data.refresh
          );
        axiosInstance.defaults.headers["Authorization"] =
          "JWT " + tokenRefreshResponse.data.access;

        return Promise.resolve();
      })
      .catch(() => {});

    return localStorage.getItem("access_token");
  }
}

const wsUrlProvider = async () => {
  const token = await getValidAccessToken();
  return `${baseWSURL}/ws/?ticket_uuid=` + token;
};

let wsClient = dcrf.default.connect(wsUrlProvider, {
  websocket: {
    maxRetries: 10,
    startClosed: !authLogIn ? true : false,
    debug: false,
  },
});

export const WebSocketProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { statusWsClient } = useSelector((state) => state.websocketSlice);
  const { authLogIn } = useSelector((state) => state.authLogInSlice);

  const wsRef = React.useRef(wsClient);

  useEffect(() => {
    if (
      statusWsClient === "1" &&
      authLogIn &&
      wsRef.current &&
      !wsRef.current.transport.isConnected()
    ) {
      // wsClient = dcrf.default.connect(wsUrlProvider, {
      //   websocket: { maxReconnectionDelay: 1000, maxRetries: 3, debug: false, },
      // });

      wsRef.current.transport.socket &&
        wsRef.current.transport.socket.reconnect();
    } else if (statusWsClient === "0") {
      wsRef.current = dcrf.default.connect(wsUrlProvider, {
        websocket: { maxRetries: 10, debug: false },
      });
    }

    return () => wsRef.current;
  }, [dispatch, statusWsClient, authLogIn]);

  return (
    <WebSocketContext.Provider value={wsRef.current}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const WebSocketContext = React.createContext(wsClient);
