import React from "react";
import InvPdfTableHeader from "./InvPdfTableHeader";
import InvPdfTableRow from "./InvPdfTableRow";
import InvPdfTableTotal from "./InvPdfTableTotal";

const InvPdfTable = (props) => {
  const { values, invoicePdfLang } = props;

  return (
    <React.Fragment>
      <InvPdfTableHeader invoicePdfLang={invoicePdfLang} />
      <InvPdfTableRow items={values.iteminv_invs} />
      <InvPdfTableTotal values={values} invoicePdfLang={invoicePdfLang} />
    </React.Fragment>
  );
};

export default InvPdfTable;
