import React, { useState, useEffect } from "react";
import { forwardRef, useImperativeHandle } from "react";
import axiosInstance from "../../Fetch/axios";
import { Formik, Form, FastField } from "formik";
import { Field, FieldArray, useFormikContext } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useSelector, useDispatch, batch } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
////// Mui
import { alpha, styled } from "@mui/material/styles";
import { FormGroup, FormControlLabel } from "@mui/material";
import { Box, Container, Checkbox, Popover } from "@mui/material";
import { useMediaQuery, useTheme, Badge, IconButton } from "@mui/material";
import { Grid, Stack, Typography, Divider } from "@mui/material";
import { Menu, MenuItem } from "@mui/material";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SaveIcon from "@mui/icons-material/Save";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import RemoveIcon from "@mui/icons-material/Remove";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
////// Store
import { setInv, addOrEditInv } from "../../Store/invSlice";
import { setStatus, deleteInv } from "../../Store/invSlice";
import { setItemForEdit } from "../../Store/itemForEditSlice";
import { setPopup } from "../../Store/popupSlice";
import { setFormTouchedInv } from "../../Store/formTouchedSlice";
import { setLoadingStatus } from "../../Store/loadingStatusSlice";
import { setPopupInv, setPopupInv2 } from "../../Store/popupSlice";
////// Controls & Components
import Autocomplete from "../../Controls/FormsUI/Autocomplete";
import AutocompleteItemForItem from "../../Controls/FormsUI/AutocompleteItemForItem";
import ButtonAction from "../../Controls/Buttons/ButtonAction";
import ButtonBasic from "../../Controls/Buttons/ButtonBasic";
import ButtonDelete from "../../Controls/Buttons/ButtonDelete";
import Contact from "../Contact/Contact";
import ContractTerm from "../Term/ContractTerm";
import EmailBasic from "../Email/EmailBasic";
import { initialValuesContact } from "../Tables/InitialValues/initialValuesContact";
import { initialValuesContractTerms } from "../Tables/InitialValues/initialValuesContractTerms";
import { initialValuesInv } from "../Tables/InitialValues/initialValuesInv";
import { initialValuesItemInv } from "../Tables/InitialValues/initialValuesItemInv";
import { initialValuesPerson } from "../Tables/InitialValues/initialValuesPerson";
import { initialValuesTermOfPayment } from "../Tables/InitialValues/initialValuesTermOfPayment";
import { invURL } from "../../Data/baseURL";
import InputArray from "../../Controls/FormsUI/InputArray";
import InputArrayNumber from "../../Controls/FormsUI/InputArrayNumber";
import ItemInv from "../ItemInv/ItemInv";
import InvPdf from "./InvPdf/InvPdf";
import LoadDetails from "../Load/LoadDetails";
import { maxNumberAllowedPerSubscription } from "../../Data/generalData";
import DesktopDatePickerBasic from "../../Controls/Inputs/DesktopDatePickerBasic";
import Popup from "../../Controls/Popups/Popup";
import SelectBasic from "../../Controls/FormsUI/SelectBasic";
import TermOfPayment from "../Term/TermOfPayment";
import TextFieldNormal from "../../Controls/Inputs/TextFieldNormal";
import TextFieldRegular from "../../Controls/Inputs/TextFieldRegular";
import TooltipBasic from "../../Controls/Inputs/TooltipBasic";
import { useSocket } from "../../Fetch/useSocket";

const MenuStyled = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(0),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const TypographyPopover = styled(Typography)(({ theme }) => ({
  padding: 16,
  color: "rgba(0, 0, 0, 0.87)",
  maxWidth: 350,
  fontSize: theme.typography.pxToRem(20),
}));

const validationSchema = Yup.object().shape({
  bill_to: Yup.object()
    .shape()
    .typeError(() => i18n.t("yup.required"))
    .required(() => i18n.t("yup.required")),
  vn: Yup.number()
    .lessThan(99999999, () => i18n.t("yup.invoice_number_max_length"))
    .positive(() => i18n.t("yup.invoice_number_should_be_positive"))
    .integer(() => i18n.t("yup.invoice_number_should_be_integer"))
    .typeError(() => i18n.t("yup.invoice_number_should_be_number"))
    .nullable(),
  date_inv: Yup.date().required(() => i18n.t("yup.required")),

  currency: Yup.object()
    .shape()
    .typeError(() => i18n.t("yup.required"))
    .required(() => i18n.t("yup.required")),
  load_detail: Yup.string()
    .max(300, () => i18n.t("yup.max_300_characters"))
    .nullable(),
  load_address: Yup.string()
    .max(300, () => i18n.t("yup.max_300_characters"))
    .nullable(),
  unload_address: Yup.string()
    .max(300, () => i18n.t("yup.max_300_characters"))
    .nullable(),

  iteminv_invs: Yup.array().of(
    Yup.object().shape({
      item_for_item_inv: Yup.object()
        .shape()
        .typeError(() => i18n.t("yup.required"))
        .required(() => i18n.t("yup.required")),
      quantity: Yup.number()
        .lessThan(10000, () => i18n.t("yup.max_4_digits"))
        .typeError(() => i18n.t("yup.invoice_number_should_be_number"))
        .nullable(),
      amount: Yup.number()
        .lessThan(100000000, () => i18n.t("yup.max_8_digits"))
        .typeError(() => i18n.t("yup.invoice_number_should_be_number"))
        .nullable(),
    })
  ),
  comment_invs: Yup.array().of(
    Yup.object().shape({ comment: Yup.string().max(400) })
  ),
});

const Inv = forwardRef((_, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const wsClient = useSocket();
  const theme = useTheme();
  const smallMedia = useMediaQuery("(max-width:1075px)");
  const smMediaDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdMediaDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgMediaDown = useMediaQuery(theme.breakpoints.down("lg"));
  const [checked, setChecked] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [disabledAddButtonClient, setDisabledAddButtonClient] = useState(false);
  const [disabledAddButtonItemInv, setDisabledAddButtonItemInv] =
    useState(false);
  const { subscription_type } = useSelector((state) => state.accountSlice);
  const { account } = useSelector((state) => state.accountSlice);
  const { company } = useSelector((state) => state.companySlice);
  const { logo, stamp } = useSelector((state) => state.avatarSlice);
  const { inv } = useSelector((state) => state.invSlice);
  const { contacts } = useSelector((state) => state.contactSlice);
  const { bankAccounts } = useSelector((state) => state.bankAccountSlice);
  const { itemsForItemInv } = useSelector((state) => state.itemForItemInvSlice);
  const { itemForEdit } = useSelector((state) => state.itemForEditSlice);
  const [invoicePdfLang, setInvoicePdfLang] = useState("");

  const legalAddress = String(
    formValues && formValues.bill_to
      ? (formValues.bill_to.countrycodelegal
          ? formValues.bill_to.countrycodelegal.value
          : "") +
          (formValues.bill_to.zipcodelegal
            ? " - " + formValues.bill_to.zipcodelegal
            : "") +
          (formValues.bill_to.citylegal
            ? ", " + formValues.bill_to.citylegal
            : "") +
          (formValues.bill_to.addresslegal
            ? ", " + formValues.bill_to.addresslegal
            : "")
      : ""
  );

  const freeAppMssg =
    subscription_type === "active_free"
      ? t("note.invoice_created_with_online_app_cargos_md")
      : "";

  let totalInvoice = 0;

  useImperativeHandle(ref, () => ({
    handleSubmit: () => handleSubmit(),
  }));

  ////// Include load details
  useEffect(() => {
    if (
      (inv.load_detail && inv.load_detail.length > 0) ||
      (inv.load_address && inv.load_address.length > 0) ||
      (inv.unload_address && inv.unload_address.length > 0)
    ) {
      setChecked(true);
    }
  }, [inv]);

  ////// Restrictions due to subscription plan
  useEffect(() => {
    if (
      (subscription_type === "active_free" &&
        contacts.length >= maxNumberAllowedPerSubscription[9]) ||
      (subscription_type === "active_pro" &&
        contacts.length >= maxNumberAllowedPerSubscription[10]) ||
      (subscription_type === "active_premium" &&
        contacts.length >= maxNumberAllowedPerSubscription[11])
    ) {
      setDisabledAddButtonClient(true);
    } else {
      setDisabledAddButtonClient(false);
    }

    if (
      (subscription_type === "active_free" &&
        itemsForItemInv.length >= maxNumberAllowedPerSubscription[12]) ||
      (subscription_type === "active_pro" &&
        itemsForItemInv.length >= maxNumberAllowedPerSubscription[13]) ||
      (subscription_type === "active_premium" &&
        itemsForItemInv.length >= maxNumberAllowedPerSubscription[14])
    ) {
      setDisabledAddButtonItemInv(true);
    } else {
      setDisabledAddButtonItemInv(false);
    }
  }, [itemsForItemInv.length, contacts.length, subscription_type]);

  ////// Start Popover
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickPopover2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
    setAnchorElHistory(null);
  };
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const id = open ? "popover" : undefined;
  ////// End Popover

  ////// Start selectmenu email & print
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorElEmail, setAnchorElEmail] = React.useState(null);
  const openSelectButton = Boolean(anchorEl3);
  const openSelectButtonEmail = Boolean(anchorElEmail);
  const handleClick = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClickEmail = (event) => {
    setAnchorElEmail(event.currentTarget);
  };
  const handleCloseSelectMenu = () => {
    setAnchorEl3(null);
  };
  const handleCloseEmail = () => {
    setAnchorElEmail(null);
  };
  ////// End selectmenu email & print

  const ITEM_HEIGHT = 48;
  const [anchorElHistory, setAnchorElHistory] = useState(null);
  const openHistory = Boolean(anchorElHistory);
  const handleClickHistory = (event) => {
    setAnchorElHistory(event.currentTarget);
  };

  const FormValues = () => {
    const { values, touched } = useFormikContext();
    useEffect(() => {
      if (values) {
        setFormValues(values);
      }
      if (Object.keys(touched).length !== 0) {
        dispatch(setFormTouchedInv(true));
      }
    }, [values, touched]);
  };

  const addOrEditAutoField = (value) => {
    let updatedIteminv_invs = null;
    if (itemForEdit.fieldName === "iteminv_invs") {
      let newValue = null;
      let newArray = [...formValues.iteminv_invs];

      newValue = { ...formValues.iteminv_invs[itemForEdit.index] };
      newValue.item_for_item_inv = value;

      updatedIteminv_invs = newArray.map((iteminv, i) => {
        let newItem = { ...iteminv };
        if (!iteminv.item_for_item_inv) {
          newItem = { ...newValue };
        } else if (i === itemForEdit.index) {
          newItem = { ...iteminv, item_for_item_inv: { ...value } };
        }

        return newItem;
      });
    }

    batch(() => {
      dispatch(setPopup(false));
      dispatch(
        setInv({
          ...formValues,
          [itemForEdit.fieldName]: updatedIteminv_invs || value,
        })
      );
      setTimeout(() => {
        dispatch(setItemForEdit(null));
      }, 500);
    });
  };

  const deleteAutoField = (id) => {
    let updatedIteminv_invs = null;
    if (itemForEdit.fieldName === "iteminv_invs") {
      updatedIteminv_invs = formValues.iteminv_invs.filter((item) => {
        return item.item_for_item_inv.id !== id;
      });
    }

    batch(() => {
      dispatch(
        setInv({
          ...formValues,
          [itemForEdit.fieldName]: updatedIteminv_invs || "",
        })
      );
      dispatch(setItemForEdit(null));
    });
  };

  const openInPopupAutoField = (item, fieldName, index = null) => {
    let fields = {};
    if (fieldName === "bill_to" && !item) {
      fields = { ...initialValuesContact };
    } else if (fieldName === "person" && !item) {
      fields = { ...initialValuesPerson };
    } else if (fieldName === "note_terms" && !item) {
      fields = { ...initialValuesTermOfPayment };
    } else if (fieldName === "contract_terms" && !item) {
      fields = { ...initialValuesContractTerms };
    } else if (fieldName === "iteminv_invs" && !item) {
      fields = { ...initialValuesItemInv };
    } else {
      fields = { ...item };
    }

    batch(() => {
      dispatch(setPopup(true));
      dispatch(
        setItemForEdit({
          ...fields,
          fieldName: fieldName,
          index: index,
        })
      );
    });
  };

  let formDataForSubmit = () => {
    return {
      id: formValues.id,
      vn: formValues.vn,
      date_inv: formValues.date_inv,
      date_due: formValues.date_due,
      bill_to: formValues.bill_to || null,
      currency: formValues.currency,
      load_detail: checked ? formValues.load_detail : "",
      load_address: checked ? formValues.load_address : "",
      unload_address: checked ? formValues.unload_address : "",
      date_load: checked ? formValues.date_load : "",
      date_unload: checked ? formValues.date_unload : "",
      load: "",
      iteminv_invs: formValues.iteminv_invs,
      comment_invs: formValues.comment_invs,
      note_terms: formValues.note_terms,
      contract_terms: formValues.contract_terms,
      person: formValues.person,
      status: formValues.status,
      quote: false,
    };
  };

  const handleSubmit = () => {
    dispatch(setLoadingStatus(true));
    axiosInstance({
      method: formValues.id ? "PUT" : "POST",
      url: formValues.id ? invURL + formValues.id + "/" : invURL,
      data: formDataForSubmit(),
    })
      .then((resp) => {
        setFormValues(null);
        setChecked(false);
        batch(() => {
          dispatch(setStatus("loaded"));
          dispatch(setInv({ vn: "" }));
          dispatch(addOrEditInv(resp.data));
          dispatch(setPopupInv(false));
          dispatch(setPopupInv2(false));
          dispatch(setLoadingStatus(false));
          dispatch(setFormTouchedInv(false));
        });
        toast.success(
          `${t("toast.invoice")} # ${resp.data.vn && resp.data.vn} ${
            formValues.id
              ? t("toast.successfully_updated")
              : t("toast.successfully_created")
          }`,
          {}
        );
      })
      .catch((error) => {
        dispatch(setLoadingStatus(false));
        if (error && error.response && error.response.status === 402) {
          toast.error(
            () => t("toast.need_to_upgrade_in_order_to_register_new_invoices"),
            {
              autoClose: 4000,
            }
          );
        } else if (
          error.response &&
          error.response.data &&
          JSON.stringify(error.response.data).toString().includes("not unique")
        ) {
          toast.error(() => t("toast.invoice_with_this_number_exist"));
        } else {
          toast.error(() => t("toast.not_saved_try_again"));
        }
      });
  };

  const handleSave = (e) => {
    e.preventDefault();
    setShowPdf(false); // related to PDF generate
    batch(() => {
      dispatch(setLoadingStatus(true));
      dispatch(setInv(formValues));
    });
    axiosInstance({
      method: formValues.id ? "PUT" : "POST",
      url: formValues.id ? invURL + formValues.id + "/" : invURL,
      data: formDataForSubmit(),
    })
      .then((resp) => {
        batch(() => {
          dispatch(addOrEditInv(resp.data));
          dispatch(setInv(resp.data));
          dispatch(setStatus("loaded"));
          dispatch(setLoadingStatus(false));
          dispatch(setFormTouchedInv(false));
        });
        toast.success(
          `${t("toast.invoice")} # ${resp.data.vn && resp.data.vn} ${
            formValues.id
              ? t("toast.successfully_updated")
              : t("toast.successfully_created")
          }`
        );
      })
      .catch((error) => {
        dispatch(setLoadingStatus(false));
        if (error && error.response && error.response.status === 402) {
          toast.error(
            () => t("toast.need_to_upgrade_in_order_to_register_new_invoices"),
            {
              autoClose: 4000,
            }
          );
        } else if (
          error.response &&
          error.response.data &&
          JSON.stringify(error.response.data).toString().includes("not unique")
        ) {
          toast.error(() => t("toast.invoice_with_this_number_exist"));
        } else {
          toast.error(() => t("toast.not_saved_try_again"));
        }
      });
  };

  const handleDelete = (_, id) => {
    if (id) {
      wsClient
        .delete("invstream", id)
        .then(() => {
          batch(() => {
            dispatch(setStatus("loaded"));
            dispatch(deleteInv(id));
            dispatch(setLoadingStatus(false));
            dispatch(setPopupInv(false));
            dispatch(setFormTouchedInv(false));
          });
          toast.success(
            `${t("toast.invoice")} # ${inv.vn ? inv.vn : ""} ${t(
              "toast.successfully_deleted"
            )}`
          );
        })
        .catch(() => {
          dispatch(setLoadingStatus(false));
          toast.error(() => t("toast.invoice_could_not_be_deleted"));
        });
    } else {
      toast.error(() => t("toast.invoice_could_not_be_found"));
    }
  };

  const handleSwitchChange = () => {
    setChecked(!checked);
  };

  function generatePdfDocument(lang) {
    setShowPdf(true);
    setInvoicePdfLang(lang);
    handleClose();
    return null;
  }

  function emailPdfDocument(values) {
    batch(() => {
      dispatch(setItemForEdit({ ...values, fieldName: "email", totalInvoice }));
      dispatch(setPopup(true));
      dispatch(setInv(formValues));
    });
    setInvoicePdfLang(arguments[3]);
    handleCloseEmail();
    return null;
  }

  return (
    <>
      <Container
        sx={{ width: "100%" }}
        maxWidth={false}
        disableGutters={lgMediaDown ? true : false}
      >
        <Formik
          enableReinitialize
          initialValues={Object.keys(inv).length !== 0 ? inv : initialValuesInv}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}
        >
          {({
            values,
            arrayHelpers,
            errors,
            validateForm,
            setTouched,
            setFieldValue,
          }) => {
            totalInvoice =
              values.iteminv_invs &&
              values.iteminv_invs
                .map((item) => {
                  let a = item.amount;
                  let b = item.quantity || 1;
                  let c =
                    item.item !== null &&
                    a *
                      b *
                      (item.item_for_item_inv &&
                        item.item_for_item_inv.vat / 100);
                  return a * b + c;
                })
                .reduce((prev, cur) => {
                  return prev + cur;
                }, 0)
                .toFixed(2);

            return (
              <Form autoComplete="off">
                <Grid container item sx={{ justifyContent: "flex-end" }}>
                  {Object.keys(errors).length !== 0 && (
                    <Typography color="error">
                      {t("yup.fields_color_red_are_mandatory")}
                    </Typography>
                  )}
                </Grid>

                <Grid container item spacing={1} sx={{ mt: 3, mb: 1 }}>
                  {/* Bill to label */}
                  <Grid container spacing={1} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Stack direction="row">
                        <Typography
                          variant="h4"
                          textAlign="start"
                          sx={{ ml: 1 }}
                        >
                          {t("inv.invoice_to")}
                        </Typography>

                        <Typography variant="h4" sx={{ pl: 1 }}>
                          *
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>

                  {/* Bill to & status */}
                  <Grid container item md={6.5} xs={12}>
                    <Grid container item md={10} xs={12}>
                      <Grid item sx={{ width: "100%" }}>
                        <Box sx={{ display: "flex" }}>
                          <Box sx={{ flexGrow: 1 }}>
                            <Autocomplete name="bill_to" />
                          </Box>
                          <Box>
                            <IconButton
                              id="client-popover"
                              color="primary"
                              sx={{ mx: 0, mt: -1 }}
                              onClick={
                                disabledAddButtonClient &&
                                values.bill_to === null
                                  ? handleClickPopover
                                  : () =>
                                      openInPopupAutoField(
                                        values.bill_to,
                                        "bill_to"
                                      )
                              }
                            >
                              <ModeEditOutlineIcon fontSize="small" />
                            </IconButton>
                            <Popover
                              id={id}
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                            >
                              <TypographyPopover>
                                {t(
                                  "toast.need_to_upgrade_in_order_to_register_new_clients"
                                )}
                              </TypographyPopover>
                            </Popover>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container item xs={12}>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          mt: 1,
                          display: smallMedia ? "inline" : "inline-flex",
                        }}
                      >
                        <Box
                          sx={{
                            display: smallMedia ? "inline" : "inline-flex",
                            minWidth: smallMedia ? undefined : 180,
                          }}
                        >
                          <Typography
                            display="inline"
                            variant="body1"
                            sx={{
                              fontWeight: "500",
                            }}
                          >
                            {t("inv.legal_address")}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "inline",
                            ml: smallMedia ? 1 : undefined,
                          }}
                        >
                          <Typography display="inline">
                            {legalAddress && legalAddress}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sx={{
                          mt: 1,
                          display: smallMedia ? "inline" : "inline-flex",
                        }}
                      >
                        <Box
                          sx={{
                            display: smallMedia ? "inline" : "inline-flex",
                            minWidth: smallMedia ? undefined : 180,
                          }}
                        >
                          <Typography
                            display="inline"
                            variant="body1"
                            sx={{
                              fontWeight: "500",
                            }}
                          >
                            {t("inv.fiscal_code")}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "inline",
                            ml: smallMedia ? 1 : undefined,
                          }}
                        >
                          <Typography display="inline">
                            {values.bill_to && values.bill_to.fiscalcode
                              ? values.bill_to.fiscalcode
                              : ""}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sx={{
                          mt: 1,
                          display: smallMedia ? "inline" : "inline-flex",
                        }}
                      >
                        <Box
                          sx={{
                            display: smallMedia ? "inline" : "inline-flex",
                            minWidth: smallMedia ? undefined : 180,
                          }}
                        >
                          <Typography
                            display="inline"
                            variant="body1"
                            sx={{
                              fontWeight: "500",
                            }}
                          >
                            {t("inv.vat_code")}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "inline",
                            ml: smallMedia ? 1 : undefined,
                          }}
                        >
                          <Typography display="inline">
                            {values.bill_to &&
                              values.bill_to.vatcode &&
                              values.bill_to.vatcode}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sx={{
                          mt: 1,
                          display: smallMedia ? "inline" : "inline-flex",
                        }}
                      >
                        <Box
                          sx={{
                            display: smallMedia ? "inline" : "inline-flex",
                            minWidth: smallMedia ? undefined : 180,
                          }}
                        >
                          <Typography
                            display="inline"
                            variant="body1"
                            sx={{
                              fontWeight: "500",
                            }}
                          >
                            {t("inv.phone_email")}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "inline",
                            ml: smallMedia ? 1 : undefined,
                          }}
                        >
                          <Typography display="inline">
                            {values.bill_to && values.bill_to.phone}
                            {values.bill_to &&
                            values.bill_to.phone &&
                            values.bill_to.email1
                              ? " / " + values.bill_to.email1
                              : values.bill_to && values.bill_to.email1
                              ? values.bill_to.email1
                              : ""}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      item
                      md={10}
                      xs={12}
                      sx={{
                        mt: 2,
                        display: mdMediaDown ? "inline" : "inline-flex",
                      }}
                    >
                      <Box
                        sx={{
                          minWidth: smallMedia ? undefined : 180,
                          mt: 1.2,
                          pr: 1,
                        }}
                      >
                        <Typography
                          variant="h6"
                          textAlign="start"
                          display="inline"
                        >
                          {t("quote.status")}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: mdMediaDown ? "flex" : "inline-flex",
                          flexGrow: mdMediaDown ? undefined : 1,
                          mt: 1.2,
                        }}
                      >
                        <Box
                          sx={{
                            display: mdMediaDown ? undefined : "inline",
                            flexGrow: 1,
                          }}
                        >
                          <SelectBasic name="status" quoteorinv="1" />
                        </Box>

                        <Box>
                          <TooltipBasic
                            title={t("notification.history")}
                            placement="bottom-start"
                          >
                            <IconButton
                              id="status-button"
                              aria-controls={
                                openHistory ? "status-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={openHistory ? "true" : undefined}
                              color="inherit"
                              sx={{ mx: 0, pt: 0 }}
                              onClick={handleClickHistory}
                            >
                              <Badge
                                badgeContent={
                                  values.history_invs &&
                                  values.history_invs.length
                                }
                              >
                                <AutoAwesomeMotionIcon />
                              </Badge>
                            </IconButton>
                          </TooltipBasic>
                        </Box>
                      </Box>

                      <Menu
                        id="status-menu"
                        aria-labelledby="status-button"
                        anchorEl={anchorElHistory}
                        open={openHistory}
                        onClose={handleClose}
                        PaperProps={{
                          elevation: 2,
                          sx: {
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 2,
                          },
                          style: {
                            maxHeight: ITEM_HEIGHT * 9.5,
                            minWidth: 300,
                            maxWidth: 650,
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        {values &&
                        values.history_invs &&
                        values.history_invs.length < 1 ? (
                          <MenuItem>
                            <Typography noWrap>
                              {t("notification.no_history")}
                            </Typography>
                          </MenuItem>
                        ) : (
                          <MenuItem>
                            <Typography sx={{ fontWeight: "bold" }}>
                              {t("notification.history")}
                            </Typography>
                          </MenuItem>
                        )}

                        {values &&
                          values.history_invs &&
                          values.history_invs
                            .slice(0)
                            .reverse()
                            .map((history, i) => {
                              return (
                                <MenuItem key={i + 350}>
                                  <Typography component="div" noWrap>
                                    <Stack direction="row">
                                      <Box>
                                        {new Date(
                                          history.date_registered
                                        ).toLocaleDateString("en-GB", {
                                          day: "numeric",
                                          month: "numeric",
                                          year: "numeric",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                        })}{" "}
                                        {history.action
                                          ? t(`status.${history.action}`)
                                          : ""}
                                      </Box>
                                      <Box sx={{ fontWeight: "bold", mx: 1 }}>
                                        {history.status
                                          ? t(`status.${history.status}`)
                                          : ""}
                                      </Box>
                                      <Box
                                        sx={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {history.changed_by &&
                                          (history.changed_by.first_name ||
                                          history.changed_by.last_name
                                            ? (history.changed_by.first_name ||
                                                "") +
                                              " " +
                                              (history.changed_by.last_name ||
                                                "")
                                            : history.changed_by.email || "")}
                                      </Box>
                                    </Stack>
                                  </Typography>
                                </MenuItem>
                              );
                            })}
                      </Menu>
                    </Grid>
                  </Grid>

                  {/* Inv #, inv date, due date, terms of payment, currency */}
                  <Grid container item md={5.5} xs={12} spacing={2}>
                    <Grid container item xs={12} spacing={1}>
                      <Grid item md={6} xs={12}>
                        <Typography
                          variant="h6"
                          textAlign={mdMediaDown ? "start" : "end"}
                        >
                          {t("inv.invoice_hash")}
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FastField
                          as={TextFieldNormal}
                          name="vn"
                          type="number"
                          placeholder={t("inv.number_will_be_generated")}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                      <Grid item md={6} xs={12}>
                        <Typography
                          variant="h6"
                          textAlign={mdMediaDown ? "start" : "end"}
                        >
                          {t("inv.invoice_date")}
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FastField
                          as={DesktopDatePickerBasic}
                          name="date_inv"
                          // type='date'
                          // views={["day", "month", "year"]}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                      <Grid item md={6} xs={12}>
                        <Typography
                          variant="h6"
                          textAlign={mdMediaDown ? "start" : "end"}
                        >
                          {t("inv.due_date")}
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <FastField
                          as={DesktopDatePickerBasic}
                          name="date_due"
                          // type='date'
                          // views={["day", "month", "year"]}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                      <Grid item md={6} xs={12}>
                        <Typography
                          variant="h6"
                          textAlign={mdMediaDown ? "start" : "end"}
                        >
                          {t("inv.payment_term")}
                        </Typography>
                      </Grid>

                      <Grid item md={6} xs={12} sx={{ width: "100%" }}>
                        <Box sx={{ display: "flex" }}>
                          <Box sx={{ flexGrow: 1 }}>
                            <Autocomplete name="note_terms" />
                          </Box>
                          <Box>
                            <IconButton
                              color="primary"
                              sx={{ mx: 0, mt: -1 }}
                              onClick={() =>
                                openInPopupAutoField(
                                  values.note_terms,
                                  "note_terms"
                                )
                              }
                            >
                              <ModeEditOutlineIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                      <Grid item md={6} xs={12}>
                        <Typography
                          variant="h6"
                          textAlign={mdMediaDown ? "start" : "end"}
                        >
                          {t("inv.currency_asterisk")}
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <SelectBasic name="currency" />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <hr />
                  </Grid>

                  <FormGroup sx={{ ml: 1.5, mt: 1 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleSwitchChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label={t("inv.include_load_details")}
                      componentsProps={{
                        typography: { variant: "button", color: "primary" },
                      }}
                    />
                  </FormGroup>

                  {/* Load details */}
                  {checked && (
                    <Grid container item spacing={1}>
                      <LoadDetails />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <hr />
                  </Grid>

                  {/* Invoice table items */}
                  <Grid container item sx={{ mt: mdMediaDown ? undefined : 1 }}>
                    <FieldArray
                      name="iteminv_invs"
                      iteminv_invsArrayHelpers={arrayHelpers}
                    >
                      {(iteminv_invsArrayHelpers) => {
                        return (
                          <>
                            {/* Table header */}

                            {!mdMediaDown ? (
                              <>
                                <Box sx={{ display: "flex", width: "100%" }}>
                                  <Box sx={{ flexGrow: 1, width: "100%" }}>
                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      sx={{ mt: 1, mb: mdMediaDown ? 0 : 1 }}
                                    >
                                      <Grid
                                        item
                                        md={6.5}
                                        xs={12}
                                        sx={{ bgcolor: "#ccc" }}
                                      >
                                        <Typography
                                          sx={{
                                            ml: 1.5,
                                            fontWeight: "600",
                                            color: "black",
                                          }}
                                          noWrap
                                        >
                                          {t("inv.description")}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={1}
                                        sx={{ bgcolor: "#ccc" }}
                                      >
                                        <Typography
                                          sx={{
                                            ml: 1.5,
                                            fontWeight: "600",
                                            color: "black",
                                          }}
                                          noWrap
                                        >
                                          {t("inv.quantity")}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={1.5}
                                        sx={{ bgcolor: "#ccc" }}
                                      >
                                        <Typography
                                          sx={{
                                            ml: 1.5,
                                            fontWeight: "600",
                                            color: "black",
                                          }}
                                        >
                                          {t("inv.rate")}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={1}
                                        sx={{ bgcolor: "#ccc" }}
                                      >
                                        <Typography
                                          sx={{
                                            ml: 1.5,
                                            fontWeight: "600",
                                            color: "black",
                                          }}
                                          noWrap
                                        >
                                          {t("inv.vat_%")}
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={2}
                                        sx={{ bgcolor: "#ccc" }}
                                      >
                                        <Typography
                                          align="right"
                                          marginRight={2}
                                          sx={{
                                            ml: 1.5,
                                            fontWeight: "600",
                                            color: "black",
                                          }}
                                        >
                                          {t("inv.amount")}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  <Box
                                    sx={{ alignSelf: "start", minWidth: 31.8 }}
                                  ></Box>
                                </Box>
                              </>
                            ) : (
                              <Grid
                                item
                                xs={12}
                                sx={{ mb: mdMediaDown ? 1.5 : undefined }}
                              >
                                <Typography variant="h6" textAlign="start">
                                  {t("general.goods_services_capital")}
                                </Typography>
                              </Grid>
                            )}

                            {/* Table rows */}
                            {values.iteminv_invs &&
                              values.iteminv_invs.map((item, i) => {
                                return (
                                  <div key={i} style={{ width: "100%" }}>
                                    <Box
                                      sx={{
                                        display: mdMediaDown
                                          ? "inline"
                                          : "flex",
                                      }}
                                    >
                                      <Box sx={{ flexGrow: 1, width: "100%" }}>
                                        <Grid container item xs={12}>
                                          <Grid
                                            item
                                            md={6.5}
                                            xs={12}
                                            sx={{ width: "100%" }}
                                          >
                                            <Box sx={{ display: "flex" }}>
                                              <Box sx={{ flexGrow: 1 }}>
                                                <AutocompleteItemForItem
                                                  name={`iteminv_invs[${i}].item_for_item_inv`}
                                                  label={
                                                    mdMediaDown
                                                      ? t("inv.description")
                                                      : null
                                                  }
                                                />
                                              </Box>
                                              <Box
                                                sx={{
                                                  mt: mdMediaDown ? 2 : -1,
                                                }}
                                              >
                                                <IconButton
                                                  id="item-inv-popover"
                                                  color="primary"
                                                  onClick={
                                                    disabledAddButtonItemInv &&
                                                    values.iteminv_invs[i]
                                                      .item_for_item_inv ===
                                                      null
                                                      ? handleClickPopover2
                                                      : () =>
                                                          openInPopupAutoField(
                                                            values.iteminv_invs[
                                                              i
                                                            ].item_for_item_inv,
                                                            "iteminv_invs",
                                                            i
                                                          )
                                                  }
                                                >
                                                  <ModeEditOutlineIcon fontSize="small" />
                                                </IconButton>
                                                <Popover
                                                  id={id}
                                                  open={open2}
                                                  anchorEl={anchorEl2}
                                                  onClose={handleClose}
                                                  anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "right",
                                                  }}
                                                >
                                                  <TypographyPopover>
                                                    {t(
                                                      "toast.need_to_upgrade_in_order_to_register_new_items_for_item_inv"
                                                    )}
                                                  </TypographyPopover>
                                                </Popover>
                                              </Box>
                                            </Box>
                                          </Grid>

                                          <Grid item md={1} xs={12}>
                                            <InputArrayNumber
                                              name={`iteminv_invs[${i}].quantity`}
                                              type="number"
                                              label={
                                                mdMediaDown
                                                  ? t("inv.quantity")
                                                  : null
                                              }
                                            />
                                          </Grid>

                                          <Grid item md={1.5} xs={12}>
                                            <InputArrayNumber
                                              name={`iteminv_invs[${i}].amount`}
                                              type="number"
                                              label={
                                                mdMediaDown
                                                  ? t("inv.rate")
                                                  : null
                                              }
                                            />
                                          </Grid>

                                          <Grid item md={1} xs={12}>
                                            <Field
                                              component={InputArray}
                                              name={`iteminv_invs[${i}].item_for_item_inv.vat`}
                                              type="number"
                                              disabled
                                              label={
                                                mdMediaDown
                                                  ? t("inv.vat_%")
                                                  : null
                                              }
                                            />
                                          </Grid>

                                          <Grid
                                            container
                                            item
                                            md={2}
                                            xs={12}
                                            sx={{ mt: mdMediaDown ? 0 : -1 }}
                                          >
                                            <Field
                                              name="article_total"
                                              component={TextFieldRegular}
                                              type="number"
                                              label={
                                                mdMediaDown
                                                  ? t("inv.total")
                                                  : null
                                              }
                                              inputProps={{
                                                style: {
                                                  textAlign: mdMediaDown
                                                    ? "start"
                                                    : "end",
                                                },
                                              }}
                                              value={
                                                Math.round(
                                                  item.amount *
                                                    (1 +
                                                      (item.item_for_item_inv
                                                        ? item.item_for_item_inv
                                                            .vat
                                                        : 0) /
                                                        100) *
                                                    (typeof item.quantity !==
                                                    "number"
                                                      ? 1
                                                      : item.quantity) *
                                                    100 +
                                                    Number.EPSILON
                                                ) / 100 || ""
                                              }
                                              onChange={(e) => {
                                                return setFieldValue(
                                                  `iteminv_invs[${i}].amount`,
                                                  e.target.value /
                                                    (1 +
                                                      (item.item_for_item_inv &&
                                                        item.item_for_item_inv
                                                          .vat &&
                                                        item.item_for_item_inv
                                                          .vat) /
                                                        100) /
                                                    (item.quantity &&
                                                    item.quantity !== "string"
                                                      ? item.quantity
                                                      : 1)
                                                );
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      </Box>

                                      {/* Remove button */}
                                      <Box
                                        sx={{
                                          alignSelf: "start",
                                          display: mdMediaDown
                                            ? "inline"
                                            : undefined,
                                        }}
                                      >
                                        <ButtonAction
                                          type="button"
                                          fullWidth={mdMediaDown ? true : false}
                                          disabled={
                                            values.iteminv_invs.length < 2
                                          }
                                          size="small"
                                          color="secondary"
                                          variant={
                                            mdMediaDown ? "contained" : "text"
                                          }
                                          sx={{ mb: mdMediaDown ? 2 : 2.5 }}
                                          style={{
                                            maxWidth: mdMediaDown
                                              ? undefined
                                              : "32px",
                                            maxHeight: mdMediaDown
                                              ? undefined
                                              : "20px",
                                            minWidth: mdMediaDown
                                              ? undefined
                                              : "32px",
                                            minHeight: mdMediaDown
                                              ? undefined
                                              : "20px",
                                          }}
                                          onClick={() =>
                                            iteminv_invsArrayHelpers.remove(i)
                                          }
                                        >
                                          <RemoveIcon />
                                        </ButtonAction>
                                      </Box>
                                    </Box>

                                    {mdMediaDown &&
                                      values.iteminv_invs.length !== i + 1 && (
                                        <Grid item xs={12}>
                                          <Divider variant="middle" />
                                        </Grid>
                                      )}
                                  </div>
                                );
                              })}

                            {/* Add button */}
                            <Grid container item>
                              <Grid item xs={12}>
                                <ButtonBasic
                                  type="button"
                                  color="primary"
                                  variant="outlined"
                                  fullWidth={smMediaDown ? true : false}
                                  disabled={
                                    values.iteminv_invs &&
                                    values.iteminv_invs.length <= 4
                                      ? false
                                      : true
                                  }
                                  style={{ marginTop: 0, marginBottom: 0 }}
                                  onClick={() =>
                                    iteminv_invsArrayHelpers.push({
                                      item_for_item_inv: null,
                                      description: "",
                                      quantity: null,
                                      amount: null,
                                      vat: null,
                                    })
                                  }
                                >
                                  <Typography noWrap variant="button">
                                    {t("button.add_line_item")}
                                  </Typography>
                                </ButtonBasic>
                              </Grid>
                            </Grid>
                          </>
                        );
                      }}
                    </FieldArray>
                  </Grid>

                  {/* Subtotal, vat, total */}
                  <Grid container item xs={12} sx={{ mb: 1, mt: 0 }}>
                    <Grid item md={4} xs={0}></Grid>

                    <Grid item md={5} xs={7} sx={{ pr: 1 }}>
                      <Stack spacing={2}>
                        <Typography variant="h6" align="right">
                          {t("inv.sub_total")}
                        </Typography>
                        <Typography variant="h6" align="right">
                          {t("inv.vat")}
                        </Typography>

                        <Divider variant="middle" />

                        <Typography
                          variant="h6"
                          align="right"
                          sx={{ fontWeight: "700" }}
                        >
                          {t("inv.total")}
                        </Typography>
                      </Stack>
                    </Grid>

                    <Grid item md={2.5} xs={4.5}>
                      <Stack spacing={2} marginRight={2}>
                        <Typography variant="h6" align="right">
                          {values.iteminv_invs &&
                            values.iteminv_invs
                              .map((item) => {
                                let a = item.amount;
                                let b =
                                  typeof item.quantity === "number"
                                    ? item.quantity
                                    : 1;
                                return a * b;
                              })
                              .reduce((prev, cur) => {
                                return prev + cur;
                              }, 0)
                              .toFixed(2)}
                        </Typography>
                        <Typography variant="h6" align="right">
                          {values.iteminv_invs &&
                            values.iteminv_invs
                              .map((item) => {
                                let c =
                                  item.item !== null &&
                                  item.amount *
                                    (typeof item.quantity === "number"
                                      ? item.quantity
                                      : 1) *
                                    (item.item_for_item_inv &&
                                      item.item_for_item_inv.vat / 100);
                                return c;
                              })
                              .reduce((prev, cur) => {
                                return prev + cur;
                              }, 0)
                              .toFixed(2)}
                        </Typography>

                        <Divider variant="middle" />

                        <Typography
                          variant="h6"
                          align="right"
                          sx={{ fontWeight: "700" }}
                        >
                          {totalInvoice}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <hr />
                  </Grid>

                  {/* Notes */}
                  <Grid container item spacing={1}>
                    <FieldArray name="comment_invs">
                      {(arrayHelpers) => {
                        return (
                          <>
                            {values.comment_invs &&
                              values.comment_invs.map((_, i) => {
                                return (
                                  <Grid
                                    item
                                    xs={12}
                                    key={i}
                                    sx={{ width: "100%" }}
                                  >
                                    <Box sx={{ display: "flex" }}>
                                      <Box sx={{ flexGrow: 1 }}>
                                        <FastField
                                          component={InputArray}
                                          name={`comment_invs[${i}].comment`}
                                          label={`${t("inv.note_to_invoice")}`}
                                          type="text"
                                          fullWidth={true}
                                          multiline={true}
                                          minRows={3}
                                          maxRows={4}
                                          helperText={
                                            errors.comment_invs &&
                                            errors.comment_invs[i].comment
                                          }
                                        />
                                      </Box>
                                      <Box sx={{ alignSelf: "center" }}>
                                        <IconButton
                                          type="button"
                                          onClick={() => arrayHelpers.remove(i)}
                                          color="secondary"
                                        >
                                          <RemoveIcon />
                                        </IconButton>
                                      </Box>
                                    </Box>
                                  </Grid>
                                );
                              })}
                            <Grid
                              container
                              item
                              xs={12}
                              style={{ paddingTop: 0 }}
                            >
                              <ButtonBasic
                                size="small"
                                color="primary"
                                variant="outlined"
                                fullWidth={smMediaDown ? true : false}
                                disabled={
                                  values.comment_invs &&
                                  values.comment_invs.length > 2
                                    ? true
                                    : false
                                }
                                style={{ marginTop: 0 }}
                                onClick={() =>
                                  arrayHelpers.push({
                                    comment: "",
                                  })
                                }
                              >
                                <Typography noWrap variant="button">
                                  {t("button.add_note_to_invoice")}
                                </Typography>
                              </ButtonBasic>
                            </Grid>
                          </>
                        );
                      }}
                    </FieldArray>
                  </Grid>

                  {/* Terms & conditions */}
                  <Grid container item spacing={1}>
                    <Grid item xs={12} sx={{ width: "100%" }}>
                      <Box sx={{ display: "flex" }}>
                        <Box sx={{ flexGrow: 1 }}>
                          <Autocomplete
                            name="contract_terms"
                            label={t("inv.invoice_terms")}
                          />
                        </Box>
                        <Box>
                          <IconButton
                            color="primary"
                            sx={{ mt: 2.5 }}
                            onClick={() =>
                              openInPopupAutoField(
                                values.contract_terms,
                                "contract_terms"
                              )
                            }
                          >
                            <ModeEditOutlineIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <hr />
                  </Grid>

                  {/* Save & delete buttons & email & generate PDF */}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      my: 3,
                      justifyContent: mdMediaDown ? "center" : "start",
                    }}
                  >
                    <Stack direction={smallMedia ? "column" : "row"}>
                      {/* Save & close save & delete buttons */}
                      <Stack direction={smMediaDown ? "column" : "row"}>
                        <ButtonBasic
                          type="button"
                          variant="outlined"
                          fullWidth={smMediaDown ? true : false}
                          startIcon={<SaveAsIcon />}
                          onClick={() =>
                            validateForm()
                              .then((errors) => {
                                if (Object.keys(errors).length > 0) {
                                  toast.error(() =>
                                    t("toast.correct_errors_on_page")
                                  );
                                  return setTouched(errors);
                                } else {
                                  handleSubmit(values);
                                }
                              })
                              .catch((e) => new Error(e))
                          }
                        >
                          <Typography variant="button">
                            {t("button.save_and_close")}
                          </Typography>
                        </ButtonBasic>

                        <ButtonBasic
                          type="button"
                          fullWidth={smMediaDown ? true : false}
                          variant="outlined"
                          startIcon={<SaveIcon />}
                          sx={{ ml: smMediaDown ? undefined : 2 }}
                          onClick={(e) =>
                            validateForm()
                              .then((errors) => {
                                if (Object.keys(errors).length > 0) {
                                  toast.error(() =>
                                    t("toast.correct_errors_on_page")
                                  );
                                  return setTouched(errors);
                                } else {
                                  handleSave(e, values);
                                }
                              })
                              .catch((e) => new Error(e))
                          }
                        >
                          <Typography variant="button">
                            {t("button.save")}
                          </Typography>
                        </ButtonBasic>

                        <ButtonDelete
                          id={values.id}
                          type="button"
                          variant="outlined"
                          color="secondary"
                          fullWidth={smMediaDown ? true : false}
                          sx={{ ml: smMediaDown ? null : 2 }}
                          handleDelete={handleDelete}
                        >
                          <Typography variant="button">
                            {t("button.delete")}
                          </Typography>
                        </ButtonDelete>
                      </Stack>

                      {/* Email select & generate PDF buttons */}
                      <Stack direction={smMediaDown ? "column" : "row"}>
                        <ButtonBasic
                          id="demo-customized-button"
                          variant="outlined"
                          color="success"
                          fullWidth={smMediaDown ? true : false}
                          startIcon={
                            smallMedia ? (
                              <ForwardToInboxIcon />
                            ) : (
                              <PictureAsPdfIcon />
                            )
                          }
                          disabled={values.id ? false : true}
                          sx={{
                            ml: smallMedia ? null : 2,
                          }}
                          aria-controls={
                            openSelectButtonEmail
                              ? "demo-customized-menu"
                              : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={
                            openSelectButtonEmail ? "true" : undefined
                          }
                          disableElevation
                          onClick={handleClickEmail}
                          endIcon={<KeyboardArrowDownIcon />}
                        >
                          <Typography variant="button">
                            {t("button.email")}
                          </Typography>
                        </ButtonBasic>
                        <MenuStyled
                          id="demo-customized-menu"
                          MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                          }}
                          anchorEl={anchorElEmail}
                          open={openSelectButtonEmail}
                          onClose={handleCloseEmail}
                          // onClick={() => setAnchorElEmail(false)}
                        >
                          <MenuItem
                            type="button"
                            disableRipple
                            onClick={(e) =>
                              validateForm()
                                .then((errors) => {
                                  if (Object.keys(errors).length > 0) {
                                    toast.error(() =>
                                      t("toast.correct_errors_on_page")
                                    );
                                    return setTouched(errors);
                                  } else {
                                    emailPdfDocument(
                                      values,
                                      "email",
                                      totalInvoice,
                                      "RO/RU"
                                    );
                                  }
                                })
                                .catch((e) => {
                                  return new Error(e);
                                })
                            }
                          >
                            <PictureAsPdfIcon />
                            <Typography>{t("general.ro_ru")}</Typography>
                          </MenuItem>
                          <MenuItem
                            type="button"
                            disableRipple
                            onClick={(e) =>
                              validateForm()
                                .then((errors) => {
                                  if (Object.keys(errors).length > 0) {
                                    toast.error(() =>
                                      t("toast.correct_errors_on_page")
                                    );
                                    return setTouched(errors);
                                  } else {
                                    emailPdfDocument(
                                      values,
                                      "email",
                                      totalInvoice,
                                      "EN"
                                    );
                                  }
                                })
                                .catch((e) => {
                                  return new Error(e);
                                })
                            }
                          >
                            <PictureAsPdfIcon />
                            <Typography>{t("general.en")}</Typography>
                          </MenuItem>
                        </MenuStyled>

                        {/* Generate PDF */}
                        <ButtonBasic
                          id="demo-customized-button"
                          variant="outlined"
                          color={showPdf ? "error" : "success"}
                          startIcon={<PictureAsPdfIcon />}
                          disabled={values.id ? false : true}
                          fullWidth={smMediaDown ? true : false}
                          endIcon={<KeyboardArrowDownIcon />}
                          sx={{
                            ml: smMediaDown ? null : 2,
                          }}
                          aria-controls={
                            openSelectButton
                              ? "demo-customized-menu"
                              : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={openSelectButton ? "true" : undefined}
                          disableElevation
                          onClick={handleClick}
                        >
                          <Typography
                            variant="button"
                            sx={{ display: showPdf ? "none" : "inline" }}
                          >
                            {t("button.generate")}
                          </Typography>

                          {showPdf ? (
                            <Typography
                              variant="button"
                              sx={{
                                display: showPdf ? "inline" : "none",
                              }}
                            >
                              {t("general.download")}
                            </Typography>
                          ) : null}
                        </ButtonBasic>
                        <MenuStyled
                          id="demo-customized-menu"
                          MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                          }}
                          anchorEl={anchorEl3}
                          open={openSelectButton}
                          onClose={handleCloseSelectMenu}
                          // onClick={() => handleCloseSelectMenu()}
                        >
                          <MenuItem
                            type="button"
                            disableRipple
                            onClick={(e) =>
                              validateForm()
                                .then((errors) => {
                                  if (Object.keys(errors).length > 0) {
                                    toast.error(() =>
                                      t("toast.correct_errors_on_page")
                                    );
                                    return setTouched(errors);
                                  } else {
                                    generatePdfDocument("RO/RU");
                                  }
                                })
                                .catch((e) => {
                                  return new Error(e);
                                })
                            }
                          >
                            <PictureAsPdfIcon
                              sx={{ display: showPdf ? "none" : "auto" }}
                            />
                            <Typography
                              sx={{ display: showPdf ? "none" : "auto" }}
                            >
                              {t("general.ro_ru")}
                            </Typography>
                            {showPdf === true && invoicePdfLang === "RO/RU" ? (
                              <InvPdf
                                account={account}
                                company={company}
                                values={values}
                                bankAccounts={bankAccounts}
                                logo={logo}
                                stamp={stamp}
                                update
                                invoicePdfLang={invoicePdfLang}
                                setShowPdf={setShowPdf}
                                showPdf={showPdf}
                                checked={checked}
                                freeAppMssg={freeAppMssg}
                              />
                            ) : null}
                          </MenuItem>
                          <MenuItem
                            type="button"
                            disableRipple
                            onClick={(e) =>
                              validateForm()
                                .then((errors) => {
                                  if (Object.keys(errors).length > 0) {
                                    toast.error(() =>
                                      t("toast.correct_errors_on_page")
                                    );
                                    return setTouched(errors);
                                  } else {
                                    generatePdfDocument("EN");
                                  }
                                })
                                .catch((e) => {
                                  return new Error(e);
                                })
                            }
                          >
                            <PictureAsPdfIcon
                              sx={{ display: showPdf ? "none" : "auto" }}
                            />
                            <Typography
                              sx={{ display: showPdf ? "none" : "auto" }}
                            >
                              {t("general.en")}
                            </Typography>

                            {showPdf === true && invoicePdfLang === "EN" ? (
                              <InvPdf
                                account={account}
                                company={company}
                                values={values}
                                bankAccounts={bankAccounts}
                                logo={logo}
                                stamp={stamp}
                                update
                                invoicePdfLang={invoicePdfLang}
                                setShowPdf={setShowPdf}
                                showPdf={showPdf}
                                checked={checked}
                                freeAppMssg={freeAppMssg}
                              />
                            ) : null}
                          </MenuItem>
                        </MenuStyled>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>

                <FormValues />
              </Form>
            );
          }}
        </Formik>
      </Container>

      <Popup
        title={
          itemForEdit &&
          (itemForEdit.fieldName === "bill_to"
            ? t("popup.title.client_details")
            : itemForEdit.fieldName === "note_terms"
            ? t("popup.title.payment_term")
            : itemForEdit.fieldName === "contract_terms"
            ? t("popup.title.invoice_terms")
            : itemForEdit.fieldName === "email"
            ? t("popup.title.email_basic")
            : t("popup.title.invoice_item"))
        }
      >
        {itemForEdit && itemForEdit.fieldName === "bill_to" && (
          <Contact
            addOrEdit={addOrEditAutoField}
            deleteAutoField={deleteAutoField}
          />
        )}
        {itemForEdit && itemForEdit.fieldName === "note_terms" && (
          <TermOfPayment
            addOrEdit={addOrEditAutoField}
            deleteAutoField={deleteAutoField}
          />
        )}
        {itemForEdit && itemForEdit.fieldName === "iteminv_invs" && (
          <ItemInv
            addOrEdit={addOrEditAutoField}
            deleteAutoField={deleteAutoField}
          />
        )}
        {itemForEdit && itemForEdit.fieldName === "contract_terms" && (
          <ContractTerm
            addOrEdit={addOrEditAutoField}
            deleteAutoField={deleteAutoField}
          />
        )}
        {itemForEdit && itemForEdit.fieldName === "email" && (
          <EmailBasic
            invoicePdfLang={invoicePdfLang}
            values={formValues}
            checked={checked}
            freeAppMssg={freeAppMssg}
          />
        )}
      </Popup>
    </>
  );
});

export default Inv;
