import React from "react";
import { useTranslation } from "react-i18next";
////// Mui
import styled from "@emotion/styled";
import { Box, Grid, useMediaQuery, Typography } from "@mui/material";
////// Controls & Components
import RegisterIcon from "../Data/Icons/register.svg";
import SettingsIcon from "../Data/Icons/user_data.svg";
import Clients from "../Data/Icons/add_client.svg";
import Email from "../Data/Icons/email.svg";
import CloudIcon from "../Data/Icons/save_storage.svg";
import Export1c from "../Data/Icons/1c.svg";

const TypographyStyled = styled(Typography)(() => ({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "30px",
  letterSpacing: "-0.02em",
  color: "#FFFFFF",
}));

const HowItWorks = () => {
  const { t } = useTranslation();
  const mdMedia = useMediaQuery("(max-width:900px)");
  const lgMedia = useMediaQuery("(max-width:1200px)");

  const advantages = [
    {
      id: 1,
      title: `${t("base.title_howitworks_advantage1")}`,
      icon: RegisterIcon,
    },
    {
      id: 2,
      title: `${t("base.title_howitworks_advantage2")}`,
      icon: SettingsIcon,
    },
    {
      id: 3,
      title: `${t("base.title_howitworks_advantage3")}`,
      icon: Clients,
    },
    {
      id: 4,
      title: `${t("base.title_howitworks_advantage4")}`,
      icon: Email,
    },
    {
      id: 5,
      title: `${t("base.title_howitworks_advantage5")}`,
      icon: CloudIcon,
    },
    {
      id: 6,
      title: `${t("base.title_howitworks_advantage6")}`,
      icon: Export1c,
    },
  ];

  return (
    <Grid
      container
      item
      sx={{
        display: "flex",
        // flexDirection: "column",
        mt: "40px",
        height: "auto",
        // mx: "auto",
        // px: { xs: "0", md: "200px" },
        maxWidth: "1000px",
        width: "100%",
        minHeight: mdMedia ? "auto" : lgMedia ? "auto" : "65vh",
        // px: mdMedia ? "0%" : "15%",
        gap: "50px",
      }}
    >
      {advantages.map((item) => (
        <Grid
          key={item.id}
          item
          xs={12}
          md={5.5}
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            height: "auto",
            // bgcolor: "#c5c5c5",
            justifyContent: { xs: "center", md: "flex-start" },
          }}

          // sx={{ pt: "35px", pb: mdMedia ? "35px" : null }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "auto",
              alignItems: "flex-start",
              alignSelf: "flex-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "100%",
                maxWidth: { xs: "50px", md: "70px" },
                maxHeight: { xs: "50px", md: "70px" },
              }}
            >
              <img
                src={item.icon}
                alt="icons"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Box>

            <Box
              sx={{
                pl: { xs: "30px", md: "50px" },
                alignSelf: "center",
              }}
            >
              <TypographyStyled sx={{ maxWidth: { xs: "auto", md: "280px" } }}>
                {item.title}
              </TypographyStyled>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
export default HowItWorks;
