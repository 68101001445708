import { createSlice } from "@reduxjs/toolkit";
import { getInvs, addOrEditInv, deleteInv } from "./invSlice";

export const searchInvSlice = createSlice({
  name: "searchQuoteSlice",
  initialState: {
    quotesUnfiltered: [],
    invsUnfiltered: [],
    invsFiltered: {
      filteredArray: [[]],
      arraySum: [],
      arrayYearToDateSum: [],
      graphMonths: [],
    },
    searchInv: null,
    allowedToAddQuote: true,
    allowedToAddInv: true,
  },
  reducers: {
    setInvsUnfiltered: (state, action) => {
      return { ...state, invsUnfiltered: [...action.payload] };
    },
    setInvsFiltered: (state, action) => {
      return { ...state, invsFiltered: { ...action.payload } };
    },
    addOrEditFilteredCurrentMonthInv: (state, action) => {
      const date_today = new Date();
      if (
        action.payload.date_inv &&
        new Date(action.payload.date_inv).getTime() >=
          new Date(date_today.getFullYear(), date_today.getMonth(), 1).getTime()
      ) {
        const i = state.invsFiltered.filteredArray[0].findIndex(
          (inv) => inv.id === action.payload.id
        );
        if (i > -1) state.invsFiltered.filteredArray[0][i] = action.payload;
        else state.invsFiltered.filteredArray[0].push(action.payload);
        return state;
      } else {
        return state;
      }
    },
    deleteFilteredCurrentMonthInv: (state, action) => {
      const currentMonthInvs = state.invsFiltered.filteredArray[0].filter(
        (inv) => inv.id !== action.payload
      );
      state.invsFiltered.filteredArray[0] = currentMonthInvs;
      return state;
    },

    setSearchInv: (state, action) => {
      return { ...state, searchInv: { ...action.payload } };
    },
    setAllowedToAddQuote: (state, action) => {
      return { ...state, allowedToAddQuote: action.payload };
    },
    setAllowedToAddInv: (state, action) => {
      return { ...state, allowedToAddInv: action.payload };
    },
  },

  // Using addOrEditInv from invSlice to update unfiltered list on invs changes//
  extraReducers: (builder) => {
    builder.addCase(getInvs.fulfilled, (state, action) => {
      return { ...state, invsUnfiltered: [...action.payload] };
    });
    builder.addCase(addOrEditInv, (state, action) => {
      const i = state.invsUnfiltered.findIndex(
        (item) => item.id === action.payload.id
      );
      if (i > -1) state.invsUnfiltered[i] = action.payload;
      else state.invsUnfiltered.unshift(action.payload);
      return state;
    });
    builder.addCase(deleteInv, (state, action) => {
      const items = state.invsUnfiltered.filter(
        (item) => item.id !== action.payload
      );
      return { ...state, invsUnfiltered: [...items] };
    });
  },
});

export const {
  setInvsUnfiltered,
  setInvsFiltered,
  setSearchInv,
  setAllowedToAddQuote,
  setAllowedToAddInv,
  addOrEditFilteredCurrentMonthInv,
  deleteFilteredCurrentMonthInv,
} = searchInvSlice.actions;

export default searchInvSlice.reducer;
