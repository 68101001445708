import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import configureStore from "./components/Store/store";
// import { createRoot } from "react-dom/client";

////// import i18n (needs to be bundled ;))
import "./i18n";
import { CircularProgress } from "@mui/material";

const container = document.getElementById("root");
const root = createRoot(container);

//// replace console.* for disable log in production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.info = () => {};
  console.error = () => {};
  console.debug = () => {};
}
console.log("111 lansare");

root.render(
  <Suspense fallback={<CircularProgress size="md" />}>
    <React.StrictMode>
      <Provider store={configureStore}>
        <App />
      </Provider>
    </React.StrictMode>
  </Suspense>
);

reportWebVitals();
