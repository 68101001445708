import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#000000";

const styles = StyleSheet.create({
  boxXL: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: -1,
    borderColor: borderColor,
    borderWidth: 1,
    height: 15,
    width: 535,
  },
  rowContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    width: 535,
  },
  description: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    // fontWeight: "bold",
    paddingLeft: 3,
    width: "45%",
  },
  qty: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    // fontWeight: "bold",
    borderLeft: borderColor,
    borderLeftWidth: 1,

    paddingLeft: 3,
    width: "12%",
  },
  rate: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    // fontWeight: "bold",
    borderLeft: borderColor,
    borderLeftWidth: 1,
    paddingLeft: 3,
    width: "15%",
  },
  vat: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    // fontWeight: "bold",
    borderLeft: borderColor,
    borderLeftWidth: 1,
    paddingLeft: 3,
    width: "13%",
  },
  amount: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    // fontWeight: "bold",
    borderLeft: borderColor,
    borderLeftWidth: 1,
    paddingLeft: 3,
    width: "15%",
  },
});

const InvPdfTableRow = ({ items }) => {
  const rows = items.map((item, i) => (
    <View style={styles.boxXL} key={item.id && item.id.toString()}>
      <View style={styles.rowContainer}>
        <Text style={styles.description}>
          {item.item_for_item_inv && item.item_for_item_inv.description}
        </Text>
        <Text style={styles.qty}>{item.quantity}</Text>
        <Text style={styles.rate}>
          {Number(item.amount) && item.amount.toFixed(2)}
        </Text>
        <Text style={styles.vat}>
          {item.item_for_item_inv && item.item_for_item_inv.vat}
        </Text>
        <Text style={styles.amount}>
          {item.amount &&
            (item.quantity || 1) &&
            (
              item.amount *
              (item.quantity || 1) *
              (item.item_for_item_inv
                ? item.item_for_item_inv.vat / 100 + 1
                : 1)
            ).toFixed(2)}
        </Text>
      </View>
    </View>
  ));
  return <>{rows}</>;
};

export default InvPdfTableRow;
