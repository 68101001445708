import React, { useState } from "react";
import { useEffect, forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import { Formik, Form, FieldArray, useFormikContext } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../Fetch/axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
////// Mui
import { styled } from "@mui/material/styles";
import { Button, Grid, useTheme, Stack } from "@mui/material";
import { Container, useMediaQuery } from "@mui/material";
import { TableContainer, Table, TableBody, Typography } from "@mui/material";
import { TableHead, TableRow, TableCell } from "@mui/material";
import { Accordion, AccordionSummary } from "@mui/material";
import { AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
////// Store
import { addOrEditContact, deleteContact } from "../../Store/contactSlice";
import { setFormSimpleTouched } from "../../Store/formTouchedSlice";
import { setItemForEdit } from "../../Store/itemForEditSlice";
import { setItemForEditBasic } from "../../Store/itemForEditBasicSlice";
import { setLoadingStatus } from "../../Store/loadingStatusSlice";
import { setPopup } from "../../Store/popupSlice";
import { setPopupSimple } from "../../Store/popupSimpleSlice";
////// Controls & Components
import Autocomplete from "../../Controls/FormsUI/Autocomplete";
import ButtonAction from "../../Controls/Buttons/ButtonAction";
import BankAccount from "../BankAccount/BankAccount";
import ButtonBasic from "../../Controls/Buttons/ButtonBasic";
import ButtonsContainer from "../../Controls/Buttons/ButtonsContainer";
import { columnsBankAccounts } from "../Tables/Columns/columnsBankAccounts";
import ConfirmDialog from "../../Controls/Popups/ConfirmDialog";
import { contactURL } from "../../Data/baseURL";
import { initialValuesContact } from "../Tables/InitialValues/initialValuesContact";
import { initialValuesBankAccount } from "../Tables/InitialValues/initialValuesBankAccount";
import PopupSimple from "../../Controls/Popups/PopupSimple";
import TextFieldNormal from "../../Controls/Inputs/TextFieldNormal";

const MuiAccordionSummaryStyled = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
}));

const TableStyled = styled(Table)(({ theme }) => ({
  marginTop: theme.spacing(6 / 4),
  "& thead th": {
    fontWeight: "400",
    color: theme.palette.primary.main,
    backgroundColor: "#9adcfb",
  },
  "& tbody th": {
    fontWeight: "300",
  },
  "& tbody tr:hover": {
    backgroundColor: "#fffbf2",
    cursor: "pointer",
  },
}));

const validationSchema = Yup.object().shape({
  companyname: Yup.string()
    .max(150, () => i18n.t("yup.max_150_characters"))
    .required(() => i18n.t("yup.required")),
  fiscalcode: Yup.string().max(20, i18n.t("yup.max_20_characters")).nullable(),
  vatcode: Yup.string().max(20, i18n.t("yup.max_20_characters")).nullable(),
  countrycodelegal: Yup.object()
    .shape()
    .required(() => i18n.t("yup.required"))
    .typeError(() => i18n.t("yup.required")),
  zipcodelegal: Yup.string()
    .max(20, i18n.t("yup.max_20_characters"))
    .nullable(),
  citylegal: Yup.string().max(50, i18n.t("yup.max_50_characters")).nullable(),
  addresslegal: Yup.string()
    .max(100, "Address code should be max 100 characters")
    .nullable(),
  countrycodepost: Yup.object()
    .shape()
    .required(() => i18n.t("yup.required"))
    .typeError(() => i18n.t("yup.required")),
  zipcodepost: Yup.string()
    .max(20, () => i18n.t("yup.max_20_characters"))
    .nullable(),
  citypost: Yup.string()
    .max(50, "City code should be max 50 characters")
    .nullable(),
  addresspost: Yup.string()
    .max(100, i18n.t("yup.max_100_characters"))
    .nullable(),
  email1: Yup.string()
    .email(() => i18n.t("yup.invalid_email"))
    .nullable(),
  phone: Yup.string().max(30, i18n.t("yup.max_30_characters")).nullable(),
  skype: Yup.string().max(30, i18n.t("yup.max_30_characters")).nullable(),
  comment: Yup.string().max(500, i18n.t("yup.max_500_characters")),
  contact_persons: Yup.array()
    .notRequired()
    .of(
      Yup.object().shape({
        first_name: Yup.string()
          .max(80, i18n.t("yup.max_80_characters"))
          .required(() => i18n.t("yup.required")),
        last_name: Yup.string().max(80, i18n.t("yup.max_80_characters")),
        email: Yup.string()
          .email(() => i18n.t("yup.invalid_email"))
          .nullable(),
        phone: Yup.string().max(80, i18n.t("yup.max_80_characters")).nullable(),
        comment: Yup.string()
          .max(80, i18n.t("yup.max_80_characters"))
          .nullable(),
      })
    ),
});

const Contact = forwardRef((props, ref) => {
  const { addOrEdit, deleteAutoField } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const smMediaDown = useMediaQuery(theme.breakpoints.down("sm"));
  const lgMediaDown = useMediaQuery(theme.breakpoints.down("lg"));
  const [expanded, setExpanded] = React.useState(false);
  const [formValues, setFormValues] = useState();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [updatedColumns, setFilteredColumns] = useState([]);
  const { itemForEdit } = useSelector((state) => state.itemForEditSlice);
  const { itemForEditBasic } = useSelector(
    (state) => state.itemForEditBasicSlice
  );

  const FormValues = () => {
    const { values, touched } = useFormikContext();
    useEffect(() => {
      if (values) {
        setFormValues(values);
      }
      if (Object.keys(touched).length !== 0 && values.id === undefined) {
        dispatch(setFormSimpleTouched(true));
      }
      if (
        Object.keys(touched).length !== 0 &&
        itemForEdit !== null &&
        values.id !== ""
      ) {
        dispatch(setFormSimpleTouched(true));
      }
    }, [values, touched]);
    return null;
  };

  useEffect(() => {
    if (smMediaDown) {
      let updatedColumns = columnsBankAccounts().filter(
        (column) =>
          column.id === "currencycode.currencycode" ||
          column.id === "iban_number"
      );
      setFilteredColumns(updatedColumns);
    } else {
      setFilteredColumns(columnsBankAccounts());
    }
  }, [smMediaDown, setFilteredColumns]);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => handleSubmit(),
  }));

  const handleSubmit = () => {
    dispatch(setLoadingStatus(true));
    axiosInstance({
      method: formValues.id ? "PATCH" : "POST",
      url: formValues.id ? contactURL + formValues.id + "/" : contactURL,
      data: {
        ...formValues,
        companyname: formValues.companyname.trim(),
        fiscalcode: formValues.fiscalcode.trim(),
      },
    })
      .then((response) => {
        if (response && response.data) {
          batch(() => {
            dispatch(setLoadingStatus(false));
            dispatch(setFormSimpleTouched(false));
            dispatch(
              addOrEditContact({
                ...response.data,
                fieldName: itemForEdit ? itemForEdit.fieldName : null,
                index: itemForEdit ? itemForEdit.index : null,
              })
            );
          });
          addOrEdit(response.data);
          toast.success(
            `${t("toast.client_capital")} ${response.data.companyname} ${t(
              "toast.successfully"
            )} ${
              response.status && response.status === 201
                ? t("toast.created")
                : t("toast.updated")
            }`
          );
        }
      })
      .catch((error) => {
        dispatch(setLoadingStatus(false));
        if (error.response.status === 402) {
          toast.error(
            () => (
              <div>
                <Typography>
                  {t("toast.need_to_upgrade_in_order_to_register_new_clients")}
                </Typography>
              </div>
            ),
            {
              autoClose: 4000,
            }
          );
        } else if (error.response.status === 404) {
          toast.error(
            () => (
              <div>
                <Typography>{t("toast.client")}</Typography>
                <Typography>{t("toast.could_not_be_updated")}</Typography>
                <Typography>{t("toast.company_could_not_be_found")}</Typography>
              </div>
            ),
            { pauseOnHover: true }
          );
        } else if (error.response) {
          toast.error(
            () => (
              <div>
                <Typography>
                  {t("toast.record_could_not_be_created_or_updated")}
                </Typography>
                <Typography>{t("toast.record_should_be_unique")}</Typography>
              </div>
            ),
            {
              pauseOnHover: true,
            }
          );
        }
      });
  };

  const handleDelete = () => {
    if (formValues.id) {
      dispatch(setLoadingStatus(true));
      axiosInstance({
        method: "DELETE",
        url: contactURL + formValues.id + "/",
      })
        .then((response) => {
          batch(() => {
            dispatch(setLoadingStatus(false));
            dispatch(deleteContact(formValues.id));
            dispatch(setPopup(false));
            dispatch(setFormSimpleTouched(false));
          });
          deleteAutoField && deleteAutoField(formValues.id);
          response.status === 204 &&
            toast.success(t("toast.record_successfully_deleted"));
        })
        .catch((error) => {
          dispatch(setLoadingStatus(false));
          error.response &&
            error.response.status === 500 &&
            toast.error(t("toast.record_not_allowed_be_deleted"));
          error.response &&
            error.response.status === 400 &&
            toast.error(t("toast.record_could_not_be_created_or_updated"));
          error.response &&
            error.response.status === 404 &&
            toast.error(t("toast.record_not_found"));
        }, []);
    } else {
      toast.error(t("toast.record_not_found"));
    }
  };

  const openInPopupAutoField = (item, fieldName, index = null) => {
    batch(() => {
      dispatch(setPopupSimple(true));
      dispatch(
        setItemForEditBasic({
          ...item,
          fieldName: fieldName,
          index: index,
        })
      );
    });

    return null;
  };

  const handleAddBankAccount = (fieldName = null, index = null) => {
    batch(() => {
      dispatch(setPopupSimple(true));
      dispatch(
        setItemForEditBasic({
          ...initialValuesBankAccount,
          fieldName: fieldName,
          index: index,
        })
      );
    });
    return null;
  };

  const addOrEditAutoField = (value) => {
    let updatedArrayBankAccounts = [];
    let arrayBankAccounts = [...formValues.contact_bank_accounts];

    if (itemForEditBasic.index === null) {
      updatedArrayBankAccounts = [...arrayBankAccounts, value];
    } else {
      updatedArrayBankAccounts = arrayBankAccounts.map((bankAccount) => {
        let newItem = null;

        if (
          itemForEditBasic.index !== null &&
          bankAccount.id === arrayBankAccounts[itemForEditBasic.index].id
        ) {
          newItem = { ...value };
        } else if (bankAccount.id) {
          newItem = { ...bankAccount };
        }
        return newItem;
      });
    }

    batch(() => {
      dispatch(setPopupSimple(false));
      dispatch(
        setItemForEdit({
          ...formValues,
          [itemForEditBasic.fieldName]: updatedArrayBankAccounts,
        })
      );
      // setTimeout(() => {
      //   dispatch(setItemForEdit(null));
      // }, 500);
    });
  };

  const onDeleteAutoField = (id, index) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });

    let updatedArrayBankAccounts = [];
    let arrayBankAccounts = [...formValues.contact_bank_accounts];

    updatedArrayBankAccounts = arrayBankAccounts.filter((_, i) => {
      return i !== index;
    });

    batch(() => {
      dispatch(
        setItemForEdit({
          ...formValues,
          contact_bank_accounts: updatedArrayBankAccounts,
        })
      );
      setTimeout(() => {
        dispatch(setItemForEditBasic(null));
      }, 100);
    });
  };

  return (
    <>
      <Container maxWidth='lg' disableGutters={lgMediaDown ? true : false}>
        <Formik
          enableReinitialize
          initialValues={
            (itemForEdit && "companyname" in itemForEdit && itemForEdit) ||
            initialValuesContact
          }
          validateOnChange={false}
          validateOnBlur={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors }) => {
            return (
              <Form autoComplete='off'>
                <Grid container item sx={{ mt: 3, mb: 5 }}>
                  {/* Company details */}
                  <Grid container item spacing={2} sx={{ mb: 2 }}>
                    <Grid container item md={8} xs={12} spacing={2}>
                      <Grid container item md={12} xs={12} spacing={1}>
                        <Grid item xs={12}>
                          <TextFieldNormal
                            name='companyname'
                            label={t("contact.company_name")}
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldNormal
                            name='addresslegal'
                            label={t("contact.legal_address")}
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          spacing={smMediaDown ? 1 : 0}
                        >
                          <Grid item md={3} xs={12}>
                            <Autocomplete
                              name='countrycodelegal'
                              label={t("contact.country_legal")}
                              required
                            />
                          </Grid>
                          <Grid item md={3} xs={4}>
                            <TextFieldNormal
                              name='zipcodelegal'
                              label={t("contact.zip_legal")}
                            />
                          </Grid>
                          <Grid item md={6} xs={8}>
                            <TextFieldNormal
                              name='citylegal'
                              label={t("contact.city_legal")}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container item md={12} xs={12} spacing={1}>
                        <Grid item xs={12}>
                          <TextFieldNormal
                            name='addresspost'
                            label={t("contact.post_address")}
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          xs={12}
                          spacing={smMediaDown ? 1 : 0}
                        >
                          <Grid item md={3} xs={12}>
                            <Autocomplete
                              name='countrycodepost'
                              label={t("contact.country_post")}
                              required
                            />
                          </Grid>
                          <Grid item md={3} xs={4}>
                            <TextFieldNormal
                              name='zipcodepost'
                              label={t("contact.zip_post")}
                            />
                          </Grid>
                          <Grid item md={6} xs={8}>
                            <TextFieldNormal
                              name='citypost'
                              label={t("contact.city_post")}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container item md={4} xs={12} spacing={1}>
                      <Grid
                        container
                        item
                        spacing={1}
                        sx={{ alignContent: "flex-start" }}
                      >
                        <Grid item xs={12}>
                          <TextFieldNormal
                            name='fiscalcode'
                            label={t("contact.fiscal_code")}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldNormal
                            name='vatcode'
                            label={t("contact.vat_code")}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldNormal
                            name='phone'
                            label={t("general.phone")}
                          />
                        </Grid>
                      </Grid>

                      <Grid container item xs={12} spacing={1}>
                        <Grid item xs={12}>
                          <TextFieldNormal
                            name='email1'
                            label={t("general.email")}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldNormal
                            name='skype'
                            label={t("general.skype")}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container item xs={12} spacing={1}>
                      <Grid item xs={12}>
                        <TextFieldNormal
                          name='comment1'
                          label={t("contact.comments")}
                          multiline={true}
                          maxRows={4}
                          minRows={4}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <hr />
                  </Grid>

                  {/* Person details */}
                  <Grid container item spacing={1} sx={{ mt: 1, mb: 2 }}>
                    <FieldArray
                      name='contact_persons'
                      render={({ push, remove }) => {
                        return (
                          <>
                            {values.contact_persons.map((_, i) => {
                              return (
                                <Grid container item key={i} xs={12}>
                                  <Accordion
                                    sx={{ flexGrow: 1 }}
                                    expanded={
                                      errors &&
                                      errors.contact_persons &&
                                      errors.contact_persons.length > 0
                                        ? true
                                        : expanded
                                    }
                                  >
                                    <MuiAccordionSummaryStyled
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls='panel1a-content'
                                      id='panel1a-header'
                                      onClick={() => {
                                        setExpanded(!expanded);
                                      }}
                                    >
                                      <Typography>
                                        {values.contact_persons[i].last_name +
                                          " " +
                                          values.contact_persons[i].first_name}

                                        {values.contact_persons[i].email &&
                                          " / " +
                                            values.contact_persons[i].email}
                                        {values.contact_persons[i].phone &&
                                          " / " +
                                            values.contact_persons[i].phone}
                                        {values.contact_persons[i].comment &&
                                          " / " +
                                            values.contact_persons[i].comment}
                                      </Typography>
                                    </MuiAccordionSummaryStyled>

                                    <AccordionDetails>
                                      <Grid container item spacing={2}>
                                        <Grid item xs={11}>
                                          <Typography variant='h6'>
                                            {t("contact.contact")}{" "}
                                            {i +
                                              1 +
                                              " / " +
                                              values.contact_persons.length}
                                          </Typography>
                                        </Grid>

                                        <Grid
                                          container
                                          item
                                          xs={1}
                                          justifyContent='flex-end'
                                        >
                                          <ButtonAction
                                            type='button'
                                            onClick={() => remove(i)}
                                            color='secondary'
                                          >
                                            <DeleteOutlineIcon />
                                          </ButtonAction>
                                        </Grid>
                                      </Grid>

                                      <Grid container item spacing={2}>
                                        <Grid
                                          container
                                          item
                                          md={6}
                                          xs={12}
                                          spacing={1}
                                        >
                                          <Grid item xs={12}>
                                            <TextFieldNormal
                                              name={`contact_persons[${i}].first_name`}
                                              label={t("person.first_name")}
                                              required
                                            />
                                          </Grid>
                                          <Grid item xs={12}>
                                            <TextFieldNormal
                                              name={`contact_persons[${i}].last_name`}
                                              label={t("person.last_name")}
                                            />
                                          </Grid>
                                        </Grid>

                                        <Grid
                                          container
                                          item
                                          md={6}
                                          xs={12}
                                          spacing={1}
                                        >
                                          <Grid item xs={12}>
                                            <TextFieldNormal
                                              name={`contact_persons[${i}].email`}
                                              label={t("general.email")}
                                            />
                                          </Grid>

                                          <Grid item xs={12}>
                                            <TextFieldNormal
                                              name={`contact_persons[${i}].phone`}
                                              label={t("general.phone")}
                                            />
                                          </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                          <TextFieldNormal
                                            name={`contact_persons[${i}].comment`}
                                            label={t("person.comments")}
                                            multiline
                                            minRows={3}
                                            maxRows={3}
                                          />
                                        </Grid>
                                      </Grid>
                                    </AccordionDetails>
                                  </Accordion>
                                </Grid>
                              );
                            })}

                            {/* Add person button */}
                            <Grid
                              container
                              item
                              xs={12}
                              sx={{ mt: expanded ? 2 : 0 }}
                            >
                              <Grid item xs={12}>
                                <Button
                                  type='button'
                                  size='small'
                                  color='primary'
                                  variant='outlined'
                                  fullWidth={smMediaDown ? true : false}
                                  onClick={() => [
                                    push({
                                      last_name: "",
                                      first_name: "",
                                      email: "",
                                      mobile: "",
                                      phone: "",
                                      comment: "",
                                    }),
                                    setExpanded(true),
                                  ]}
                                >
                                  <Typography variant='button' noWrap>
                                    {t("button.add_contact_person")}
                                  </Typography>
                                </Button>
                              </Grid>
                            </Grid>
                          </>
                        );
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <hr />
                  </Grid>

                  {/* Bank accounts */}
                  <Grid container item spacing={1} sx={{ mt: 1, mb: 2 }}>
                    <TableContainer sx={{ ml: 0.9 }}>
                      <TableStyled size='small'>
                        <TableHead>
                          <TableRow>
                            {updatedColumns.map((column, i) => {
                              return (
                                <TableCell
                                  key={i}
                                  align={column.align}
                                  sx={{
                                    minWidth: column.minWidth,
                                    maxWidth: column.maxWidth,
                                  }}
                                >
                                  <Typography noWrap fontWeight='550'>
                                    {column.label}
                                  </Typography>
                                </TableCell>
                              );
                            })}
                            {!smMediaDown && (
                              <TableCell
                                align='left'
                                sx={{ minWidth: 110, maxWidth: 130, pl: 3 }}
                              >
                                <Typography noWrap fontWeight='550'>
                                  {t("column.actions")}
                                </Typography>
                              </TableCell>
                            )}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {values.contact_bank_accounts.map((row, i) => (
                            <TableRow
                              key={i}
                              id={row.id}
                              onDoubleClick={() => {
                                openInPopupAutoField(
                                  row,
                                  "contact_bank_accounts",
                                  i
                                );
                              }}
                            >
                              {updatedColumns.map((column, i) => {
                                return (
                                  <TableCell
                                    key={column.id}
                                    sx={{
                                      minWidth: column.minWidth,
                                      maxWidth: column.maxWidth,
                                    }}
                                  >
                                    {column.id.startsWith("currencycode.") && (
                                      <Typography noWrap>
                                        {row.currencycode.currencycode}
                                      </Typography>
                                    )}

                                    {/* All other cases render below */}
                                    <Typography noWrap>
                                      {row[column.id]}
                                    </Typography>
                                  </TableCell>
                                );
                              })}

                              {!smMediaDown && (
                                <TableCell
                                  align='left'
                                  sx={{ minWidth: 60, maxWidth: 130 }}
                                >
                                  <Stack direction='row'>
                                    <ButtonAction
                                      color='primary'
                                      style={{
                                        maxWidth: "40px",
                                        maxHeight: "20px",
                                        minWidth: "40px",
                                        minHeight: "20px",
                                      }}
                                      onClick={() => {
                                        openInPopupAutoField(
                                          row,
                                          "contact_bank_accounts",
                                          i
                                        );
                                      }}
                                    >
                                      <ModeEditOutlineIcon fontSize='small' />
                                    </ButtonAction>

                                    <ButtonAction
                                      color='secondary'
                                      style={{
                                        maxWidth: "40px",
                                        maxHeight: "20px",
                                        minWidth: "40px",
                                        minHeight: "20px",
                                      }}
                                      onClick={() => {
                                        setConfirmDialog({
                                          isOpen: true,
                                          title: t(
                                            "popup.are_you_sure_to_delete_record"
                                          ),
                                          subTitle: t(
                                            "popup.you_can_not_undo_this_operation"
                                          ),
                                          onConfirm: () =>
                                            onDeleteAutoField(row.id, i),
                                        });
                                      }}
                                    >
                                      <DeleteOutlineIcon
                                        color='secondary'
                                        fontSize='small'
                                      />
                                    </ButtonAction>
                                  </Stack>
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </TableStyled>
                    </TableContainer>

                    {/* add bank account button */}
                    <Grid item xs={12} sx={{ mt: 1 }}>
                      <ButtonBasic
                        size='small'
                        color='primary'
                        variant='outlined'
                        fullWidth={smMediaDown ? true : false}
                        disabled={
                          values.contact_bank_accounts &&
                          values.contact_bank_accounts.length > 5
                            ? true
                            : false
                        }
                        style={{ marginTop: 0 }}
                        onClick={() =>
                          handleAddBankAccount("contact_bank_accounts")
                        }
                      >
                        <Typography noWrap variant='button'>
                          {t("button.add_bank_account")}
                        </Typography>
                      </ButtonBasic>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <hr />
                  </Grid>

                  {/* Save & Close Button */}
                  <Grid container item xs={12} spacing={3} sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                      <ButtonsContainer
                        handleDelete={handleDelete}
                        values={values}
                      />
                    </Grid>
                  </Grid>
                  <FormValues />
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Container>

      <PopupSimple title={t("popup.title.bank_account_details")}>
        <BankAccount
          addOrEdit={addOrEditAutoField}
          onDeleteAutoField={onDeleteAutoField}
        />
      </PopupSimple>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      ></ConfirmDialog>
    </>
  );
});

export default Contact;
