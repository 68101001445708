import React from "react";
import { useState, useEffect, forwardRef, useCallback } from "react";
import { useImperativeHandle } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Formik, Form, FastField, useFormikContext } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../Fetch/axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
////// Mui
import { alpha, useTheme } from "@mui/material";
import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import { Box, Paper, useMediaQuery } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
////// Store
import { setPopup } from "../../Store/popupSlice";
import { setFormSimpleTouched } from "../../Store/formTouchedSlice";
import { setLoadingStatus } from "../../Store/loadingStatusSlice";
import { setItemEmail } from "../../Store/itemEmailSlice";
////// Components
import ChipsInput from "../../Controls/FormsUI/ChipsInput";
import TextFieldNormal from "../../Controls/Inputs/TextFieldNormal";
import { InvPdfAttachment } from "../Inv/InvPdf/InvPdf";
import { emailInvURL } from "../../Data/baseURL";
import { QuotePdfAttachment } from "../Quote/QuotePdf/QuotePdf";
import { initialValuesEmailBasic } from "../Tables/InitialValues/initialValuesEmailBasic";

const validationSchema = Yup.object().shape({
  to: Yup.array()
    .min(1, () => i18n.t("yup.to_must_have_min_1_email"))
    .max(3, "Maximum 3 emails")
    .of(
      Yup.string(() => i18n.t("yup.invalid_email"))
        .required(() => i18n.t("yup.invalid_email"))
        .min(6, () => i18n.t("yup.invalid_email"))
        .email(() => i18n.t("yup.invalid_email"))
    ),
  cc: Yup.array()
    .of(
      Yup.string(() => i18n.t("yup.invalid_email"))
        .required(() => i18n.t("yup.invalid_email"))
        .min(6, () => i18n.t("yup.invalid_email"))
        .email(() => i18n.t("yup.invalid_email"))
    )
    .min(1, () => i18n.t("yup.cc_must_have_min_1_email"))
    .max(3, "Maximum 3 emails"),
  subject: Yup.string()
    .max(150, () => i18n.t("yup.max_150_characters"))
    .required(() => i18n.t("yup.required")),
  body: Yup.string().max(500, () => i18n.t("yup.max_500_characters")),
});

const EmailBasic = forwardRef((props, ref) => {
  const { invoicePdfLang, checked, checkedEntries, freeAppMssg } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const theme = useTheme();
  const xsMedia = useMediaQuery("(max-width:900px)");
  const lgMediaDown = useMediaQuery(theme.breakpoints.down("lg"));
  const [to, setTo] = useState([]);
  const [cc, setCc] = useState([]);
  const [file, setFile] = useState(null);
  const [formValues, setFormValues] = React.useState();
  const { quote } = useSelector((state) => state.quoteSlice);
  const { inv } = useSelector((state) => state.invSlice);
  const { itemEmail } = useSelector((state) => state.itemEmailSlice);
  const { account } = useSelector((state) => state.accountSlice);
  const { company } = useSelector((state) => state.companySlice);
  const { bankAccounts } = useSelector((state) => state.bankAccountSlice);
  const { logo, stamp } = useSelector((state) => state.avatarSlice);
  const { itemForEdit } = useSelector((state) => state.itemForEditSlice);

  const FormValues = () => {
    const { values, touched } = useFormikContext();
    useEffect(() => {
      if (values) {
        setFormValues(values);
      }
      if (Object.keys(touched).length !== 0) {
        dispatch(setFormSimpleTouched(true));
      }
    }, [values, touched]);
    return null;
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: () => handleSend(),
  }));

  useEffect(() => {
    dispatch(
      setItemEmail({
        ...itemForEdit,
        to:
          itemForEdit.person && itemForEdit.person.email.length > 3
            ? [itemForEdit.person.email]
            : itemForEdit.bill_to && itemForEdit.bill_to.email1.length > 3
            ? [itemForEdit.bill_to.email1]
            : [],

        cc: [account && account.email],
        subject:
          (company && company.companyname ? company.companyname + " > " : "") +
          (itemForEdit.bill_to ? itemForEdit.bill_to.companyname || "" : "") +
          ` / ${t(
            itemForEdit && itemForEdit.quote
              ? "quote.quote_hash"
              : "inv.invoice_hash"
          )}` +
          (itemForEdit.qn || itemForEdit.vn),
        body:
          itemForEdit && itemForEdit.quote
            ? `${t("email_basic.hi_capital")},

${t("email_basic.a_new_quote_has_been_issued")} ${
                company && company.companyname
                  ? " " + company.companyname
                  : account.first_name && account.last_name
                  ? " " + account.first_name + " " + account.last_name
                  : account.first_name
                  ? " " + account.first_name
                  : account.last_name
                  ? " " + account.last_name
                  : ""
              }. 
        
${t("email_basic.here_is_a_quick_summary_colon")}

${t("quote.quote_hash")} ${itemForEdit.qn && (itemForEdit.qn || "")} 

${t("quote.quote_date_colon")} ${
                itemForEdit.date_inv &&
                new Date(itemForEdit.date_inv).toLocaleDateString("en-GB")
              }

${t("quote.total_quote_amount")}: ${
                (itemForEdit.totalInvoice && itemForEdit.totalInvoice + " ") +
                (itemForEdit.currency && itemForEdit.currency.currencycode)
              }

${t("email_basic.you_can_view_quote_in_attachment")}.

${t("email_basic.best_regards")},
${
  company && company.companyname
    ? t("email_basic.team") + company.companyname
    : (account.first_name || "") + " " + (account.last_name || "")
}


${t("note.quote_created_with_online_app_cargos_md")}
`
            : `${t("email_basic.hi_capital")},

${t("email_basic.a_new_invoice_has_been_issued")} ${
                company && company.companyname
                  ? " " + company.companyname
                  : account.first_name && account.last_name
                  ? " " + account.first_name + " " + account.last_name
                  : account.first_name
                  ? " " + account.first_name
                  : account.last_name
                  ? " " + account.last_name
                  : ""
              }. 
        
${t("email_basic.here_is_a_quick_summary_colon")}

${t("inv.invoice_hash")} ${itemForEdit.vn && itemForEdit.vn} 

${t("inv.invoice_date_colon")} ${
                itemForEdit.date_inv &&
                new Date(itemForEdit.date_inv).toLocaleDateString("en-GB")
              }

${t("inv.total_invoice_amount")}: ${
                (itemForEdit.totalInvoice && itemForEdit.totalInvoice + " ") +
                (itemForEdit.currency && itemForEdit.currency.currencycode)
              }

${t("email_basic.you_can_view_invoice_in_attachment")}.

${t("email_basic.best_regards")},
${
  company && company.companyname
    ? t("email_basic.team") + company.companyname
    : (account.first_name || "") + " " + (account.last_name || "")
}


${t("note.invoice_created_with_online_app_cargos_md")}
`,
      })
    );
  }, [dispatch, account, company, itemForEdit, t, pathname]);

  const handleSend = () => {
    dispatch(setLoadingStatus(true));
    if (file) {
      const formData = new FormData();
      formData.append("to", JSON.stringify(to) || "");
      formData.append("cc", JSON.stringify(cc) || "");
      formData.append("subject", formValues.subject);
      formData.append("body", formValues.body || "");
      formData.append(
        "file_name",
        `${t(
          pathname === "/apps/quotes" ? "quote.quote_hash" : "inv.invoice_hash"
        )} ${formValues.qn || formValues.vn}.pdf`
      );
      formData.append(
        "attachment",
        new File([file], { type: "application/pdf" })
      );
      axiosInstance({
        method: "POST",
        url: emailInvURL,
        data: formData,
      })
        .then((response) => {
          if (response.status === 200) {
            batch(() => {
              dispatch(setLoadingStatus(false));
              dispatch(setPopup(false));
              dispatch(setItemEmail(null));
            });
            toast.success(
              `${t(
                pathname === "/apps/quotes"
                  ? "quote.quote_hash"
                  : "inv.invoice_hash"
              )} ${formValues.qn || formValues.vn || ""} ${t(
                "toast.successfully_emailed"
              )}`
            );
          }
        })
        .catch((error) => {
          dispatch(setLoadingStatus(false));
          error.response &&
            error.response.status === 400 &&
            toast.error(() => t("toast.invoice_could_not_be_emailed"));
        }, []);
    } else {
      toast.error("Invoice attachment is missing, please try again");
      dispatch(setLoadingStatus(false));
    }
  };

  ////// using useCallback because use Field and not FastField
  const handleSelecetedTagsTo = useCallback((selectedItems) => {
    setTo([...selectedItems]);
    return null;
  }, []);

  const handleSelecetedTagsCc = useCallback((selectedItems) => {
    setCc([...selectedItems]);
    return null;
  }, []);

  return (
    <>
      <Container maxWidth='lg' disableGutters={lgMediaDown ? true : false}>
        <Formik
          enableReinitialize
          initialValues={itemEmail || initialValuesEmailBasic}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}
        >
          {({ values, errors, validateForm, setTouched, handleBlur }) => {
            return (
              <Form autoComplete='off'>
                <Grid container item spacing={2} sx={{ mt: 3, mb: 5 }}>
                  {/* Send button & attachment */}
                  <Grid
                    component={Paper}
                    elevation={0}
                    container
                    item
                    xs={12}
                    sx={{
                      ml: 2,
                      mt: 0,
                      height: 92,
                      border: 2,
                      borderRadius: 2,
                      position: "relative",
                      backgroundColor: theme.palette.background.paper,
                      borderColor: alpha(
                        theme.palette.primary.dark,
                        theme.palette.action.activatedOpacity
                      ),
                    }}
                  >
                    <Grid item xs={5} sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Button
                          type='button'
                          variant='text'
                          sx={{ mb: 2, pb: 0, pt: 0.1, mt: !xsMedia ? 0 : 1.2 }}
                          onClick={() =>
                            validateForm()
                              .then((errors) => {
                                if (Object.keys(errors).length > 0) {
                                  return setTouched(errors);
                                } else {
                                  handleSend();
                                }
                              })
                              .catch((e) => new Error(e))
                          }
                        >
                          <Stack direction='column'>
                            <SendIcon fontSize='large' />
                            {!xsMedia && (
                              <Typography variatnt='h6'>
                                {t("button.send")}
                              </Typography>
                            )}
                          </Stack>
                        </Button>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={7}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "right",
                      }}
                    >
                      <Box
                        sx={{
                          pt: 2.2,
                          mr: 3,
                        }}
                      >
                        {itemForEdit && itemForEdit.quote ? (
                          <QuotePdfAttachment
                            account={account}
                            company={company}
                            values={quote}
                            logo={logo}
                            invoicePdfLang={invoicePdfLang}
                            setFile={setFile}
                            checked={checked}
                            checkedEntries={checkedEntries}
                            freeAppMssg={freeAppMssg}
                          />
                        ) : (
                          <InvPdfAttachment
                            account={account}
                            company={company}
                            values={inv}
                            bankAccounts={bankAccounts}
                            logo={logo}
                            stamp={stamp}
                            invoicePdfLang={invoicePdfLang}
                            setFile={setFile}
                            checked={checked}
                            checkedEntries={checkedEntries}
                            freeAppMssg={freeAppMssg}
                          />
                        )}
                      </Box>
                    </Grid>
                  </Grid>

                  {/* From, to, cc, subject */}
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={xsMedia ? 0 : 1}
                    sx={{ mt: 2 }}
                  >
                    <Grid item xs={xsMedia ? 12 : 1.5}>
                      <Typography
                        variant='h6'
                        textAlign={xsMedia ? "start" : "end"}
                        align='center'
                      >
                        {t("email_basic.from")}
                      </Typography>
                    </Grid>

                    <Grid item xs={xsMedia ? 12 : 10.5}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          pt: xsMedia ? 0 : 0.75,
                          pl: 0.2,
                        }}
                      >
                        <Typography variant='body1'>
                          {"CONTDEPLATA.MD <support@contdeplata.md>"}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={1.5}>
                      <Typography
                        variant='h6'
                        textAlign={xsMedia ? "start" : "end"}
                      >
                        {t("email_basic.to")}
                      </Typography>
                    </Grid>

                    <Grid item xs={xsMedia ? 12 : 10.5}>
                      <FastField
                        component={ChipsInput}
                        name='to'
                        selectedTags={handleSelecetedTagsTo}
                        fullWidth
                        variant='outlined'
                        onBlur={handleBlur}
                        tags={values.to}
                        helperText={errors.to}
                      />
                    </Grid>

                    <Grid item xs={xsMedia ? 12 : 1.5}>
                      <Typography
                        variant='h6'
                        textAlign={xsMedia ? "start" : "end"}
                      >
                        {t("email_basic.cc")}
                      </Typography>
                    </Grid>

                    <Grid item xs={xsMedia ? 12 : 10.5}>
                      <FastField
                        component={ChipsInput}
                        name='cc'
                        selectedTags={handleSelecetedTagsCc}
                        fullWidth
                        variant='outlined'
                        tags={values.cc}
                        onBlur={handleBlur}
                        helperText={errors.cc}
                      />
                    </Grid>

                    <Grid item xs={xsMedia ? 12 : 1.5}>
                      <Typography
                        variant='h6'
                        textAlign={xsMedia ? "start" : "end"}
                      >
                        {t("email_basic.subject")}
                      </Typography>
                    </Grid>

                    <Grid item xs={xsMedia ? 12 : 10.5} sx={{ mt: 1 }}>
                      <TextFieldNormal name='subject' type='text' />
                    </Grid>
                  </Grid>

                  {/* Email body */}
                  <Grid item xs={12}>
                    <TextFieldNormal
                      name='body'
                      type='text'
                      fullWidth={true}
                      multiline={true}
                      minRows={20}
                      maxRows={20}
                    />
                  </Grid>
                </Grid>
                <FormValues />
              </Form>
            );
          }}
        </Formik>
      </Container>
    </>
  );
});

export default EmailBasic;
