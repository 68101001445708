import React from "react";
import QuotePdfTableRow from "./QuotePdfTableRow";
import QuotePdfTableTotal from "./QuotePdfTableTotal";
import QuotePdfTableHeader from "./QuotePdfTableHeader";
import { View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  boxXL: {
    flexDirection: "column",
    justifyContent: "flex-start",
    width: 538,
    height: "auto",
    borderRadius: "5px",
    borderColor: "#cccccc",
    borderWidth: "1px",
  },
});

const QuotePdfTable = (props) => {
  const { values, invoicePdfLang } = props;

  return (
    <React.Fragment>
      <View
        style={{
          ...styles.boxXL,
          marginTop: "5px",
          backgroundColor: "#f5f5f5",
        }}
      >
        <View
          style={{
            ...styles.boxXL,
            backgroundColor: "#cccccc",
            borderBottomRightRadius: "0",
            borderBottomLeftRadius: "0",
            marginLeft: -1,
            marginTop: -1,
          }}
        >
          <QuotePdfTableHeader invoicePdfLang={invoicePdfLang} />
        </View>
        <QuotePdfTableRow
          items={values.iteminv_invs}
          invoicePdfLang={invoicePdfLang}
        />
      </View>
      <QuotePdfTableTotal values={values} invoicePdfLang={invoicePdfLang} />
    </React.Fragment>
  );
};

export default QuotePdfTable;
