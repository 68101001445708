import React from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
////// Mui
import { CardContent, CardHeader, Divider, useTheme } from "@mui/material";
import { Box, Card, Typography } from "@mui/material";

const Invoiced6Months = (props) => {
  const { filtered } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  let dataLabelsMonths = filtered.graphMonths.map((month) =>
    t(`month.${month}`)
  );

  const data = {
    datasets: [
      {
        data: filtered.arraySum.slice(6).reverse(),
        backgroundColor: "#FB8C00",
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        label: t("dashboard.previous_year"),
        maxBarThickness: 10,
      },
      {
        data: filtered.arraySum.slice(0, 6).reverse(),
        backgroundColor: "#3F51B5",
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        label: t("dashboard.current_year"),
        maxBarThickness: 10,
      },
    ],
    labels: dataLabelsMonths,
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    xAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary,
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0,
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider,
        },
      },
    ],
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  return (
    <React.Fragment>
      <Card {...props}>
        <CardHeader
          action={
            <Box size='small' sx={{ color: "#000000" }}>
              <Typography>{t("dashboard.last_6_months")}</Typography>
            </Box>
          }
          title={t("dashboard.latest_sales")}
          subheader={t("dashboard.in_mdl_at_nbm_exchange_rate")}
          subheaderTypographyProps={{ fontWeight: "500" }}
          sx={{ backgroundColor: "#f1f1f1", minHeight: 85 }}
        />

        <Divider />

        <CardContent>
          <Box
            sx={{
              height: 375,
              position: "relative",
            }}
          >
            <Bar data={data} options={options} />
          </Box>
        </CardContent>

        <Divider />

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            minHeight: 90,
          }}
        >
          {/* <Button
            color='primary'
            endIcon={<ArrowRightIcon fontSize='small' />}
            size='small'
          >
            <Typography variant='button'>{t("dashboard.overview")}</Typography>
          </Button> */}
        </Box>
      </Card>
    </React.Fragment>
  );
};

export default Invoiced6Months;
