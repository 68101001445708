export const initialValuesInv = {
  qn: "",
  vn: "",
  series: "",
  bill_to: null,
  date_inv: new Date(new Date(new Date()).toString().split("GMT")[0] + " UTC")
    .toISOString()
    .split("T")[0],
  date_due: null,

  currency: null,
  load_detail: "",
  load_address: "",
  unload_address: "",
  date_load: null,
  date_unload: null,
  iteminv_invs: [
    {
      item_for_item_inv: null,
      description: "",
      quantity: "",
      amount: "",
      vat: null,
    },
  ],
  article_total: "",
  entry_invs: [
    {
      id: "",
      date_load: null,
      date_unload: null,

      shipper: "",
      shipperinstructions1: "",
      shipperinstructions2: "",

      consignee: "",
      consigneeinstructions1: "",
      consigneeinstructions2: "",

      country_load: "",
      zip_load: "",
      city_load: "",

      country_unload: "",
      zip_unload: "",
      city_unload: "",

      entrydetails: [
        {
          pieces: "",
          weight: "",
          ldm: "",
          volume: "",
          dims: "",
        },
      ],

      tail_lift: "",
      palletexchange: "",
      dangerousgoods: "",
      dangerousgoods_class: "",
      temp_control: "",
      temp_control_details: "",
    },
  ],
  comment_invs: [
    {
      comment: "",
    },
  ],
  note_terms: "",
  contract_terms: "",
  mode: null,
  incoterm: null,
  status: null,
  person: null,
};
