import React from "react";
import { useField, useFormikContext } from "formik";
////// Mui
import { Checkbox, FormControl } from "@mui/material";
import { FormControlLabel, FormGroup, FormLabel } from "@mui/material";

const CheckboxSimple = ({ label, legend, ...otherProps }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(otherProps);

  const handleChange = (e) => {
    const { checked } = e.target;
    setFieldValue(otherProps.name, checked);
  };

  const configCheckbox = {
    ...field,
    ...otherProps,
    sx: { ...otherProps.sx },
    onChange: handleChange,
  };

  const configFormControl = {};

  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
  }

  return (
    <React.Fragment>
      <FormControl {...configFormControl}>
        <FormLabel component='legend'>{legend}</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox {...configCheckbox} />}
            label={label}
            labelPlacement='end'
          />
        </FormGroup>
      </FormControl>
    </React.Fragment>
  );
};

export default CheckboxSimple;
