export const initialValuesContact = {
  companyname: "",
  fiscalcode: "",
  vatcode: "",
  email1: "",
  skype: "",
  addresslegal: "",
  countrycodelegal: "",
  zipcodelegal: "",
  citylegal: "",
  addresspost: "",
  countrycodepost: "",
  zipcodepost: "",
  citypost: "",
  bank_name: "",
  comment: "",
  contact_persons: [],
  contact_bank_accounts: [],
};
