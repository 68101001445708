import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { baseURL } from "../Data/baseURL";

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 10000,
  headers: {
    Authorization: localStorage.getItem("access_token")
      ? "JWT " + localStorage.getItem("access_token")
      : null,
    "X-CSRFToken": window.CSRF_TOKEN,
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

// Obtain the fresh refresh token each time the function is called
function getRefreshToken() {
  return localStorage.getItem("refresh_token");
}

// Obtain the fresh token each time the function is called
function getAccessToken() {
  return localStorage.getItem("access_token");
}

// Use interceptor to inject the token to requests
axiosInstance.interceptors.request.use((request) => {
  request.headers["Authorization"] = `JWT ${getAccessToken()}`;
  return request;
});

const refreshAuthLogic = async (failedRequest) => {
  return await axiosInstance
    .post("/api/token/refresh/", {
      refresh: getRefreshToken(),
    })
    .then((tokenRefreshResponse) => {
      localStorage.setItem("access_token", tokenRefreshResponse.data.access);
      tokenRefreshResponse.data.refresh &&
        localStorage.setItem(
          "refresh_token",
          tokenRefreshResponse.data.refresh
        );

      axiosInstance.defaults.headers["Authorization"] =
        "JWT " + tokenRefreshResponse.data.access;

      failedRequest.response.config.headers["Authorization"] =
        "JWT " + tokenRefreshResponse.data.access;

      return Promise.resolve();
    })
    .catch(() => {});
};

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

export default axiosInstance;
