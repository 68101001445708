import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../Fetch/axios";
import { usersURL } from "../Data/baseURL";

////// To get only 1 user, request.user

export const getAccount = createAsyncThunk(
  "accountSlice/getAccount",
  async () => {
    return await axiosInstance.get(`${usersURL}1/`).then((response) => {
      if (response.data) return response.data;
    });
  }
);

export const accountSlice = createSlice({
  name: "accountSlice",
  initialState: {
    account: null,
    statusAccount: null,
    subscription_type: null,
  },
  reducers: {
    setAccount: (state, action) => {
      return { ...state, account: { ...action.payload } };
    },
  },
  extraReducers: {
    [getAccount.pending]: (state) => {
      state.status = "loading";
    },
    [getAccount.fulfilled]: (state, action) => {
      return {
        ...state,
        account: { ...action.payload },
        status: "loaded",
        subscription_type: action.payload.subscription_type || null,
      };
    },
    [getAccount.rejected]: (state) => {
      return { ...state, status: "failed" };
    },
  },
});

export const { setAccount } = accountSlice.actions;

export default accountSlice.reducer;
