export function columnsPersons() {
  return [
    {
      id: "last_name",
      label: "Last Name",
      minWidth: 40,
      align: "left",
    },
    {
      id: "first_name",
      label: "First Name",
      minWidth: 40,
      align: "left",
    },
    {
      id: "email",
      label: "Email",
      minWidth: 50,
      align: "left",
    },
    {
      id: "phone",
      label: "Phone",
      minWidth: 30,
      align: "left",
    },
    {
      id: "comment",
      label: "Comments",
      minWidth: 30,
      align: "left",
    },
  ];
}
