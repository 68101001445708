const CdpLogo = (props) => (
  <svg
    width='115'
    height='53'
    viewBox='0 0 115 53'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M27.9885 26.615C26.5478 27.9808 24.8302 29.0445 22.8354 29.8062C20.8546 30.5679 18.6867 30.9487 16.3318 30.9487C13.9908 30.9487 11.8229 30.5679 9.82817 29.8062C7.83345 29.0445 6.10192 27.9742 4.63358 26.5953C3.16524 25.2033 2.02243 23.5749 1.20515 21.7101C0.401715 19.8322 0 17.7901 0 15.5839C0 13.3645 0.401715 11.3224 1.20515 9.45764C2.02243 7.59285 3.16524 5.97101 4.63358 4.59211C6.10192 3.20009 7.83345 2.12324 9.82817 1.36156C11.8229 0.599885 13.9908 0.219047 16.3318 0.219047C18.6728 0.219047 20.8338 0.599885 22.8147 1.36156C24.7955 2.12324 26.5063 3.18039 27.9469 4.53302L24.1237 8.21664C23.1679 7.13979 22.0182 6.29932 20.6745 5.69523C19.3308 5.09114 17.8556 4.7891 16.2487 4.7891C14.7249 4.7891 13.312 5.05831 12.0099 5.59674C10.7216 6.13516 9.59961 6.89027 8.6438 7.86207C7.688 8.83386 6.93998 9.97637 6.39974 11.2896C5.87335 12.6028 5.61016 14.0343 5.61016 15.5839C5.61016 17.1335 5.87335 18.5649 6.39974 19.8782C6.93998 21.1783 7.688 22.3208 8.6438 23.3057C9.59961 24.2775 10.7216 25.0326 12.0099 25.571C13.312 26.1094 14.7249 26.3787 16.2487 26.3787C17.8556 26.3787 19.3308 26.0766 20.6745 25.4725C22.0182 24.8684 23.1679 24.028 24.1237 22.9511L27.9885 26.615Z'
      fill='#FFE492'
    />
    <path
      d='M89.4301 30.3578H83.2382L66.9895 8.86669V30.3578H61.504V0.810002H67.6959L83.9446 22.3208V0.810002H89.4301V30.3578Z'
      fill='#FFE492'
    />
    <path
      d='M105.546 30.3578H100.06V5.38005H90.5854V0.810002H115V5.38005H105.546V30.3578Z'
      fill='#FFE492'
    />
    <path
      d='M0.0997362 53V35.2713H5.98417C8.25317 35.2713 10.2147 35.6299 11.8686 36.3469C13.5226 37.0639 14.7984 38.0882 15.696 39.4199C16.5936 40.7436 17.0424 42.3155 17.0424 44.1357C17.0424 45.9479 16.5936 47.5199 15.696 48.8515C14.7984 50.1831 13.5226 51.2074 11.8686 51.9245C10.2147 52.6415 8.25317 53 5.98417 53H0.0997362ZM3.39103 50.258H6.10884C7.71293 50.258 9.07599 50.0216 10.198 49.5488C11.3284 49.0682 12.1886 48.3709 12.7787 47.4568C13.3771 46.5428 13.6763 45.4358 13.6763 44.1357C13.6763 42.8356 13.3771 41.7285 12.7787 40.8145C12.1886 39.9005 11.3284 39.2071 10.198 38.7343C9.07599 38.2537 7.71293 38.0134 6.10884 38.0134H3.39103V50.258Z'
      fill='white'
    />
    <path
      d='M18.6267 53V35.2713H30.4829V38.0134H21.918V42.6819H29.9842V45.4121H21.918V50.258H30.6075V53H18.6267Z'
      fill='white'
    />
    <path
      d='M37.7033 53V35.2713H43.8869C45.4495 35.2713 46.7917 35.5077 47.9138 35.9805C49.0441 36.4533 49.9085 37.1269 50.5069 38.0016C51.1136 38.8762 51.417 39.9202 51.417 41.1336C51.417 42.3392 51.1136 43.3832 50.5069 44.2657C49.9085 45.1482 49.0483 45.8297 47.9262 46.3104C46.8042 46.7832 45.4661 47.0195 43.9119 47.0195H40.9946V53H37.7033ZM40.9946 44.2893H43.5752C45.0131 44.2893 46.1185 44.0135 46.8915 43.462C47.6644 42.9026 48.0509 42.1264 48.0509 41.1336C48.0509 40.1408 47.6644 39.3726 46.8915 38.8289C46.1185 38.2852 45.0131 38.0134 43.5752 38.0134H40.9946V44.2893Z'
      fill='white'
    />
    <path
      d='M65.1567 53H53.1883V35.2713H56.4796V50.258H65.1567V53Z'
      fill='white'
    />
    <path
      d='M68.636 53H65.1826L72.7127 35.2713H76.2159L83.7335 53H80.2802L78.5722 48.8633H70.3439L68.636 53ZM71.4286 46.1331H77.4876L74.4581 38.7934L71.4286 46.1331Z'
      fill='white'
    />
    <path
      d='M91.1399 53H87.8487V38.0134H82.1637V35.2713H96.8124V38.0134H91.1399V53Z'
      fill='white'
    />
    <path
      d='M98.4591 53H95.0057L102.536 35.2713H106.039L113.557 53H110.103L108.395 48.8633H100.167L98.4591 53ZM101.252 46.1331H107.311L104.281 38.7934L101.252 46.1331Z'
      fill='white'
    />
    <path
      d='M60.1893 15.1789C60.1893 6.79715 52.886 0 43.8801 0C34.8743 0 27.5709 6.79715 27.5709 15.1789C27.5709 23.5606 34.8743 30.3578 43.8801 30.3578C52.886 30.3578 60.1893 23.5606 60.1893 15.1789Z'
      fill='#FFE492'
    />
    <path
      d='M52.0643 22.7958C56.587 18.5866 56.587 11.765 52.0643 7.56191C47.5416 3.35269 40.2186 3.35269 35.696 7.56191C31.1733 11.7711 31.1733 18.5866 35.696 22.7958C40.2186 27.0051 47.5416 27.0051 52.0643 22.7958Z'
      fill='#043873'
    />
    <path
      d='M50.9731 21.7802C54.891 18.1339 54.891 12.2239 50.9731 8.5775C47.0552 4.93115 40.7051 4.93115 36.7872 8.5775C32.8693 12.2239 32.8693 18.1339 36.7872 21.7802C40.7051 25.4266 47.0552 25.4266 50.9731 21.7802Z'
      fill='#F4DE7F'
    />
    <path
      d='M50.9731 21.7802C54.891 18.1339 54.891 12.2239 50.9731 8.5775L36.7872 21.7802C40.7051 25.4266 47.0552 25.4266 50.9731 21.7802Z'
      fill='#043873'
    />
    <path
      d='M52.0643 22.7958C56.587 18.5866 56.587 11.765 52.0643 7.56191L35.696 22.7958C40.2186 27.0051 47.5416 27.0051 52.0643 22.7958Z'
      fill='#043873'
    />
    <path
      d='M47.7257 11.9547C47.7257 12.6399 46.8909 13.1293 46.8383 13.0682C46.2138 12.3952 45.1488 11.8935 44.0642 11.8935C42.9664 11.8935 42.2959 12.3523 42.2959 13.0376C42.2959 13.8329 43.1965 14.047 44.4586 14.304C45.964 14.6099 47.9492 15.1728 47.9492 17.1734C47.9492 18.7151 46.7988 19.7796 44.8859 20.055V20.6484C44.8859 21.352 44.5901 21.6885 43.9327 21.6885H43.8473C43.157 21.6885 42.9138 21.3703 42.9138 20.6484V20.0856C40.9286 19.8898 39.7979 19.0455 39.7979 18.1951C39.7979 17.5404 40.5867 17.0632 40.6525 17.1244C41.277 17.828 42.4865 18.4031 43.7684 18.4031C44.9319 18.4031 45.6024 17.9442 45.6024 17.259C45.6024 16.4208 44.5835 16.1884 43.3871 15.9314C41.9475 15.6438 40.0411 15.1055 40.0411 13.0926C40.0411 11.6304 41.1586 10.5781 42.927 10.2722V9.70934C42.927 9.00577 43.203 8.66927 43.8604 8.66927H43.959C44.6295 8.66927 44.8925 8.98741 44.8925 9.70934V10.2294C46.7265 10.4129 47.7257 11.1716 47.7257 11.9547Z'
      fill='#6A665D'
    />
    <path
      d='M45.609 17.259C45.609 16.4208 44.5901 16.1884 43.3937 15.9314C43.3082 15.913 43.2162 15.8947 43.1242 15.8763L41.2375 17.6322C41.9015 18.0849 42.8152 18.4092 43.7684 18.4092C44.9385 18.4031 45.609 17.9504 45.609 17.259Z'
      fill='#F2D55A'
    />
    <path
      d='M50.9731 8.5775L47.6731 11.6488C47.706 11.7467 47.7257 11.8507 47.7257 11.9547C47.7257 12.6399 46.8909 13.1293 46.8383 13.0682C46.7331 12.958 46.6148 12.8479 46.4899 12.75L44.7544 14.3652C46.2138 14.6894 47.9492 15.3012 47.9492 17.1734C47.9492 18.7151 46.7988 19.7796 44.8859 20.055V20.6484C44.8859 21.352 44.5901 21.6885 43.9327 21.6885H43.8473C43.157 21.6885 42.9138 21.3703 42.9138 20.6484V20.0856C41.4019 19.9326 40.3895 19.4126 39.9885 18.7946L36.7806 21.7802C40.6985 25.4266 47.0486 25.4266 50.9665 21.7802C54.891 18.1339 54.891 12.2239 50.9731 8.5775Z'
      fill='#F2D55A'
    />
    <path
      d='M40.659 17.1244C40.5933 17.0632 39.8045 17.5343 39.8045 18.1951C39.8045 18.397 39.8702 18.605 39.9951 18.7946L41.2441 17.6322C41.014 17.4731 40.8168 17.3018 40.659 17.1244Z'
      fill='#948F85'
    />
    <path
      d='M44.4652 14.304C43.203 14.047 42.3025 13.8329 42.3025 13.0376C42.3025 12.3523 42.973 11.8935 44.0708 11.8935C44.9714 11.8935 45.8654 12.2422 46.4964 12.75L47.6731 11.6549C47.4496 10.988 46.4899 10.3946 44.9056 10.2355V9.71546C44.9056 8.99965 44.6427 8.67539 43.9722 8.67539H43.8736C43.2162 8.67539 42.9401 9.01188 42.9401 9.71546V10.2783C41.1718 10.5842 40.0543 11.6365 40.0543 13.0987C40.0543 14.9892 41.7306 15.5766 43.1307 15.8825L44.761 14.3652C44.6558 14.3407 44.5572 14.3224 44.4652 14.304Z'
      fill='#948F85'
    />
    <path
      d='M46.8383 13.0682C46.8843 13.1293 47.7257 12.6399 47.7257 11.9547C47.7257 11.8507 47.706 11.7528 47.6731 11.6488L46.4964 12.7439C46.6213 12.8479 46.7331 12.9519 46.8383 13.0682Z'
      fill='#E5AA17'
    />
  </svg>
);

export default CdpLogo;
