////// In order to a access env viriables with dotenv package, those must be prefixed with REACT_APP_
export const baseURL = process.env.REACT_APP_BASE_URL; // change to https: and delete port 8000 for docker development
export const baseWSURL = process.env.REACT_APP_BASE_WSURL; // change to wss: and delete port 8000 for docker development

export const appURL = "/apps";

export const authURL = "/auth/users/";

export const bankAccountURL = "/app/bank-accounts/";

export const companyURL = "/app/companies/";

export const contactURL = "/app/clients/";

export const contractTermURL = "/app/terms/";

export const currencyURL = "/app/currencies/";

export const countryURL = "/app/countries/";

export const emailInvURL = "/app/invemail/";

export const exRatesURL = "/app/exchange-rates/";

export const imageURL = "/app/image/";

export const invURL = "/app/invs/";

export const incotermURL = "/app/incoterms/"; // Incoterms types

export const itemForItemInvtURL = "/app/items-for-item-inv/";

export const logInURL = "/api/token/";

export const modeURL = "/app/modes/"; // Transport mode types

export const noteURL = "/app/notes/";

export const notificationURL = "/app/notifications/";

export const notificationReadURL = "/app/notification-read/";

export const statusURL = "/app/statuses/"; // Statuses types

export const termsOfPaymentURL = "/app/terms-of-payment/";

export const userCreateURL = "/app/users-create/"; // for user create by manager only

export const usersURL = "/app/users/";
