import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import axiosInstance from "../Fetch/axios";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
////// Mui
import { styled } from "@mui/material/styles";
import { Avatar, Box, Typography, Divider, IconButton } from "@mui/material";
import { Menu, MenuItem, ListItemIcon } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import { Logout, Settings } from "@mui/icons-material";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import StarIcon from "@mui/icons-material/Star";
////// Store
import { setCompany, setStatusCompany } from "../Store/companySlice";
import { setLogo, setStamp } from "../Store/avatarSlice";
import { setAuthLogIn, setStatusLogIn } from "../Store/authLogInSlice";
import { getAccount } from "../Store/accountSlice";
import { setAvatar } from "../Store/avatarSlice";
////// Controls & Components
import TooltipBasic from "../Controls/Inputs/TooltipBasic";
import { baseURL } from "../Data/baseURL";

const TypographyStyled = styled(Typography)(() => ({
  "&.MuiTypography-root": { fontWeight: "500" },
}));

export default function AccountMenu() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const xsMediaOnly = useMediaQuery(theme.breakpoints.only("xs"));
  const { authLogIn, statusLogIn } = useSelector(
    (state) => state.authLogInSlice
  );
  const { account, statusAccount } = useSelector((state) => state.accountSlice);
  const { company } = useSelector((state) => state.companySlice);
  const { avatar, logo, statusLogo, statusStamp } = useSelector(
    (state) => state.avatarSlice
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  ////// GET account
  useEffect(() => {
    if (
      authLogIn &&
      !account &&
      statusAccount === null &&
      statusLogIn === "loaded"
    ) {
      dispatch(getAccount());
    }
  }, [dispatch, account, statusAccount, statusLogIn, authLogIn]);

  ///// GET avatar
  useEffect(() => {
    const getAvatar = (imageid) => {
      axiosInstance({
        method: "GET",
        url: "/app/image/",
        data: JSON.stringify({ unique_field: imageid }),
        params: { unique_field: imageid },
      })
        .then((response) => {
          if (response.data && response.data) {
            dispatch(setAvatar(response.data.s3_url));
          }
        })
        .catch(() => {}, []);
    };

    if (account && account.image && avatar === null) {
      getAvatar(account.image);
    }
  }, [account, avatar, dispatch]);

  const getLogo = useCallback(
    (imageid) => {
      axiosInstance({
        method: "GET",
        url: "/app/image/",
        data: JSON.stringify({ unique_field: imageid }),
        params: { unique_field: imageid },
      })
        .then((response) => {
          if (response.data && response.data) {
            dispatch(setLogo(response.data.s3_url));
          }
        })
        .catch(() => {});
    },
    [dispatch]
  );

  const getStamp = useCallback(
    (imageid) => {
      axiosInstance({
        method: "GET",
        url: "/app/image/",
        data: JSON.stringify({ unique_field: imageid }),
        params: { unique_field: imageid },
      })
        .then((response) => {
          if (response.data && response.data) {
            dispatch(setStamp(response.data.s3_url));
          }
        })
        .catch(() => {});
    },
    [dispatch]
  );

  ////// GET company, logo & stamp
  useEffect(() => {
    if (account && account.company && !company) {
      dispatch(setCompany(account.company));
      dispatch(setStatusCompany("loaded"));
    }

    if (account && !account.company) {
      dispatch(setStatusCompany("loaded"));
    }

    if (company && company.logo && statusLogo === null) {
      getLogo(company.logo);
    }

    if (company && company.stamp && statusStamp === null) {
      getStamp(company.stamp);
    }
  }, [account, company, dispatch, getLogo, getStamp, statusLogo, statusStamp]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleLogout() {
    axios.post(baseURL + "/app/logout/blacklist/", {
      refresh_token: localStorage.getItem("refresh_token"),
    });
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    axiosInstance.defaults.headers["Authorization"] = null;
    dispatch(setAuthLogIn(false));
    dispatch(setStatusLogIn(null));
    navigate("/");
    toast.success(() => t("toast.logged_out_successfully"), {
      style: { marginTop: xsMediaOnly ? 0 : 50 },
    });
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <TooltipBasic
          title={t("general.account_settings")}
          placement='bottom-start'
        >
          <IconButton onClick={handleClick} size='small' sx={{ ml: 2 }}>
            <Avatar sx={{ width: 36, height: 35 }} src={avatar && avatar}>
              <Typography sx={{ fontSize: 16 }}>
                {account &&
                  (account.first_name ? account.first_name[0] : "A") +
                    (account.last_name ? account.last_name[0] : "A")}
              </Typography>
            </Avatar>
          </IconButton>
        </TooltipBasic>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 2,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 2,
            "& .MuiAvatar-root": {
              width: 45,
              height: 45,
              ml: -0.5,
              mr: 1,
              mb: 0,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
            minWidth: 320,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem component={NavLink} to='/apps/company'>
          <Avatar src={logo} variant='square' />
          <TypographyStyled>{t("general.company_profile")}</TypographyStyled>
        </MenuItem>

        <MenuItem component={NavLink} to='/apps/account'>
          <Avatar src={avatar} />
          <TypographyStyled>{t("general.account_profile")}</TypographyStyled>
        </MenuItem>

        <MenuItem component={NavLink} to='/apps/subscription'>
          <Avatar sx={{ bgcolor: "black" }}>
            <StarIcon />
          </Avatar>
          <TypographyStyled>{t("general.subscription")}</TypographyStyled>
        </MenuItem>

        <Divider />

        <MenuItem component={NavLink} to='/apps/users'>
          <ListItemIcon>
            <PersonAddAltIcon />
          </ListItemIcon>
          <TypographyStyled>{t("general.user_management")}</TypographyStyled>
        </MenuItem>

        <MenuItem component={NavLink} to='/apps/settings'>
          <ListItemIcon>
            <Settings fontSize='small' />
          </ListItemIcon>
          <TypographyStyled>{t("settings.settings")}</TypographyStyled>
        </MenuItem>

        <MenuItem onClick={() => handleLogout()}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          <TypographyStyled>{t("general.logout")}</TypographyStyled>
        </MenuItem>
      </Menu>
    </>
  );
}
