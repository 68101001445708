import React from "react";
import { Button } from "@mui/material";

const ButtonAction = ({ children, fullWidth, ...otherProps }) => {
  const configButton = {
    fullWidth: fullWidth,
    ...otherProps,
    sx: { ...otherProps.sx },
  };

  return (
    <React.Fragment>
      <Button {...configButton}>{children}</Button>
    </React.Fragment>
  );
};

export default ButtonAction;
