import React, { useEffect } from "react";
import { BlobProvider } from "@react-pdf/renderer";
import { usePDF } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
////// Mui
import { Link, Stack, Typography } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
////// Controls & Components
import InvPdfDocument from "./InvPdfDocument";
// import InvPdfDocument1 from "./InvTemplate1/InvPdfDocument1";
// import InvPdfDocumentEn1 from "./InvPdfEn/InvPdfEnTemplate1/InvPdfDocumentEn1";

//// Register fonts / but call them in advance from Inv.js
// Font.register({
//   family: "Oswald",
//   src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
// });
// Font.register({
//   family: "Roboto",
//   fonts: [
//     {
//       src: `/Roboto-Regular.ttf`,
//     },
//     {
//       src: `/Roboto-Bold.ttf`,
//       fontWeight: "bold",
//     },
//     // {
//     //   src: `/Roboto-Normal.ttf`,
//     //   fontWeight: "normal",
//     //   fontStyle: "normal",
//     // },
//     // {
//     //   src: `/Roboto-BoldNormal.ttf`,
//     //   fontWeight: "bold",
//     //   fontStyle: "normal",
//     // },
//   ],
// });

const InvPdf = (props) => {
  const {
    account,
    company,
    values,
    bankAccounts,
    logo,
    stamp,
    invoicePdfLang,
    setShowPdf,
    showPdf,
    checked,
    freeAppMssg,
  } = props;

  const { t } = useTranslation();
  const [instance] = usePDF({
    document: InvPdfDocument(
      account,
      company,
      values,
      bankAccounts,
      logo,
      stamp,
      invoicePdfLang,
      checked,
      freeAppMssg
    ),
  });

  useEffect(() => {
    if (instance.blob && setShowPdf) {
      setShowPdf(false);
    }
  }, [setShowPdf, instance.blob]);

  function downloadLink(blob) {
    let element = document.createElement("a");
    element.download = `${t("inv.invoice_hash")} ${values.vn || ""}`;
    element.setAttribute("href", URL.createObjectURL(blob));
    element.setAttribute("target", "_blank");
    element.style.display = "None";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  if (instance.loading)
    return (
      <Typography variant='button' color='error'>
        {t("invpdf.loading_3dots")}{" "}
      </Typography>
    );

  // if (instance.error) return <div>Something went wrong, please try again.</div>;

  return (
    <>
      <BlobProvider
        document={InvPdfDocument(
          account,
          company,
          values,
          bankAccounts,
          logo,
          stamp,
          invoicePdfLang,
          checked,
          freeAppMssg
        )}
      >
        {() => {
          return null;
        }}
      </BlobProvider>

      {showPdf && instance.blob && downloadLink(instance.blob)}
    </>
  );
};

export const InvPdfAttachment = (props) => {
  const {
    account,
    company,
    values,
    bankAccounts,
    setFile,
    logo,
    stamp,
    invoicePdfLang,
    setShowPdf,
    checked,
    freeAppMssg,
  } = props;

  const { t } = useTranslation();

  const [instance] = usePDF({
    document: InvPdfDocument(
      account,
      company,
      values,
      bankAccounts,
      logo,
      stamp,
      invoicePdfLang,
      checked,
      freeAppMssg
    ),
  });

  useEffect(() => {
    setFile && instance.blob && setFile(instance.blob);
  }, [instance.blob, setFile]);

  useEffect(() => {
    if (instance.blob && setShowPdf) {
      setShowPdf(false);
    }
  }, [setShowPdf, instance.blob]);

  if (instance.loading)
    return (
      <Typography variant='button' color='error'>
        {t("invpdf.loading_3dots")}{" "}
      </Typography>
    );

  // if (instance.error) return <div>Something went wrong, please try again.</div>;

  return (
    <>
      <Link
        href={instance.url}
        download={`Invoice # ${values && values.vn && (values.vn || "")}`}
        sx={{ textDecoration: "none", mr: 2, color: "#F40F02", width: "100%" }}
      >
        <Stack direction='row' sx={{ width: "100%", justifyContent: "center" }}>
          <PictureAsPdfIcon color='#F40F02' />
          {invoicePdfLang === "RO/RU" && (
            <Typography variant='button' sx={{ pl: 1 }}>{`${t(
              "inv.invoice_hash"
            )} ${values && (values.vn || "")}`}</Typography>
          )}
          {invoicePdfLang === "EN" && (
            <Typography variant='button' sx={{ pl: 1 }}>
              Invoice # {values && (values.vn || "")}
            </Typography>
          )}
        </Stack>
      </Link>
      {/* {showPdf && instance.blob && downloadLink(instance.blob)} */}
    </>
  );
};

export const InvPdfBlob = (props) => {
  const {
    account,
    company,
    values,
    bankAccounts,
    setFile,
    setShowPdf,
    logo,
    stamp,
    invoicePdfLang,
    checked,
    freeAppMssg,
  } = props;

  const [blob, setBlob] = React.useState(null);

  const handleBlob = (blob) => {
    setBlob(blob);
  };

  if (blob) {
    setShowPdf(false);
    setFile(blob);
  }

  return (
    <>
      <BlobProvider
        document={InvPdfDocument(
          account,
          company,
          values,
          bankAccounts,
          logo,
          stamp,
          invoicePdfLang,
          checked,
          freeAppMssg
        )}
      >
        {({ blob }) => {
          handleBlob(blob);
          return null;
        }}
      </BlobProvider>
    </>
  );
};

export default InvPdf;
