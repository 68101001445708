const CheckBoxIcon = (props) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.0059 9.67688V10.5051C19.0047 12.4466 18.3761 14.3356 17.2137 15.8905C16.0512 17.4455 14.4173 18.583 12.5556 19.1335C10.6938 19.6839 8.70404 19.6178 6.88294 18.945C5.06183 18.2722 3.507 17.0288 2.45034 15.4001C1.39367 13.7714 0.891777 11.8448 1.01952 9.90764C1.14725 7.97043 1.89778 6.12642 3.15915 4.65061C4.42053 3.1748 6.12517 2.14628 8.01885 1.71843C9.91253 1.29057 11.8938 1.48632 13.6671 2.27648"
      stroke="#FFE492"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.0059 3.3027L10.0029 12.3146L7.30206 9.61376"
      stroke="#FFE492"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckBoxIcon;
