import React, { useEffect, forwardRef } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../Fetch/axios";
import { toast } from "react-toastify";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
////// Mui
import { useTheme, useMediaQuery } from "@mui/material";
import { Grid, Container, Typography } from "@mui/material";
////// Store
import { setFormSimpleTouched } from "../../Store/formTouchedSlice";
import { setLoadingStatus } from "../../Store/loadingStatusSlice";
import { setPerson } from "../../Store/personSlice";
import { addOrEditContact } from "../../Store/contactSlice";
import { setPopup } from "../../Store/popupSlice";
////// Controls & Components
import ButtonsContainer from "../../Controls/Buttons/ButtonsContainer";
import { contactURL } from "../../Data/baseURL";
import { initialValuesPerson } from "../Tables/InitialValues/initialValuesPerson";
import TextFieldNormal from "../../Controls/Inputs/TextFieldNormal";

const validationSchema = Yup.object({
  first_name: Yup.string(() => i18n.t("yup.max_80_characters")).required(() =>
    i18n.t("yup.required")
  ),
  last_name: Yup.string(() => i18n.t("yup.max_80_characters")),
  email: Yup.string()
    .email(() => i18n.t("yup.invalid_email"))
    .nullable(),
  phone: Yup.string(() => i18n.t("yup.max_30_characters")).nullable(),
  comment: Yup.string(() => i18n.t("yup.max_250_characters")).nullable(),
});

const Person = forwardRef((props, ref) => {
  const { addOrEdit, deleteAutoField, bill_to } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const lgMediaDown = useMediaQuery(theme.breakpoints.down("lg"));
  const { itemForEdit } = useSelector((state) => state.itemForEditSlice);

  useEffect(() => {
    if (itemForEdit != null) {
      dispatch(setPerson({ ...itemForEdit }));
    }
  }, [itemForEdit, dispatch]);

  React.useImperativeHandle(ref, () => ({
    handleSubmit: () => handleSubmit(),
  }));

  const handleSubmit = (values) => {
    dispatch(setLoadingStatus(true));

    let newContactPersons = [...bill_to.contact_persons];
    const i = newContactPersons.findIndex((person) => person.id === values.id);
    if (i > -1) newContactPersons[i] = values;
    else newContactPersons.push(values);

    axiosInstance({
      method: bill_to.id ? "PATCH" : "POST",
      url: bill_to.id ? contactURL + bill_to.id + "/" : contactURL,
      data: { ...bill_to, contact_persons: newContactPersons },
    })
      .then((response) => {
        if (response.data) {
          batch(() => {
            dispatch(setLoadingStatus(false));
            dispatch(setFormSimpleTouched(false));
            dispatch(addOrEditContact(response.data));
          });
          addOrEdit(response.data);
          toast.success(
            `${t("toast.client_capital")} ${response.data.companyname} ${t(
              "toast.successfully"
            )} ${
              response.action === "create"
                ? t("toast.created")
                : t("toast.updated")
            }`
          );
        }
      })
      .catch((error) => {
        dispatch(setLoadingStatus(false));
        if (error && error.promise && error.response.status === 402) {
          toast.error(
            () => (
              <div>
                <Typography>
                  {t("toast.need_to_upgrade_in_order_to_register_new_clients")}
                </Typography>
              </div>
            ),
            {
              autoClose: 4000,
            }
          );
        } else if (error && error.response && error.response.status === 404) {
          toast.error(
            () => (
              <div>
                <Typography>{t("toast.client")}</Typography>
                <Typography>{t("toast.could_not_be_updated")}</Typography>
                <Typography>{t("toast.company_could_not_be_found")}</Typography>
              </div>
            ),
            { pauseOnHover: true }
          );
        } else {
          toast.error(
            () => (
              <div>
                <Typography>
                  {t("toast.record_could_not_be_created_or_updated")}
                </Typography>
                <Typography>{t("toast.record_should_be_unique")}</Typography>
              </div>
            ),
            {
              pauseOnHover: true,
            }
          );
        }
      }, []);
  };

  const handleDelete = (id) => {
    let oldContactPersons = [...bill_to.contact_persons];
    let newContactPersons = oldContactPersons.filter((item) => item.id !== id);

    axiosInstance({
      method: "PATCH",
      url: bill_to.id ? contactURL + bill_to.id + "/" : contactURL,
      data: { ...bill_to, contact_persons: newContactPersons },
    })
      .then((response) => {
        batch(() => {
          dispatch(setPopup(false));
          dispatch(setFormSimpleTouched(false));
          dispatch(addOrEditContact(response.data));
        });
        deleteAutoField(id);
        response.status === 204 && toast.success(`Person entry deleted`);
      })
      .catch(() => {}, []);
  };

  return (
    <Container maxWidth='md' disableGutters={lgMediaDown ? true : false}>
      <Formik
        enableReinitialize
        initialValues={itemForEdit || initialValuesPerson}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form autoComplete='off'>
            <Grid container item spacing={2} sx={{ mt: 3, mb: 5 }}>
              <Grid container item md={6} xs={12} spacing={1}>
                <Grid item xs={12}>
                  <TextFieldNormal
                    name='first_name'
                    label={t("person.first_name")}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextFieldNormal
                    name='last_name'
                    label={t("person.last_name")}
                  />
                </Grid>
              </Grid>

              <Grid container item md={6} xs={12} spacing={1}>
                <Grid item xs={12}>
                  <TextFieldNormal name='email' label={t("general.email")} />
                </Grid>

                <Grid item xs={12}>
                  <TextFieldNormal name='phone' label={t("general.phone")} />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <TextFieldNormal
                  name='comment'
                  label={t("person.comments")}
                  multiline
                  minRows={3}
                  maxRows={3}
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>
                <ButtonsContainer
                  values={values}
                  handleDelete={() => handleDelete(values.id)}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
});

export default Person;
