import React, { useEffect } from "react";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { sum } from "lodash-es";
import { useTranslation } from "react-i18next";
////// Mui
import { Box, Card, CardContent, Stack, useMediaQuery } from "@mui/material";
import { CardHeader, Divider, Typography, useTheme } from "@mui/material";
////// Store
import { getInvs } from "../../Store/invSlice";
////// Controls & components

const colorArray = [
  "#3F51B5",
  "#FB8C00",
  "#e53935",
  "#4caf50",
  "#834bff",
  "#00bcd4",
];

export const Invoiced30Days = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const small = useMediaQuery("(min-width:600px)");
  const [invoicedAmount, setInvoicedAmount] = React.useState({
    amountAndCurrency: [],
    uniqueCurrencies: [],
    amounts: [],
    totalAmounts: 0,
  });
  const { invs, statusInvs } = useSelector((state) => state.invSlice);
  const { invsUnfiltered } = useSelector((state) => state.searchInvSlice);
  const { currencies } = useSelector((state) => state.currencySlice);
  const { exRates } = useSelector((state) => state.exRatesSlice);

  ////// GET invs list & set invsUnfiltered
  useEffect(() => {
    if (statusInvs !== "loaded" && statusInvs !== "loading") {
      dispatch(getInvs());
    }
  }, [dispatch, invs.length, statusInvs]);

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  const data = {
    datasets: [
      {
        data: exRates ? invoicedAmount.amounts : null,
        backgroundColor: colorArray,
        borderWidth: 8,
        borderColor: "#FFFFFF",
        hoverBorderColor: "#FFFFFF",
      },
    ],
    labels: invoicedAmount.uniqueCurrencies.map((curr) => ` MDL (${curr})`),
  };

  const graphTotals = invoicedAmount.uniqueCurrencies.map((curr, i) => ({
    title: curr,
    icon:
      (currencies.length > 0 &&
        curr &&
        currencies.find((currency) => currency.currencycode === curr)
          .currency_symbol) ||
      "",
    color: colorArray[i],
    value:
      invoicedAmount.totalAmounts !== 0
        ? (
            (invoicedAmount.amounts[i] / invoicedAmount.totalAmounts) *
            100
          ).toFixed(1)
        : 0,
  }));

  ////// Calculate sales by amount & currency
  useEffect(() => {
    let filteredInvs = invsUnfiltered.filter((item) => {
      let dateInv = new Date(item.date_inv).getTime();
      let d = new Date();
      let datePast = d.setDate(d.getDate() - 30);
      return item.quote === false && dateInv >= datePast;
    });

    let generalUniqueArray = filteredInvs.map(
      (inv) =>
        inv.iteminv_invs &&
        inv.iteminv_invs
          .map((item) => {
            let a = item.amount;
            let b = item.quantity || 1;
            let c =
              item.item !== null &&
              a *
                b *
                (item.item_for_item_inv && item.item_for_item_inv.vat / 100);
            return a * b + c;
          })
          .reduce((prev, cur, i) => {
            return {
              val: (i === 0 ? prev : prev.val) + cur,
              curr: inv.currency && inv.currency.currencycode,
            };
          }, 0)
    );

    let uniqueCurrencies = generalUniqueArray
      .filter(
        (elem, index) =>
          generalUniqueArray.findIndex((obj) => obj.curr === elem.curr) ===
          index
      )
      .map((elem) => elem.curr);

    let amounts = uniqueCurrencies.map((uniqueCurr) => {
      return generalUniqueArray
        .filter((item) => {
          return item.curr === uniqueCurr;
        })
        .reduce((prev, cur) => {
          return (
            prev +
            (cur &&
              cur.val &&
              uniqueCurr &&
              cur.val *
                (uniqueCurr === "MDL"
                  ? 1
                  : exRates && exRates.Valute
                  ? Number(
                      exRates.Valute.find((obj) => obj.CharCode === uniqueCurr)
                        .Value
                    )
                  : 0))
          );
        }, 0);
    });

    setInvoicedAmount((curr) => ({
      ...curr,
      amountAndCurrency: generalUniqueArray,
      uniqueCurrencies: uniqueCurrencies,
      amounts: amounts,
      totalAmounts: sum(amounts),
    }));
  }, [invsUnfiltered, exRates]);

  return (
    <Card>
      <CardHeader
        action={
          <Box size='small' sx={{ color: "#000000" }}>
            <Typography>{t("dashboard.last_30_days")}</Typography>
          </Box>
        }
        title={t("dashboard.latest_sales")}
        subheader={t("dashboard.in_mdl_at_nbm_exchange_rate")}
        subheaderTypographyProps={{ fontWeight: "500" }}
        sx={{ backgroundColor: "#f1f1f1", minHeight: 85 }}
      />

      <Divider />

      <CardContent>
        <Box
          sx={{
            height: 375,
            position: "relative",
          }}
        >
          {invoicedAmount.uniqueCurrencies.length > 0 ? (
            <>
              <Doughnut data={data} options={options} />
            </>
          ) : (
            t("dashboard.no_data_available_for_last_30_days")
          )}
        </Box>
      </CardContent>

      <Divider />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",

          minHeight: 90,
        }}
      >
        <Stack direction={small ? "row" : "column"}>
          {graphTotals.map(({ color, icon, title, value }) => (
            <Box
              key={title}
              sx={{
                p: 1,
                textAlign: "center",
              }}
            >
              <Typography color='textPrimary' variant='body2'>
                {icon || "L"}
              </Typography>
              <Typography color='textPrimary' variant='body2'>
                {title || "MDL"}
              </Typography>
              <Typography style={{ color }} variant='h5'>
                {value || 0.0}%
              </Typography>
            </Box>
          ))}
        </Stack>
      </Box>
    </Card>
  );
};

export default Invoiced30Days;
