import { createSlice } from "@reduxjs/toolkit";

export const searchQuoteSlice = createSlice({
  name: "searchQuoteSlice",
  initialState: {
    quotesFiltered: [], // not used yet
    searchQuote: null,
  },
  reducers: {
    setInvsUnfiltered: (state, action) => {
      return { ...state, invsUnfiltered: [...action.payload] };
    },
    setQuotesFiltered: (state, action) => {
      return { ...state, quotesFiltered: { ...action.payload } };
    },
    setSearchQuote: (state, action) => {
      return { ...state, searchQuote: { ...action.payload } };
    },
    setAllowedToAddInv: (state, action) => {
      return { ...state, allowedToAddInv: action.payload };
    },
  },
});

export const {
  setInvsUnfiltered,
  setInvsFiltered,
  setAllowedToAddInv,
  setSearchQuote,
  setQuotesFiltered,
} = searchQuoteSlice.actions;

export default searchQuoteSlice.reducer;
