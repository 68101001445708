const CargoIcon = (props) => (
  <svg
    viewBox='0 0 1000 1000'
    xmlSpace='preserve'
    width='24px'
    height='24px'
    {...props}
  >
    <path d='m217.3 85.4 62.6-62.6c4-4 7.5-6.9 10.4-8.7 2.9-1.8 5.8-2.9 8.9-3.4 3-.5 7.3-.8 12.8-.8h244.6l-75.4 75.4H217.3zm603.1 75.4v-38.4c0-12.6-2.8-21.9-8.5-27.9s-15.4-9-29.2-9H556.5l75.4-75.4h226.2c14.1 0 23.9 2.8 29.4 8.5s8.3 15.4 8.3 29.2v37.7l-75.4 75.3zm-716.2 113v-49c0-9.5.9-17.3 2.8-23.2 1.9-5.9 6.2-12.3 13-19l59.6-59.6h263.8c-27.1 27.6-46.6 47.1-58.4 58.4-4.3 4-7.6 7.7-10 10.9-2.4 3.3-4.1 6.8-5.1 10.7-1 3.9-1.6 7-1.7 9.2-.1 2.3-.2 6.3-.2 12.1v49.4H104.2zm339.3 0v-49.4c0-7 .2-12.1.6-15.1.4-3 1.8-7 4.3-12.1 2.5-5 6.5-10.3 12.1-15.8l58.4-58.4H762c13.8 0 20.7 6.7 20.7 20v55.4l-75.4 75.4H443.5zm376.9 75.4V198.4l75.4-75.4v150.8l-75.4 75.4zM443.5 462.3V311.5h263.8l75.4-75.4v150.8l-75.4 75.4H443.5zm-339.3 0V311.5H368v150.8H104.2zm716.2 75.4V386.9l75.4-75.4v150.8l-75.4 75.4zM443.5 650.8V500h263.8l75.4-75.4v150.8l-75.4 75.4H443.5zm-339.3 0V500H368v150.8H104.2zm716.2 37.7V575.4l75.4-75.4v113.1l-75.4 75.4zM28.8 990V839.2H745L971.2 613v150.8l-37.7 37.7v-37.7l-37.7 37.7v37.7l-75.4 75.4v-37.7l-37.7 37.7v37.7L745 990H594.2v-37.7h-113V990H330.4v-37.7H217.3V990H28.8zm414.7-188.5V688.4h263.8l75.4-75.4v113.1l-75.4 75.4H443.5zm-339.3 0V688.4H368v113.1H104.2z' />
  </svg>
);

export default CargoIcon;
