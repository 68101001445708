import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../Fetch/axios";
import { companyURL } from "../Data/baseURL";

export const getCompany = createAsyncThunk(
  "companySlice/getCompany",
  async () => {
    return await axiosInstance.get(companyURL).then((response) => {
      if (response.data) return response.data;
    });
  }
);

export const companySlice = createSlice({
  name: "companySlice",
  initialState: {
    company: null,
    statusCompany: null,
  },
  reducers: {
    setCompany: (state, action) => {
      return { ...state, company: { ...action.payload } };
    },
    setStatusCompany: (state, action) => {
      state.statusCompany = action.payload;
      return state;
    },
  },
  extraReducers: {
    [getCompany.pending]: (state) => {
      state.statusCompany = "loading";
    },
    [getCompany.fulfilled]: (state, action) => {
      return {
        ...state,
        company: { ...action.payload },
        statusCompany: "loaded",
      };
    },
    [getCompany.rejected]: (state) => {
      state.statusCompany = "failed";
    },
  },
});

export const { setCompany, setStatusCompany } = companySlice.actions;

export default companySlice.reducer;
