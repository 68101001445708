import React from "react";
import { FastField } from "formik";
import { useTranslation } from "react-i18next";
////// Mui
import { Grid } from "@mui/material";
////// Controls & Components
import DesktopDatePickerBasic from "../../Controls/Inputs/DesktopDatePickerBasic";
import TextFieldNormal from "../../Controls/Inputs/TextFieldNormal";

const LoadDetails = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <FastField
          as={TextFieldNormal}
          name='load_detail'
          label={t("inv.load_details")}
        />
      </Grid>

      <Grid item xs={12} md={10}>
        <FastField
          as={TextFieldNormal}
          name='load_address'
          label={t("inv.loading_address")}
        />
      </Grid>

      <Grid item xs={12} md={2}>
        <FastField
          as={DesktopDatePickerBasic}
          name='date_load'
          label={t("inv.loading_date")}
        />
      </Grid>

      <Grid item xs={12} md={10}>
        <FastField
          as={TextFieldNormal}
          name='unload_address'
          label={t("inv.unloading_address")}
        />
      </Grid>

      <Grid item xs={12} md={2}>
        <FastField
          as={DesktopDatePickerBasic}
          name='date_unload'
          label={t("inv.unloading_date")}
        />
      </Grid>
    </React.Fragment>
  );
};

export default LoadDetails;
