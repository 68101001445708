import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  boxXL: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: 5,
    width: 535,
  },
  boxM: {
    // flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 2,
    width: 535,
  },
  text3: {
    fontSize: 10,
    textAlign: "left",
    fontFamily: "Roboto",
    // fontWeight: "bold",
    width: 535,
  },
  text4: {
    fontSize: 8,
    textAlign: "left",
    fontFamily: "Roboto",
    width: 535,
  },
});

const InvPdfConditions = (props) => {
  const { values, invoicePdfLang } = props;

  return (
    <>
      <View style={styles.boxXL}>
        <Text style={styles.text3}>
          {invoicePdfLang === "RO/RU"
            ? "Termenii si conditiile / Условия и положения:"
            : "Terms and conditions:"}
        </Text>
      </View>
      <View style={styles.boxXL}>
        <Text style={styles.text4}>
          {values &&
            values.contract_terms &&
            values.contract_terms.term_description}
        </Text>
      </View>
    </>
  );
};

export default InvPdfConditions;
