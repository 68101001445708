import React from "react";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const ButtonRegister = ({ children, ...otherProps }) => {
  const theme = useTheme();
  const configButton = {
    ...otherProps,
    sx: {
      mt: 1,
      mr: 1,
      mb: 1,
      backgroundColor: theme.palette.baseblue.light,
      pt: "10px",
      pb: "10px",
      px: "20px",
      gap: "10px",
      fontSize: "18px",
      textTransform: "none",
      ...otherProps.sx,
    },
  };

  return (
    <React.Fragment>
      <Button {...otherProps} {...configButton} endIcon={<ArrowForwardIcon />}>
        {children}
      </Button>
    </React.Fragment>
  );
};

export default ButtonRegister;
