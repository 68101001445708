import { createSlice } from "@reduxjs/toolkit";

export const popupOpenSlice = createSlice({
  name: "popupOpenSlice",
  initialState: {
    popupOpen: false,
  },
  reducers: {
    setPopupOpen: (state, action) => {
      state.popupOpen = action.payload;
      return state;
    },
  },
});

export const { setPopupOpen } = popupOpenSlice.actions;

export default popupOpenSlice.reducer;
