import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

// // Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 15,
    paddingBottom: 80,
    paddingLeft: 47,
    paddingRight: 35,
    // size: "A4",
  },
  boxM: {
    // flexDirection: "row",
    marginBottom: 2,
    justifyContent: "flex-start",
    width: 300,
  },
  boxXL: {
    marginTop: 6,
    flexDirection: "row",
    marginBottom: 2,
    justifyContent: "flex-start",
    width: 535,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  line: {
    strokeWidth: 2,
    color: "black",
  },
  rowContainer: {
    flexDirection: "row",
    marginBottom: 1,
    justifyContent: "flex-start",
    width: 535,
  },
  rowContainerBasic: {
    flexDirection: "row",
    marginTop: 1,
    marginBottom: 1,
    justifyContent: "flex-start",
  },
  orderDate: {
    fontSize: 12,
    fontStyle: "bold",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 16,
    textAlign: "right",
    color: "black",
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Roboto",
  },
  text2: {
    fontSize: 12,
    textAlign: "left",
    fontFamily: "Roboto",
  },
  text3: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Roboto",
    // fontStyle: "normal",
    width: "38%",
    // fontWeight: "bold",
  },
  text4: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Roboto",
    width: "62%",
    // fontStyle: "normal",
    // fontWeight: "bold",
  },
  titleM: {
    fontSize: 12,
    textAlign: "left",
    fontFamily: "Roboto",
    marginBottom: 4,
  },
  titleXL: {
    flexDirection: "row",
    fontSize: 14,
    textAlign: "left",
    fontFamily: "Roboto",
    marginBottom: 2,
  },
});

const QuotePdfUp = (props) => {
  const { account, company, invoicePdfLang } = props;

  return (
    <React.Fragment>
      {/* Seller */}
      <View style={{ ...styles.boxXL }}>
        <View style={styles.boxM}>
          <View style={styles.rowContainer}>
            <Text style={styles.text4}>
              {company && (company.companyname || "-/-")}
            </Text>
          </View>
          <View style={styles.rowContainer}>
            <Text style={styles.text4}>
              {company &&
                company.countrycodelegal &&
                company.countrycodelegal.value}
              {company && company.zipcodelegal
                ? "-" + company.zipcodelegal
                : ""}
              {company && company.citylegal ? " " + company.citylegal : ""}
              {company && company.addresslegal
                ? ", " + company.addresslegal
                : ""}
            </Text>
          </View>
          <View style={styles.rowContainer}>
            <Text style={styles.text4}>
              Cod fisc. / TVA: {company && (company.fiscalcode || "")}
              {company && company.fiscalcode && company.vatcode
                ? " / " + company.vatcode
                : company && company.vatcode
                ? company.vatcode
                : ""}
            </Text>
          </View>
          <View style={styles.rowContainer}>
            <Text style={styles.text4}>
              Tel / email: {company && company.phone ? company.phone : ""}
              {company && company.phone && company.email
                ? " / " + company.email
                : company && company.email
                ? company.email
                : ""}
            </Text>
          </View>
          <View style={styles.rowContainer}>
            <Text style={styles.text4}>
              {invoicePdfLang === "RO/RU"
                ? "Persoana de contact/ Контактное лицо:"
                : "Contact person: "}
            </Text>
          </View>
          <View style={styles.rowContainer}>
            <Text style={styles.text4}>
              {account
                ? account.first_name
                  ? (account.first_name || "") +
                    (" " + (account.last_name || ""))
                  : account.last_name || ""
                : ""}
              {account &&
                (account.first_name || account.last_name
                  ? account.phone
                    ? (" / " + account.phone || "") +
                      (" / " + account.email || "")
                    : account.email
                    ? " / " + account.email
                    : ""
                  : account.phone
                  ? (account.phone || "") + (" " + account.email || "")
                  : account.email || "")}
            </Text>
          </View>
        </View>
      </View>
    </React.Fragment>
  );
};

export default QuotePdfUp;
