import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#000000";

const styles = StyleSheet.create({
  boxXL: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: 5,
    marginBottom: -1,
    borderColor: borderColor,
    borderWidth: 1,
    height: 35,
    width: 535,
  },
  boxM: {
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  description: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    // fontWeight: "heavy",
    paddingLeft: 3,
  },
  qty: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    // fontWeight: "heavy",
    // borderLeft: borderColor,
    // borderLeftWidth: 1,
    paddingLeft: 3,
  },
  rate: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    // fontWeight: "heavy",
    // borderLeft: borderColor,
    // borderLeftWidth: 1,
    paddingLeft: 3,
  },
  vat: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    // fontWeight: "heavy",
    // borderLeft: borderColor,
    // borderLeftWidth: 1,
    paddingLeft: 3,
  },
  amount: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    // fontWeight: "heavy",
    // borderLeft: borderColor,
    // borderLeftWidth: 1,
    paddingLeft: 3,
  },
});

const InvoiceTableHeader = (props) => {
  const { invoicePdfLang } = props;
  return (
    <View style={styles.boxXL}>
      <View style={{ ...styles.boxM, width: "45%" }}>
        <Text style={styles.description}>
          {invoicePdfLang === "RO/RU"
            ? "Denumirea marfurilor / serviciilor"
            : "Description"}
        </Text>
        <Text style={styles.description}>
          {invoicePdfLang === "RO/RU" ? "Наименование товаров / услуг" : ""}
        </Text>
      </View>
      <View
        style={{
          ...styles.boxM,
          width: "12%",
          borderLeft: borderColor,
          borderLeftWidth: 1,
        }}
      >
        <Text style={styles.qty}>
          {invoicePdfLang === "RO/RU" ? "Cantitatea" : "Quantity"}
        </Text>
        <Text style={styles.qty}>
          {invoicePdfLang === "RO/RU" ? "Количество" : ""}
        </Text>
      </View>
      <View
        style={{
          ...styles.boxM,
          width: "15%",
          borderLeft: borderColor,
          borderLeftWidth: 1,
        }}
      >
        <Text style={styles.qty}>
          {invoicePdfLang === "RO/RU" ? "Pret unitar" : "Rate"}
        </Text>
        <Text style={styles.qty}>
          {invoicePdfLang === "RO/RU" ? "Цена единицы" : ""}
        </Text>
      </View>
      <View
        style={{
          ...styles.boxM,
          width: "13%",
          borderLeft: borderColor,
          borderLeftWidth: 1,
        }}
      >
        <Text style={styles.qty}>
          {invoicePdfLang === "RO/RU" ? "Cota TVA %" : "VAT rate %"}
        </Text>
        <Text style={styles.qty}>
          {invoicePdfLang === "RO/RU" ? "Ставка НДС %" : ""}
        </Text>
      </View>
      <View
        style={{
          ...styles.boxM,
          borderLeft: borderColor,
          borderLeftWidth: 1,
          width: "15%",
        }}
      >
        <Text style={styles.qty}>
          {invoicePdfLang === "RO/RU" ? "Valoarea totala" : "Total amount"}
        </Text>
        <Text style={styles.qty}>
          {invoicePdfLang === "RO/RU" ? "Общая сумма" : ""}
        </Text>
      </View>
    </View>
  );
};

export default InvoiceTableHeader;
