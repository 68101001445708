import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../Fetch/axios";

const url = "app/persons/";

export const getItems = createAsyncThunk("personsSlice/getItems", async () => {
  return axiosInstance
    .get(url)
    .then((response) => {
      if (response.data) return response.data;
    })
    .catch(() => {}, []);
});

export const personsSlice = createSlice({
  name: "personsSlice",
  initialState: {
    persons: [],
    person: {},
    status: null,
  },
  reducers: {
    setItems: (state, action) => {
      return { ...state, persons: [...action.payload] };
    },
    setPerson: (state, action) => {
      return { ...state, preson: { ...action.payload } };
    },
    addOrEditItem: (state, { payload: values }) => {
      const i = state.persons.findIndex((item) => item.id === values.id);
      if (i > -1) state.persons[i] = values;
      else state.persons.push(values);
      return state;
    },

    deleteItem: (state, { payload }) => {
      const newItems = state.persons.filter((item) => item.id !== payload);

      return { ...state, persons: [...newItems] };
    },
  },
  extraReducers: {
    [getItems.pending]: (state) => {
      state.status = "loading";
    },
    [getItems.fulfilled]: (state, action) => {
      Array.isArray(action.payload) && (state.persons = action.payload);
      Array.isArray(action.payload) && (state.status = "loaded");
    },
    [getItems.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export const { setItems, setPerson, addOrEditItem, deleteItem } =
  personsSlice.actions;

export default personsSlice.reducer;
