import React from "react";
import { useEffect } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { batch, useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../Fetch/axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
////// Mui
import { Container, Grid, Typography } from "@mui/material";
////// Store
import { addOrEditItem } from "../../Store/contractTermSlice";
import { deleteContractTerm } from "../../Store/contractTermSlice";
import { setPopup } from "../../Store/popupSlice";
import { setFormSimpleTouched } from "../../Store/formTouchedSlice";
import { setLoadingStatus } from "../../Store/loadingStatusSlice";
////// Components & Controls
import ButtonsContainer from "../../Controls/Buttons/ButtonsContainer";
import { initialValuesContractTerms } from "../Tables/InitialValues/initialValuesContractTerms";
import { contractTermURL } from "../../Data/baseURL";
import TextFieldNormal from "../../Controls/Inputs/TextFieldNormal";

const validationSchema = Yup.object({
  term_short: Yup.string()
    .min(3, () => i18n.t("yup.min_3_characters"))
    .max(100, () => i18n.t("yup.max_100_characters"))
    .required(() => i18n.t("yup.required")),
  term_description: Yup.string()
    .max(2000, () => i18n.t("yup.max_2000_characters"))
    .required(() => i18n.t("yup.required")),
});

const ContractTerm = (props) => {
  const { addOrEdit, deleteAutoField } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = React.useState();
  const { itemForEdit } = useSelector((state) => state.itemForEditSlice);

  const FormValues = () => {
    const { values, touched } = useFormikContext();
    useEffect(() => {
      if (values) {
        setFormValues(values);
      }
      if (Object.keys(touched).length !== 0 && values.id === undefined) {
        dispatch(setFormSimpleTouched(true));
      }
      if (
        Object.keys(touched).length !== 0 &&
        itemForEdit !== null &&
        values.id !== ""
      ) {
        dispatch(setFormSimpleTouched(true));
      }
    }, [values, touched]);
    return null;
  };

  const handleSubmit = () => {
    dispatch(setLoadingStatus(true));
    const data = {
      term_short: formValues.term_short.trim(),
      term_description: formValues.term_description,
    };
    axiosInstance({
      method: formValues.id ? "PUT" : "POST",
      url: formValues.id
        ? contractTermURL + formValues.id + "/"
        : contractTermURL,
      data: data,
    })
      .then((response) => {
        batch(() => {
          dispatch(setLoadingStatus(false));
          dispatch(setFormSimpleTouched(false));
          dispatch(
            addOrEditItem({
              ...response.data,
              fieldName: itemForEdit ? itemForEdit.fieldName : null,
              index: itemForEdit ? itemForEdit.index : null,
            })
          );
        });
        addOrEdit(response.data);
        response.status === 200 &&
          toast.success(() => t("toast.record_successfully_updated"));
        response.status === 201 &&
          toast.success(() => t("toast.record_successfully_created"));
      })
      .catch((error) => {
        dispatch(setLoadingStatus(false));
        if (
          error.response &&
          error.response.data &&
          error.response.status !== 500 &&
          error.response.data.find((el) => el.includes("unique"))
        ) {
          toast.error(() => (
            <div>
              <Typography>
                {t("toast.term_condition_with_this_description_exist")}
              </Typography>
            </div>
          ));
        } else if (error.response && error.response.status === 404) {
          toast.error(() => t("toast.record_not_found"));
        } else {
          toast.error(() => t("toast.record_could_not_be_created_or_updated"));
        }
      }, []);
  };

  const handleDelete = () => {
    if (formValues.id) {
      dispatch(setLoadingStatus(true));
      axiosInstance({
        method: "DELETE",
        url: contractTermURL + formValues.id + "/",
      })
        .then((response) => {
          batch(() => {
            dispatch(setLoadingStatus(false));
            dispatch(deleteContractTerm(formValues.id));
            dispatch(setPopup(false));
            dispatch(setFormSimpleTouched(false));
          });
          deleteAutoField && deleteAutoField(formValues.id);
          response.status === 204 &&
            toast.success(t("toast.record_successfully_deleted"));
        })
        .catch((error) => {
          dispatch(setLoadingStatus(false));
          error.response &&
            error.response.status === 400 &&
            toast.error(t("toast.record_could_not_be_created_or_updated"));
          error.response &&
            error.response.status === 404 &&
            toast.error(t("toast.record_not_found"));
        }, []);
    } else {
      toast.error(t("toast.record_not_found"));
    }
  };

  return (
    <Container maxWidth='xl' sx={{ width: "100%" }}>
      <Formik
        enableReinitialize
        initialValues={itemForEdit || initialValuesContractTerms}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form autoComplete='off'>
            <Grid container spacing={2} sx={{ mt: 3, mb: 5 }}>
              <Grid item xs={12}>
                <TextFieldNormal
                  name='term_short'
                  label={t("contract_term.short_description")}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextFieldNormal
                  name='term_description'
                  label={t("contract_term.term_description")}
                  multiline
                  minRows={15}
                  required
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: 2 }}>
                <ButtonsContainer values={values} handleDelete={handleDelete} />
              </Grid>
            </Grid>
            <FormValues />
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ContractTerm;
