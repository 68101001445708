import { createSlice } from "@reduxjs/toolkit";

export const itemEmailSlice = createSlice({
  name: "itemEmailSlice",
  initialState: {
    itemEmail: null,
    status: null,
  },
  reducers: {
    setItemEmail: (state, action) => {
      return { ...state, itemEmail: { ...action.payload } };
    },
    setFieldTo: (state, action) => {
      return {
        ...state,
        itemEmail: { ...state.itemEmail, to: action.payload },
      };
    },
    addFile: (state, action) => {
      return {
        ...state,
        itemEmail: { ...state.itemEmail, attachment: action.payload },
      };
    },
  },
});

export const { setItemEmail, setFieldTo, addFile } = itemEmailSlice.actions;

export default itemEmailSlice.reducer;
