import React from "react";
import { useTranslation } from "react-i18next";
// Mui
import { Button, Stack, Typography } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
// Controls & Components
import ConfirmDialog from "../Popups/ConfirmDialog";

const ButtonsContainer = ({ values, handleSave, handleDelete }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const xsMedia = useMediaQuery(theme.breakpoints.only("xs"));
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const isSaveClose = true;

  const isSave = "sn" in values || "vn" in values;

  return (
    <>
      <Stack direction={xsMedia ? "column" : "row"} spacing={2}>
        {isSaveClose && (
          <Button type='submit' variant='outlined' startIcon={<SaveAsIcon />}>
            <Typography variant='button'>
              {t("button.save_and_close")}
            </Typography>
          </Button>
        )}

        {isSave && (
          <Button
            type='button'
            variant='outlined'
            onClick={(e) => handleSave(e, values)}
            startIcon={<SaveIcon />}
          >
            <Typography variant='button'>{t("button.save")}</Typography>
          </Button>
        )}
        <Button
          type='button'
          variant='outlined'
          color='secondary'
          startIcon={<DeleteIcon />}
          onClick={(e) => {
            setConfirmDialog({
              isOpen: true,
              title: t("popup.are_you_sure_to_delete_record"),
              subTitle: t("popup.you_can_not_undo_this_operation"),
              onConfirm: (e) => [
                handleDelete(e, values.id),
                setConfirmDialog({ ...confirmDialog, isOpen: false }),
              ],
            });
          }}
        >
          <Typography variant='button'>{t("button.delete")}</Typography>
        </Button>
      </Stack>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      ></ConfirmDialog>
    </>
  );
};

export default ButtonsContainer;
