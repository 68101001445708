import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
////// Mui
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { IconButton, Toolbar, Typography, Button, Stack } from "@mui/material";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
////// Controls & Components
import { appURL } from "../Data/baseURL";
import NotificationMenu from "./NotificationMenu";
import AccountMenu from "./AccountMenu";
import CdpLogo from "../Data/Icons/CdpLogo";
import SelectLang from "../Controls/FormsUI/SelectLang";
import EastIcon from "@mui/icons-material/East";

const drawerWidth = 265;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // height: theme.spacing(7),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const ButtonStyled = styled(Button)(() => ({
  marginRight: 4,
  marginLeft: 4,
  borderRadius: 2,
  "&:hover": {
    opacity: 0.7,
  },
}));

const Navbar = (props) => {
  const { open, setOpen } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const { pathname } = useLocation();
  const mdMediaDown = useMediaQuery(theme.breakpoints.down("md"));
  const smMediaDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdLgMediaMax = useMediaQuery("(max-width:1200px)");
  const { authLogIn, statusLogIn } = useSelector(
    (state) => state.authLogInSlice
  );

  function checkPathName() {
    if (pathname === "/") return true;
    if (pathname === `${appURL}/login`) return false;
    if (pathname === `${appURL}/signup`) return false;
    if (pathname === `${appURL}/terms-conditions`) return false;
    if (pathname === `${appURL}/faq`) return false;
    if (pathname === `${appURL}/support`) return false;
    else return false;
  }
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  // console.log("1212", mdLgMediaMax);

  return (
    <React.Fragment>
      <AppBar
        position={checkPathName() && !authLogIn ? "absolute" : "fixed"}
        open={open}
        style={{
          backgroundColor: "#043873",
          boxShadow: "none",
        }}
      >
        <Toolbar
          disableGutters={mdLgMediaMax ? false : true}
          sx={{
            bgcolor: "transparent",
            height: 56,
            width: "100%",
            maxWidth: checkPathName() && !authLogIn ? "1300px" : "auto",
            mx: "auto",
            my: !authLogIn && { xs: 0, md: "15px" },
            px: !authLogIn
              ? { xs: "30px", md: "60px" }
              : { xs: "20px", md: "20px" },
          }}
        >
          {authLogIn ? (
            <>
              {pathname !== "/" && (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  sx={{
                    mr: mdMediaDown ? 0.5 : 4,
                    ...(open && { display: "none" }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Typography
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1, ml: theme.spacing(1) }}
              >
                {pathname === "/" && (
                  <Button
                    component={NavLink}
                    to={`${appURL}`}
                    color="inherit"
                    size="large"
                  >
                    <DashboardIcon sx={{ mr: 1.5 }} />
                  </Button>
                )}
                {pathname === `${appURL}` && t("dashboard.dashboard")}
                {pathname === `${appURL}/account` && t("general.my_profile")}
                {pathname === `${appURL}/company` &&
                  t("general.company_profile")}
                {pathname === `${appURL}/users` && t("general.user_management")}
                {pathname === `${appURL}/subscription` &&
                  t("general.subscription")}
                {pathname.startsWith(`${appURL}/quotes`) &&
                  t(smMediaDown ? "general.quotes_short" : "general.quotes")}
                {pathname.startsWith(`${appURL}/invoices`) && t("general.invs")}
                {pathname === `${appURL}/clients` && t("general.clients")}
                {pathname === `${appURL}/bank-accounts` &&
                  t("general.bank_accounts")}
                {pathname === `${appURL}/items-for-item-inv` &&
                  t("general.invoice_items")}
                {pathname === `${appURL}/terms` && t("general.invoice_terms")}
                {pathname === `${appURL}/terms-of-payment` &&
                  t("general.terms_of_payment")}
                {pathname === `${appURL}/countries` && t("general.countries")}
                {pathname === `${appURL}/currencies` && t("general.currencies")}
                {pathname === `${appURL}/settings` && t("settings.settings")}
                {pathname === `${appURL}/faq` && t("faq.frequent_questions")}
                {pathname === `${appURL}/support` && t("general.support")}
              </Typography>

              <Stack direction="row" spacing={1}>
                <NotificationMenu />
                <AccountMenu />
              </Stack>
            </>
          ) : mdLgMediaMax ? (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                sx={{
                  mr: mdMediaDown ? 0.5 : 4,
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Button
                component={NavLink}
                to="/"
                sx={{
                  display: "flex",
                  py: 0,
                  height: 60,
                  px: 0,

                  justifyContent: "flex-start",
                }}
              >
                <CdpLogo
                  style={{
                    width: "60%",
                    height: "auto",
                  }}
                />
              </Button>

              <Stack
                direction="row"
                sx={{
                  justifyContent: "flex-end",
                  alignSelf: "center",

                  flexGrow: 1,
                }}
              >
                <SelectLang />
              </Stack>
            </>
          ) : (
            <>
              {statusLogIn === "notAuthorized" && (
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignContent: "flex-center",
                  }}
                >
                  <Button component={NavLink} to="/">
                    <CdpLogo
                      style={{
                        width: "100%",
                        maxWidth: "100px",
                        height: "auto",
                      }}
                    />
                  </Button>

                  {!authLogIn && checkPathName() && (
                    <Stack
                      direction="row"
                      sx={{ justifyContent: "center", alignItems: "center" }}
                      spacing={"15px"}
                    >
                      <ButtonStyled
                        onClick={() =>
                          document.getElementById("howitworks").scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          })
                        }
                        sx={{ textTransform: "none", px: 1 }}
                        size="large"
                      >
                        <Typography
                          color="#FFF"
                          variant="text"
                          sx={{
                            fontSize: 18,
                          }}
                        >
                          {t("base.title_howitworks")}
                        </Typography>
                      </ButtonStyled>
                      <ButtonStyled
                        onClick={() =>
                          document.getElementById("aboutUs").scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          })
                        }
                        sx={{ textTransform: "none", px: 1 }}
                        size="large"
                      >
                        <Typography
                          color="#FFF"
                          variant="text"
                          sx={{
                            fontSize: 18,
                          }}
                        >
                          {t("base.title_about_us")}
                        </Typography>
                      </ButtonStyled>

                      <ButtonStyled
                        onClick={() =>
                          document.getElementById("subscribes").scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          })
                        }
                        sx={{ textTransform: "none", px: 1 }}
                        size="large"
                      >
                        <Typography
                          color="#FFF"
                          variant="text"
                          sx={{
                            fontSize: 18,
                          }}
                        >
                          {t("general.price")}
                        </Typography>
                      </ButtonStyled>

                      <ButtonStyled
                        component={NavLink}
                        variant="outlined"
                        to="/apps/login"
                        size="large"
                        sx={{
                          backgroundColor: "#FFE492",
                          px: "40px",
                          py: "10px",
                          textTransform: "none",
                          borderRadius: "4px",
                          "&:hover": {
                            backgroundColor: "#fff392",
                            opacity: 1,
                          },
                        }}
                      >
                        <Typography
                          color="#FFF"
                          sx={{
                            fontSize: 18,
                            color: "#000",
                            fontWeight: 500,
                          }}
                        >
                          {t("button.login_lower")}
                        </Typography>
                      </ButtonStyled>
                      <ButtonStyled
                        component={NavLink}
                        variant="outlined"
                        to="/apps/signup"
                        size="large"
                        sx={{
                          backgroundColor: "#4F9CF9",
                          px: "21px",
                          py: "10px",
                          textTransform: "none",
                          borderRadius: "4px",
                          "&:hover": {
                            backgroundColor: "#2880E8",
                            opacity: 1,
                          },
                        }}
                        endIcon={<EastIcon sx={{ color: "#FFF" }} />}
                      >
                        <Typography
                          color="#000"
                          sx={{
                            fontSize: 18,
                            color: "#FFF",
                            fontWeight: 500,
                            textTransform: "none",
                          }}
                        >
                          {t("button.try_for_free")}
                        </Typography>
                      </ButtonStyled>
                      <SelectLang />
                    </Stack>
                  )}
                </Grid>
              )}
            </>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Navbar;
