import React from "react";
import { useState, useEffect } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector, batch } from "react-redux";
import axiosInstance from "../../Fetch/axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
////// Mui
import { Container, Grid, Typography } from "@mui/material";
import { Stack, Button, useTheme, useMediaQuery } from "@mui/material";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteIcon from "@mui/icons-material/Delete";
////// Store
import { addOrEditItem, deleteNote } from "../../Store/noteTermSlice";
import { setFormSimpleTouched } from "../../Store/formTouchedSlice";
import { setLoadingStatus } from "../../Store/loadingStatusSlice";
import { setPopup } from "../../Store/popupSlice";
// Controls & Components
import ConfirmDialog from "../../Controls/Popups/ConfirmDialog";
import { initialValuesTermOfPayment } from "../Tables/InitialValues/initialValuesTermOfPayment";
import { noteURL } from "../../Data/baseURL";
import TextFieldNormal from "../../Controls/Inputs/TextFieldNormal";

const validationSchema = Yup.object({
  note_short: Yup.string()
    .min(3, () => i18n.t("yup.min_3_characters"))
    .max(100, () => i18n.t("yup.max_100_characters"))
    .required(() => i18n.t("yup.required")),
  note_description: Yup.string()
    .max(500, () => i18n.t("yup.max_500_characters"))
    .required(() => i18n.t("yup.required")),
});

const TermOfPayment = (props) => {
  const { addOrEdit, deleteAutoField } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const xsMedia = useMediaQuery(theme.breakpoints.only("xs"));
  const [formValues, setFormValues] = useState();
  const { itemForEdit } = useSelector((state) => state.itemForEditSlice);
  const [confirmDialog, setConfirmDialog] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const FormValues = () => {
    const { values, touched } = useFormikContext();
    useEffect(() => {
      if (values) {
        setFormValues(values);
      }
      if (Object.keys(touched).length !== 0 && values.id === undefined) {
        dispatch(setFormSimpleTouched(true));
      }
      if (
        Object.keys(touched).length !== 0 &&
        itemForEdit !== null &&
        values.id !== ""
      ) {
        dispatch(setFormSimpleTouched(true));
      }
    }, [values, touched]);
    return null;
  };

  // useImperativeHandle(refSecond, () => ({
  //   handleSubmit: () => handleSubmit(),
  // }));

  const handleSubmit = () => {
    dispatch(setLoadingStatus(true));
    const data = {
      note_short: formValues.note_short.trim(),
      note_description: formValues.note_description,
    };
    axiosInstance({
      method: formValues.id ? "PATCH" : "POST",
      url: formValues.id ? noteURL + formValues.id + "/" : noteURL,
      data: data,
    })
      .then((response) => {
        batch(() => {
          dispatch(setLoadingStatus(false));
          dispatch(setFormSimpleTouched(false));

          dispatch(
            addOrEditItem({
              ...response.data,
              fieldName: itemForEdit ? itemForEdit.fieldName : null,
              index: itemForEdit ? itemForEdit.index : null,
            })
          );
        });
        addOrEdit(response.data);
        toast.success(() =>
          t(
            formValues.id
              ? "toast.record_successfully_updated"
              : "toast.record_successfully_created"
          )
        );
      })
      .catch((error) => {
        dispatch(setLoadingStatus(false));
        if (error.response && error.response.status === 405) {
          toast.error(() => (
            <div>
              <Typography>Method not allowd.</Typography>
            </div>
          ));
        } else if (
          error.response &&
          error.response.data &&
          error.response.status !== 500 &&
          error.response.data.find((el) => el.includes("unique"))
        ) {
          toast.error(() => (
            <div>
              <Typography>
                {t("toast.term_of_payment_with_this_description_exist")}
              </Typography>
            </div>
          ));
        } else if (error.response && error.response.status === 404) {
          toast.error(() => (
            <div>
              <Typography>{t("toast.record_not_found")}</Typography>
            </div>
          ));
        } else {
          toast.error(() => (
            <div>
              <Typography>
                {t("toast.terms_of_payment_could_not_be_created_or_update")}
              </Typography>
            </div>
          ));
        }
      }, []);
  };

  const handleDelete = () => {
    if (formValues.id) {
      dispatch(setLoadingStatus(true));
      axiosInstance({
        method: "DELETE",
        url: noteURL + formValues.id + "/",
      })
        .then((response) => {
          batch(() => {
            dispatch(setLoadingStatus(false));
            dispatch(setPopup(false));
            dispatch(deleteNote(formValues.id));
            dispatch(setFormSimpleTouched(false));
          });
          deleteAutoField && deleteAutoField(formValues.id);
          response.status === 204 &&
            toast.success(() => t("toast.record_successfully_deleted"));
        })
        .catch((error) => {
          dispatch(setLoadingStatus(false));
          error.response &&
            error.response.status === 400 &&
            toast.error(() => t("toast.record_could_not_be_deleted"));
          error.response &&
            error.response.status === 404 &&
            toast.error(() => t("toast.record_not_found"));
        }, []);
    } else {
      toast.error(t("toast.record_not_found"));
    }
  };

  return (
    <React.Fragment>
      <Container maxWidth='md'>
        <Formik
          enableReinitialize
          initialValues={itemForEdit || initialValuesTermOfPayment}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => {
            return (
              <Form autoComplete='off'>
                <Grid container item spacing={2} sx={{ mt: 3, mb: 5 }}>
                  <Grid item xs={12}>
                    <TextFieldNormal
                      name='note_short'
                      label={t("term_of_payment.short_note")}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mb: 3 }}>
                    <TextFieldNormal
                      name='note_description'
                      label={t("term_of_payment.note_description")}
                      multiline={true}
                      minRows={5}
                      maxRows={5}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction={xsMedia ? "column" : "row"} spacing={2}>
                      <Button
                        type='submit'
                        variant='outlined'
                        startIcon={<SaveAsIcon />}
                      >
                        <Typography variant='button'>
                          {t("button.save_and_close")}
                        </Typography>
                      </Button>

                      <Button
                        type='button'
                        variant='outlined'
                        color='secondary'
                        startIcon={<DeleteIcon />}
                        onClick={(e) => {
                          setConfirmDialog({
                            isOpen: true,
                            title: t("popup.are_you_sure_to_delete_record"),
                            subTitle: t(
                              "popup.you_can_not_undo_this_operation"
                            ),
                            onConfirm: (e) => [
                              handleDelete(e, values.id),
                              setConfirmDialog({
                                ...confirmDialog,
                                isOpen: false,
                              }),
                            ],
                          });
                        }}
                      >
                        <Typography variant='button'>
                          {t("button.delete")}
                        </Typography>
                      </Button>
                    </Stack>
                    {/* <ButtonsContainer
                      values={values}
                      handleDelete={handleDelete} */}
                  </Grid>
                </Grid>
                <FormValues />
              </Form>
            );
          }}
        </Formik>
      </Container>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      ></ConfirmDialog>
    </React.Fragment>
  );
};

export default TermOfPayment;
