import { createSlice } from "@reduxjs/toolkit";

export const popupSimpleSlice = createSlice({
  name: "popupSimpleSlice",
  initialState: {
    popupSimple: false,
  },
  reducers: {
    setPopupSimple: (state, action) => {
      return { ...state, popupSimple: action.payload };
    },
  },
});

export const { setPopupSimple } = popupSimpleSlice.actions;

export default popupSimpleSlice.reducer;
