import React from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
////// Mui
import { Dialog, DialogTitle, DialogContent, Paper } from "@mui/material";
import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
////// Store
import { setPopupNotification } from "../../Store/popupNotificationSlice";
////// Controls & Components
import ButtonAction from "../Buttons/ButtonAction";

function PaperComponent(props) {
  return (
    <Draggable
      handle='#draggable-popupregular-title'
      cancel={'[class*="popupregular-cancel"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const PopupRegular = ({ title, children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mdMediaDown = useMediaQuery(theme.breakpoints.down("md"));
  const { loadingStatus } = useSelector((state) => state.loadingStatusSlice);
  const { popupNotification } = useSelector(
    (state) => state.popupNotificationSlice
  );

  function handleEscClose() {
    dispatch(setPopupNotification(false));
  }

  return (
    <React.Fragment>
      <Dialog
        open={popupNotification}
        maxWidth='lg'
        onClose={handleEscClose}
        sx={{
          "& .MuiDialog-container": {
            justifyContent: "flex-center",
            alignItems: "flex-start",
          },
        }}
        PaperProps={{
          sx: {
            mt: 2,
            m: mdMediaDown ? 0.75 : undefined,
          },
        }}
        PaperComponent={PaperComponent}
        BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.1)" } }}
      >
        <DialogTitle sx={{ bgcolor: "#f5f5f5" }}>
          <Grid container item id='draggable-popupregular-title'>
            <Grid item xs={9}>
              <Typography variant='h6'>{title}</Typography>
            </Grid>

            <Grid
              container
              item
              xs={3}
              justifyContent={"flex-end"}
              id='popupregular-cancel'
            >
              <ButtonAction color='secondary' onClick={() => handleEscClose()}>
                {t("button.esc")}
              </ButtonAction>
            </Grid>
          </Grid>
        </DialogTitle>

        <Box sx={{ width: "100%" }}>
          {loadingStatus === true ? (
            <LinearProgress />
          ) : (
            <LinearProgress variant='determinate' value={100} />
          )}
        </Box>

        <DialogContent sx={{ p: 1.5 }} dividers>
          {children}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default PopupRegular;
