import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
// import blackLogo from "../images/logo_cargosmd_black.svg";
import message from "../../Data/Icons/icon_message.svg";
import phone from "../../Data/Icons/icon_phone.svg";
import skype from "../../Data/Icons/icon_skype.svg";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CdpLogo from "../../Data/Icons/CdpLogo";
import SocialMedia from "./SocialMedia";
// import { useEffect } from "react";

const footerColumns = [
  {
    id: 9001,
    title: "contact.contact",
    list: ["+37322999470", "support@cargosmd.com", "cargos.md"],
  },
  {
    id: 9002,
    title: "general.aplication",
    list: [
      "general.title_presentation",
      "general.characteristics",
      "general.price",
    ],
    linkto: ["/#overview", "/#characteristics", "/#subscribes"],
  },
  {
    id: 9003,
    title: "general.support",
    list: [
      "base.title_howitworks",
      "general.guide_and_tutorials",
      "terms_and_conditions.terms_and_conditions",
    ],
    linkto: ["#howitworks", "#features", "#services"],
  },
];

const Footer = () => {
  const { t } = useTranslation();

  const location = useLocation();
  useEffect(() => {
    // console.log("123", location.hash);

    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <Grid
      container
      item
      sx={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        mx: "auto",
        pt: "30px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          mx: "auto",
          maxWidth: "1300px",
          px: { xs: "30px", sm: "60px" },
          pt: { xs: "0", md: "14px" },
          pb: { xs: "30px", md: "14px" },
        }}
      >
        <Grid container item xs={12}>
          {/* CARGOS MD */}
          <Grid item md={3} sx={{ display: { xs: "none", md: "flex" } }}>
            <Box
              sx={{
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Button
                component={NavLink}
                to="/"
                sx={{
                  px: 0,
                  mb: "10px",
                  height: 60,
                }}
              >
                <CdpLogo
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </Button>
              <SocialMedia color="#fff" sx={{ py: "10px" }} />
              <Typography
                sx={{
                  pt: "18x",
                  fontFamily: "Inter",
                  color: "#fff",
                  textAlign: "left",
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  maxWidth: "240px",
                }}
              >
                {t("base.footer_short_description")}
              </Typography>
            </Box>
          </Grid>
          {footerColumns.map((column, index) => (
            <Grid
              key={column.id}
              item
              sm={4}
              md={3}
              xs={12}
              sx={{
                pl: { xs: 0, md: index === 1 ? "80px" : "50px" },
                pb: { xs: "30px", sm: 0 },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  mb: { xs: 0, sm: "20px" },
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    color: "#FFF",
                    textAlign: "justify",
                    fontSize: "18px",
                    lineHeight: "24px",
                    fontStyle: "normal",
                    fontWeight: 700,
                  }}
                >
                  {t(column.title)}
                </Typography>
              </Box>
              {column.list.map((row, i) => (
                <List key={row} sx={{ p: 0 }} disablePadding>
                  <ListItem disablePadding>
                    <ListItemButton
                      disableRipple
                      sx={{
                        px: 0,
                        overflow: {
                          xs: "visible",
                          sm: "hidden",
                          md: "visible",
                        },
                        "&:hover": {
                          backgroundColor: "transparent",
                          opacity: 0.5,
                        },
                      }}
                      onClick={(e) => {
                        if (index === 0 && i === 0) {
                          window.open("tel:" + row);
                          e.preventDefault();
                        }
                        if (index === 0 && i === 1) {
                          window.location.href = "mailto:support@cargosmd.com";
                          e.preventDefault();
                        }
                        if (index === 0 && i === 2) {
                          window.location.href = "skype:cargos.md?chat";
                          e.preventDefault();
                        }
                      }}
                      component={
                        index === 1 ||
                        (index === 2 && i === 2) ||
                        (index === 2 && i === 1) ||
                        (index === 2 && i === 0)
                          ? NavLink
                          : ""
                      }
                      to={
                        index === 1
                          ? column.linkto[i]
                          : index === 2 && i === 2
                          ? "/apps/terms-conditions"
                          : index === 2 && i === 1
                          ? "/apps/faq"
                          : index === 2 && i === 0
                          ? "#howitworks"
                          : ""
                      }
                    >
                      <ListItemIcon
                        sx={{
                          display: index === 0 ? "flex" : "none",
                          minWidth: "auto",
                          mr: "20px",
                          color: "#FFF",
                        }}
                      >
                        <img
                          src={i === 0 ? phone : i === 1 ? message : skype}
                          alt=""
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          fontFamily: "Inter",
                          fontSize: "14px",
                          color: "#FFF",
                          fontWeight: 400,
                          lineHeight: "20px",
                        }}
                        primary={t(row)}
                        sx={{ m: 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              ))}
            </Grid>
          ))}
        </Grid>
      </Box>
      {/* Copyright */}
      <Box
        sx={{
          maxWidth: "1300px",
          width: "100%",
          mx: "auto",
          px: { xs: "30px", sm: "60px" },

          pt: { xs: "0", sm: "20px", md: "80px" },
          pb: { xs: "10px", md: "14px" },
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            color: "#FFF",
          }}
          align="left"
        >
          {"© Contdeplata "} {new Date().getFullYear()}
          {" | Powered by "}
          <a
            href="https://cargosmd.com"
            style={{
              color: "#fff",
              textDecoration: "none",
              ":hover": {
                color: "#00000",
              },
            }}
          >
            Cargos Software
          </a>
        </Typography>
      </Box>
    </Grid>
  );
};

export default Footer;
