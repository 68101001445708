import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

////// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 15,
    paddingBottom: 80,
    paddingLeft: 47,
    paddingRight: 35,
  },
  boxM: {
    flexDirection: "column",
    marginBottom: 2,
    justifyContent: "flex-start",
    width: 267,
  },
  boxXL: {
    marginTop: 6,
    flexDirection: "row",
    marginBottom: 2,
    justifyContent: "flex-start",
    width: 535,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  line: {
    strokeWidth: 2,
    color: "black",
  },
  rowContainer: {
    flexDirection: "row",
    marginBottom: 1,
    justifyContent: "flex-start",
    width: 535,
  },
  rowContainerBasic: {
    flexDirection: "row",
    marginTop: 1,
    marginBottom: 1,
    justifyContent: "flex-start",
  },
  orderDate: {
    fontSize: 12,
    fontStyle: "bold",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 16,
    textAlign: "right",
    color: "black",
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Roboto",
  },
  text2: {
    fontSize: 12,
    textAlign: "left",
    fontFamily: "Roboto",
    width: 250,
  },
  text3: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Roboto",
    // fontStyle: "normal",
    width: 250,
    // fontWeight: "bold",
  },
  text4: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Roboto",
    width: 250,
    // fontStyle: "normal",
    // fontWeight: "bold",
  },
  titleM: {
    fontSize: 12,
    textAlign: "left",
    fontFamily: "Roboto",
    marginBottom: 4,
  },
  titleXL: {
    flexDirection: "row",
    fontSize: 14,
    textAlign: "left",
    fontFamily: "Roboto",
    marginBottom: 2,
  },
});

const InvPdfUp1 = (props) => {
  const { account, company, values, bankAccounts, invoicePdfLang } = props;

  const payerLegalAddress =
    values &&
    values.bill_to &&
    (values.bill_to.countrycodelegal &&
      values.bill_to.countrycodelegal.value &&
      values.bill_to.countrycodelegal.value) +
      (values.bill_to.zipcodelegal ? " - " + values.bill_to.zipcodelegal : "") +
      (values.bill_to.citylegal ? ", " + values.bill_to.citylegal : "") +
      (values.bill_to.addresslegal ? ", " + values.bill_to.addresslegal : "");

  const currencyInv = bankAccounts.find(
    (obj) =>
      `${obj.currencycode && obj.currencycode.currencycode}` ===
      `${values.currency && values.currency.currencycode}`
  );

  const payerBankAccount =
    values &&
    values.bill_to &&
    values.bill_to.contact_bank_accounts &&
    values.bill_to.contact_bank_accounts.find(
      (obj) =>
        `${obj.currencycode && obj.currencycode.currencycode}` ===
        `${values.currency && values.currency.currencycode}`
    );

  const supplierAndPayerFromMD =
    company &&
    company.countrycodelegal &&
    company.countrycodelegal.value === "MD" &&
    values &&
    values.bill_to &&
    values.bill_to.countrycodelegal.value === company.countrycodelegal.value;

  const supplierBankAccountInMdl =
    supplierAndPayerFromMD &&
    values &&
    values.currency &&
    bankAccounts &&
    bankAccounts.find((account) => account.currencycode.currencycode === "MDL");

  return (
    <React.Fragment>
      <View style={styles.boxXL}>
        {/* Seller */}
        <View style={styles.boxM}>
          <Text style={{ ...styles.text2, paddingBottom: 5 }}>
            {invoicePdfLang === "RO/RU"
              ? "Furnizor / Поставщик:"
              : "Invoice from:"}
          </Text>

          <Text style={styles.text2}>
            {company && company.companyname ? company.companyname : "--/--"}
          </Text>

          <Text style={styles.text4}>
            {company &&
              company.countrycodelegal &&
              company.countrycodelegal.value}
            {company && company.zipcodelegal && "-" + company.zipcodelegal}
            {company && company.citylegal && " " + company.citylegal}
            {company && company.addresslegal && ", " + company.addresslegal}
          </Text>

          <Text style={styles.text4}>
            {invoicePdfLang === "RO/RU"
              ? "Cod fisc. / TVA / Фиск. код / НДС: "
              : "Fiscal code/ VAT number: "}
            {company && company.fiscalcode ? company.fiscalcode || "" : ""}
            {company && company.fiscalcode && company.vatcode
              ? " / " + (company.vatcode || "")
              : company && company.vatcode
              ? company.vatcode
              : ""}
          </Text>

          <Text style={styles.text4}>
            {invoicePdfLang === "RO/RU"
              ? " Cont/ Счет (IBAN): "
              : "Bank account (IBAN): "}

            {supplierBankAccountInMdl
              ? supplierBankAccountInMdl.iban_number || ""
              : values.currency && bankAccounts && currencyInv
              ? currencyInv.iban_number || ""
              : ""}
          </Text>

          <Text style={styles.text4}>
            {invoicePdfLang === "RO/RU"
              ? "Banca/ Банк / BIC / SWIFT:"
              : "Bank name/ SWIFT: "}

            {supplierBankAccountInMdl
              ? supplierBankAccountInMdl.bank_name +
                " / " +
                supplierBankAccountInMdl.bank_code
              : values.currency && bankAccounts && currencyInv
              ? currencyInv.bank_name + " / " + currencyInv.bank_code
              : ""}
          </Text>

          <Text style={styles.text4}>
            {invoicePdfLang === "RO/RU"
              ? "Tel/Тел. / email/имейл: "
              : "Tel/ email: "}
            {company
              ? company.phone && company.email
                ? company.phone + " / " + company.email
                : company.phone || "" + company.email || ""
              : ""}
          </Text>
          <Text style={styles.text4}>
            {invoicePdfLang === "RO/RU"
              ? "Pers. cont/ Конт. лицо: "
              : "Contact person: "}
            {values && values.owner && values.owner.first_name
              ? values.owner.first_name + " "
              : ""}
            {values && values.owner && values.owner.last_name
              ? values.owner.last_name
              : ""}
          </Text>
          <Text style={styles.text4}>
            {account &&
              (account.phone && account.email
                ? account.phone + " / " + account.email
                : account.phone
                ? account.phone
                : account.email || "")}
          </Text>
        </View>

        {/* Payer */}
        <View style={styles.boxM}>
          <Text style={{ ...styles.text2, paddingBottom: 5 }}>
            {invoicePdfLang === "RO/RU"
              ? "Platitor / Плательщик:"
              : "Invoice to:"}
          </Text>

          <Text style={styles.text2}>
            {values.bill_to ? values.bill_to.companyname || "---" : "---"}
          </Text>

          <Text style={styles.text4}>{payerLegalAddress || ""}</Text>

          <Text style={styles.text4}>
            {invoicePdfLang === "RO/RU"
              ? "Cod fiscal / TVA / Фиск. код / НДС: "
              : "Fiscal code/ VAT number: "}
            {values && values.bill_to && (values.bill_to.fiscalcode || "")}
            {values &&
            values.bill_to &&
            values.bill_to.fiscalcode &&
            values.bill_to.vatcode
              ? " / " + values.bill_to.vatcode
              : values && values.bill_to && (values.bill_to.vatcode || "")}
          </Text>

          <Text style={styles.text4}>
            {invoicePdfLang === "RO/RU"
              ? "Cont/ Счет (IBAN): "
              : " Bank account(IBAN): "}
            {payerBankAccount && (payerBankAccount.iban_number || " ")}
          </Text>

          <Text style={styles.text4}>
            {invoicePdfLang === "RO/RU"
              ? "Banca/ Банк / BIC / SWIFT: "
              : " Bank name/ SWIFT: "}
            {payerBankAccount && (payerBankAccount.bank_name || "")}
            {payerBankAccount &&
            payerBankAccount.bank_name &&
            payerBankAccount.bank_code
              ? " / " + payerBankAccount.bank_code
              : payerBankAccount && (payerBankAccount.bank_code || "")}
          </Text>

          <Text style={styles.text4}>
            {invoicePdfLang === "RO/RU"
              ? "Tel/Тел. / email/имейл: "
              : "Tel/ email: "}{" "}
            {values && values.bill_to ? values.bill_to.phone || "" : ""}
            {values &&
            values.bill_to &&
            values.bill_to.phone &&
            values.bill_to.email1
              ? " / " + values.bill_to.email1
              : values && values.bill_to
              ? values.bill_to.email1 || ""
              : ""}
          </Text>
        </View>
      </View>
    </React.Fragment>
  );
};

export default InvPdfUp1;
