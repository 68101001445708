import React from "react";
import { Font, Text, View, StyleSheet } from "@react-pdf/renderer";

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
});

const borderColor = "#000000";

const styles = StyleSheet.create({
  boxXL: {
    marginTop: 10,
    flexDirection: "row",
    marginBottom: 1,
    justifyContent: "flex-start",
    width: 535,
  },
  rowContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    width: 535,
  },
  freeSpace: {
    fontSize: 10,
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    // fontWeight: "heavy",
    paddingLeft: 3,
    width: "35%",
  },
  text: {
    fontSize: 10,
    textAlign: "right",
    fontFamily: "Roboto",
    fontStyle: "normal",
    // fontWeight: "heavy",
    borderBottomColor: borderColor,
    borderBottomWidth: 0.5,
    paddingRight: 8,
    width: "50%",
  },
  amount: {
    fontSize: 10,
    textAlign: "left",
    fontFamily: "Roboto",
    fontStyle: "normal",
    // fontWeight: "heavy",
    borderBottomColor: borderColor,
    borderBottomWidth: 0.5,
    paddingLeft: 3,
    width: "15%",
  },
});

const InvPdfTableTotal = (props) => {
  const { values, invoicePdfLang } = props;

  return (
    <>
      <View style={styles.boxXL}>
        <View style={styles.rowContainer}>
          <Text style={styles.freeSpace}></Text>
          <Text style={styles.text}>
            {invoicePdfLang === "RO/RU"
              ? "Valarea totala fara TVA / Общая сумма без НДС:"
              : "Total amount without VAT:"}
          </Text>
          <Text style={styles.amount}>
            {values.iteminv_invs &&
              values.iteminv_invs
                .map((item) => {
                  let a = item.amount;
                  let b = item.quantity || 1;
                  return a * b;
                })
                .reduce((prev, cur) => {
                  return prev + cur;
                }, 0)
                .toFixed(2)}
          </Text>
        </View>
      </View>
      <View style={styles.boxXL}>
        <View style={styles.rowContainer}>
          <Text style={styles.freeSpace}></Text>
          <Text style={styles.text}>
            {invoicePdfLang === "RO/RU"
              ? "Valoarea totala a TVA / Общая сумма НДС:"
              : "Total VAT amount:"}
          </Text>
          <Text style={styles.amount}>
            {values.iteminv_invs &&
              values.iteminv_invs
                .map((item) => {
                  let c =
                    item.item !== null &&
                    item.amount *
                      (item.quantity || 1) *
                      (item.item_for_item_inv &&
                        item.item_for_item_inv.vat / 100);
                  return c;
                })
                .reduce((prev, cur) => {
                  return prev + cur;
                }, 0)
                .toFixed(2)}
          </Text>
        </View>
      </View>
      <View style={styles.boxXL}>
        <View style={styles.rowContainer}>
          <Text style={styles.freeSpace}></Text>
          <Text style={{ ...styles.text, fontSize: 10 }}>
            {invoicePdfLang === "RO/RU"
              ? "Valoarea totala / Общая сумма "
              : " Total amount "}
            ({values && values.currency && values.currency.currencycode}):
          </Text>
          <Text style={{ ...styles.amount, fontSize: 10 }}>
            {values.iteminv_invs &&
              values.iteminv_invs
                .map((item) => {
                  let a = item.amount;
                  let b = item.quantity || 1;
                  let c =
                    item.item !== null &&
                    a *
                      b *
                      (item.item_for_item_inv &&
                        item.item_for_item_inv.vat / 100);
                  return a * b + c;
                })
                .reduce((prev, cur) => {
                  return prev + cur;
                }, 0)
                .toFixed(2)}
          </Text>
        </View>
      </View>
    </>
  );
};

export default InvPdfTableTotal;
