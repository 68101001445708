import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { batch, useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../Fetch/axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
////// Mui
import { Container, Grid } from "@mui/material";
////// Store
import { addOrEditItem } from "../../Store/itemForItemInvSlice";
import { deleteItemForItemInv } from "../../Store/itemForItemInvSlice";
import { setLoadingStatus } from "../../Store/loadingStatusSlice";
import { setFormSimpleTouched } from "../../Store/formTouchedSlice";
import { setPopup } from "../../Store/popupSlice";
////// Components & Controls
import ButtonsContainer from "../../Controls/Buttons/ButtonsContainer";
import { initialValuesItemInv } from "../Tables/InitialValues/initialValuesItemInv";
import { itemForItemInvtURL } from "../../Data/baseURL";
import TextFieldNormal from "../../Controls/Inputs/TextFieldNormal";

const validationSchema = Yup.object({
  description: Yup.string()
    .min(3, () => i18n.t("yup.min_3_characters"))
    .max(100, () => i18n.t("yup.max_100_characters"))
    .required(() => i18n.t("yup.required")),
  code: Yup.string()
    .min(3, () => i18n.t("yup.min_3_characters"))
    .max(30, () => i18n.t("yup.max_30_characters"))
    .nullable(),
  vat: Yup.number(() => i18n.t("yup.max_2_digits"))
    .typeError(() => i18n.t("yup.max_2_digits"))
    .max(99, () => i18n.t("yup.max_2_digits"))
    .moreThan(-1, () => i18n.t("yup.must_be_a_positive_number"))
    .required(() => i18n.t("yup.required")),
});

const ItemInv = forwardRef((props, ref) => {
  const { addOrEdit, deleteAutoField } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = React.useState();
  const { itemForEdit } = useSelector((state) => state.itemForEditSlice);

  const FormValues = () => {
    const { values, touched } = useFormikContext();

    useEffect(() => {
      if (values) {
        setFormValues(values);
      }
      if (Object.keys(touched).length !== 0 && values.id === undefined) {
        dispatch(setFormSimpleTouched(true));
      }
      if (
        Object.keys(touched).length !== 0 &&
        itemForEdit !== null &&
        values.id !== ""
      ) {
        dispatch(setFormSimpleTouched(true));
      }
    }, [values, touched]);
    return null;
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: () => handleSubmit(),
  }));

  const handleSubmit = () => {
    dispatch(setLoadingStatus(true));
    let description = formValues.description.trim();
    axiosInstance({
      method: formValues.id ? "PUT" : "POST",
      url: formValues.id
        ? itemForItemInvtURL + formValues.id + "/"
        : itemForItemInvtURL,
      data: { ...formValues, description: description },
    })
      .then((response) => {
        batch(() => {
          dispatch(setLoadingStatus(false));
          dispatch(setFormSimpleTouched(false));
          dispatch(
            addOrEditItem({
              ...response.data,
              fieldName: itemForEdit ? itemForEdit.fieldName : null,
              index: itemForEdit ? itemForEdit.index : null,
            })
          );
        });
        addOrEdit(response.data);
        response.status === 200 &&
          toast.success(() => t("toast.record_successfully_updated"));
        response.status === 201 &&
          toast.success(() => t("toast.record_successfully_created"));
      })
      .catch((error) => {
        dispatch(setLoadingStatus(false));
        if (
          error.response &&
          error.response.status &&
          error.response.status === 402
        ) {
          toast.error(() =>
            t(
              "toast.need_to_upgrade_in_order_to_register_new_items_for_item_inv"
            )
          );
        } else if (
          error.response &&
          error.response.status &&
          error.response.status === 404
        ) {
          toast.error(() => t("toast.record_not_found"));
        } else if (
          (error.response &&
            error.response.data &&
            typeof error.response.data === "object" &&
            !Array.isArray(error.response.data) &&
            error.response.data !== null &&
            Object.values(error.response.data).indexOf("unique") > -1) ||
          (error.response &&
            error.response.data &&
            typeof error.response.data === "object" &&
            Array.isArray(error.response.data) &&
            error.response.data !== null &&
            error.response.data.find((el) => el.includes("unique")))
        ) {
          toast.error(() => t("toast.item_with_this_description_exist"), {
            autoClose: 4000,
          });
        } else {
          toast.error(() => t("toast.record_could_not_be_created_or_updated"));
        }
      });
  };

  const handleDelete = () => {
    if (formValues.id) {
      dispatch(setLoadingStatus(true));
      axiosInstance({
        method: "DELETE",
        url: itemForItemInvtURL + formValues.id + "/",
      })
        .then((response) => {
          batch(() => {
            dispatch(setPopup(false));
            dispatch(setLoadingStatus(false));
            dispatch(deleteItemForItemInv(formValues.id));
          });
          deleteAutoField && deleteAutoField(formValues.id);
          response.status === 204 &&
            toast.success(t("toast.record_successfully_deleted"));
        })
        .catch((error) => {
          dispatch(setLoadingStatus(false));
          error.response &&
            (error.response.status === 404 || error.response.status === 500) &&
            toast.error(t("toast.record_not_allowed_be_deleted"));
        }, []);
    } else {
      toast.error(t("toast.record_not_found"));
    }
  };

  return (
    <Container maxWidth='md'>
      <Formik
        enableReinitialize
        initialValues={itemForEdit || initialValuesItemInv}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form autoComplete='off'>
            <Grid container item spacing={2} sx={{ mt: 3, mb: 5 }}>
              <Grid item md={6} xs={12}>
                <TextFieldNormal
                  name='description'
                  label={t("inv.description")}
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <TextFieldNormal name='code' label={t("inv.code_optional")} />
              </Grid>

              <Grid item md={3} xs={12}>
                <TextFieldNormal
                  name='vat'
                  label={t("inv.vat_rate")}
                  type='number'
                  required
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>
                <ButtonsContainer values={values} handleDelete={handleDelete} />
              </Grid>
            </Grid>

            <FormValues />
          </Form>
        )}
      </Formik>
    </Container>
  );
});

export default ItemInv;
