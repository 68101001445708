import { configureStore } from "@reduxjs/toolkit";
import accountSliceReducer from "./accountSlice";
import accountGroupUserSliceReducer from "./accountGroupUserSlice";
import avatarSliceReducer from "./avatarSlice";
import authLogInSliceReducer from "./authLogInSlice";
import bankAccountSliceReducer from "./bankAccountSlice";
import itemForEditSliceReducer from "./itemForEditSlice";
import companySliceReducer from "./companySlice";
import contactSliceReducer from "./contactSlice";
import contractTermSliceReducer from "./contractTermSlice";
import countrySliceReducer from "./countrySlice";
import currencySliceReducer from "./currencySlice";
import exRatesSliceReducer from "./exRatesSlice";
import formTouchedReducer from "./formTouchedSlice";
import invSliceReducer from "./invSlice";
import itemEmailSliceReducer from "./itemEmailSlice";
import itemForItemInvSliceReducer from "./itemForItemInvSlice";
import itemForEditBasicSliceReducer from "./itemForEditBasicSlice";
import loadingStatusSliceReducer from "./loadingStatusSlice";
import noteTermSliceReducer from "./noteTermSlice";
import notificationSliceReducer from "./notificationSlice";
import personSliceReducer from "./personSlice";
import popupSliceReducer from "./popupSlice";
import popupBasicSliceReducer from "./popupBasicSlice";
import popupNotificationSliceReducer from "./popupNotificationSlice";
import popupOpenSliceReducer from "./popupOpenSlice";
import popupSimpleSliceReducer from "./popupSimpleSlice";
import popupTopSliceReducer from "./popupTopSlice";
import quoteSliceReducer from "./quoteSlice";
import searchInvSliceReducer from "./searchInvSlice";
import searchQuoteSliceReducer from "./searchQuoteSlice";
import statusSliceReducer from "./statusSlice";
import websocketReducer from "./websocketSlice";

export default configureStore({
  reducer: {
    accountSlice: accountSliceReducer,
    accountGroupUserSlice: accountGroupUserSliceReducer,
    authLogInSlice: authLogInSliceReducer,
    avatarSlice: avatarSliceReducer,
    bankAccountSlice: bankAccountSliceReducer,
    companySlice: companySliceReducer,
    contactSlice: contactSliceReducer,
    contractTermSlice: contractTermSliceReducer,
    countrySlice: countrySliceReducer,
    currencySlice: currencySliceReducer,
    formTouchedSlice: formTouchedReducer,
    exRatesSlice: exRatesSliceReducer,
    invSlice: invSliceReducer,
    itemEmailSlice: itemEmailSliceReducer,
    itemForEditSlice: itemForEditSliceReducer,
    itemForEditBasicSlice: itemForEditBasicSliceReducer,
    itemForItemInvSlice: itemForItemInvSliceReducer,
    loadingStatusSlice: loadingStatusSliceReducer,
    noteTermSlice: noteTermSliceReducer,
    notificationSlice: notificationSliceReducer,
    personSlice: personSliceReducer,
    popupBasicSlice: popupBasicSliceReducer,
    popupOpenSlice: popupOpenSliceReducer,
    popupSimpleSlice: popupSimpleSliceReducer,
    popupTopSlice: popupTopSliceReducer,
    popupNotificationSlice: popupNotificationSliceReducer,
    popupSlice: popupSliceReducer,
    quoteSlice: quoteSliceReducer,
    searchInvSlice: searchInvSliceReducer,
    searchQuoteSlice: searchQuoteSliceReducer,
    statusSlice: statusSliceReducer,
    websocketSlice: websocketReducer,
  },
});
