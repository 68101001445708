import React from "react";
import i18next from "i18next";
import { useLocation } from "react-router-dom";
////// Mui
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { alpha, InputBase } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
////// Controls & Components
import { langs } from "../../Data/generalData";

const InputBaseStyled = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    borderColor: alpha(
      theme.palette.error.dark,
      theme.palette.action.activatedOpacity
    ),
    fontWeight: 500,
    fontSize: 18,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: alpha(
        theme.palette.error.dark,
        theme.palette.action.activatedOpacity
      ),
      fontWeight: "500",
    },
  },
}));

const SelectLang = () => {
  const { pathname } = useLocation();
  const [lang, setLang] = React.useState(
    localStorage.getItem("i18nextLng" || "ro")
  );
  const currentPath = pathname;

  const handleChange = (e) => {
    setLang(e.target.value);
    i18next.changeLanguage(e.target.value);
  };

  return (
    <Box>
      <FormControl
        fullWidth
        sx={{
          border: currentPath === "/apps/settings" ? 1 : 0,
          borderRadius: 1,
          backgroundColor: currentPath === "/apps/settings" ? "#f5f5f5" : false,
          color: "rgba(118, 118, 118, 0.3)",
        }}
      >
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          input={
            <InputBaseStyled
              style={{
                color: currentPath !== "/apps/settings" ? "#FFF" : "black",
                "& .MuiBoxRoot": { padingRight: 0 },
              }}
            />
          }
          value={lang}
          onChange={handleChange}
          variant="standard"
        >
          {langs.map((option, i) => {
            return (
              <MenuItem key={i} value={option.code}>
                {option.lang}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectLang;
