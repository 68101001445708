import React from "react";
////// Mui
import { styled } from "@mui/material/styles";
import { FormHelperText, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#f5f5f5" : "#f5f5f5",
    fontSize: 16,
    fontWeight: 500,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
}));

const TextFieldRegular = ({ name, required, label, ...otherProps }) => {
  const inputLabelprops = {
    shrink: true,
    sx: {
      marginBottom: -1,
      fontSize: 18,
      fontWeight: 700,
    },
  };

  const configTextField = {
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
    size: "small",
    sx: { mb: 3 },
  };

  const configHelperText = {
    sx: {
      color: "red",
      fontSize: 14,
      fullWidth: true,
    },
  };

  let helperText = "";

  return (
    <React.Fragment>
      <InputLabel {...inputLabelprops}>{label}</InputLabel>
      <TextFieldStyled name={name} {...configTextField} />
      <FormHelperText {...configHelperText}>{helperText}</FormHelperText>
    </React.Fragment>
  );
};

export default TextFieldRegular;
