import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../Fetch/axios";
import { invURL } from "../Data/baseURL";

export const getInvs = createAsyncThunk("invSlice/getInvs", async () => {
  const response = await axiosInstance.get(invURL);
  return response.data;
});

export const invSlice = createSlice({
  name: "invSlice",
  initialState: {
    inv: {},
    invs: [],
    statusInvs: null,
    changedTime: null,
  },
  reducers: {
    setInvs: (state, action) => {
      return { ...state, invs: [...action.payload] };
    },
    setInv: (state, action) => {
      return { ...state, inv: { ...action.payload } };
    },
    setStatus: (state, action) => {
      state.statusInvs = action.payload;
      return state;
    },
    addOrEditInv: (state, action) => {
      const i = state.invs.findIndex((inv) => inv.id === action.payload.id);
      if (i > -1) state.invs[i] = action.payload;
      else state.invs.unshift(action.payload);
      return state;
    },
    deleteInv: (state, action) => {
      const invs = state.invs.filter((inv) => inv.id !== action.payload);
      return { ...state, invs: [...invs] };
    },
  },
  extraReducers: {
    [getInvs.pending]: (state) => {
      state.statusInvs = "loading";
    },
    [getInvs.fulfilled]: (state, action) => {
      state.invs = action.payload;
      state.statusInvs = "loaded";
    },
    [getInvs.rejected]: (state) => {
      state.statusInvs = "failed";
    },
  },
});

export const { setInvs, setInv, setStatus, addOrEditInv, deleteInv } =
  invSlice.actions;

export default invSlice.reducer;
