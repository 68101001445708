import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

const ButtonLoading = ({ children, loading, endIcon, ...otherProps }) => {
  const configButton = {
    loading: loading,
    endIcon: endIcon,
    sx: { mt: 1, mr: 1, mb: 1, ...otherProps.sx },
    ...otherProps,
  };

  return <LoadingButton {...configButton}>{children}</LoadingButton>;
};

export default ButtonLoading;
