export const notFoundImage =
  "https://cargomd.s3.eu-central-1.amazonaws.com/inv-frontend/404error.svg";

export const ContDePlata_Logo =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/ContDePlata_Logo.png";

export const ContDePlataLogo_onwhite1 =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/ContDePlataLogo_onwhite1.svg";

export const mainImage =
  "https://cargomd.s3.eu-central-1.amazonaws.com/inv-frontend/MainImage.svg";

export const logistics =
  "https://cargomd.s3.eu-central-1.amazonaws.com/inv-frontend/Logistics.jpg";

export const euFlag =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/eu.svg";

export const mdFlag =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/md.svg";

export const roFlag =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/ro.svg";

export const ruFlag =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/ru.svg";

export const uaFlag =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/ua.svg";

export const usFlag =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/us.svg";

export const createIcon_1 =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/CreateIcon_1.svg";

export const aboutUsAnimate =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/aboutUs_animate.svg";

export const element =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/Element.svg";

export const apple =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/apple.svg";

export const android =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/android.svg";

export const windows =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/windows.svg";

export const background =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/background.svg";

export const background2 =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/background2.svg";

export const export1c =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/Export1C.svg";

export const heroDecor =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/HeroDecor.svg";

export const heroImage3 =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/HeroImage3.svg";

export const component1 =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/component1.svg";

export const printInvoice =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/PrintInvoice.svg";

export const securityImage =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/SecurityImage.svg";

export const workTogether =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/WorkTogether.svg";

export const quote_up =
  "https://cargomd.s3.eu-central-1.amazonaws.com/gen-frontend/quote_up.svg";
