import React from "react";
import { useFormikContext, useField } from "formik";
////// Mui
import { styled } from "@mui/material/styles";
import { FormHelperText, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
// import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const DesktopDatePickerStyled = styled(DesktopDatePicker)(({ theme }) => ({
  "& .MuiInputAdorment-root": {
    backgroundColor: "#f5f5f5",
  },
}));

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#f5f5f5" : "#f5f5f5",
    fontSize: 16,
    fontWeight: 500,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    cursor: "pointer",
  },
}));

const DesktopDatePickerBasic = ({ name, required, label, ...otherProps }) => {
  const [field, meta] = useField(String(name));
  const { setFieldValue } = useFormikContext();
  const [value, setValue] = React.useState(field.value);

  function dateIsValid(date) {
    return date instanceof Date && !isNaN(date);
  }

  const handleChange = (newValue) => {
    setValue(newValue);
    setFieldValue(
      field.name,
      dateIsValid(newValue)
        ? new Date(new Date(newValue).toString().split("GMT")[0] + " UTC")
            .toISOString()
            .split("T")[0]
        : ""
    );
  };

  const inputLabelprops = {
    shrink: true,
    sx: {
      marginBottom: -1,
      fontSize: 18,
      fontWeight: 700,
    },
  };

  const configTextField = {
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
    size: "small",
  };

  const configHelperText = {
    sx: {
      color: "red",
      fontSize: 14,
      fullWidth: true,
    },
  };

  let helperText = null;

  const helperTextShow = !field.name.startsWith("iteminv_invs");

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    helperText = meta.error;
  }

  return (
    <React.Fragment>
      <InputLabel {...inputLabelprops}>{label}</InputLabel>
      <DesktopDatePickerStyled
        inputFormat='dd/MM/yyyy'
        onChange={handleChange}
        value={value}
        renderInput={(params) => (
          <TextFieldStyled name={name} {...configTextField} {...params} />
        )}
      />
      <FormHelperText error {...configHelperText}>
        {helperTextShow && helperText}
      </FormHelperText>
    </React.Fragment>
  );
};

export default DesktopDatePickerBasic;
