import React from "react";
import { useTranslation } from "react-i18next";
////// Mui
import { Stack, useTheme, IconButton } from "@mui/material";
import { OutlinedInput, useMediaQuery } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
////// Controls & Components
import ButtonAction from "../Buttons/ButtonAction";

const QuickSearchInput = ({
  quickSearchInput,
  handleQuickSearch,
  clearQuickSearch,
  handleRefresh,
  refresh,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smMediaDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <React.Fragment>
      <Stack direction='row' spacing={0} justifyContent='end'>
        <FormControl
          variant='outlined'
          size='small'
          sx={{ height: 36 }}
          fullWidth={smMediaDown ? true : false}
        >
          <OutlinedInput
            id='standard-adornment'
            type='text'
            placeholder={t("general.quick_search")}
            value={quickSearchInput.target}
            onChange={handleQuickSearch}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton aria-label='clear' onClick={clearQuickSearch}>
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <ButtonAction
          color='primary'
          size='large'
          onClick={() => handleRefresh()}
          disabled={refresh}
        >
          <RefreshIcon />
        </ButtonAction>
      </Stack>
    </React.Fragment>
  );
};

export default QuickSearchInput;
