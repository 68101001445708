import React, { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
////// Mui
import { styled } from "@mui/material/styles";
import { useTheme, List } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import FlagIcon from "@mui/icons-material/Flag";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import GavelIcon from "@mui/icons-material/Gavel";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PaidIcon from "@mui/icons-material/Paid";
import QuizIcon from "@mui/icons-material/Quiz";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
////// Controls & Components
import { appURL } from "../Data/baseURL";
import InvIcon from "../Data/BasicIcons/InvIcon";
import QuoteIcon from "../Data/Icons/QuoteIcon";
import TooltipBasic from "../Controls/Inputs/TooltipBasic";

const ListItemButtonStyled = styled(ListItemButton)(() => ({
  color: "rgba(255,255,255,.6)",

  "&.MuiListItemButton-root": {
    paddingTop: 10,
    paddingBottom: 10,
  },
  "& svg": {
    color: "rgba(255,255,255,0.8)",
    transition: "0.2s",
    transform: "translateX(0) rotate(0)",
  },
  "&:hover, &:focus": {
    bgcolor: "unset",
    "& svg:first-of-type": {
      transform: "translateX(-4px) rotate(-20deg)",
    },
    "& svg:last-of-type": {
      right: 0,
      opacity: 1,
    },
  },
  "&.Mui-selected": {
    color: "white",
    bgcolor: "green",
    "& svg:first-of-type": {
      color: "white",
    },
  },
  "&:after": {
    content: '""',
    position: "absolute",
    height: "80%",
    display: "block",
    left: 0,
    width: "1px",
    bgcolor: "divider",
  },
}));

const ListItemTextStyled = styled(ListItemText)(() => ({
  ".MuiListItemText-primary": { fontWeight: "500" },
}));

const ListItemIconStyled = styled(ListItemIcon)(() => ({
  color: "inherit",
}));

const HeaderDrawerList = (props) => {
  const { authLogIn, open, handleDrawerClose } = props;
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const theme = useTheme();
  const lgMediaDown = useMediaQuery(theme.breakpoints.down("lg"));
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (_, index) => {
    setSelectedIndex(index);
  };

  ////// Drawer selected item change
  useEffect(() => {
    if (
      pathname === `${appURL}` ||
      pathname === `${appURL}/account` ||
      pathname === `${appURL}/company`
    ) {
      setSelectedIndex(0);
    }
    if (pathname.startsWith(`${appURL}/quotes`)) {
      setSelectedIndex(1);
    }
    if (pathname.startsWith(`${appURL}/invoices`)) {
      setSelectedIndex(2);
    }
    if (pathname.startsWith(`${appURL}/clients`)) {
      setSelectedIndex(3);
    }
    if (pathname.startsWith(`${appURL}/items-for-item-inv`)) {
      setSelectedIndex(5);
    }
    if (pathname.startsWith(`${appURL}/terms-of-payment`)) {
      setSelectedIndex(6);
    }
    if (pathname === `${appURL}/terms`) {
      setSelectedIndex(7);
    }
    if (pathname.startsWith(`${appURL}/countries`)) {
      setSelectedIndex(8);
    }
    if (pathname.startsWith(`${appURL}/currencies`)) {
      setSelectedIndex(9);
    }
    if (pathname.startsWith(`${appURL}/faq`)) {
      setSelectedIndex(10);
    }
    if (pathname.startsWith(`${appURL}/support`)) {
      setSelectedIndex(11);
    }
    if (pathname === `${appURL}/signup`) {
      setSelectedIndex(20);
    }
    if (pathname === `${appURL}/login`) {
      setSelectedIndex(21);
    }
  }, [pathname]);

  return (
    <>
      {!authLogIn && lgMediaDown && (
        <List disablePadding>
          <ListItemButtonStyled
            selected={selectedIndex === 0}
            onClick={(event) => [
              handleListItemClick(event, 0),
              handleDrawerClose(),
            ]}
            component={Link}
            to='/'
            sx={{ py: 2 }}
          >
            <ListItemIconStyled>
              <HomeIcon />
            </ListItemIconStyled>
            <ListItemTextStyled
              primary={t("general.main_page")}
              primaryTypographyProps={{ fontWeight: "800" }}
            />
          </ListItemButtonStyled>

          <ListItemButtonStyled
            selected={selectedIndex === 20}
            onClick={(event) => [
              handleListItemClick(event, 20),
              handleDrawerClose(),
            ]}
            component={Link}
            to={`${appURL}/signup`}
            sx={{ py: 2 }}
          >
            <ListItemIconStyled>
              <HowToRegIcon />
            </ListItemIconStyled>
            <ListItemTextStyled primary={t("button.signup_for_free")} />
          </ListItemButtonStyled>

          <ListItemButtonStyled
            selected={selectedIndex === 21}
            onClick={(event) => [
              handleListItemClick(event, 21),
              handleDrawerClose(),
            ]}
            component={Link}
            to={`${appURL}/login`}
            sx={{ py: 2 }}
          >
            <ListItemIconStyled>
              <LockOpenIcon />
            </ListItemIconStyled>
            <ListItemTextStyled primary={t("general.login")} />
          </ListItemButtonStyled>
        </List>
      )}

      {authLogIn && pathname !== "/" && (
        <List disablePadding>
          <TooltipBasic
            title={t("dashboard.dashboard")}
            placement='right-start'
            disableHoverListener={open ? true : false}
          >
            <ListItemButtonStyled
              selected={selectedIndex === 0}
              component={Link}
              to={`${appURL}`}
              divider
              sx={{
                "&.MuiListItemButton-divider": {
                  borderColor: "black",
                },
              }}
              onClick={(event) => [
                handleListItemClick(event, 0),
                lgMediaDown && handleDrawerClose(),
              ]}
            >
              <ListItemIconStyled>
                <HomeIcon />
              </ListItemIconStyled>
              <ListItemTextStyled
                primary={t("dashboard.dashboard")}
                primaryTypographyProps={{
                  fontWeight: "800",
                }}
              />
            </ListItemButtonStyled>
          </TooltipBasic>

          <TooltipBasic
            title={t("general.quotes")}
            placement='right-start'
            disableHoverListener={open ? true : false}
          >
            <ListItemButtonStyled
              selected={selectedIndex === 1}
              onClick={(event) => [
                handleListItemClick(event, 1),
                lgMediaDown && handleDrawerClose(),
              ]}
              component={Link}
              to={`${appURL}/quotes`}
            >
              <ListItemIconStyled>
                <QuoteIcon />
              </ListItemIconStyled>
              <ListItemTextStyled primary={t("general.quotes_short")} />
            </ListItemButtonStyled>
          </TooltipBasic>

          <TooltipBasic
            title={t("general.invoices")}
            placement='right-start'
            disableHoverListener={open ? true : false}
          >
            <ListItemButtonStyled
              selected={selectedIndex === 2}
              component={Link}
              to={`${appURL}/invoices`}
              divider
              sx={{
                "&.MuiListItemButton-divider": {
                  borderColor: "black",
                },
              }}
              onClick={(event) => [
                handleListItemClick(event, 2),
                lgMediaDown && handleDrawerClose(),
              ]}
            >
              <ListItemIconStyled>
                <InvIcon />
              </ListItemIconStyled>
              <ListItemTextStyled primary={t("general.invoices")} />
            </ListItemButtonStyled>
          </TooltipBasic>

          <TooltipBasic
            title={t("general.clients")}
            placement='right-start'
            disableHoverListener={open ? true : false}
          >
            <ListItemButtonStyled
              selected={selectedIndex === 3}
              onClick={(event) => [
                handleListItemClick(event, 3),
                lgMediaDown && handleDrawerClose(),
              ]}
              component={Link}
              to={`${appURL}/clients`}
            >
              <ListItemIconStyled>
                <PeopleAltIcon />
              </ListItemIconStyled>
              <ListItemTextStyled primary={t("general.clients")} />
            </ListItemButtonStyled>
          </TooltipBasic>

          <TooltipBasic
            title={t("general.bank_accounts_drawer")}
            placement='right-start'
            disableHoverListener={open ? true : false}
          >
            <ListItemButtonStyled
              selected={selectedIndex === 4}
              onClick={(event) => [
                handleListItemClick(event, 4),
                lgMediaDown && handleDrawerClose(),
              ]}
              component={Link}
              to={`${appURL}/bank-accounts`}
            >
              <ListItemIconStyled>
                <EuroSymbolIcon />
              </ListItemIconStyled>
              <ListItemTextStyled primary={t("general.bank_accounts_drawer")} />
            </ListItemButtonStyled>
          </TooltipBasic>

          <TooltipBasic
            title={t("general.invoice_items")}
            placement='right-start'
            disableHoverListener={open ? true : false}
          >
            <ListItemButtonStyled
              selected={selectedIndex === 5}
              onClick={(event) => [
                handleListItemClick(event, 5),
                lgMediaDown && handleDrawerClose(),
              ]}
              component={Link}
              to='/apps/items-for-item-inv'
            >
              <ListItemIconStyled>
                <PriceCheckIcon />
              </ListItemIconStyled>
              <ListItemTextStyled primary={t("general.invoice_items")} />
            </ListItemButtonStyled>
          </TooltipBasic>

          <TooltipBasic
            title={t("general.terms_of_payment")}
            placement='right-start'
            disableHoverListener={open ? true : false}
          >
            <ListItemButtonStyled
              selected={selectedIndex === 6}
              onClick={(event) => [
                handleListItemClick(event, 6),
                lgMediaDown && handleDrawerClose(),
              ]}
              component={Link}
              to='/apps/terms-of-payment'
            >
              <ListItemIconStyled>
                <CreditCardIcon />
              </ListItemIconStyled>
              <ListItemTextStyled primary={t("general.terms_of_payment")} />
            </ListItemButtonStyled>
          </TooltipBasic>

          <TooltipBasic
            title={t("general.invoice_terms")}
            placement='right-start'
            disableHoverListener={open ? true : false}
          >
            <ListItemButtonStyled
              selected={selectedIndex === 7}
              component={Link}
              to='/apps/terms'
              divider
              sx={{
                "&.MuiListItemButton-divider": {
                  borderColor: "black",
                },
              }}
              onClick={(event) => [
                handleListItemClick(event, 7),
                lgMediaDown && handleDrawerClose(),
              ]}
            >
              <ListItemIconStyled>
                <GavelIcon />
              </ListItemIconStyled>
              <ListItemTextStyled primary={t("general.invoice_terms")} />
            </ListItemButtonStyled>
          </TooltipBasic>

          <TooltipBasic
            title={t("general.countries")}
            placement='right-start'
            disableHoverListener={open ? true : false}
          >
            <ListItemButtonStyled
              selected={selectedIndex === 8}
              onClick={(event) => [
                handleListItemClick(event, 8),
                lgMediaDown && handleDrawerClose(),
              ]}
              component={Link}
              to='/apps/countries'
            >
              <ListItemIconStyled>
                <FlagIcon />
              </ListItemIconStyled>
              <ListItemTextStyled primary={t("general.countries")} />
            </ListItemButtonStyled>
          </TooltipBasic>

          <TooltipBasic
            title={t("general.currencies")}
            placement='right-start'
            disableHoverListener={open ? true : false}
          >
            <ListItemButtonStyled
              selected={selectedIndex === 9}
              onClick={(event) => [
                handleListItemClick(event, 9),
                lgMediaDown && handleDrawerClose(),
              ]}
              component={Link}
              to='/apps/currencies'
            >
              <ListItemIconStyled>
                <PaidIcon />
              </ListItemIconStyled>
              <ListItemTextStyled primary={t("general.currencies")} />
            </ListItemButtonStyled>
          </TooltipBasic>

          {/*Questions and answers  */}
          <TooltipBasic
            title={t("faq.frequent_questions")}
            placement='right-start'
            disableHoverListener={open ? true : false}
          >
            <ListItemButtonStyled
              selected={selectedIndex === 10}
              onClick={(event) => [
                handleListItemClick(event, 10),
                lgMediaDown && handleDrawerClose(),
              ]}
              component={Link}
              to={`${appURL}/faq`}
              sx={{ height: "64px" }}
            >
              <ListItemIconStyled sx={{ alignSelf: "start", mt: 1 }}>
                <QuizIcon />
              </ListItemIconStyled>
              <ListItemTextStyled
                sx={{
                  display: "inline-block",
                  whiteSpace: "pre-line",
                }}
                primary={t("faq.frequent_questions")}
              />
            </ListItemButtonStyled>
          </TooltipBasic>

          {/*Support*/}
          <TooltipBasic
            title={t("general.support")}
            placement='right-start'
            disableHoverListener={open ? true : false}
          >
            <ListItemButtonStyled
              selected={selectedIndex === 11}
              onClick={(event) => [
                handleListItemClick(event, 11),
                lgMediaDown && handleDrawerClose(),
              ]}
              component={Link}
              to={`${appURL}/support`}
              sx={{ height: "64px" }}
            >
              <ListItemIconStyled sx={{ alignSelf: "start", mt: 1 }}>
                <SupportAgentIcon />
              </ListItemIconStyled>
              <ListItemTextStyled
                sx={{
                  display: "inline-block",
                  whiteSpace: "pre-line",
                }}
                primary={t("general.support")}
              />
            </ListItemButtonStyled>
          </TooltipBasic>
        </List>
      )}
    </>
  );
};

export default HeaderDrawerList;
