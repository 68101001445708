import React, { useState, useRef, memo } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
////// Mui
import { LinearProgress, Paper } from "@mui/material";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { Box, Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
////// Store
import { setPopupQuote } from "../../Store/popupSlice";
import { setFormTouchedQuote } from "../../Store/formTouchedSlice";
////// Controls & Components
import ButtonAction from "../Buttons/ButtonAction";
import ConfirmTouched from "./ConfirmTouched";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-popupquote"
      cancel={'[class*="popupquote-cancel"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const PopupQuote = memo((props) => {
  const { title, children } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const lgMediaDown = useMediaQuery(theme.breakpoints.down("lg"));
  const { loadingStatus } = useSelector((state) => state.loadingStatusSlice);
  const { formTouchedQuote } = useSelector((state) => state.formTouchedSlice);
  const [confirmTouched, setConfirmTouched] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    type: "",
  });
  const { popupQuote } = useSelector((state) => state.popupSlice);

  const invCompRef = useRef();

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ref: invCompRef });
    }
    return child;
  });

  function handleEscClose() {
    if (!formTouchedQuote) {
      confirmEscClose();
    } else {
      setConfirmTouched({
        isOpen: true,
        title: t("popup.you_have_unsaved_changes"),
        onBackToPage: () => [
          setConfirmTouched({ ...confirmTouched, isOpen: false }),
        ],
        onSave: () => [
          invCompRef.current.handleSubmit(),
          setConfirmTouched({ ...confirmTouched, isOpen: false }),
          dispatch(setFormTouchedQuote(false)),
        ],
        onConfirm: () => [
          confirmEscClose(),
          setConfirmTouched({ ...confirmTouched, isOpen: false }),
          dispatch(setFormTouchedQuote(false)),
        ],
      });
    }
  }

  function confirmEscClose() {
    dispatch(setPopupQuote(false));
  }

  return (
    <>
      <Dialog
        keepMounted
        open={popupQuote}
        maxWidth="xl"
        fullWidth={false}
        disableEnforceFocus // property for CreateMenu not to exceed Maximum call stack
        sx={{
          "& .MuiDialog-container": {
            justifyContent: "flex-center",
            alignItems: "flex-start",
          },
        }}
        PaperProps={{
          sx: {
            mt: 2,
            m: lgMediaDown ? 0.75 : undefined,
          },
        }}
        onClose={handleEscClose}
        PaperComponent={PaperComponent}
        BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.1)" } }}
      >
        <DialogTitle sx={{ bgcolor: "#ffd827" }}>
          <Grid container item xs={12}>
            <Grid
              container
              item
              xs={10}
              style={{ cursor: "move" }}
              id="draggable-popupquote"
            >
              <Grid item xs={12}>
                <Typography variant="h6">{title}</Typography>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={2}
              justifyContent={"flex-end"}
              id="popupquote-cancel"
            >
              <ButtonAction color="secondary" onClick={() => handleEscClose()}>
                {t("button.esc")}
              </ButtonAction>
            </Grid>
          </Grid>
        </DialogTitle>

        {/* LiniarProgress */}
        <Box sx={{ width: "100%" }}>
          {loadingStatus === true ? (
            <LinearProgress />
          ) : (
            <LinearProgress variant="determinate" value={100} />
          )}
        </Box>

        <DialogContent>{childrenWithProps}</DialogContent>
      </Dialog>

      <ConfirmTouched
        confirmTouched={confirmTouched}
        setConfirmTouched={setConfirmTouched}
      />
    </>
  );
});

export default PopupQuote;
