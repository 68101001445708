import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../Fetch/axios";
import { toast } from "react-toastify";
import i18n from "i18next";
import { contractTermURL } from "../Data/baseURL";

export const getItems = createAsyncThunk(
  "contractTermSlice/getItems",
  async (route) => {
    return axiosInstance
      .get(contractTermURL)
      .then((response) => {
        if (response.data) {
          route !== "/" &&
            toast.success(i18n.t("toast.contract_terms_list_updated"));
          return response.data;
        }
      })
      .catch(() => {});
  }
);

export const contractTermSlice = createSlice({
  name: "contractTermSlice",
  initialState: {
    contractTerm: {},
    contractTerms: [],
    statusContractTerms: null,
  },
  reducers: {
    setItems: (state, action) => {
      return { ...state, noteTerms: [...action.payload] };
    },
    setNoteTerm: (state, action) => {
      return { ...state, noteTerm: { ...action.payload } };
    },
    addOrEditItem: (state, { payload: values }) => {
      const i = state.contractTerms.findIndex((item) => item.id === values.id);
      if (i > -1) state.contractTerms[i] = values;
      else state.contractTerms.unshift(values);
      return state;
    },
    deleteContractTerm: (state, { payload }) => {
      const newItems = state.contractTerms.filter(
        (item) => item.id !== payload
      );
      return { ...state, contractTerms: [...newItems] };
    },
  },
  extraReducers: {
    [getItems.pending]: (state) => {
      state.statusContractTerms = "loading";
    },
    [getItems.fulfilled]: (state, action) => {
      Array.isArray(action.payload) === true &&
        (state.contractTerms = action.payload);
      state.statusContractTerms = "loaded";
    },
    [getItems.rejected]: (state) => {
      state.statusContractTerms = "failed";
    },
  },
});

export const { setItems, setNoteTerm, addOrEditItem, deleteContractTerm } =
  contractTermSlice.actions;

export default contractTermSlice.reducer;
